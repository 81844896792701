import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { StyledButton } from './styles/Modal.styles';

const CommentModal = ({ 
    title, 
    placeholder, 
    commentModalOpen, 
    handleModalClose, 
    handleSave, 
    text, 
    setText 
}) => {
  return (
    <>
      <Dialog open={commentModalOpen} onClose={handleModalClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <textarea 
            value={text} 
            onChange={(e) => setText(e.target.value)} 
            rows={6} 
            cols={50} 
            style={{
              width: '100%',
              resize: 'none', 
              borderRadius: 10, 
              padding: '10px' 
            }}
            placeholder={placeholder}
          />
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleModalClose} color="primary">
            Voltar
          </StyledButton>
          <StyledButton onClick={handleSave} color="primary">
            Salvar
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CommentModal;