/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withFormik, Field } from 'formik'
import { Form } from 'antd'
// import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { StyledLoginForm, Title, A } from './styles/Form.styles'
import SubmitButton from '../button/SubmitButton'
import EmailInput from '../input/EmailInput'
import PasswordInput from '../input/PasswordInput'
import { studentLogin } from '../../store/auth/actions'
import history from '../../routes/history'

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    studentLogin
  }, dispatch)

const handleSubmit = (values, formikBag) => {
  values.grant_type = 'password'
  values.username = values.username.includes("@") ? values.username : values.username + "@edu.nh.rs.gov.br"
  formikBag.props.studentLogin(values)
  const query = new URLSearchParams(window.location.search)
  const newId = query.get('examId')
  if (newId) {
    history.push('/student/exam?examId=' + newId)
  }
}

const validationSchema = props => {
  return yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required()
  })
}

const handleForgotPassword = delegate => e => {
  e.preventDefault()
  delegate()
}

const StudentLoginForm = ({
  loading,
  isValid,
  onForgotPassword
}) => {
  return (
    <div>
      <StyledLoginForm>
        <div className={'col-sm-12'}>
          <Field
            name='username'
            style={{ width: '100%', marginTop: '15px' }}
            component={EmailInput}
          />
        </div>
        <div className={'col-sm-12'}>
          <Field
            name='password'
            component={PasswordInput}
          />
        </div>
        <div className={'col-sm-12'}>
          <Form.Item>
            <SubmitButton
              disabled={!isValid}
              loading={loading}
            >
            Entrar
            </SubmitButton>
            {/* <Link to='/register'>Registrar</Link> */}
            <A
              href='/'
              onClick={handleForgotPassword(onForgotPassword)}
              style={{ float: 'right' }}
            >
            Esqueci a senha
            </A>
          </Form.Item>
        </div>
      </StyledLoginForm>
    </div>
  )
}

StudentLoginForm.propTypes = {
  loading: PropTypes.bool,
  isValid: PropTypes.bool,
  onForgotPassword: PropTypes.func
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit,
    validationSchema
  })
)

export default enhance(StudentLoginForm)
