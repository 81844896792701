/* eslint-disable */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import history from '../../routes/history'
import * as yup from 'yup'
import { registerStudent } from '../../store/auth/actions'
import { withFormik, Field } from 'formik'

import { Form, Spin } from 'antd'
import EmailInput from '../input/EmailInput'
import TextInput from '../input/TextInput'
import SubmitButton from '../button/SubmitButton'
import { GroupLabel, StyledRegisterForm } from './styles/Form.styles'
import PasswordInput from '../input/PasswordInput'

const validationSchema = props => {
  return yup.object().shape({
    Name: yup.string().required(),
    Email: yup.string().email().required(),
    Password: yup.string().min(6).required(),
    PasswordConfirmation: yup.string().min(6).equals(yup.ref('Password'), 'Senhas não conferem').required()
  })
}
const StudentForm = ({
  isValid,
  loading,
  register,
  validation,
  company,
  values,
  match
}) => {
  useEffect(() => {
    console.log('company', company)
    values.CompanyId = company.Id
  }, [values, company])

  return (
    <StyledRegisterForm>
      { company && company.Id !== null && company.Id !== undefined &&
      <Spin spinning={loading}>
        <GroupLabel>Informações Básicas</GroupLabel>
        <Field
          name='Name'
          label='Nome'
          component={TextInput}
          icon='user'
        />
        <Field
          name='Email'
          label='Email'
          component={EmailInput}
        />
        <GroupLabel>Segurança</GroupLabel>
        <Field
          name='Password'
          label='Senha'
          component={PasswordInput}
        />
        <Field
          name='PasswordConfirmation'
          label='Confirmação de Senha'
          component={PasswordInput}
        />
        <Form.Item>
          <SubmitButton
            disabled={!isValid}
            loading={loading}
          >
                Registrar
          </SubmitButton>
        </Form.Item>
      </Spin>
      }
      {
        ((company === undefined || company === null) || (company.Id === null && false)) &&
        <span>Cadastro Indisponível</span>
      }
    </StyledRegisterForm>

  )
}

StudentForm.propTypes = {
  isValid: PropTypes.bool,
  loading: PropTypes.bool,
  validation: PropTypes.object,
  match: PropTypes.object,
  values: PropTypes.object,
  company: PropTypes.object,
  register: PropTypes.func
}

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  validation: auth.validation,
  form: auth.form
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    registerStudent
  }, dispatch)

const handleSubmit = (values, formikBag) => {
  values.Profile = 'Student'
  formikBag.props.registerStudent(values)
  history.push('/login')
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit,
    validationSchema,
    enableReinitialize: true
  })
)

export default enhance(StudentForm)
