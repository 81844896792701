import createReducer from '../createReducer'
import {
  READ_GRADE,
  READ_GRADE_SUCCESS,
  READ_GRADE_FAILURE,
  UPDATE_GRADE,
  UPDATE_GRADE_SUCCESS,
  UPDATE_GRADE_FAILURE,
} from './constants'

const initialState = {
  grade: {},
  request: undefined,
  loading: false,
  error: {}
}

export default createReducer(initialState, {
  [READ_GRADE]: (state, action) => ({
    ...state,
    request: action.payload,
    loading: true,
    error: initialState.error
  }),
  [READ_GRADE_SUCCESS]: (state, action) => ({
    ...state,
    grade: action.payload,
    loading: false,
    error: initialState.error
  }),
  [READ_GRADE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [UPDATE_GRADE]: (state, action) => ({
    ...state,
    request: action.payload,
    loading: true,
    error: initialState.error
  }),
  [UPDATE_GRADE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [UPDATE_GRADE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
})
