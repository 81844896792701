import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

// Reducers
import authReducers from "./auth/reducers";
import documentsReducers from "./documents/reducers";
import revisionsReducers from "./revisions/reducers";
import templatesReducers from "./templates/reducers";
import outputsReducers from "./outputs/reducers";
import filesReducers from "./files/reducers";
import feedbacksReducers from "./feedbacks/reducers";
import organizationsReducers from "./organizations/reducers";
import examsReducers from "./exams/reducers";
import questionsReducers from "./questions/reducers";
import companiesReducers from "./companies/reducers";
import usersReducers from "./users/reducers";
import gradesReducers from "./grades/reducers";

// Sagas
import authSagas from "./auth/sagas";
import documentsSagas from "./documents/sagas";
import templatesSagas from "./templates/sagas";
import outputsSagas from "./outputs/sagas";
import filesSagas from "./files/sagas";
import feedbacksSagas from "./feedbacks/sagas";
import organizationsSagas from "./organizations/sagas";
import examsSagas from "./exams/sagas";
import questionsSagas from "./questions/sagas";
import companiesSagas from "./companies/sagas";
import usersSagas from "./users/sagas";
import gradesSagas from "./grades/sagas";

// Configure Reducers
export const rootReducer = combineReducers({
  auth: authReducers,
  documents: documentsReducers,
  revisions: revisionsReducers,
  templates: templatesReducers,
  outputs: outputsReducers,
  files: filesReducers,
  grades: gradesReducers,
  feedbacks: feedbacksReducers,
  organizations: organizationsReducers,
  companies: companiesReducers,
  exams: examsReducers,
  questions: questionsReducers,
  users: usersReducers,
});

// Configure Sagas
export function* rootSaga() {
  yield all([
    authSagas(),
    documentsSagas(),
    templatesSagas(),
    outputsSagas(),
    filesSagas(),
    gradesSagas(),
    feedbacksSagas(),
    organizationsSagas(),
    examsSagas(),
    questionsSagas(),
    companiesSagas(),
    usersSagas(),
  ]);
}
