import styled from '@emotion/styled'

export const Content = styled.div`
  width: 976px;
  margin: 0 auto;
`
export const Buttons = styled.div`
  display: flex;
  justify-content:center;
  margin: auto;
  width: 100%;
  margin-top: 10px;
  button {
    margin-left: 25px;
  }
`
