import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { SUPPORTED_FILE_TYPES } from '../../utils/constants'
import { uploadTemplate } from '../../store/templates/actions'

import InputHelpModal from '../modal/InputHelpModal'
import { Button, Upload } from 'antd'

const TemplateUploadInput = ({ uploading, loading, uploadTemplate }) => {
  const [helpType, setHelpType] = useState('')

  const validate = file => {
    const helpType = {
      [file.size > 10485760]: 'heavy',
      [!SUPPORTED_FILE_TYPES.includes(file.type)]: 'default'
    }[true]
    helpType && setHelpType(helpType)
    return !helpType
  }

  const handleChange = ({ file }) => {
    validate(file) && uploadTemplate(file)
  }

  return (
    <Fragment>
      <Upload
        accept={SUPPORTED_FILE_TYPES.join(',')}
        beforeUpload={() => false}
        disabled={uploading || loading}
        onChange={handleChange}
        showUploadList={false}
      >
        <Button
          disabled={uploading || loading}
          icon='upload'
          loading={uploading}
          shape='round'
          size='large'
          type='primary'
        >
          {uploading ? 'Enviando modelo ...' : 'Enviar novo modelo'}
        </Button>
      </Upload>
      <InputHelpModal
        type={helpType}
        onClose={() => setHelpType(null)}
      />
    </Fragment>
  )
}

TemplateUploadInput.propTypes = {
  uploading: PropTypes.bool,
  loading: PropTypes.bool,
  uploadTemplate: PropTypes.func.isRequired
}

const mapStateToProps = ({ templates }) => ({
  uploading: templates.uploading,
  loading: templates.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    uploadTemplate
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TemplateUploadInput)
