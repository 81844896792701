import { all, call, takeLatest, put } from 'redux-saga/effects'
import { normalizeError } from '../../utils/errorHelpers'
import { message } from 'antd'
import * as actions from './actions'
import * as types from './constants'
import * as services from './services'

export function * createCompany ({ payload }) {
  try {
    yield call(services.createCompany, payload)
    message.success('Empresa Criada com sucesso')
    yield put(actions.createCompanySuccess())
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Criar Empresa')
    message.error(errorInfo.message)
    yield put(actions.createCompanyFailure(errorInfo))
  }
}

export function * editCompany ({ payload: { id, company } }) {
  try {
    yield call(services.editCompany, id, company)
    message.success('Empresa Editada com sucesso')
    yield put(actions.editCompanySuccess())
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Editar Empresa')
    message.error(errorInfo.message)
    yield put(actions.editCompanyFailure(errorInfo))
  }
}

export function * fetchCompany ({ payload: id }) {
  try {
    let response = yield call(services.fetchCompany, id)
    console.log('response', response)
    yield put(actions.fetchCompanySuccess(response))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Buscar Empresa')
    message.error(errorInfo.message)
    yield put(actions.fetchCompanyFailure(errorInfo))
  }
}

export function * fetchCompanyBasic ({ payload: id }) {
  try {
    let response = yield call(services.fetchCompanyBasic, id)
    console.log('response', response)
    yield put(actions.fetchCompanyBasicSuccess(response))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Buscar Empresa')
    message.error(errorInfo.message)
    yield put(actions.fetchCompanyBasicFailure(errorInfo))
  }
}

export function * deleteCompany ({ payload: id }) {
  try {
    yield call(services.deleteCompany, id)
    window.location.reload()
    yield put(actions.deleteCompanySuccess())
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Remover Empresa')
    message.error(errorInfo.message)
    yield put(actions.deleteCompanyFailure(errorInfo))
  }
}

export function * fetchCompanies ({ payload: { title, page } }) {
  try {
    let companies = {
      companies: [],
      total: 0
    }
    let response = yield call(services.fetchCompanies, title, page)
    companies.total = response.TotalItens
    companies.companies = response.Itens
    yield put(actions.fetchCompaniesSuccess(companies))
  } catch (error) {
    // const errorInfo = normalizeError(error, 'Falha ao Buscar Empresas')
    // message.error(errorInfo.message)
    // yield put(actions.fetchCompaniesFailure(errorInfo))
  }
}

export function * fetchCompanyGrades ({ payload: { id } }) {
  try {
    console.log(id)
    let response = yield call(services.fetchCompanyGrades, id)
    console.log(response)
    yield put(actions.fetchCompanyGradesSuccess(response.grades))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Buscar Empresas')
    message.error(errorInfo.message)
    yield put(actions.fetchCompanyGradesFailure(errorInfo))
  }
}

export function * watchFetchCompanyGrades () {
  yield takeLatest(types.FETCH_COMPANY_GRADES, fetchCompanyGrades)
}


export function * watchFetchCompanies () {
  yield takeLatest(types.FETCH_COMPANIES, fetchCompanies)
}

export function * watchFetchCompany () {
  yield takeLatest(types.FETCH_COMPANY, fetchCompany)
}

export function * watchFetchCompanyBasic () {
  yield takeLatest(types.FETCH_COMPANY_BASIC, fetchCompanyBasic)
}

export function * watchCreateCompany () {
  yield takeLatest(types.CREATE_COMPANY, createCompany)
}

export function * watchEditCompany () {
  yield takeLatest(types.EDIT_COMPANY, editCompany)
}

export function * watchDeleteCompany () {
  yield takeLatest(types.DELETE_COMPANY, deleteCompany)
}

export default function * () {
  yield all([
    watchCreateCompany(),
    watchEditCompany(),
    watchFetchCompanies(),
    watchFetchCompany(),
    watchFetchCompanyGrades(),
    watchFetchCompanyBasic(),
    watchDeleteCompany()
  ])
}
