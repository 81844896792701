import { act } from "react-dom/test-utils";
import createReducer from "../createReducer";
import * as ctes from "./constants";

function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const marcarAlternativa = (questao, alternativa) => {
  questao.Alternatives = questao.Alternatives.map((a) => {
    a.Correct = a.Order === alternativa.Order ? !a.Correct : false;
    return a;
  });

  return questao;
};

const marcarQuestao = (questao) => {
  questao.Selected = !questao.Selected;
  return questao;
};

const getIndexById = (array, Id) => {
  return array.findIndex((el) => el.Id === Id);
};

const replaceQuestao = (exames, exameId, novaQuestao) => {
  const exame_index = getIndexById(exames, exameId);
  const questao_index = getIndexById(
    exames[exame_index].Questions,
    novaQuestao.Id
  );
  exames[exame_index].Questions[questao_index] = novaQuestao;
  return exames;
};

const replaceQuestoes = (exames, questoes) => {
  const examQuestionMap = {};
  for (const questao of questoes) {
    const exameId = questao.examId;
    if (exameId in examQuestionMap) {
      examQuestionMap[exameId].push(questao.question);
    } else {
      examQuestionMap[exameId] = [questao.question];
    }
  }

  for (const [exameId, questions] of Object.entries(examQuestionMap)) {
    const exame_index = getIndexById(exames, exameId);
    exames[exame_index].Questions = questions;
  }

  return exames;
};

const criarExamsList = (exames) => ({
  exams: exames,
  total: exames.length,
});

const marcarAlternativaDaQuestao = ({
  exames,
  exameId,
  questao,
  alternativa,
}) => {
  const novaQuestao = marcarAlternativa(questao, alternativa);
  const novoExames = replaceQuestao(exames, exameId, novaQuestao);
  return criarExamsList(novoExames);
};

const selectParentContentQuestions = (exams, examId, question) => {
  const examIndex = getIndexById(exams, examId);
  let newExams = exams;

  // selected before update
  if (question.Selected) {
    const questions = question.ParentContent.map((questId) => {
      let questIndex = getIndexById(exams[examIndex].Questions, questId);
      let parentChildren = exams[examIndex].Questions[questIndex].Content;
      exams[examIndex].Questions[questIndex].Selected = false;
      parentChildren.forEach((childId) => {
        if (childId !== question.Id) {
          let childIndex = getIndexById(exams[examIndex].Questions, childId);
          if (exams[examIndex].Questions[childIndex].Selected) exams[examIndex].Questions[questIndex].Selected = true;
        }
      });
      return exams[examIndex].Questions[questIndex];
    });
  } else {
    const questions = question.ParentContent.map((questId) => {
      let questIndex = getIndexById(exams[examIndex].Questions, questId);
      exams[examIndex].Questions[questIndex].Selected = true;
      return exams[examIndex].Questions[questIndex];
    });

    questions.forEach((quest) => {
      newExams = replaceQuestao(newExams, examId, quest)
    });
  }
  return newExams;
};

const checkIfExamHasQuestao = (exames, exameId, questao) => {
  const exame = exames.find(e => e.Id === exameId);
  if (exame === undefined) return false;
  const findedQuestion = exame.Questions.find(q => q.Id === questao.Id);
  if (findedQuestion === undefined) return false;
  return true;
}

const selecionarQuestao = ({ exames, exameId, questao }) => {
  const examHasQuestao = checkIfExamHasQuestao(exames, exameId, questao);
  if (examHasQuestao) {
    if (questao.ParentContent && questao.ParentContent.length > 0) {
      exames = selectParentContentQuestions(exames, exameId, questao);
    }
    const novaQuestao = marcarQuestao(questao);
    exames = replaceQuestao(exames, exameId, novaQuestao);
    return criarExamsList(exames);
  }
  return undefined;
};

const salvarQuestao = ({ exames, exameId, questao }) => {
  const novoExames = replaceQuestao(exames, exameId, questao);
  return criarExamsList(novoExames);
};

const addNewQuestions = (newQuestions, oldQuestions) => {
  const newSize = newQuestions.length;
  const oldSize = oldQuestions.length;
  if (oldSize < newSize) {
    const newSlice = newQuestions.slice(oldSize - 1);
    const oldSlice = oldQuestions.slice(0, oldSize - 1).map((value, index) => {
      if (value.Id && newQuestions[index].Id) {
        value.Id = newQuestions[index].Id;
      }
      return value;
    });

    return oldSlice.concat(newSlice);
  }
  return oldQuestions;
};

const checkIfIsDifferent = ({ exams, selected }) => {
  if (selected) {
    if (exams.length < selected.length) {
      return selected;
    } else {
      const imax = Math.min(exams.length, selected.length);
      for (let i = 0; i < imax; i++) {
        exams[i].Questions = addNewQuestions(
          exams[i].Questions,
          selected[i].Questions
        );
      }
    }
  }
  return exams;
};

const salvarQuestoes = ({ exames, questoes }) => {
  // questoes é um objeto do tipo: {question, examId}
  const novoExames = replaceQuestoes(exames, questoes);
  return criarExamsList(novoExames);
};

const appendTag = (obj, tag) => {
  obj.Tags.push(tag);
  return obj;
};

const removeTagByIndex = (obj, tagIndex) => {
  obj.Tags.splice(tagIndex, 1);
  return obj;
};

const replaceContent = (obj, content) => {
  obj.Content = content;
  return obj;
};

const adicionarTag = ({ exames, exameId, questao, tag }) => {
  const novaQuestao = appendTag(questao, tag);
  const novoExames = replaceQuestao(exames, exameId, novaQuestao);
  return criarExamsList(novoExames);
};

const removerTag = ({ exames, exameId, questao, tagIndex }) => {
  const novaQuestao = removeTagByIndex(questao, tagIndex);
  const novoExames = replaceQuestao(exames, exameId, novaQuestao);
  return criarExamsList(novoExames);
};

const changeContent = ({ exames, exameId, questao, content }) => {
  const novaQuestao = replaceContent(questao, content);
  const novoExames = replaceQuestao(exames, exameId, novaQuestao);
  return criarExamsList(novoExames);
};

const adicionarExamTag = ({ exames, exameId, tag }) => {
  const exame_index = getIndexById(exames, exameId);
  const novoExame = appendTag(exames[exame_index], tag);
  exames[exame_index] = novoExame;
  return exames;
};

const removerExamTag = ({ exames, exameId, tagIndex }) => {
  const exame_index = getIndexById(exames, exameId);
  const novoExame = removeTagByIndex(exames[exame_index], tagIndex);
  exames[exame_index] = novoExame;
  return exames;
};

const initialState = {
  pages: null,
  id: null,
  user: null,
  editar: false,
  editQuestions: false,
  equationImage: {},
  examImage: {},
  file: null,
  downloadFile: null,
  resolutions: [],
  startedExam: false,
  endExam: false,
  showResult: false,
  selectedExams: [],
  selectedQuestions: [],
  cartQuestions: [],
  selected: [],
  answers: undefined,
  result: [],
  uploadedUrls: [],
  loadingUrls: false,
  examsList: [],
  loading: false,
  loadingFetchSelected: false,
  error: {},
  changePage: false,
  updateId: makeid(7),
  editing: {},
  examInfo: undefined,
  openTest: {},
  powerbiEmbed: {},
  alternativeChecked: true,
  gptCreatedQuestions: [],
  loadingVideos: false,
  fsIds: [],
  anos: [],
  disciplinas: [],
  unidades: undefined,
  resultTrilhas: [],
  resultRW: [],
  updateRW: false,
  consolidacaoRaw: [],
  readingAndWritingCSVData: [],
  studentsAll: [],
  matrixEF: [],
  matrixEM: [],
  treeIdEF: null,
  treeIdEM: null,
  treeIdHabilities: null,
  habilities: [],
  currentPage: 1,
  totalPages: 1,
  totalDocs: 10,
  ItensPerPage: 10,
  batching: false,
  loadingEndOnlineExam: false,
  loadingUpdateRW: false,
};

const appendCreatedQuestions = (exam, examNew) => {
  examNew[0].Id = makeid(7);
  for (let i = 0; i < examNew[0].Questions.length; i++) {
    examNew[0].Questions[i].Id = makeid(7);
    examNew[0].Questions[i].Selected = true;
  }

  if (exam.length === 0) return examNew;

  examNew[0].Questions = examNew[0].Questions.concat(exam[0].Questions);

  for (let i = 0; i < exam[0].Questions.length; i++) {
    examNew[0].Questions[i].Number = i + 1;
  }

  return examNew;
}

const addNonDuplicates = (initialSelected, newSelected) => {
  const newExams = [];

  for (const ns of newSelected) { 
    const auxIdx = initialSelected.findIndex(initial => initial.Id === ns.Id);
    if (auxIdx !== -1) {
      initialSelected[auxIdx] = addNewCartQuestions(initialSelected[auxIdx], ns);
    } else {
      newExams.push(ns);
    }
  }
  return [...getCachedCart(initialSelected, newSelected), ...newExams];
}

const addNewCartQuestions = (initialExam, newExam) => {
  const initialExamQuestionIds = initialExam.Questions.map(q => q.Id);

  for (const question of newExam.Questions) {
    if (!initialExamQuestionIds.includes(question.Id)) {
      initialExam.Questions.push(question);
    }
  }
  
  return initialExam;
}

const getCachedCart = (newSelected, cart) => {
  for (let i = 0; i < newSelected.length; i++) {
    const res = cart.find(c => c.Id === newSelected[i].Id);
    if (res) {
      for (let iq = 0; iq < newSelected[i].Questions.length; iq++) {
        const resQuestion = res.Questions.find(rq => rq.Id === newSelected[i].Questions[iq].Id);
        if (resQuestion) newSelected[i].Questions[iq] = resQuestion;
      }
    };
  }
  return newSelected;
}

const questaoHandler = {
  marcarAlternativaDaQuestao,
  selecionarQuestao,
  salvarQuestao,
  salvarQuestoes,
  adicionarTag,
  removerTag,
  adicionarExamTag,
  removerExamTag,
  changeContent,
};

export default createReducer(initialState, {
  [ctes.CLEAR_EXAM_MODULE]: () => initialState,
  [ctes.CHECK_PAGES_AVAILABLE]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.CHECK_PAGES_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    pages: action.payload,
  }),
  [ctes.CHECK_PAGES_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.UPLOAD_RECOGNITION]: (state, action) => ({
    ...state,
    fsIds: [],
    loading: true,
    error: initialState.error,
  }),
  [ctes.UPLOAD_RECOGNITION_SUCCESS]: (state, action) => ({
    ...state,
    fsIds: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.UPLOAD_RECOGNITION_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.UPLOAD_EXAM]: (state, action) => ({
    ...state,
    id: initialState.id,
    file: action.payload,
    loading: true,
    error: initialState.error,
  }),
  [ctes.UPLOAD_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    selected: action.payload,
    loading: false,
    editar: true,
    editQuestions: true,
    error: initialState.error,
  }),
  [ctes.UPLOAD_EXAM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.UPLOAD_EXAM_MOBILE]: (state, action) => ({
    ...state,
    id: initialState.id,
    file: action.payload,
    loading: true,
    error: initialState.error,
  }),
  [ctes.UPLOAD_EXAM_MOBILE_SUCCESS]: (state, action) => ({
    ...state,
    selected: action.payload,
    loading: false,
    editar: true,
    editQuestions: true,
    error: initialState.error,
  }),
  [ctes.UPLOAD_EXAM_MOBILE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.CHANGE_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    changePage: true,
  }),
  [ctes.CHANGE_PAGE_FAILURE]: (state, action) => ({
    ...state,
    changePage: false,
  }),
  [ctes.CLASSIFY_EXAM]: (state, action) => ({
    ...state,
    id: initialState.id,
    file: action.payload,
    loading: true,
    error: initialState.error,
  }),
  [ctes.CLASSIFY_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    selected: action.payload,
    loading: false,
    editar: true,
    editQuestions: true,
    error: initialState.error,
  }),
  [ctes.CLASSIFY_EXAM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.SEND_IMAGES]: (state, action) => ({
    ...state,
    file: action.payload,
    loadingUrls: true,
    error: initialState.error,
  }),
  [ctes.SEND_IMAGES_SUCCESS]: (state, action) => ({
    ...state,
    uploadedUrls: action.payload,
    loadingUrls: false,
    error: initialState.error,
  }),
  [ctes.SEND_IMAGES_FAILURE]: (state, action) => ({
    ...state,
    loadingUrls: false,
    error: action.payload,
  }),
  [ctes.FETCH_ONLINE_EXAM]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_ONLINE_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    resolutions: action.payload.Questions,
    selected: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_ONLINE_EXAM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_ONLINE_EXAM_INFO]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_ONLINE_EXAM_INFO_SUCCESS]: (state, action) => ({
    ...state,
    examInfo: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_ONLINE_EXAM_INFO_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_RESULT_EXAM]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_RESULT_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    resolutions: action.payload.Questions,
    selected: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_RESULT_EXAM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_INDIVIDUAL_RESULT_EXAM]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_INDIVIDUAL_RESULT_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    resolutions: action.payload.Questions,
    selected: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_INDIVIDUAL_RESULT_EXAM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_STUDENT_EXAMS]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_STUDENT_EXAMS_SUCCESS]: (state, action) => ({
    ...state,
    resolutions: action.payload.Questions,
    selected: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_STUDENT_EXAMS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.START_ONLINE_EXAM]: (state, action) => ({
    ...state,
    startedExam: false,
    loading: true,
    error: action.payload,
  }),
  [ctes.START_ONLINE_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    startedExam: true,
    loading: false,
    error: initialState.error,
    user: action.payload,
  }),
  [ctes.START_ONLINE_EXAM_FAILURE]: (state, action) => ({
    ...state,
    startedExam: false,
    loading: false,
    error: action.payload,
  }),
  [ctes.END_ONLINE_EXAM]: (state, action) => ({
    ...state,
    endExam: false,
    loading: true,
    loadingEndOnlineExam: true,
    error: action.payload,
  }),
  [ctes.END_ONLINE_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    endExam: true,
    loading: false,
    loadingEndOnlineExam: false,
    error: initialState.error,
  }),
  [ctes.END_ONLINE_EXAM_FAILURE]: (state, action) => ({
    ...state,
    endExam: false,
    loading: false,
    loadingEndOnlineExam: false,
    error: action.payload,
  }),
  [ctes.ANS_QUESTION]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
    alternativeChecked: initialState.alternativeChecked,
    loadingAns: true,
  }),
  [ctes.ANS_QUESTION_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error,
    alternativeChecked: true,
  }),
  [ctes.ANS_QUESTION_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
    alternativeChecked: false,
  }),
  [ctes.FETCH_EXAM]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    resolutions: action.payload.Questions,
    selected: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_EXAM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_EXAM_RESULT]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_EXAM_RESULT_SUCCESS]: (state, action) => ({
    ...state,
    resolutions: action.payload.Questions,
    selected: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_EXAM_RESULT_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_EXAM_MATRIX_EF]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.FETCH_EXAM_MATRIX_EF_SUCCESS]: (state, action) => ({
    ...state,
    treeIdEF: action.payload.Id,
    matrixEF: action.payload.KnowledgeTree,
    loading: false,
  }),
  [ctes.FETCH_EXAM_MATRIX_EF_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_EXAM_MATRIX_EM]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.FETCH_EXAM_MATRIX_EM_SUCCESS]: (state, action) => ({
    ...state,
    treeIdEM: action.payload.Id,
    matrixEM: action.payload.KnowledgeTree,
    loading: false,
  }),
  [ctes.FETCH_EXAM_MATRIX_EM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_EXAM_HABILITIES]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.FETCH_EXAM_HABILITIES_SUCCESS]: (state, action) => ({
    ...state,
    treeIdHabilities: action.payload.Id,
    habilities: action.payload.KnowledgeTree,
    loading: false,
  }),
  [ctes.FETCH_EXAM_HABILITIES_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_STUDENT_RESULT]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_STUDENT_RESULT_SUCCESS]: (state, action) => ({
    ...state,
    selected: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_STUDENT_RESULT_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_STUDENT_RESULT_ALL]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_STUDENT_RESULT_ALL_SUCCESS]: (state, action) => ({
    ...state,
    studentsAll: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_STUDENT_RESULT_ALL_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_STUDENT_RESULT_EXAM]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_STUDENT_RESULT_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    result: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_STUDENT_RESULT_EXAM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    resolutions: action.payload.Questions,
    selected: action.payload,
    loading: false,
    showResult: true,
    error: initialState.error,
  }),
  [ctes.FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_EXAM_IMAGE]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_EXAM_IMAGE_SUCCESS]: (state, action) => ({
    ...state,
    examImage: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_EXAM_IMAGE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_EXAM_REPORT]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_POWERBI_REPORT]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_POWERBI_REPORT_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    powerbiEmbed: action.payload,
  }),
  [ctes.FETCH_POWERBI_REPORT_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_EQUATION_IMAGE]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_EQUATION_IMAGE_SUCCESS]: (state, action) => ({
    ...state,
    equationImage: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_EQUATION_IMAGE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.DOWNLOAD_EXAM]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.DOWNLOAD_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    downloadFile: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.EXAM_DOWNLOAD_SUCCESS]: (state, action) => ({
    ...state,
    id: action.payload,
    loading: true,
    error: initialState.error,
  }),
  [ctes.DOWNLOAD_EXAM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FINISH_EXAM]: (state, action) => ({
    ...state,
    loading: true,
    resolutions: action.payload,
    error: action.payload,
  }),
  [ctes.FINISH_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FINISH_EXAM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_EXAMS]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.FETCH_EXAMS_SUCCESS]: (state, action) => ({
    ...state,
    examsList: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.FETCH_EXAMS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.CREATE_QUESTIONS_BY_AI]: (state, action) => ({
    ...state,
    loading: action.loading,
    error: action.payload,
  }),
  [ctes.CREATE_QUESTIONS_BY_AI_SUCCESS]: (state, action) => ({
    ...state,
    loading: action.loading,
    gptCreatedQuestions: appendCreatedQuestions(state.gptCreatedQuestions, action.payload),
  }),
  [ctes.CREATE_QUESTIONS_BY_AI_FAILURE]: (state, action) => ({
    ...state,
    loading: action.loading,
    error: action.payload,
  }),
  [ctes.DELETE_EXAM]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.DELETE_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error,
  }),
  [ctes.DELETE_EXAM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.DELETE_TAG]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.DELETE_TAG_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    examsList: action.payload,
    error: initialState.error,
  }),
  [ctes.DELETE_TAG_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.ADD_TAG]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.ADD_TAG_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    examsList: action.payload,
    error: initialState.error,
  }),
  [ctes.ADD_TAG_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.SET_EXAM_ID]: (state, action) => ({
    ...state,
    id: action.payload.examId,
    editQuestions: action.payload.editable,
    loading: true,
  }),
  [ctes.SET_CAN_EDIT]: (state, action) => ({
    ...state,
    editar: action.payload.canEdit,
    editQuestions: action.payload.editable,
    loading: true,
  }),
  [ctes.FETCH_SELECTED]: (state, action) => ({
    ...state,
    loading: true,
    loadingFetchSelected: true,
    error: action.payload,
  }),
  [ctes.FETCH_SELECTED_SUCCESS]: (state, action) => ({
    ...state,
    selected: checkIfIsDifferent({
      exams: action.payload,
      selected: state.selected,
    }),
    loading: false,
    loadingFetchSelected: false,
    error: initialState.error,
  }),
  [ctes.FETCH_SELECTED_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    loadingFetchSelected: false,
    error: action.payload,
  }),
  [ctes.DELETE_QUESTION]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.DELETE_QUESTION_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    selected: action.payload,
    error: initialState.error,
  }),
  [ctes.DELETE_QUESTION_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.SEARCH_EXAM_TAG]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.SEARCH_EXAM_TAG_SUCCESS]: (state, action) => ({
    ...state,
    examsList: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.SEARCH_EXAM_TAG_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.SELECTED_EXAMS]: (state, action) => ({
    ...state,
    selectedExams: action.payload,
    loading: false,
    error: action.payload,
  }),
  [ctes.SELECTED_QUESTIONS]: (state, action) => ({
    ...state,
    selectedQuestions: action.payload,
    loading: false,
    error: action.payload,
  }),
  [ctes.SET_CART_QUESTIONS]: (state, action) => ({
    ...state,
    cartQuestions: action.payload,
    loading: false,
    error: null,
  }),
  [ctes.CREATE_EXAM]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload,
  }),
  [ctes.CREATE_EXAM_SUCCESS]: (state, action) => ({
    ...state,
    id: action.payload,
    loading: false,
    error: initialState.error,
  }),
  [ctes.CREATE_EXAM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.GET_VIDEOS_LOADED]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [ctes.GET_VIDEOS_LOADED_SUCCESS]: (state, action) => ({
    ...state,
    loadingVideos: false, // TODO: change to this in the future to loading work: action.payload,
    error: initialState.error,
  }),
  [ctes.GET_VIDEOS_LOADED_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [ctes.MARCAR_ALTERNATIVA]: (state, action) => {
    const exams = questaoHandler.marcarAlternativaDaQuestao(action.payload);
    return {
      ...state,
      selected: exams.exams,
      updateId: makeid(7),
    };
  },
  [ctes.ADICIONAR_TAG]: (state, action) => {
    const exams = questaoHandler.adicionarTag(action.payload);
    return {
      ...state,
      selected: exams.exams,
      updateId: makeid(7),
    };
  },
  [ctes.REMOVER_TAG]: (state, action) => {
    const exams = questaoHandler.removerTag(action.payload);
    return {
      ...state,
      selected: exams.exams,
      updateId: makeid(7),
    };
  },
  [ctes.CHANGE_CONTENT]: (state, action) => {
    const exams = questaoHandler.changeContent(action.payload);
    return {
      ...state,
      selected: exams.exams,
      updateId: makeid(7),
    };
  },
  [ctes.ADICIONAR_EXAM_TAG]: (state, action) => {
    const exams = questaoHandler.adicionarExamTag(action.payload);
    return {
      ...state,
      selected: exams.exams,
      updateId: makeid(7),
    };
  },
  [ctes.REMOVER_EXAM_TAG]: (state, action) => {
    const exams = questaoHandler.removerExamTag(action.payload);
    return {
      ...state,
      selected: exams.exams,
      updateId: makeid(7),
    };
  },
  [ctes.SELECIONAR_QUESTAO]: (state, action) => {
    let selected;
    let cartQuestions;

    const exams = questaoHandler.selecionarQuestao(action.payload);
    if (exams === undefined) {
      selected = state.selected;
      const cart = questaoHandler.selecionarQuestao({
        exames: state.cartQuestions,
        exameId: action.payload.exameId,
        questao: action.payload.questao
      });
      cartQuestions = cart.exams;
    } else {
      selected = exams.exams;
      cartQuestions = state.cartQuestions;
    }

    return {
      ...state,
      selected: selected,
      cartQuestions: cartQuestions,
      updateId: makeid(7),
    };
  },
  [ctes.SALVAR_QUESTAO]: (state, action) => {
    const exams = questaoHandler.salvarQuestao(action.payload);
    return {
      ...state,
      selected: exams.exams,
      updateId: makeid(7),
    };
  },
  [ctes.SALVAR_QUESTOES]: (state, action) => {
    const exams = questaoHandler.salvarQuestoes(action.payload);
    return {
      ...state,
      selected: exams.exams,
      updateId: makeid(7),
    };
  },
  [ctes.SET_EDITING]: (state, action) => ({
    ...state,
    editing: action.payload,
  }),
  [ctes.SEARCH_SELECTED]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.SEARCH_SELECTED_SUCCESS]: (state, action) => {
    const currentPage = action.payload.data[0].Page;
    const totalPages = Math.ceil(action.payload.data[0].TotalItens / action.payload.data[0].ItensPerPage);
    if (!action.payload.byBatch || currentPage == 1)
      return {
        ...state,
        batching: action.payload.byBatch,
        currentPage: currentPage,
        totalPages: totalPages,
        totalDocs: action.payload.data[0].TotalItens,
        ItensPerPage: action.payload.data[0].ItensPerPage,
        selected: getCachedCart(action.payload.data[0].Itens, state.cartQuestions),
        cartQuestions: addNonDuplicates(state.cartQuestions, action.payload.data[0].Itens),
        loading: false,
      };

    return {
      ...state,
      batching: currentPage <= totalPages ? true : false,
      currentPage: currentPage,
      totalPages: totalPages,
      totalDocs: action.payload.data[0].TotalItens,
      ItensPerPage: action.payload.data[0].ItensPerPage,
      selected: getCachedCart([...state.selected, ...action.payload.data[0].Itens], state.cartQuestions),
      cartQuestions: addNonDuplicates(state.cartQuestions, action.payload.data[0].Itens),
      loading: false,
    }
  },
  [ctes.SEARCH_SELECTED_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
  [ctes.OPEN_TEST_UPDATE]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.OPEN_TEST_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    openTest: action.payload,
    loading: false,
  }),
  [ctes.OPEN_TEST_UPDATE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_ANO_TRILHAS]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.FETCH_ANO_TRILHAS_SUCCESS]: (state, action) => ({
    ...state,
    anos: action.payload,
    loading: false,
  }),
  [ctes.FETCH_ANO_TRILHAS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_DISCIPLINAS_TRILHAS]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.FETCH_DISCIPLINAS_TRILHAS_SUCCESS]: (state, action) => ({
    ...state,
    disciplinas: action.payload,
    loading: false,
  }),
  [ctes.FETCH_DISCIPLINAS_TRILHAS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_UNIDADES_TEMATICAS]: (state, action) => ({
    ...state,
  }),
  [ctes.FETCH_UNIDADES_TEMATICAS_SUCCESS]: (state, action) => ({
    ...state,
    unidades: action.payload,
  }),
  [ctes.FETCH_UNIDADES_TEMATICAS_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [ctes.FETCH_STUDENTS_TRILHAS]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.FETCH_STUDENTS_TRILHAS_SUCCESS]: (state, action) => ({
    ...state,
    studentTrilhas: action.payload,
    loading: false,
  }),
  [ctes.FETCH_STUDENTS_TRILHAS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_RESULT_TRILHAS]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.FETCH_RESULT_TRILHAS_SUCCESS]: (state, action) => ({
    ...state,
    resultTrilhas: action.payload,
    loading: false,
  }),
  [ctes.FETCH_RESULT_TRILHAS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_CONSOLIDACAO]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.FETCH_CONSOLIDACAO_SUCCESS]: (state, action) => ({
    ...state,
    consolidacaoRaw: action.payload,
    loading: false,
  }),
  [ctes.FETCH_CONSOLIDACAO_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_READ_AND_WRITING]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.FETCH_READ_AND_WRITING_SUCCESS]: (state, action) => ({
    ...state,
    resultRW: action.payload,
    loading: false,
  }),
  [ctes.FETCH_READ_AND_WRITING_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.FETCH_READING_AND_WRITING_DATA_CSV]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [ctes.FETCH_READING_AND_WRITING_DATA_CSV_SUCCESS]: (state, action) => ({
    ...state,
    readingAndWritingCSVData: action.payload,
    loading: false,
  }),
  [ctes.FETCH_READING_AND_WRITING_DATA_CSV_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [ctes.UPDATE_RESULT_RW]: (state, action) => ({
    ...state,
    loadingUpdateRW: true,
  }),
  [ctes.UPDATE_RESULT_RW_SUCCESS]: (state, action) => ({
    ...state,
    resultRW: action.payload,
    updateRW: !state.updateRW,
    error: null,
    loadingUpdateRW: false,
  }),
  [ctes.UPDATE_RESULT_RW_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
    loadingUpdateRW: false,
  }),
});
