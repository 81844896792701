/* eslint-disable */
import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { getTags } from "../../store/questions/actions";

import {
  Chip,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  TextField,
} from "@material-ui/core";

import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  tags: {
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  text: {
    width: theme.spacing(30),
    "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
      paddingRight: 0,
    },
  },
}));

function Tags({
  tags = [],
  disableEdicao = false,
  removerTag,
  adicionarTag,
  loadingTags,
  optionsTags,
  getTags,
}) {
  const classes = useStyles();
  const [actualTag, setActualTag] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [validTag, setValidTag] = useState(false);

  const editTag = (e) => {
    setActualTag(e.target.value);
    if (options) {
      setValidTag(options.includes(e.target.value));
    }
  };

  const handleIconButton = () => {
    if (actualTag === "") return;
    if (tags.includes(actualTag)) {
      setActualTag("");
      return;
    }
    adicionarTag(actualTag);
    setActualTag("");
  };

  const handleAutocompleteChange = (event, value) => {
    if (value !== null) {
      if (!tags.includes(value)) {
        adicionarTag(value);
      }
    }
    setActualTag("");
  };

  const handleAutocompleteInputChange = (event, value) => {
    if(value === "" || !value) return;
    setLoading(true);
    getTags(value);
  };

  useEffect(() => {
    if (actualTag && Array.isArray(optionsTags)) {
      setValidTag(optionsTags.includes(actualTag));
    }
  }, [actualTag, optionsTags]);

  return (
    <Grid className={classes.tags} container>
      {tags &&
        tags.map((tag, index) => (
          <Chip
            color="primary"
            className={classes.chip}
            label={tag}
            disabled={disableEdicao}
            onDelete={() => removerTag(index)}
            key={tag}
          />
        ))}
      {!disableEdicao && (
        <Autocomplete
          className={classes.text}
          options={
            optionsTags
              ? optionsTags.filter((option) => !tags.includes(option))
              : []
          }
          getOptionLabel={(option) => option}
          loading={loading || loadingTags}
          onInputChange={handleAutocompleteInputChange}
          onChange={handleAutocompleteChange}
          value={actualTag}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Tags"
              value={actualTag}
              onChange={editTag}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleIconButton}>
                      <Add />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          )}
        />
      )}
    </Grid>
  );
}

Tags.propTypes = {
  tags: PropTypes.array,
  disableEdicao: PropTypes.bool,
  removerTag: PropTypes.func,
  adicionarTag: PropTypes.func,
  loadingTags: PropTypes.bool,
  optionsTags: PropTypes.array,
  getTags: PropTypes.func,
};

const mapStateToProps = ({ questions }) => ({
  loadingTags: questions.loadingTags,
  optionsTags: questions.optionsTags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTags,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
