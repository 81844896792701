import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { fetchCompanies } from '../../store/companies/services'
import { Select, Spin } from 'antd'
import { StyledSelect } from '../question/QuestionFilterHeader/styles.js'

function SelectCompanies ({ onChange, companies = [] }) {
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState('')
  const [loading, setLoading] = useState(false)

  async function loadOptions (value) {
    setLoading(true)

    let dados = []
    let values = []
    let first
    await fetchCompanies(value || '', 1).then(companies => {
      companies.Itens.map(j => {
        if (first === undefined) {
          // first = j.Id
        }
        let option =
          <Select.Option
            key={j.Id}
            value={j.Id}
            title={j.Name}
          >
            {j.Name}
          </Select.Option>

        dados.push(option)
        values.push(j.Id)

        return option
      })
    })

    setOptions(dados)
    setLoading(false)
    if (companies.length > 0) {
      setSelected(companies)
    } else {
      onChange(values)
    }
  }

  function handleChange (value) {
    onChange(value)
    setSelected(value)
  }

  useEffect(() => {
    loadOptions()
  }, [])

  return (
    <StyledSelect
      mode={'multiple'}
      className='select-input'
      value={selected}
      onChange={value => handleChange(value)}
      showSearch
      onSearch={value => loadOptions(value)}
      style={{ width: '100%' }}
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      notFoundContent={loading ? <Spin spinning size='small' /> : 'Nada encontrado'}
      placeholder={'Escola(s)'}
      loading={loading}
      allowClear
    >
      <Select.Option
        value={''}
        title={'Todas'}
      >
        {'Todas'}
      </Select.Option>
      {options}
    </StyledSelect>

  )
}

SelectCompanies.propTypes = {
  onChange: PropTypes.func,
  companies: PropTypes.array
}

export default SelectCompanies
