import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Page = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  padding: 0.75rem;
  width: 100%;

  .container {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center; 
  }

  .switch-container {
    text-align: center;
    margin-top: 50px;
    flex-direction: column;
    width: 100%; 
    max-width: 400px; 
  }
  
  .switch-container .MuiButton-root {
    width: 201px; /* default width */
    padding: 10px 20px; 
    font-size: 16px;
    line-height: 1.1; 
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  }

  @media only screen and (max-width: 440px) {
    .switch-container .MuiButton-root {
      max-width: 180px; 
    }
  }

  @media only screen and (max-width: 400px) {
    .switch-container .MuiButton-root {
      max-width: 160px; 
    }
  }

  @media only screen and (max-width: 360px) {
    .switch-container .MuiButton-root {
      max-width: 130px; 
    }
  }
  
  .login-container {
    margin-bottom: 10px; 
    width: 100%; 
    max-width: 403px; 
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
`

export const Col = styled.div`
  ${props => props.expand && css`
    flex: 1;
    :not(:last-child) { margin-right: 1rem; }
  `}
`

export const UploadTitle = styled.h2`
  font-weight: 400;
  font-size: 1.8rem;
  color: #000;
`

export const Title = styled.h2`
  font-weight: 500;
  font-size: 1.25rem;
  color: #000;
`