import http, { updateToken } from '../../utils/http2'

const userEndpoint = '/user/'
const subscriptionEndpoint = '/subscription/'
const createEndpoint = '/user'
const deleteUserEndpoint = '/inactive'
const azureEndpoint = "/user/embedToken"
const updatePasswordEndpoint = userEndpoint + 'update/password'
const searchUserEndpoint = userEndpoint + 'search/paged'
const infoUserEndpoint = userEndpoint + 'authenticated/info'
const expirationDateEndpoint = userEndpoint + 'userplan/expiration'
const spreadsheetUploadEndPoint = process.env.UNOCONV_URL + "/spreadsheet";
const checksubscriptionStatusEndpoint = subscriptionEndpoint + 'status'
const trilhasEndpoint = process.env.TRILHAS_URL + "/core/simulie/token"

export const createUser = user => {
  updateToken()
  return http
    .post(createEndpoint, user)
    .then(res => res.data).catch(error => error.response)
}

export const updateUser = user => {
  updateToken()
  return http
    .put(userEndpoint + user.Id, user)
    .then(res => res.data).catch(error => error.data)
}

export const updatePassword = user => {
  updateToken()
  return http
    .post(updatePasswordEndpoint, user)
    .then(res => res.data).catch(error => error.data)
}

export const fetchUsers = (title, page, status) => {
  updateToken()
  if (page === undefined || page === '') { page = 1 }
  let url = searchUserEndpoint + `?status=` + status + `&page=` + page
  if (title !== undefined && title !== '') {
    url += `&name=` + title
  }
  return http
    .get(url)
    .then(res => res.data).catch(error => error.data)
}

export const trilhasLogin = (email) => {
  let token = window.sessionStorage.getItem('token')
  return http
    .post(trilhasEndpoint, {
      Email: email,
      UserId: email,
      Authorization: "Bearer " + token,
    })
    .then(res => res.data).catch(error => error.data)
}

export const fetchUser = id => {
  updateToken()
  return http
    .get(userEndpoint + id)
    .then(res => res.data).catch(error => error.data)
}

export const infoUser = () => {
  updateToken();
  let token = window.sessionStorage.getItem('token')
  http.defaults.headers.common.Authorization = 'Bearer ' + token

  return http
    .get(infoUserEndpoint)
    .then(res => res.data).catch(error => error.data)
}

export const fetchPlanExpirationDate = () => {
  updateToken()
   let token = window.sessionStorage.getItem('token')
   http.defaults.headers.common.Authorization = 'Bearer ' + token

  return http
    .get(expirationDateEndpoint)
    .then(res => res.data).catch(error => error.data)
}

export const checkSubscriptionStatus = () => {
  updateToken();
  return http
    .get(checksubscriptionStatusEndpoint)
    .then(res => res.data)
    .catch(error => error.response);
};

export const deleteUser = id => {
  updateToken()
  console.log(id)
  return http
    .put(userEndpoint + id + deleteUserEndpoint)
    .then(res => res.data).catch(error => error.data)
}

export const uploadSpreadsheet = (file) => {
  updateToken();
  var formData = new FormData();
  formData.append("file", file);
  return http
    .post(`${spreadsheetUploadEndPoint}`, formData)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const getEmbedToken = (reportId, groupId) => {
  updateToken();
  const url = azureEndpoint + `?reportId=${reportId}&workspaceId=${groupId}`;
  return http
    .get(`${url}`)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const getSeriesInfo = async (userId) => {
  updateToken();
  return http
    .get(userEndpoint + `userSeries?userId=${userId}`)
    .then((res) => res.data)
    .catch((error) => error.data);
};
