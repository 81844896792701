import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import { clearDocumentModule } from '../store/documents/actions'
// import { clearTemplateModule } from '../store/templates/actions'
// import { clearOutputModule } from '../store/outputs/actions'

import TemplateUploadInput from '../components/input/TemplateUploadInput'
import TemplatesTable from '../components/table/TemplatesTable'
import { Spin } from 'antd'
import { Page, Row, Col, Title } from './styles/Page.styles'

const TemplatesPage = ({
  // clearDocumentModule,
  // clearTemplateModule,
  // clearOutputModule,
  loading
}) => {
  // useEffect(() => {
  // clearDocumentModule()
  // clearTemplateModule()
  // clearOutputModule()
  // }, [])

  return (
    <Page>
      <Row>
        <Col><Title>Modelos</Title></Col>
        <Col><TemplateUploadInput /></Col>
      </Row>
      <Spin spinning={loading}>
        <TemplatesTable />
      </Spin>
    </Page>
  )
}

TemplatesPage.propTypes = {
  // clearDocumentModule: PropTypes.func.isRequired,
  // clearTemplateModule: PropTypes.func.isRequired,
  // clearOutputModule: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

const mapStateToProps = ({ templates }) => ({
  loading: templates.loading
})

const mapDispatchToPtops = dispatch =>
  bindActionCreators({
    // clearDocumentModule,
    // clearTemplateModule,
    // clearOutputModule
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToPtops)(TemplatesPage)
