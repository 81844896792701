import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { StyledBackdrop, StyledChildren } from './styles/Backdrop.styles'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

const Backdrop = props => {
  const { children, visible } = props

  return (
    <Fragment>
      <StyledChildren visible={visible}>{children}</StyledChildren>
      <ReactCSSTransitionGroup
        transitionName='fade'
        transitionAppear
        transitionAppearTimeout={400}
        transitionEnterTimeout={400}
        transitionLeaveTimeout={400}
      >
        {
          visible &&
          <StyledBackdrop
            {...props}
            children={null}
          />
        }
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}

Backdrop.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool
}

export default Backdrop
