import styled from '@emotion/styled'

export const Container = styled.header`
    background: #fff;
    box-shadow: 0 0 2px 0 darkgrey;
    border-width: 1px;
    border-top: solid 0 transparent;
    border-left: solid 0 transparent;
    border-right: solid 0 transparent;
    border-bottom: solid 2px;
    border-color: #514fff;
    padding: 1rem;
`

export const Content = styled.div`
    align-items: center;
    display: flex;
    margin: 0 auto;
    max-width: 90rem;
    max-height: 4rem;
    justify-content: space-between;
`

export const Group = styled.div`
    display: flex;
    max-width: 120px;
    align-items: center;
    > *:not(:last-child) { margin-right: 1rem; }
    svg { max-height: 4rem; }
`
