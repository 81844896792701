import React from 'react'
import step1 from '../../assets/convert-step1.png'
import step2 from '../../assets/convert-step2.png'
import step3 from '../../assets/convert-step3.png'
import step4 from '../../assets/convert-step4.png'
import { Image } from './styles/Help.styles'

const DocHelp = () => (
  <>
    <p>No momento, não podemos processar arquivos no formato do Word 97-2003. Use a função "Salvar Como" do Word para converter o seu arquivo para o formato DOCX:</p>
    <ol>
      <li>
        No canto superior-esquerdo da janela do Word, selecione a aba "Arquivo".
        <Image src={step1} alt='Passo 1' />
      </li>
      <li>
        Na barra da esquerda, selecione a opção "Salvar Como".
        <Image src={step2} alt='Passo 2' />
      </li>
      <li>
        Clique no ícone "Procurar".
        <Image src={step3} alt='Passo 3' />
      </li>
      <li>
        No campo "Tipo", escolha "Documento do Word (*.docx)" e salve o arquivo novamente.
        <Image src={step4} alt='Passo 4' />
      </li>
    </ol>
  </>
)

export default DocHelp
