import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import UserForm from '../../components/form/UserForm'
import { Spin } from 'antd'
import { Col, Row } from '../../components/table/styles/Table.styles'
import { Title } from '../../components/form/styles/Form.styles'
import { Page } from '../styles/Page.styles'
import InviteForm from '../../components/form/InviteForm'
import InviteStudentForm from '../../components/form/InviteStudentForm'
import { StyledButton } from '../../components/modal/styles/Modal.styles'

const NewUserPage = ({
  loading
}) => {
  const [signupSpace, setSignupSpace] = useState(undefined)

  return (
    <Page>
      <Row center>
        <Col center size={5}>
          <Row center>
            <Col center size={5}><Title>Cadastrar Usuário</Title></Col>
          </Row>
          <br />
          <StyledButton type='primary' onClick={() => setSignupSpace('new')}>
            Criar Usuário
          </StyledButton>
          <br />
          {/* <br />
          <Button type='primary' onClick={() => setSignupSpace('invite-student')}>
            Convidar Aluno por Email
          </Button>
          <br />
          <br />
          <Button type='primary' onClick={() => setSignupSpace('invite-professor')}>
            Convidar Professor por Email
          </Button>
          <br /> */}
        </Col>
      </Row>
      { signupSpace
        ? <Spin spinning={loading}>
          {
            signupSpace === 'new'
              ? <UserForm /> : (signupSpace === 'invite-student'
                ? <InviteStudentForm /> : (
                  signupSpace === 'invite-professor'
                    ? <InviteForm /> : ''
                ))
          }
        </Spin> : ''
      }
    </Page>
  )
}

NewUserPage.propTypes = {
  loading: PropTypes.bool
}

const mapStateToProps = ({ files }) => ({
  loading: files.loading
})

export default connect(mapStateToProps)(NewUserPage)
