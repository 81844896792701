import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TemplateForm from '../components/form/TemplateForm'
import { Spin } from 'antd'
import { Page, Row, Col, Title } from './styles/Page.styles'

const NewTemplatePage = ({
  loading
}) => {
  return (
    <Page>
      <Row>
        <Col><Title>Cadastrar Modelo</Title></Col>
      </Row>
      <Spin spinning={loading}>
        <TemplateForm />
      </Spin>
    </Page>
  )
}

NewTemplatePage.propTypes = {
  loading: PropTypes.bool
}

const mapStateToProps = ({ files }) => ({
  loading: files.loading
})

export default connect(mapStateToProps)(NewTemplatePage)
