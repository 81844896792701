import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as yup from 'yup'
import { register } from '../../store/auth/actions'
import { withFormik, Field } from 'formik'

import { Form, Spin, Modal, message } from 'antd'
import Animation from '../animation/Animation'
import FeedbackLink from '../link/FeedbackLink'
import EmailInput from '../input/EmailInput'
import PasswordInput from '../input/PasswordInput'
import TextInput from '../input/TextInput'
import SubmitButton from '../button/SubmitButton'
import { StyledRegisterForm, FeedbackGroup, GroupLabel } from './styles/Form.styles'
import SelectCompany from '../input/SelectCompany'

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  wasRegisteredSuccessfully: auth.wasRegisteredSuccessfully
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    register
  }, dispatch)

const handleSubmit = (values, formikBag) => {
  formikBag.props.register(values)
  message.success('Registro efetuado com sucesso! Aguarde e-mail de confirmação de ativação')
}

const validationSchema = props => {
  return yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().min(6).required(),
    passwordConfirmation: yup.string().min(6).equals(yup.ref('password'), 'Senhas não conferem').required()
  })
}

class RegisterForm extends Component {
  state = {
    showModal: false
  }

  static propTypes = {
    loading: PropTypes.bool,
    wasRegisteredSuccessfully: PropTypes.bool,
    isValid: PropTypes.bool,
    values: PropTypes.object,
    errors: PropTypes.object
  }

  componentDidUpdate () {
    if (this.props.wasRegisteredSuccessfully) {
      this.setState({ showModal: true })
    }
  }

  render () {
    const {
      showModal
    } = this.state
    const {
      loading,
      isValid
    } = this.props

    return (
      <>
        <StyledRegisterForm>
          <Spin spinning={loading}>
            <GroupLabel>Informações Básicas</GroupLabel>
            <Field
              name='name'
              label='Nome'
              component={TextInput}
              icon='user'
            />
            <Field
              name='email'
              label='Email'
              component={EmailInput}
            />
            <GroupLabel>Segurança</GroupLabel>
            <Field
              name='password'
              label='Senha'
              component={PasswordInput}
            />
            <Field
              name='passwordConfirmation'
              label='Confirmação de Senha'
              component={PasswordInput}
            />
            <Field
              name='companyId'
              label='Empresa'
              component={SelectCompany}
            />
            <Form.Item>
              <SubmitButton
                disabled={!isValid}
                loading={loading}
              >
                Registrar
              </SubmitButton>
            </Form.Item>
          </Spin>
        </StyledRegisterForm >
        <Modal
          visible={showModal}
          closable={false}
          footer={null}
        >
          <Animation>
            <FeedbackGroup>
              <FeedbackLink
                title='Pronto! Agora só falta validar seu email. Por favor verifique sua caixa de entrada'
                icon='mail'
                iconColor='#52c41a'
                link='/login'
              >
                Ok! Leve-me para a tela de login
              </FeedbackLink>
            </FeedbackGroup>
          </Animation>
        </Modal>
      </>
    )
  }

  isFieldValid = (...fields) => {
    const { values, errors } = this.props
    return fields.every(field => {
      return values.hasOwnProperty(field) &&
        !errors.hasOwnProperty(field)
    })
  }
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit,
    validationSchema
  })
)

export default enhance(RegisterForm)
