/* eslint-disable */
import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import history from '../routes/history'

import DocumentWarnings from '../components/warnings/DocumentWarnings'

import WarningsNav from '../components/warnings/WarningsNav'
import ProcessingMessage from '../components/message/ProcessingMessage'
import WarningMessage from '../components/message/warning/WarningMessage'

import { Page, Row } from './styles/Page.styles'

import { Button } from 'antd'

import { Buttons } from './styles/Revision.styles'

// eslint-disable-next-line react/prop-types
function RevisionPage ({ documentId, templateId, outputs, loading, uploadExam, savedFile }) {
  function handleDownload () {
    const output = outputs.find(o => o.template.id === templateId && o.document.id === documentId)

    const url = !output
      ? null
      : output.files_url && output.files_url[0]

    const link = document.createElement('a')
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function handleSave (e) {
    e.preventDefault()
    let file = savedFile
    if (file === null || file === undefined) {
      file = window.sessionStorage.getItem('saved_file')
    }
    uploadExam(file)
    // history.push('/exam')
  }

  return (
    <Page>
      {
        loading
          ? <ProcessingMessage />
          : (<>
            <WarningMessage />
            <DocumentWarnings />
            <WarningsNav />

            <Row>
              <Buttons>
                <Button type='primary' disabled={loading} onClick={handleDownload} shape='round' >Baixar e Corrigir</Button>
                <Button type='primary' disabled={loading} shape='round' onClick={handleSave} >Salvar sem corrigir</Button>
              </Buttons>
            </Row>
          </>)
      }

    </Page>
  )
}

const mapStateToProps = ({ documents, templates, outputs }) => ({
  outputs: outputs.outputs,
  loading: documents.loading || outputs.loading || templates.loading,
  documentId: documents.id,
  savedFile: documents.savedFile,
  templateId: templates.id
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({

  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RevisionPage)
