import styled from '@emotion/styled'

export const Container = styled.div`
  margin: 0;
  margin-bottom: 24px;
  .ant-collapse-header {
    background-color: #ffeaa7;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

export const Group = styled.div`
  margin: 0 auto;
  max-width: 25rem;
  padding: 1rem;
  text-align: center;
  > *:not(:last-child) { margin-bottom: 1rem; }
`

export const HeaderLabel = styled.div`
  color: #e67e22;
  font-weight: bold;
  float: right;
  display: flex;
  align-items: center;
  i {
    margin: 0 1rem 0 .5rem;
    font-size: 1.5rem;
  }
`

export const Warning = styled.div`
  margin-bottom: 1rem;
`

export const WarningLabel = styled.span`
  text-transform: uppercase;
  font-weight: bold;
`

export const WarningContent = styled.div`
  p { margin-bottom: 0; }
`
