import React, { useState, useRef, useEffect, forwardRef } from "react";
import { addStyles, EditableMathField } from "react-mathquill";
import Button from "@material-ui/core/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/styles";
import Tooltip from "@mui/material/Tooltip";

import Division from "./icons/divisao.svg";
import Summation from "./icons/somatorio.svg";
import Integral from "./icons/integral.svg";
import SquareRoot from "./icons/raiz1.svg";
import SquareRoot2 from "./icons/raiz2.svg";
import SubSuper from "./icons/subsuperscript.svg";
import Limit from "./icons/limite.svg";
import Sub from "./icons/sub.svg";
import Super from "./icons/super.svg";
import Overline from "./icons/barrado.svg";
import Circumflex from "./icons/circunflexo.svg";
import BigCap from "./icons/bigcap.svg";
import BigCup from "./icons/bigcup.svg";
import Prod from "./icons/prod.svg";
import Coprod from "./icons/coprod.svg";
import Parentheses from "./icons/parenteses.svg";
import Brackets from "./icons/colchetes.svg";
import Bracets from "./icons/chaves.svg";
import Absolute from "./icons/modulo.svg";
import Integral2 from "./icons/integral2.svg";
import Operations from "./icons/operations.svg";
import Letters from "./icons/letras.svg";
import Arrow from "./icons/setas.svg";
import Relations from "./icons/relacoes.svg";
import Others from "./icons/diversos.svg";
import Alpha from "./icons/alfa.svg";
import Beta from "./icons/beta.svg";
import Gamma from "./icons/gamma.svg";
import Delta from "./icons/delta.svg";
import Epsilon from "./icons/epsilon.svg";
import Varepsilon from "./icons/varepsilon.svg";
import Zeta from "./icons/zeta.svg";
import Eta from "./icons/eta.svg";
import Theta from "./icons/theta.svg";
import Vartheta from "./icons/vartheta.svg";
import Iota from "./icons/iota.svg";
import Kappa from "./icons/kappa.svg";
import Lambda from "./icons/lambda.svg";
import Mu from "./icons/mu.svg";
import Nu from "./icons/nu.svg";
import Xi from "./icons/xi.svg";
import Pi from "./icons/pi.svg";
import Varpi from "./icons/varpi.svg";
import Rho from "./icons/rho.svg";
import Varrho from "./icons/varrho.svg";
import Sigma from "./icons/sigma.svg";
import Varsigma from "./icons/varsigma.svg";
import Tau from "./icons/tau.svg";
import Upsilon from "./icons/upsilon.svg";
import Phi from "./icons/phi.svg";
import Varphi from "./icons/varphi.svg";
import Chi from "./icons/chi.svg";
import Psi from "./icons/psi.svg";
import Omega from "./icons/omega.svg";
import Le from "./icons/le.svg";
import Ge from "./icons/ge.svg";
import Prec from "./icons/prec.svg";
import Succ from "./icons/succ.svg";
import MuchLess from "./icons/muchless.svg";
import MuchGreater from "./icons/muchgreater.svg";
import Equiv from "./icons/equiv.svg";
import NotEqual from "./icons/notequal.svg";
import Sim from "./icons/sim.svg";
import Simeq from "./icons/simeq.svg";
import Approx from "./icons/thickapprox.svg";
import Subset from "./icons/subset.svg";
import Supset from "./icons/supset.svg";
import Subseteq from "./icons/subseteq.svg";
import Supseteq from "./icons/supseteq.svg";
import In from "./icons/in.svg";
import Ni from "./icons/ni.svg";
import NotIn from "./icons/notin.svg";
import Gets from "./icons/gets.svg";
import To from "./icons/to.svg";
import Leftrightarrow from "./icons/leftrightarrow.svg";
import Leftrightarrow2 from "./icons/Leftrightarrow2.svg";
import Leftarrow from "./icons/Leftarrow.svg";
import Rightarrow from "./icons/Rightarrow.svg";
import Uparrow from "./icons/uparrow.svg";
import Downarrow from "./icons/downarrow.svg";
import Updownarrow from "./icons/updownarrow.svg";
import Uparrow2 from "./icons/uparrow2.svg";
import Downarrow2 from "./icons/downarrow2.svg";
import Updownarrow2 from "./icons/updownarrow2.svg";
import Times from "./icons/times.svg";
import Divs from "./icons/divs.svg";
import Dot from "./icons/dot.svg";
import Pm from "./icons/pm.svg";
import Mp from "./icons/mp.svg";
import Ast from "./icons/ast.svg";
import Circ from "./icons/circ.svg";
import Bullet from "./icons/bullet.svg";
import Wedge from "./icons/wedge.svg";
import Vee from "./icons/vee.svg";
import Cap from "./icons/cap.svg";
import Cup from "./icons/cup.svg";
import Forall from "./icons/forall.svg";
import Exists from "./icons/exists.svg";
import Bot from "./icons/bot.svg";
import Top from "./icons/top.svg";
import Triangle from "./icons/triangle.svg";
import Infty from "./icons/infty.svg";

// inserts the required css to the <head> block.
addStyles();

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    width: "25px",
    height: "40px",
  },
  menuButton: {
    width: "50px",
    height: "30px",
  },
  mathField: {
    width: "400px",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      overflow: "auto",
    },
  },
}));

const EditableMathExample = ({ onLatexChange }) => {
  const [latex, setLatex] = useState("");
  const [operations, setOperations] = useState(null);
  const [letters, setLetters] = useState(null);
  const [relations, setRelations] = useState(null);
  const [arrows, setArrows] = useState(null);
  const [others, setOthers] = useState(null);

  const mathQuillRef = useRef(null);

  const classes = useStyles();

  const funcs = {
    1: "\\frac{}{}",
    2: "\\sum_{}^{}",
    3: "\\int_{}^{}",
    4: "\\sqrt{}",
    5: "\\sqrt[]{}",
    6: "\\lim_{}\\to_{}",
    7: "\\mathrm{}_{}^{}",
    8: "_{}",
    9: "^{}",
    10: "\\bar{}",
    11: "\\hat{}",
    12: "\\bigcap_{}^{}",
    13: "\\bigcup_{}^{}",
    14: "\\prod_{}^{}",
    15: "\\coprod_{}^{}",
    16: "\\left(  \\right)",
    17: "\\left[  \\right]",
    18: "\\left\\{  \\right\\}",
    19: "\\left|  \\right|",
    20: "\\oint_{}^{}",
    21: "\\alpha",
    22: "\\beta",
    23: "\\gamma",
    24: "\\delta",
    25: "\\epsilon",
    26: "\\varepsilon",
    27: "\\zeta",
    28: "\\eta",
    29: "\\theta",
    30: "\\vartheta",
    31: "\\iota",
    32: "\\kappa",
    33: "\\lambda",
    34: "\\mu",
    35: "\\nu",
    36: "\\xi",
    37: "\\pi",
    38: "\\varpi",
    39: "\\rho",
    40: "\\varrho",
    41: "\\sigma",
    42: "\\varsigma",
    43: "\\tau",
    44: "\\upsilon",
    45: "\\phi",
    46: "\\varphi",
    47: "\\chi",
    48: "\\psi",
    49: "\\omega",
    50: "\\le",
    51: "\\ge",
    52: "\\prec ",
    53: "\\succ",
    54: "\\ll ",
    55: "\\gg",
    56: "\\equiv",
    57: "\\neq",
    58: "\\sim",
    59: "\\simeq",
    60: "\\approx",
    61: "\\subset",
    62: "\\supset",
    63: "\\subseteq",
    64: "\\supseteq",
    65: "\\in",
    66: "\\ni",
    67: "\\notin",
    68: "\\gets",
    69: "\\to",
    70: "\\leftrightarrow",
    71: "\\Leftarrow",
    72: "\\Rightarrow",
    73: "\\Leftrightarrow",
    74: "\\uparrow",
    75: "\\downarrow",
    76: "\\updownarrow",
    77: "\\Uparrow",
    78: "\\Downarrow",
    79: "\\Updownarrow",
    80: "\\times",
    81: "\\div",
    82: "\\cdot",
    83: "\\pm",
    84: "\\mp",
    85: "\\ast",
    86: "\\circ",
    87: "\\bullet",
    88: "\\wedge",
    89: "\\vee",
    90: "\\cap",
    91: "\\cup",
    92: "\\forall",
    93: "\\exists",
    94: "\\bot",
    95: "\\top",
    96: "\\triangle",
    97: "\\infty",
  };

  const handleLatexChange = (mathField) => {
    const newLatex = mathField.latex();
    setLatex(newLatex);
    onLatexChange(newLatex);
  };

  const handleSymbol = (e) => {
    mathQuillRef.current.write(funcs[e]);
  };

  const openOperationsMenu = (event) => {
    setOperations(event.currentTarget);
  };

  const closeOperationsMenu = () => {
    setOperations(null);
  };

  const openLettersMenu = (event) => {
    setLetters(event.currentTarget);
  };

  const closeLettersMenu = () => {
    setLetters(null);
  };

  const openRelationsMenu = (event) => {
    setRelations(event.currentTarget);
  };

  const closeRelationsMenu = () => {
    setRelations(null);
  };

  const openArrowsMenu = (event) => {
    setArrows(event.currentTarget);
  };

  const closeArrowsMenu = () => {
    setArrows(null);
  };

  const openOthersMenu = (event) => {
    setOthers(event.currentTarget);
  };

  const closeOthersMenu = () => {
    setOthers(null);
  };

  const MenuIcon = ({ Icon, number, handleSymbol }) => {
    return (
      <MenuItem onClick={() => handleSymbol(number)}>
        <ListItemIcon>
          <Icon className={classes.menuIcon} />
        </ListItemIcon>
      </MenuItem>
    );
  };

  const operationsMenu = () => (
    <Menu
      anchorEl={operations}
      open={Boolean(operations)}
      onClose={closeOperationsMenu}
    >
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={6}>
          <MenuIcon Icon={Division} number={1} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Summation} number={2} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Integral} number={3} handleSymbol={handleSymbol} />
          <MenuIcon Icon={SquareRoot} number={4} handleSymbol={handleSymbol} />
          <MenuIcon Icon={SquareRoot2} number={5} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Limit} number={6} handleSymbol={handleSymbol} />
        </Grid>

        <Grid item xs={6}>
          <MenuIcon Icon={SubSuper} number={7} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Sub} number={8} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Super} number={9} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Overline} number={10} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Circumflex} number={11} handleSymbol={handleSymbol} />
          <MenuIcon Icon={BigCap} number={12} handleSymbol={handleSymbol} />
        </Grid>

        <Grid item xs={6}>
          <MenuIcon Icon={BigCup} number={13} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Prod} number={14} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Coprod} number={15} handleSymbol={handleSymbol} />
          <MenuIcon
            Icon={Parentheses}
            number={16}
            handleSymbol={handleSymbol}
          />
          <MenuIcon Icon={Brackets} number={17} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Bracets} number={18} handleSymbol={handleSymbol} />
        </Grid>

        <Grid item xs={6}>
          <MenuIcon Icon={Absolute} number={19} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Integral2} number={20} handleSymbol={handleSymbol} />
        </Grid>
      </Grid>
    </Menu>
  );

  const lettersMenu = () => (
    <Menu anchorEl={letters} open={Boolean(letters)} onClose={closeLettersMenu}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={6}>
          <MenuIcon Icon={Alpha} number={21} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Beta} number={22} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Gamma} number={23} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Delta} number={24} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Epsilon} number={25} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Varepsilon} number={26} handleSymbol={handleSymbol} />
        </Grid>

        <Grid item xs={6}>
          <MenuIcon Icon={Zeta} number={27} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Eta} number={28} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Theta} number={29} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Vartheta} number={30} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Iota} number={31} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Kappa} number={32} handleSymbol={handleSymbol} />
        </Grid>

        <Grid item xs={6}>
          <MenuIcon Icon={Lambda} number={33} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Mu} number={34} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Nu} number={35} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Xi} number={36} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Pi} number={37} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Varpi} number={38} handleSymbol={handleSymbol} />
        </Grid>

        <Grid item xs={6}>
          <MenuIcon Icon={Rho} number={39} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Varrho} number={40} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Sigma} number={41} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Varsigma} number={42} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Tau} number={43} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Upsilon} number={44} handleSymbol={handleSymbol} />
        </Grid>

        <Grid item xs={6}>
          <MenuIcon Icon={Phi} number={45} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Varphi} number={46} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Chi} number={47} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Psi} number={48} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Omega} number={49} handleSymbol={handleSymbol} />
        </Grid>
      </Grid>
    </Menu>
  );

  const relationsMenu = () => (
    <Menu
      anchorEl={relations}
      open={Boolean(relations)}
      onClose={closeRelationsMenu}
    >
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={6}>
          <MenuIcon Icon={Le} number={50} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Ge} number={51} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Prec} number={52} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Succ} number={53} handleSymbol={handleSymbol} />
          <MenuIcon Icon={MuchLess} number={54} handleSymbol={handleSymbol} />
          <MenuIcon
            Icon={MuchGreater}
            number={55}
            handleSymbol={handleSymbol}
          />
        </Grid>

        <Grid item xs={6}>
          <MenuIcon Icon={Equiv} number={56} handleSymbol={handleSymbol} />
          <MenuIcon Icon={NotEqual} number={57} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Sim} number={58} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Simeq} number={59} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Approx} number={60} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Subset} number={61} handleSymbol={handleSymbol} />
        </Grid>

        <Grid item xs={6}>
          <MenuIcon Icon={Supset} number={62} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Subseteq} number={63} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Supseteq} number={64} handleSymbol={handleSymbol} />
          <MenuIcon Icon={In} number={65} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Ni} number={66} handleSymbol={handleSymbol} />
          <MenuIcon Icon={NotIn} number={67} handleSymbol={handleSymbol} />
        </Grid>
      </Grid>
    </Menu>
  );

  const arrowsMenu = () => (
    <Menu anchorEl={arrows} open={Boolean(arrows)} onClose={closeArrowsMenu}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={6}>
          <MenuIcon Icon={Gets} number={68} handleSymbol={handleSymbol} />
          <MenuIcon Icon={To} number={69} handleSymbol={handleSymbol} />
          <MenuIcon
            Icon={Leftrightarrow}
            number={70}
            handleSymbol={handleSymbol}
          />
          <MenuIcon Icon={Leftarrow} number={71} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Rightarrow} number={72} handleSymbol={handleSymbol} />
          <MenuIcon
            Icon={Leftrightarrow2}
            number={73}
            handleSymbol={handleSymbol}
          />
        </Grid>

        <Grid item xs={6}>
          <MenuIcon Icon={Uparrow} number={74} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Downarrow} number={75} handleSymbol={handleSymbol} />
          <MenuIcon
            Icon={Updownarrow}
            number={76}
            handleSymbol={handleSymbol}
          />
          <MenuIcon Icon={Uparrow2} number={77} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Downarrow2} number={78} handleSymbol={handleSymbol} />
          <MenuIcon
            Icon={Updownarrow2}
            number={79}
            handleSymbol={handleSymbol}
          />
        </Grid>
      </Grid>
    </Menu>
  );

  const othersMenu = () => (
    <Menu anchorEl={others} open={Boolean(others)} onClose={closeOthersMenu}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={6}>
          <MenuIcon Icon={Times} number={80} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Divs} number={81} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Dot} number={82} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Pm} number={83} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Mp} number={84} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Ast} number={85} handleSymbol={handleSymbol} />
        </Grid>

        <Grid item xs={6}>
          <MenuIcon Icon={Circ} number={86} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Bullet} number={87} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Wedge} number={88} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Vee} number={89} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Cap} number={90} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Cup} number={91} handleSymbol={handleSymbol} />
        </Grid>

        <Grid item xs={6}>
          <MenuIcon Icon={Forall} number={92} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Exists} number={93} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Bot} number={94} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Top} number={95} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Triangle} number={96} handleSymbol={handleSymbol} />
          <MenuIcon Icon={Infty} number={97} handleSymbol={handleSymbol} />
        </Grid>
      </Grid>
    </Menu>
  );

  const handleMathquillDidMount = (mathField) => {
    mathQuillRef.current = mathField;
  };

  return (
    <div>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={12}>
          <EditableMathField
            mathquillDidMount={handleMathquillDidMount}
            className={classes.mathField}
            latex={latex}
            onChange={handleLatexChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Tooltip title="Operações Matemáticas">
            <Button onClick={openOperationsMenu}>
              <Operations className={classes.menuButton} />
            </Button>
          </Tooltip>
          <Tooltip title="Letras Gregas">
            <Button onClick={openLettersMenu}>
              <Letters className={classes.menuButton} />
            </Button>
          </Tooltip>
          <Tooltip title="Relações">
            <Button onClick={openRelationsMenu}>
              <Relations className={classes.menuButton} />
            </Button>
          </Tooltip>
          <Tooltip title="Setas">
            <Button onClick={openArrowsMenu}>
              <Arrow className={classes.menuButton} />
            </Button>
          </Tooltip>
          <Tooltip title="Outras operações">
            <Button onClick={openOthersMenu}>
              <Others className={classes.menuButton} />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {operationsMenu()}
      {lettersMenu()}
      {relationsMenu()}
      {arrowsMenu()}
      {othersMenu()}
    </div>
  );
};

export default EditableMathExample;
