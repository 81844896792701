import DashboardTemplate from '../components/template/DashboardTemplate'
import StudentDashboardTemplate from '../components/template/StudentDashboardTemplate'

import UploadPage from '../pages/UploadPage'
import RevisionPage from '../pages/RevisionPage'
import OutputPage from '../pages/OutputPage'
import FilesPage from '../pages/FilesPage'
import TemplatesPage from '../pages/TemplatesPage'
import NewTemplatePage from '../pages/NewTemplatePage'
import EditTemplatePage from '../pages/EditTemplatePage'
import LoginPage from '../pages/LoginPage'
import SignUpPage from '../pages/SignUpPage'
import PasswordRecoveryPage from '../pages/PasswordRecoveryPage'
import PublicPage from '../pages/PublicPage'
import ListExamsPage from '../pages/ListExamsPage'
import ListQuestionsPage from '../pages/ListQuestionsPage'
import ListQuestionsConfigPage from '../pages/ListQuestionsConfigPage'
import ListQuestionsViewPage from '../pages/ListQuestionsViewPage'
import ResultStudentIndividualExam from '../pages/ResultStudentIndividualExam'
import Layouts from '../pages/Layouts'
// import ListQuestionsResultViewPage from '../pages/ListQuestionsResultViewPage'
import ListStudentsFromExamPage from '../pages/ListStudentsFromExamPage'
import SelectQuestionsPage from '../pages/SelectQuestionsPage'
import ReviseQuestionsPage from '../pages/ReviseQuestionsPage'
import ListCompaniesPage from '../pages/Company/ListCompaniesPage'
import NewCompanyPage from '../pages/Company/NewCompanyPage'
import EditCompanyPage from '../pages/Company/EditCompanyPage'
import ListUserPage from '../pages/User/ListUserPage'
import NewUserPage from '../pages/User/NewUserPage'
import NewClassPage from '../pages/User/NewClassPage'
import EditUserPage from '../pages/User/EditUserPage'
import MyUserPage from '../pages/User/MyUserPage'
import InvitePage from '../pages/User/InvitePage'
import InviteStudentPage from '../pages/User/InviteStudentPage'
import DocentePage from '../pages/User/DocentePage'
import StudentPage from '../pages/User/StudentPage'
import RegisterPage from '../pages/RegisterPage'
import StudentLoginPage from '../pages/StudentLoginPage'
import StudentPainelPage from '../pages/StudentPainelPage'
import StudentExamPage from '../pages/StudentExamPage'
import StudentResultsPage from '../pages/StudentResultsPage'
import StudentSurveyPage from '../pages/StudentSurveyPage'
import ResultExamsPage from '../pages/ResultExamsPage'
import ClassificationPage from '../pages/ClassificationPage'
import EditClassPage from '../pages/User/EditClassPage'
import StartProfessor from '../pages/Tests/StartProfessor'
import ProfessorExamPage from '../pages/ProfessorExamPage'
import CheckoutPage from '../pages/CheckoutPage'
import OpenExamPage from '../pages/OpenExamPage'
import GPTCreatePage from '../pages/GPTCreatePage'
import UploadSpreadsheetPage from '../pages/User/UploadSpreadsheetPage'
import ReleasePage from '../pages/ReleasePage'

export const privateRoutes = [
  {
    path: '/upload',
    component: UploadPage,
    template: DashboardTemplate
  },
  // TODO: Reactivate route page based on warnings after revision module has been implemented
  {
    path: '/revision',
    component: RevisionPage,
    template: DashboardTemplate
  },
  {
    path: '/output',
    component: OutputPage,
    template: DashboardTemplate
  },
  {
    path: '/files',
    component: FilesPage,
    template: DashboardTemplate
  },
  {
    path: '/templates',
    component: TemplatesPage,
    template: DashboardTemplate
  },
  {
    path: '/templates/new',
    component: NewTemplatePage,
    template: DashboardTemplate
  },
  {
    path: '/templates/:id/edit',
    component: EditTemplatePage,
    template: DashboardTemplate
  },
  {
    path: '/companies',
    component: ListCompaniesPage,
    template: DashboardTemplate
  },
  {
    path: '/companies/new',
    component: NewCompanyPage,
    template: DashboardTemplate
  },
  {
    path: '/companies/:id/edit',
    component: EditCompanyPage,
    template: DashboardTemplate
  },
  {
    path: '/users',
    component: ListUserPage,
    template: DashboardTemplate
  },
  {
    path: '/users/new',
    component: NewUserPage,
    template: DashboardTemplate
  },
  {
    path: '/users/new/class',
    component: NewClassPage,
    template: DashboardTemplate
  },
  {
    path: '/users/edit/class',
    component: EditClassPage,
    template: DashboardTemplate
  },
  {
    path: '/tests/start',
    component: StartProfessor,
    template: DashboardTemplate
  },
  {
    path: '/users/:id/edit',
    component: EditUserPage,
    template: DashboardTemplate
  },
  {
    path: '/users/:id/meus_dados',
    component: MyUserPage,
    template: DashboardTemplate
  },
  {
    path: '/users/invite',
    component: InvitePage,
    template: DashboardTemplate
  },
  {
    path: '/users/invite-student',
    component: InviteStudentPage,
    template: DashboardTemplate
  },
  {
    path: '/list-exams',
    component: ListExamsPage,
    template: DashboardTemplate,
    default: true
  },
  {
    path: '/list-exams/list-questions',
    component: ListQuestionsPage,
    template: DashboardTemplate
  },
  {
    path: '/classification',
    component: ClassificationPage,
    template: DashboardTemplate
  },
  {
    path: '/list-exams/list-questions-view',
    component: ListQuestionsViewPage,
    template: DashboardTemplate
  },
  {
    path: '/list-exams/list-questions-config',
    component: ListQuestionsConfigPage,
    template: DashboardTemplate
  },
  {
    path: '/select-questions',
    component: SelectQuestionsPage,
    template: DashboardTemplate
  },
  {
    path: '/ai-creation',
    component: GPTCreatePage,
    template: DashboardTemplate
  },
  {
    path: '/revise-questions',
    component: ReviseQuestionsPage,
    template: DashboardTemplate
  },
  {
    path: '/result-exams',
    component: ResultExamsPage,
    template: DashboardTemplate
  },
  {
    path: '/result-exams/list-students',
    component: ListStudentsFromExamPage,
    template: DashboardTemplate
  },
  {
    path: '/result-exams/individual',
    component: ResultStudentIndividualExam,
    template: DashboardTemplate
  },
  {
    path: '/layouts',
    component: Layouts,
    template: DashboardTemplate
  },
  {
    path: '/professor/exam',
    component: ProfessorExamPage // Página de prova online
  },
  {
    path: '/send-spreadsheet',
    component: UploadSpreadsheetPage,
    template: DashboardTemplate
  },
  {
    path: '/releases',
    component: ReleasePage,
    template: DashboardTemplate
  },
]

export const notLoggedRoutes = [
  {
    path: '/login',
    component: LoginPage
  },
  {
    path: '/signup',
    component: SignUpPage
  },
  {
    path: '/users/register/:id',
    component: DocentePage
  },
  {
    path: '/users/register-student/:id',
    component: StudentPage
  },
  {
    path: '/register',
    component: RegisterPage
  },
  {
    path: '/forget-password',
    component: PasswordRecoveryPage
  },
  {
    path: '/student',
    component: StudentLoginPage
  },
  {
    path: '/open-exam',
    component: OpenExamPage
  },
  {
    path: '/open-result-exam/individual',
    component: ResultStudentIndividualExam,
  }
]

export const studentRoutes = [
  {
    path: '/student',
    component: StudentPainelPage, // Lista as provas disponíveis
    template: StudentDashboardTemplate,
    default: true
  },
  {
    path: '/results',
    component: StudentResultsPage, // Lista os resultados
    template: StudentDashboardTemplate
  },
  {
    path: '/student/exam',
    component: StudentExamPage // Página de prova online
  },
  {
    path: '/survey',
    component: StudentSurveyPage, // Página de pesquisa de opinião
    template: StudentDashboardTemplate
  },
  {
    path: '/student/result-exam/individual',
    component: ResultStudentIndividualExam,
    template: StudentDashboardTemplate
  }
]

export const publicRoutes = [
  {
    path: '/',
    component: PublicPage
  },
  {
    path: '/checkout-page',
    component: CheckoutPage
  }
]
