import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "antd";
import { Button, Grid } from "@material-ui/core";

import AdminButton from "../button/AdminButton";
import { StyledMenuSubMenu } from "../button/styles/AdminButton.styles";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Container, MenuItem } from "./styles/HeaderNavigation.styles";
import { connect } from "react-redux";
import "./styles/NavigationItem.scss";
import { fetchChangePage, changePageSuccess } from "../../store/exams/actions";
import {
  checkSubscriptionStatus,
  handleTrilhasLogin,
} from "../../store/users/actions";
import { logout } from "../../store/auth/actions";
import HelpModal from "../modal/HelpModal";
import ReleasePopover from "../releases/ReleasesPopover";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const HeaderNavigation = ({
  match,
  user,
  fetchChangePage,
  logout,
  checkSubscriptionStatus,
  handleTrilhasLogin,
  subscriptionStatus,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [openHelp, setOpenHelp] = React.useState(false);
  const [buttonVariant, setButtonVariant] = React.useState({
    upload: "text",
    criar: "text",
    montar: "text",
    listar: "text",
    resultados: "text",
    empresas: "text",
    usuarios: "text",
    aplicacao: "text",
    dados: "text",
    pagamento: "text",
    trilhas: "text",
    logout: "text",
    lancamentos: "text",
  });
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const selectedKeys = [match.path.substr(1)];

  const selectButton = (buttons) => {
    for (const button of buttons) {
      const { path, name } = button;
      const selectedVariant = "contained";
      if (
        window.location.pathname.includes(path) &&
        buttonVariant[name] !== selectedVariant
      ) {
        buttonVariant[name] = selectedVariant;
        setButtonVariant({ ...buttonVariant, name: selectedVariant });
        return;
      }
    }
  };

  useEffect(() => {
    checkSubscriptionStatus();
  }, []);

  useEffect(() => {
    const buttons = [
      {
        path: "upload",
        name: "upload",
      },
      {
        path: "ai-creation",
        name: "criar",
      },
      {
        path: "stripe",
        name: "pagamento",
      },
      {
        path: "trilhas",
        name: "trilhas",
      },
      {
        path: "select-questions",
        name: "montar",
      },
      {
        path: "list-exams",
        name: "listar",
      },
      {
        path: "result-exams",
        name: "resultados",
      },
      {
        path: "companies",
        name: "empresas",
      },
      {
        path: "tests/start",
        name: "aplicacao",
      },
      {
        path: "meus_dados",
        name: "dados",
      },
      {
        path: "users",
        name: "usuarios",
      },
      {
        path: "releases",
        name: "lancamentos",
      },
    ];

    selectButton(buttons);

    return () => {
      if (window.location.pathname === "/list-exams/list-questions") {
        fetchChangePage(true);
      }
    };
  }, [window.location.pathname]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleHelp = () => {
    setOpenHelp(true);
  };

  const handleReleasesClick = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const handleCloseHelp = () => {
    setOpenHelp(false);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Container
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <ul className={"NavigationItems"}>
        {user !== null && user.Role !== "Guest" && (
          <Button
            variant={buttonVariant.upload}
            color="primary"
            onClick={() => (window.location = "/upload")}
          >
            Upload
          </Button>
        )}
        {user !== null && user.Role !== "Guest" && (
          <Button
            variant={buttonVariant.criar}
            color="primary"
            onClick={() => (window.location = "/ai-creation")}
          >
            Criar
          </Button>
        )}
        {user !== null && user.Role !== "Guest" && (
          <Button
            variant={buttonVariant.montar}
            color="primary"
            onClick={() => (window.location = "/select-questions")}
          >
            Montar
          </Button>
        )}
        {user !== null && user.Role !== "Guest" && (
          <Button
            variant={buttonVariant.listar}
            color="primary"
            onClick={() => (window.location = "/list-exams")}
          >
            Listar
          </Button>
        )}
        <Button
          variant={buttonVariant.resultados}
          color="primary"
          onClick={() => (window.location = "/result-exams")}
        >
          Resultados
        </Button>
        {user !== null && user.Role !== "Guest" && (
          <>
            <Button
              variant={buttonVariant.lancamentos}
              color="primary"
              onClick={handleReleasesClick}
            >
              Lançamentos
            </Button>
            <ReleasePopover
              popoverAnchorEl={popoverAnchorEl}
              setPopoverAnchorEl={setPopoverAnchorEl}
              user={user}
            />
          </>
        )}
        {user !== null && user.Role !== "Retail" && user.Role !== "Guest" && (
          <Button
            variant={buttonVariant.trilhas}
            color="primary"
            onClick={() => handleTrilhasLogin(user.Email)}
          >
            Trilhas
          </Button>
        )}
      </ul>
    </Container>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      onClose={handleMobileMenuClose}
      selectedKeys={selectedKeys}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      mode="horizontal"
    >
      <StyledMenuSubMenu title="Menu">
        <Grid container direction="column">
          <Button
            variant={buttonVariant.upload}
            color="primary"
            onClick={() => (window.location = "/upload")}
          >
            Upload
          </Button>
          {user.Role != "Student" && (
            <Button
              variant={buttonVariant.criar}
              color="primary"
              onClick={() => (window.location = "/ai-creation")}
            >
              Criar
            </Button>
          )}
          <Button
            variant={buttonVariant.montar}
            color="primary"
            onClick={() => (window.location = "/select-questions")}
          >
            Montar
          </Button>
          <Button
            variant={buttonVariant.listar}
            color="primary"
            onClick={() => (window.location = "/list-exams")}
          >
            Listar
          </Button>
          <Button
            variant={buttonVariant.resultados}
            color="primary"
            onClick={() => (window.location = "/result-exams")}
          >
            Resultados
          </Button>
          {user !== null && user.Role !== "Guest" && (
            <>
              <Button
                variant={buttonVariant.lancamentos}
                color="primary"
                onClick={handleReleasesClick}
              >
                Lançamentos
              </Button>
              <ReleasePopover
                popoverAnchorEl={popoverAnchorEl}
                setPopoverAnchorEl={setPopoverAnchorEl}
                user={user}
              />
            </>
          )}
          {user !== null && user.Role !== "Retail" && (
            <Button
              variant={buttonVariant.trilhas}
              color="primary"
              onClick={() => handleTrilhasLogin(user.Email)}
            >
              Trilhas
            </Button>
          )}
        </Grid>
      </StyledMenuSubMenu>
    </Menu>
  );

  const handleLogout = async (e, delegate) => {
    e.preventDefault();
    await delegate();
    window.location = user.Role === "Student" ? "/student" : "/login";
  };

  const handlePaymentLogout = async (e, delegate) => {
    e.preventDefault();
    await delegate();
    window.open(
      "https://billing.stripe.com/p/login/9AQ8xyfjaczZenS5kk",
      "_blank"
    );
  };

  const renderAdminMenu = (
    <Menu selectedKeys={selectedKeys} mode="horizontal">
      <StyledMenuSubMenu title={user !== null ? user.Name : ""}>
        {user !== null && user.Role !== "Student" && (
          <Grid container direction="column">
            {user !== null &&
              (user.Role === "SuperUser" || user.Role === "Super") && (
                <Button
                  variant={buttonVariant.empresas}
                  color="primary"
                  onClick={() => (window.location = "/companies")}
                >
                  Empresas
                </Button>
              )}
            {user !== null &&
              (user.Role === "SuperUser" ||
                user.Role === "Super" ||
                user.Role === "Master" ||
                user.Role === "Retail" ||
                (user.Role === "Teacher" && user.Coordinator === true)) && (
                <Button
                  variant={buttonVariant.usuarios}
                  color="primary"
                  onClick={() => (window.location = "/users")}
                >
                  Usuários
                </Button>
              )}
            {user !== null &&
              (user.Role === "Master" ||
                user.Role === "Retail" ||
                user.Role === "Teacher") && (
                <Button
                  variant={buttonVariant.aplicacao}
                  color="primary"
                  onClick={() => (window.location = "/tests/start")}
                >
                  Aplicação
                </Button>
              )}
          </Grid>
        )}
        {user !== null &&
          (user.Role === "SuperUser" ||
            user.Role === "Super" ||
            user.Role === "Master" ||
            user.Role === "Teacher" ||
            user.Role === "Retail") && <hr />}
        <Grid container direction="column">
          {user !== null && user.Role === "Retail" && (
            <Button
              variant={buttonVariant.pagamento}
              color="primary"
              onClick={(e) => {
                if (subscriptionStatus.Plan === "Trial") {
                  window.location.href = `${process.env.STRIPE_URL}?prefilled_email=${user.Email}&client_reference_id=${user.Id}`;
                } else {
                  handlePaymentLogout(e, logout);
                }
              }}
            >
              Pagamento
            </Button>
          )}
          {user !== null && user.Role !== "Student" && (
            <Button
              variant={buttonVariant.dados}
              color="primary"
              onClick={() =>
                (window.location = "/users/" + user.Id + "/meus_dados")
              }
            >
              Meus Dados
            </Button>
          )}
          {user !== null && user.Role === "Master" && (
            <Button
              variant={buttonVariant.dados}
              color="primary"
              onClick={() => (window.location = "/send-spreadsheet")}
            >
              Cadastros
            </Button>
          )}
          <Button
            variant={buttonVariant.logout}
            color="primary"
            onClick={(e) => handleLogout(e, logout)}
          >
            Logout
          </Button>
        </Grid>
      </StyledMenuSubMenu>
    </Menu>
  );

  return (
    <Fragment>
      <HelpModal open={openHelp} handleClose={handleCloseHelp} />
      <Container>
        <div className={classes.sectionDesktop}>{renderMenu}</div>
        <div className={classes.sectionMobile}>
          {user !== null && renderAdminMenu}
          <div>{renderMobileMenu}</div>
        </div>
      </Container>
      <div className={classes.sectionDesktop}>
        {user !== null && renderAdminMenu}
      </div>
    </Fragment>
  );
};

HeaderNavigation.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  logout: PropTypes.func,
  fetchChangePage: PropTypes.func,
  changePageSuccess: PropTypes.func,
  changePage: PropTypes.any,
  checkSubscriptionStatus: PropTypes.func.isRequired,
  handleTrilhasLogin: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth, exams }) => ({
  user: auth.loggedUser,
  changePage: exams.changePage,
  subscriptionStatus: users.subscriptionStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePageSuccess,
      fetchChangePage,
      logout,
      checkSubscriptionStatus,
      handleTrilhasLogin,
    },
    dispatch
  );

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default enhance(HeaderNavigation);
