import createReducer from '../createReducer'
import {
  CLEAR_TEMPLATE_MODULE,
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_FAILURE,
  UPLOAD_TEMPLATE,
  UPLOAD_TEMPLATE_SUCCESS,
  UPLOAD_TEMPLATE_FAILURE,
  VALIDATE_TEMPLATE,
  VALIDATE_TEMPLATE_SUCCESS,
  VALIDATE_TEMPLATE_FAILURE,
  SET_TEMPLATE_ID,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_FAILURE,
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  EDIT_TEMPLATE,
  EDIT_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE_FAILURE
} from './constants'

const initialState = {
  id: '3139a96f-75cc-449c-af4a-417ac45bfc1e',
  file: null,
  query: {},
  validation: {},
  organizations: [],
  templates: [],
  template: {},
  loading: false,
  uploading: false,
  error: {}
}

export default createReducer(initialState, {
  [CLEAR_TEMPLATE_MODULE]: () => initialState,
  [FETCH_TEMPLATES]: (state, action) => ({
    ...state,
    query: action.payload,
    templates: initialState.templates,
    loading: true,
    error: initialState.error
  }),
  [FETCH_TEMPLATES_SUCCESS]: (state, action) => ({
    ...state,
    templates: action.payload,
    loading: false,
    error: initialState.error
  }),
  [FETCH_TEMPLATES_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [UPLOAD_TEMPLATE]: (state, action) => ({
    ...state,
    id: initialState.id,
    file: action.payload,
    uploading: true,
    error: initialState.error
  }),
  [UPLOAD_TEMPLATE_SUCCESS]: (state, action) => ({
    ...state,
    id: action.payload,
    uploading: false,
    error: initialState.error
  }),
  [UPLOAD_TEMPLATE_FAILURE]: (state, action) => ({
    ...state,
    uploading: false,
    error: action.payload
  }),
  [VALIDATE_TEMPLATE]: (state, action) => ({
    ...state,
    validation: initialState.validation,
    loading: true,
    error: initialState.error
  }),
  [VALIDATE_TEMPLATE_SUCCESS]: (state, action) => ({
    ...state,
    validation: action.payload,
    loading: false,
    error: initialState.error
  }),
  [VALIDATE_TEMPLATE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [SET_TEMPLATE_ID]: (state, action) => ({
    ...state,
    id: action.payload,
    file: initialState.file,
    validation: initialState.validation
  }),
  [CREATE_TEMPLATE]: (state, action) => ({
    ...state,
    loading: true
  }),
  [CREATE_TEMPLATE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false
  }),
  [CREATE_TEMPLATE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [DELETE_TEMPLATE]: (state, action) => ({
    ...state,
    loading: true
  }),
  [DELETE_TEMPLATE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false
  }),
  [DELETE_TEMPLATE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [FETCH_ORGANIZATIONS]: (state, action) => ({
    ...state,
    organizations: initialState.organizations,
    loading: true,
    error: initialState.error
  }),
  [FETCH_ORGANIZATIONS_SUCCESS]: (state, action) => ({
    ...state,
    organizations: action.payload,
    loading: false,
    error: initialState.error
  }),
  [FETCH_ORGANIZATIONS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [FETCH_TEMPLATE]: (state, action) => ({
    ...state,
    template: initialState.template,
    loading: true,
    error: initialState.error
  }),
  [FETCH_TEMPLATE_SUCCESS]: (state, action) => ({
    ...state,
    template: action.payload,
    loading: false,
    error: initialState.error
  }),
  [FETCH_TEMPLATE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [EDIT_TEMPLATE]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [EDIT_TEMPLATE_SUCCESS]: (state, action) => ({
    ...state,
    template: action.payload,
    loading: false,
    error: initialState.error
  }),
  [EDIT_TEMPLATE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  })
})
