import React, { useState } from 'react'
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { uploadSpreadsheet } from '../../store/users/actions'
import { Page } from '../styles/Page.styles'
import { Col, Row } from '../../components/table/styles/Table.styles'
import { Title } from '../../components/form/styles/Form.styles'

const UploadSpreadsheetPage = ({uploadSpreadsheet}) => {

    const [file, setFile] = useState([])

    const Input = styled('input')({
        display: 'none',
    });

    const onFileChange = event => {
      setFile(event.target.files[0])
      uploadSpreadsheet(event.target.files[0])
    }

    return (
        <Page>
            <Row center>
              <Col center size={3}><Title>Enviar Planilha</Title></Col>
            </Row>
            <Row>
              <Col center size={3}>
                <label htmlFor="contained-button-file">
                    <Input accept=".xlsx, .xls" id="contained-button-file" multiple type="file" onChange={onFileChange} />
                    <Button variant="contained" component="span">
                    Upload
                    </Button>
                </label>
                </Col>
            </Row>
            <Row>
              <Col center>
                <a href='https://simulie-api-prod.s3.sa-east-1.amazonaws.com/Planilha+Exemplo.xlsx' target='_blank'>Baixar template de planilha</a>
              </Col>
            </Row>
      </Page>
    );
};

UploadSpreadsheetPage.propTypes = {
    uploadSpreadsheet: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadSpreadsheet,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps) (UploadSpreadsheetPage);