import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";

import { clearDocumentModule } from "../store/documents/actions";
import { clearTemplateModule } from "../store/templates/actions";
import { clearOutputModule } from "../store/outputs/actions";
import { clearOrganizationModule } from "../store/organizations/actions";
import {
  clearExamModule,
  checkPagesAvailable,
} from "../store/exams/actions";
import { infoUser } from "../store/users/actions";

import ContainerCard from "../components/card/ContainerCard";
import DocumentDraggerInput from "../components/input/DocumentDraggerInput";
import { Icon } from "antd";
import { Page, UploadTitle, Title } from "./styles/Page.styles";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { TYPE_OF_EDUCATION } from "../components/question/QuestionFilterHeader/constant";

const useStyles = makeStyles((theme) => ({
  page: {
    alignItems: "center",
  },
  gabarito: {
    marginTop: 32,
    maxWidth: 728,
  },
}));

const UploadPage = ({
  checkPagesAvailable,
  clearDocumentModule,
  clearTemplateModule,
  clearOutputModule,
  clearOrganizationModule,
  clearExamModule,
  loading,
  infoUser,
  pages,
  user,
}) => {

  const classes = useStyles();
  const [scholarship, setScholarship] = useState(undefined);

  useEffect(() => {
    clearDocumentModule();
    clearTemplateModule();
    clearOutputModule();
    clearOrganizationModule();
    clearExamModule();
    infoUser();
    checkPagesAvailable();
  }, []);
  // (DOCX, DOC, PDF, RTF, JPEG, JPG, PNG)

  const handleScholarship = (e) => {
    setScholarship(e.target.value);
  }

  const v2AuthIds = ["6b14ee60-2c78-4277-82bf-9669759efddc"];

  return (
    <Page className={classes.page}>
      {pages !== undefined && (
        <ContainerCard centered centerText>
          <Title> Você pode enviar até {pages || 0} páginas</Title>
        </ContainerCard>
      )}
      <FormControl style={{ marginTop: "24px", width: "256px" }}>
        <InputLabel>Selecione o segmento *</InputLabel>
        <Select
          defaultValue={scholarship}
          onChange={handleScholarship}
        >
          {Object.keys(TYPE_OF_EDUCATION).map((education) =>
            <MenuItem value={TYPE_OF_EDUCATION[education].slug}>{TYPE_OF_EDUCATION[education].label}</MenuItem>
          )}
        </Select>
      </FormControl>
      {scholarship && (
        <>
          {user && (user.Id === "65bf06d0-d660-42d0-af16-f72e8887e20c" || user.Id === "5d192e43-2bcd-478b-ab70-3681e7c64ff6") ? (
            <>
              <ContainerCard
                centered
                style={{ width: "100%", marginBottom: 16 }}
                maxWidth={60}
                marginTop={2}
              >
                <DocumentDraggerInput recognition count={10}>
                  <UploadTitle>
                    {loading
                      ? "Processando..."
                      : "Upload em Lote (DOCX, DOC, PDF, RTF, JPG, PNG)"}
                  </UploadTitle>
                </DocumentDraggerInput>
              </ContainerCard>
            </>
          ) : (
            <ContainerCard
              centered
              centerText
              style={{ width: "100%" }}
              maxWidth={60}
              marginTop={user && v2AuthIds.includes(user.Id) ? 0.5 : 2}
            >
              <DocumentDraggerInput authorization={user && v2AuthIds.includes(user.Id)} scholarship={scholarship}>
                <Icon rotate={!loading && 20} type={loading ? "loading" : "file"} />
                <UploadTitle>
                  {loading
                    ? "Processando..."
                    : "Clique ou arraste (DOCX, DOC, PDF, RTF, JPG, PNG)"}
                </UploadTitle>
              </DocumentDraggerInput>
            </ContainerCard>
          )}
        </>
      )}
    </Page>
  );
};

UploadPage.propTypes = {
  clearDocumentModule: PropTypes.func.isRequired,
  clearTemplateModule: PropTypes.func.isRequired,
  clearOutputModule: PropTypes.func.isRequired,
  clearOrganizationModule: PropTypes.func.isRequired,
  clearExamModule: PropTypes.func.isRequired,
  infoUser: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  pages: PropTypes.string,
  checkPagesAvailable: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams, users }) => ({
  fsIds: exams.fsIds,
  loading: exams.loading,
  pages: exams.pages,
  user: users.infoUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      checkPagesAvailable,
      clearDocumentModule,
      clearTemplateModule,
      clearOutputModule,
      clearOrganizationModule,
      clearExamModule,
      infoUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UploadPage);
