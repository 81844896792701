import React, { Component } from 'react'
import SignUpForm from '../components/form/SignUpForm'
import { Page } from './styles/Page.styles'

class SignUpPage extends Component {

  render () {

    return (
      <Page>
        <SignUpForm />
      </Page>
    )
  }
}

export default SignUpPage