import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
    Dialog,
    DialogTitle,
    Button,
    TextField,
    Grid,
    Typography,
    IconButton,
    Box,
} from "@material-ui/core";

const TextFieldDialog = ({open, closeDialog, textFieldValue, handleChange, handleSave, title, label}) => {
    return (
        <Dialog
            fullWidth
            onClose={closeDialog}
            open={open}
            >
            <Box sx={{
                pl: 5, pr: 5, pb: 5,
            }}>
                <DialogTitle style={{padding: '16px 0px'}}>
                <Grid container justifyContent="space-between" alignItems="center">
                <Typography>{title}</Typography>
                <IconButton onClick={closeDialog} style={{padding: '0px'}}>
                    <CloseIcon/>
                </IconButton>         
                </Grid>
                </DialogTitle>
                <Grid container spacing={4} direction="column">
                <Grid item>
                    <TextField
                    label={label}
                    variant="outlined"
                    value={textFieldValue}
                    onChange={handleChange}
                    multiline
                    fullWidth            
                    />
                </Grid>
                <Grid container justifyContent="center">
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    >
                    Salvar
                    </Button>
                </Grid>
                </Grid>
            </Box>
        </Dialog>
    )
}

export default TextFieldDialog;