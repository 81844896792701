import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { makeStyles } from '@material-ui/core/styles'
import { Menu } from 'antd'

import AdminButton from '../button/AdminButton'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { Container } from './styles/HeaderNavigation.styles'
import { connect } from 'react-redux'
import './styles/NavigationItem.scss'
import { NH_SUPER_ID } from '../../utils/constants'

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))

const HeaderStudentNavigation = ({ match, user }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [showSurveyOption, setShowSurveyOption] = useState(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const selectedKeys = [match.path.substr(1)]

  useEffect(() => {
    console.log('Header Student Navigation')
  })

  useEffect(() => {
    if (user && user.SuperId === NH_SUPER_ID) {
      setShowSurveyOption(true)
    }
  }, [user])

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const updateLinkActive = function (props) {
    let url = window.location.pathname
    console.log(url)
    console.log(props)
    console.log(url.indexOf('/blabla'))
    if (url.indexOf('/student') !== -1 && props === '/student') {
      return true
    } else if (url.indexOf('/results') !== -1 && props === '/results') {
      return true
    } else if (url.indexOf('/survey') !== -1 && props === '/survey') {
      return true
    } else {
      return false
    }
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Container
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <ul className={'NavigationItems'}>
        <li className='NavigationItem'>
          <NavLink
            to='/student'
            exac
            isActive={() => updateLinkActive('/student')}
            activeClassName='active'>Provas</NavLink>
        </li>
        <li className='NavigationItem'>
          <NavLink
            to='/results'
            exac
            isActive={() => updateLinkActive('/results')}
            activeClassName='active'>Resultados</NavLink>
        </li>
        {showSurveyOption && (
          <li className='NavigationItem'>
            <NavLink
              to='/survey'
              exac
              isActive={() => updateLinkActive('/survey')}
              activeClassName='active'
            >
              Pesquisa
            </NavLink>
          </li>
        )}
      </ul>
    </Container>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      onClose={handleMobileMenuClose}
      selectedKeys={selectedKeys}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      mode='horizontal'
    >
      <Menu.SubMenu title='Menu'>
        <Menu.Item key='provas'>
          <Link to={'/student'} exac={false} isActive={() => updateLinkActive('/student')} activeClassName='active'>
            Provas
          </Link>
        </Menu.Item>
        <Menu.Item key='resultados'>
          <Link to={'/results'} exac={false} isActive={() => updateLinkActive('/results')} activeClassName='active'>
            Resultados
          </Link>
        </Menu.Item>
        {showSurveyOption && (
          <Menu.Item key='resultados'>
            <Link to={'/survey'} exac={false} isActive={() => updateLinkActive('/survey')} activeClassName='active'>
              Pesquisa
            </Link>
          </Menu.Item>
        )}
      </Menu.SubMenu>
    </Menu>
  )

  return (
    <Fragment>
      <Container>
        <div className={classes.sectionDesktop}>
          {renderMenu}
        </div>
        <div className={classes.sectionMobile}>
          {
            user !== null && <AdminButton user={user} selectedKeys={selectedKeys} />
          }
          <div>
            {renderMobileMenu}
          </div>
        </div>
      </Container>
      <div className={classes.sectionDesktop}>
        {
          user !== null && <AdminButton user={user} selectedKeys={selectedKeys} />
        }
      </div>
    </Fragment >
  )
}

HeaderStudentNavigation.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = ({ users, auth }) => ({
  user: auth.loggedUser
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
  }, dispatch)

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter

)

export default enhance(HeaderStudentNavigation)
