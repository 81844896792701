import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { SUPPORTED_FILE_TYPES } from '../../utils/constants'
import { uploadTemplate } from '../../store/templates/actions'

import InputHelpModal from '../modal/InputHelpModal'
import { TemplateDragger, DraggerTitle } from './styles/Input.styles'

const TemplateDraggerInput = ({
  children,
  title,
  loading,
  uploadTemplate
}) => {
  const [helpType, setHelpType] = useState('')

  const validate = file => {
    const helpType = {
      [file.size > 10485760]: 'heavy',
      [!SUPPORTED_FILE_TYPES.includes(file.type)]: 'default'
    }[true]
    helpType && setHelpType(helpType)
    return !helpType
  }

  const handleChange = ({ file }) => {
    validate(file) && uploadTemplate(file)
  }

  return (
    <Fragment>
      <TemplateDragger
        accept={SUPPORTED_FILE_TYPES.join(',')}
        beforeUpload={() => false}
        disabled={loading}
        onChange={handleChange}
        showUploadList={false}
      >
        {children}
        {title && <DraggerTitle>{title}</DraggerTitle>}
      </TemplateDragger>
      <InputHelpModal
        type={helpType}
        onClose={() => setHelpType('')}
      />
    </Fragment>
  )
}

TemplateDraggerInput.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  loading: PropTypes.bool,
  uploadTemplate: PropTypes.func.isRequired
}

const mapStateToProps = ({ templates }) => ({
  loading: templates.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    uploadTemplate
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TemplateDraggerInput)
