/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DataGrid } from "@mui/x-data-grid";

import {
  clearExamModule,
  fetchStudentResultExam,
  fetchStudentIndividualResultExam,
  fetchStudentResult,
  openTestUpdate,
} from "../store/exams/actions";
// import history from '../routes/history'

import QuestionResultBlock from "../components/question/QuestionResultBlock";
import ContainerCard from "../components/card/ContainerCard";

import { Page } from "./styles/Page.styles";
import { Content } from "./styles/DetailsPage.styles";
import { Empty } from "./styles/ListExamsPage.styles";
import { Grid, Switch } from "@material-ui/core";

function ListQuestionViewPage({
  examId,
  user,
  fetchStudentResultExam,
  clearExamModule,
  fetchStudentIndividualResultExam,
  fetchStudentResult,
  students,
  loading,
  editar,
  selected,
  isSuperAdmin,
  openTest,
  openTestUpdate
}) {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [showTags, setShowTags] = useState(false);
  const [tags, setTags] = useState([]);
  const [table, setTable] = useState(undefined);
  const [tableTags, setTableTags] = useState(undefined);
  const [loadQuestions, setLoadQuestions] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openId, setOpenId] = useState("");
  const [openExamId, setOpenExamId] = useState("");
  const [mean, setMean] = useState(0.0);
  const [firstRender, setFirstRender] = useState(true);

  function handleSuffleQuestions(array) {
    let newArray = extractQuestions(array);
    setSelectedQuestions(newArray);
  }

  useEffect(() => {
    clearExamModule();

    let url = new URL(window.location.href);
    let examId = url.searchParams.get("examId");
    let studentId = url.searchParams.get("studentId");
    let open = url.searchParams.get("isOpen");
    if (open !== "1") {
      fetchStudentResultExam({
        examId: examId,
        page: 1,
        itemsPerPage: 10
      });
    }

    if (studentId) {
      if (open === "1") {
        setIsOpen(true);
        setOpenId(studentId);
        setOpenExamId(examId);
      }
      fetchStudentResult(examId, studentId, isOpen);
    } else {
      fetchStudentIndividualResultExam(examId);
    }
  }, []);

  useEffect(() => {
    if (
      selected !== undefined &&
      selected.Questions !== undefined &&
      selected.Questions.length > 0
    ) {
      setTags(extractTags([selected]));
      setSelectedQuestions(selected.Questions);
      setLoadQuestions(true);
      if (isOpen) {
        handleOpenTestUpdate(selected.Questions);
      }
    }

    // if (selected && selected.length === 0) {
    // let url = new URL(window.location.href)
    // let examId = url.searchParams.get('examId')
    // history.push('/result-exams/list-students?examId=' + examId)
    // }
  }, [selected]);

  useEffect(() => {
    if (students) {
      mountTable();
    }
    
  }, [students]);

  const getType = (key) => {
    if (key.startsWith("name")) {
      return "name";
    } else {
      return "default";
    }
  };

  const isNumeric = (num) => {
    return !isNaN(num);
  };

  const handleOpenTestUpdate = (questions) => {
    let grade = 10 * questions.filter(q => q.IsCorrect === true).length / questions.length;
    setMean(grade);
    openTestUpdate(openExamId, openId, grade);
  }

  const mountTable = () => {
    let grades = undefined;
    let userId = user.Id;
    if (isOpen) userId = openId;
    for (var escola in students) {
      for (var turma in students[escola]) {
        for (var aluno in students[escola][turma]) {
          if (aluno === userId) {
            grades = students[escola][turma][aluno];
          }
        }
      }
    }

    if (!grades) return;

    let sumQuestions = 0;
    const row = {
      id: 1,
      name: grades.name,
    };

    for (var key in grades.questions) {
      const qNum = Number(key) + 1;
      row["Questão " + qNum] = grades.questions[key];
      sumQuestions += grades.questions[key];
    }
    row["Média Questões"] = sumQuestions / grades.questions.length;

    for (var key in grades.tags_mean) {
      row[key] = grades.tags_mean[key];
    }

    const columType = {
      name: (key) => ({
        field: key,
        headerName: "Nome",
        sortable: false,
        width: 200,
      }),
      default: (key) => ({
        field: key,
        headerName: key,
        width: 120,
        type: "number",
        renderCell: (params) => params.value || "0.00",
      }),
    };

    const columns = Object.keys(row).map((key) => {
      const type = getType(key);
      return columType[type](key);
    });

    const posTotalGrade = columns.map((e) => e.field).indexOf("Média Questões") + 1;
    const questionColumns = columns.slice(2, posTotalGrade);
    const tagsColumns = columns.slice(posTotalGrade);

    for (var key in row) {
      if (isNumeric(row[key]) && key != "id" && key != "click") {
        row[key] = Number(row[key]).toFixed(2);
      }
    }

    setTable(
      <div style={{ height: "25vh", width: "100%" }}>
        <DataGrid
          rows={[row]}
          columns={questionColumns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          disableColumnMenu
          // onRowClick={(event) => handleViewAction(idExam, event)}
        />
      </div>
    );

    setTableTags(
      <div style={{ height: "25vh", width: "100%" }}>
        <DataGrid
          rows={[row]}
          columns={tagsColumns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          disableColumnMenu
          // onRowClick={(event) => handleViewAction(idExam, event)}
        />
      </div>
    );
  };

  function extractQuestions(array) {
    let newArray = [];
    if (array) {
      for (let i = 0; i < array.length; i++) {
        newArray.push(array[i].question);
      }
    }

    return newArray;
  }

  function updateSelected(array) {
    setSelectedQuestions(array);
  }

  function extractTags(exams) {
    let tagList = [];
    if (exams !== undefined) {
      for (let x = 0; x < exams.length; x++) {
        if (exams[x].Questions !== undefined && exams[x].Questions !== null) {
          for (let y = 0; y < exams[x].Questions.length; y++) {
            for (let z = 0; z < exams[x].Questions[y].Tags.length; z++) {
              tagList.push(exams[x].Questions[y].Tags[z]);
            }
          }
        }
      }
    }

    return [...new Set(tagList)];
  }

  function updateCorrectAnswer(question) {
    let updateSelectedQuestions = selectedQuestions.map((quest) => {
      if (quest.Id === question.Id) {
        return question;
      }
      return quest;
    });

    setSelectedQuestions(updateSelectedQuestions);
  }

  function updateQuestionType(question, type) {
    if (question !== "undefined") {
      let updateSelectedQuestions = selectedQuestions.map((quest) => {
        if (quest.Id === question.Id) {
          return { ...quest, Type: type };
        }
        return quest;
      });

      setSelectedQuestions(updateSelectedQuestions);
    }
  }

  function removeModal(array) {
    setSelectedQuestions(array);
  }

  const handleSwitch = () => {
    setShowTags(!showTags)
  }

  return (
    <Page>
      {table && <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item>Questões</Grid>
          <Grid item>
            <Switch checked={showTags} onChange={handleSwitch} color="primary" />
          </Grid>
        <Grid item>Tags</Grid>
      </Grid>}
      {openTest && openTest.openTestMean && <Grid container justifyContent="space-around" alignItems="center" spacing={1}>
        <Grid item>Média geral: {Number(openTest["openTestMean"]).toFixed(2)}</Grid>
        <Grid item>Número de participantes: {Number(openTest["openTestCounter"]).toFixed(2)}</Grid>
        <Grid item>Sua média: {mean} </Grid>
      </Grid>}
      {selected !== undefined &&
      selected.Questions !== undefined &&
      selected.Questions.length > 0 ? (
        <Content style={{ width: "100%", maxWidth: "1200px" }}>
          {showTags ? tableTags : table}
          {loadQuestions && (
            <QuestionResultBlock
              isSuperAdmin={isSuperAdmin}
              loading={loading}
              selectedQuestions={selectedQuestions}
              loadQuestions
              tags={tags}
              exam={selected}
              onShuffle={handleSuffleQuestions}
              changeTypeInline
              examId={examId}
              updateSelected={updateSelected}
              updateCorrectAnswer={updateCorrectAnswer}
              updateType={updateQuestionType}
              removeModal={removeModal}
              justView
              exams={selected}
              pickedExams={selected}
              editable={false}
              editView
              sufflable
              onlyModal
              createView={false}
              view
            />
          )}
          <br />
        </Content>
      ) : (
        <ContainerCard padding={0} shadowYOffset={5}>
          <Empty
            description="Nenhum arquivo encontrado!"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        </ContainerCard>
      )}
    </Page>
  );
}

ListQuestionViewPage.propTypes = {
  examId: PropTypes.string,
  students: PropTypes.object,
  edit: PropTypes.bool,
  editar: PropTypes.bool,
  selected: PropTypes.object,
  loading: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  clearExamModule: PropTypes.func,
  fetchStudentResult: PropTypes.func,
  fetchStudentResultExam: PropTypes.func,
  fetchStudentIndividualResultExam: PropTypes.func,
  openTest: PropTypes.object,
  openTestUpdate: PropTypes.func,
};

const mapStateToProps = ({ exams, auth }) => ({
  user: auth.loggedUser,
  examId: exams.id,
  edit: exams.editQuestions,
  selected: exams.selected,
  editar: exams.editar,
  loading: exams.loading,
  resolutions: exams.resolutions,
  isSuperAdmin: auth.loggedUser.Role === "SuperUser",
  students: exams.result,
  openTest: exams.openTest,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearExamModule,
      fetchStudentResultExam,
      fetchStudentIndividualResultExam,
      fetchStudentResult,
      openTestUpdate,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListQuestionViewPage);
