import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoginForm from '../components/form/LoginForm';
import StudentLoginForm from '../components/form/StudentLoginForm';
import PasswordRecoveryModal from '../components/modal/PasswordRecoveryModal';
import { Page } from './styles/Page.styles';
import MuiButton from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

class StudentLoginPage extends Component {
  state = {
    isModalVisible: false,
    userType: 'student',
    redirectTo: null
  };

  render() {
    const { isModalVisible, userType, redirectTo } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <Page>
        <div className="container">
          <div className="switch-container">
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              <MuiButton
                variant={userType === 'professor' ? 'contained' : 'outlined'}
                onClick={() => this.handleUserTypeChange('professor')}
              >
                Professor
              </MuiButton>
              <MuiButton
                variant={userType === 'student' ? 'contained' : 'outlined'}
                onClick={() => this.handleUserTypeChange('student')}
              >
                Estudante
              </MuiButton>
            </ButtonGroup>
          </div>
          <div className="login-container">
              <StudentLoginForm onForgotPassword={this.toggleModal} />
              <PasswordRecoveryModal visible={isModalVisible} toggle={this.toggleModal} />
          </div>
        </div>
      </Page>
    );
  }

  handleUserTypeChange = userType => {
    const { history } = this.props;
    const redirectTo = userType === 'professor' ? '/login' : '/student';
    history.push(redirectTo);
  };

  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };
}

export default StudentLoginPage;
