import {
  CLEAR_DOCUMENT_MODULE,
  // SET_FILE,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  VALIDATE_DOCUMENT,
  VALIDATE_DOCUMENT_SUCCESS,
  VALIDATE_DOCUMENT_FAILURE
} from './constants'

export const clearDocumentModule = () => ({
  type: CLEAR_DOCUMENT_MODULE
})

// export const setFile = file => ({
//   type: SET_FILE,
//   payload: file
// })

export const uploadDocument = file => ({
  type: UPLOAD_DOCUMENT,
  payload: file
})

export const uploadDocumentSuccess = info => ({
  type: UPLOAD_DOCUMENT_SUCCESS,
  payload: info
})

export const uploadDocumentFailure = error => ({
  type: UPLOAD_DOCUMENT_FAILURE,
  payload: error
})

export const validateDocument = document => ({
  type: VALIDATE_DOCUMENT,
  payload: document
})

export const validateDocumentSuccess = validation => ({
  type: VALIDATE_DOCUMENT_SUCCESS,
  payload: validation
})

export const validateDocumentFailure = error => ({
  type: VALIDATE_DOCUMENT_FAILURE,
  payload: error
})
