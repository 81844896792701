import { all, call, takeLatest, put } from 'redux-saga/effects'
import { normalizeError } from '../../utils/errorHelpers'
import { message } from 'antd'

import * as actions from './actions'
import * as types from './constants'
import * as services from './services'

export function * sendFeedback ({ payload }) {
  try {
    yield call(services.sendFeedback, payload)
    message.success('Feedback enviado com sucesso')
    yield put(actions.sendFeedbackSuccess())
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao enviar feedback')
    message.error(errorInfo.message)
    yield put(actions.sendFeedbackFailure(errorInfo))
  }
}

// Watchers
export function * watchSendFeedback () {
  yield takeLatest(types.SEND_FEEDBACK, sendFeedback)
}

export default function * () {
  yield all([
    watchSendFeedback()
  ])
}
