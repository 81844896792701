import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchOrganizations } from '../../store/templates/actions'

import { Select } from 'antd'
import ValidatedSharedInput from './ValidatedSharedInput'

const OrganizationInput = ({
  label,
  field,
  form,
  HelpComponent,
  fetchOrganizations,
  loading,
  organizations
}) => {
  const [search, setSearch] = useState('')
  useEffect(() => { fetchOrganizations() }, [])
  useEffect(() => {
    if (!field.value || !organizations.length) return
    const organization = organizations.find(o => o.id === field.value)
    organization && setSearch(organization.name || '')
  }, [field.value])

  const options = organizations.map(organization =>
    <Select.Option
      key={organization.id}
      value={organization.id}
      name={organization.name}
    >
      {organization.name}
    </Select.Option>
  )

  const createOption = search &&
    !organizations.find(organization => organization.name.toLowerCase() === search.toLowerCase()) &&
    (
      <Select.Option
        key={search.charAt(0).toUpperCase() + search.slice(1)}
        value={`new:${search.charAt(0).toUpperCase() + search.slice(1)}`}
        name={search.charAt(0).toUpperCase() + search.slice(1)}
      >
        {search.charAt(0).toUpperCase() + search.slice(1)}
      </Select.Option>
    )

  return (
    <ValidatedSharedInput
      touched={form.touched[field.name]}
      error={form.errors[field.name]}
      label={label}
      HelpComponent={HelpComponent}
    >
      <Select
        allowClear
        showSearch
        loading={loading}
        className='organization-input'
        placeholder='Criar ou selecionar organização'
        filterOption={(input, option) => option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onSearch={setSearch}
        value={search}
        onChange={value => form.setFieldValue(field.name, value)}
      >
        {options}
        {createOption}
      </Select>
    </ValidatedSharedInput>
  )
}

OrganizationInput.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  HelpComponent: PropTypes.func,
  fetchOrganizations: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  organizations: PropTypes.array
}

const mapStateToProps = ({ templates }) => ({
  loading: templates.loading,
  organizations: templates.organizations
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchOrganizations
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationInput)
