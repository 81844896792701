const _listJoin = (sequence) => {
  const array = Array.from(sequence)
  if (array.length === 0) return ''
  if (array.length === 1) return array[0]
  return `${array.join(', ')} e ${array.pop()}`
}

export const renderQuestionNumber = (questionNumber) => {
  if (!questionNumber) return '??'
  return questionNumber.toString().padStart(2, 0)
}

export const renderQuestionPosition = (questionPosition) => {
  const { $type, before, after } = questionPosition
  if ($type === 'DocumentStart') {
    return 'no início da prova'
  }

  if ($type === 'DocumentEnd') {
    return 'no final da prova'
  }

  if ($type === 'BetweenQuestions') {
    if (!before && !after) {
      return 'entre duas questões sem número'
    }

    const questionStartText = before
      ? `entre a questão ${renderQuestionNumber(before)}`
      : 'entre uma questão sem número'

    const questionEndText = after
      ? `e a questão ${renderQuestionNumber(after)}`
      : 'e uma questão sem número'

    return questionStartText + questionEndText
  }
}

export const renderQuestionWarning = (questionWarning) => {
  const {
    $type,
    alternativeCount,
    majorityCount,
    reference,
    indexes
  } = questionWarning

  return {
    NoAlternativeChunksWarning: 'A questão não possui nenhum bloco de alternativas.',
    MultipleAlternativeChunksWarning: 'A questão tem mais de um bloco de alternativas.',
    MinorityAlternativeCountWarning: 'A questão tem ' + alternativeCount + ' alternativas, mas a maioria das outras questões da prova têm ' + majorityCount + ' alternativas.',
    AnonymousQuestionWarning: 'A questão não foi numerada no arquivo de entrada.',
    MissingBoldInStatementWarning: 'O enunciado diz “' + reference + '”, mas nenhuma parte da questão está em negrito.',
    MissingUnderlineInStatementWarning: 'O enunciado diz “' + reference + '”, mas nenhuma parte da questão está sublinhada.',
    LineReferenceWarning: 'Verifique, e corrija se necessário, a referência “' + reference + '” — o número da linha pode ter se alterado durante a diagramação.',
    MissingQuestionNumberWarning: 'Não há nenhuma questão com este número.',
    DuplicateQuestionNumberWarning: 'Há mais de uma questão com este número.',
    UnsortedQuestionNumberWarning: 'A questão está fora da ordem numérica.',
    DuplicateAlternativeContentWarning: indexes && 'As alternativas ' + _listJoin(Array.from(indexes).map(function (it) { return String.fromCodePoint('A'.codePointAt(0) + it) })) + ' são idênticas.',
    DuplicateAlternativeLetterWarning: 'Duas alternativas da questão têm a mesma letra.'
  }[$type]
}
