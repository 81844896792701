import React, { useState, useRef, useEffect } from "react";
import TextFieldDialog from "../../modal/TextFieldDialog";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { updateResultRW } from "../../../store/exams/actions";
import { message } from "antd";

const Observation = ({
  studentId,
  openObsevation,
  setOpenObservation,
  observation,
  setObservation,
  area,
  month,
  updateResultRW,
  setResultRW,
  setSizeAndCount,
  resultRW,
  updateRW,
}) => {
  const studentsData = useRef([]);
  const [studentsResult, setStudentsResult] = useState([]);

  useEffect(() => {
    if (month && resultRW && resultRW[month]) {
      const students = Object.entries(resultRW[month])
        .map(([key, value]) => ({
          StudentId: key,
          ...value,
        }))
        .sort((a, b) => a.Name.localeCompare(b.Name));
      setStudentsResult(students);
      studentsData.current = students;
    } else {
      setStudentsResult([]);
    }
  }, [month, resultRW, updateRW]);

  const closeObservationDialog = () => {
    setOpenObservation(false);
    handleEmptyObservation(studentId);
    setObservation({ message: "" });
  };

  const handleSaveObservation = () => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];
    setOpenObservation(false);
    if (!studentData.Observation) {
      studentData.Observation = {};
    }
    studentData.Observation[area] = observation.message;
    handleEmptyObservation(studentId);
    const resultRW = setResultRW(month, studentId, studentData);
    updateResultRW(resultRW, studentData);
    message.info("Salvando marcação...");
  };

  const handleEmptyObservation = (studentId) => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];
    if (observation.message === "") {
      const naoAvaliado = area === "Writing" ? "NaoAvaliado" : "NaoAvaliadoRF";
      studentData[area][naoAvaliado] = false;
    }
    studentsData.current[studentIndex] = studentData;
    const resultRW = setResultRW(month, studentId, studentData);
    updateResultRW(resultRW, studentData);
    message.info("Salvando marcação...");
    setSizeAndCount(studentsData.current);
  };

  const handleObservationChange = (e) => {
    setObservation({
      id: studentId,
      message: e.target.value,
    });
  };

  return (
    <TextFieldDialog
      open={openObsevation}
      closeDialog={closeObservationDialog}
      textFieldValue={observation.message}
      handleChange={handleObservationChange}
      handleSave={handleSaveObservation}
      title={"Insira o motivo:"}
      label={"Observação"}
    />
  );
};

Observation.propTypes = {
  updateResultRW: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams }) => ({
  resultRW: exams.resultRW,
  updateRW: exams.updateRW,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateResultRW,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Observation);
