import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import history from '../../routes/history'
import { download } from '../../utils/fileHelpers'
import { deleteTemplate, fetchTemplates } from '../../store/templates/actions'

import AdminPermission from '../permission/AdminPermission'
import ActionList from '../list/ActionList'
import ContainerCard from '../card/ContainerCard'
import TemplateModal from '../modal/TemplateModal'
import { List, Spin, Tooltip } from 'antd'
import { Row, Col, Header, Empty, Pagination, Search } from './styles/Table.styles'
import { Name, OrganizationName } from './styles/TemplatesTable.styles'

const TemplatesTable = ({ deleteTemplate, fetchTemplates, templates, loading }) => {
  const [template, setTemplate] = useState()
  const [search, setSearch] = useState('')

  useEffect(() => {
    fetchTemplates({ page: 1, pageSize: 10 })
  }, [])

  const renderSearch = type => (
    <Search
      type={type}
      onChange={e => setSearch(e.target.value)}
      onSearch={search => fetchTemplates({ page: Number(templates.page), pageSize: 10, search })}
      placeholder='Buscar'
      value={search}
    />
  )

  const header = (
    <Row>
      <Col size={4}><Header>nome</Header>{renderSearch('desktop')}</Col>
      <AdminPermission><Col center size={2}><Header>organização</Header></Col></AdminPermission>
      <Col center size={2}><Header>data</Header></Col>
      <Col center><Header>ações</Header></Col>
    </Row>
  )

  const renderName = name => (
    <Tooltip
      title={name}
      placement='topLeft'
    >
      <Name>
        {name}
      </Name>
    </Tooltip>
  )

  const renderOrganizationName = organizationName => {
    const name = organizationName || 'Não há vínculo'

    return (
      <Tooltip
        title={name}
        placement='topLeft'
      >
        <OrganizationName disabled={!organizationName}>
          {name}
        </OrganizationName>
      </Tooltip>
    )
  }

  const renderDate = date => {
    const localeDate = new Date(date).toLocaleDateString()
    return (
      <Tooltip
        title={localeDate}
        placement='topLeft'
      >
        {localeDate}
      </Tooltip>
    )
  }

  const renderActions = item => item && (
    <ActionList
      // onView={() => setTemplate(item)}
      onEdit={() => history.push(`/templates/${item.id}/edit`)}
      onDownload={() => download(item.file_url, item.name)}
      onDelete={() => deleteTemplate(item.id)}
    />
  )

  const renderItem = item => (
    <List.Item>
      <Row>
        <Col size={4}>{renderName(item.name)}</Col>
        <AdminPermission><Col center size={2}>{renderOrganizationName(item.organization_name)}</Col></AdminPermission>
        <Col center size={2}>{renderDate(item.created_at)}</Col>
        <Col center>{renderActions(item)}</Col>
      </Row>
    </List.Item>
  )

  const pagination = templates.total > 10 && (
    <Pagination
      current={Number(templates.page)}
      total={Number(templates.total)}
      onChange={page => fetchTemplates({ page, pageSize: 10, search })}
    />
  )

  const empty = (
    <Empty
      description='Nenhum arquivo encontrado'
      image={Empty.PRESENTED_IMAGE_DEFAULT}
    />
  )

  const templateModal = (
    <TemplateModal
      template={template}
      visible={!!template}
      onCancel={() => setTemplate(null)}
    />
  )

  return (
    <Spin spinning={loading}>
      {renderSearch('mobile')}
      {header}
      <ContainerCard
        padding={0}
        shadowYOffset={5}
      >
        <List
          dataSource={templates}
          locale={{ emptyText: empty }}
          renderItem={renderItem}
        />
      </ContainerCard>
      {pagination}
      {templateModal}
    </Spin>
  )
}

TemplatesTable.propTypes = {
  deleteTemplate: PropTypes.func.isRequired,
  fetchTemplates: PropTypes.func.isRequired,
  templates: PropTypes.array,
  loading: PropTypes.bool
}

const mapStateToProps = ({ templates }) => ({
  templates: templates.templates,
  loading: templates.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    deleteTemplate,
    fetchTemplates
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesTable)
