import styled from '@emotion/styled'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  .ant-list-split .ant-list-item {
    border-bottom: none;
  }

  input {
    margin-right: 10px;
    margin-top: 5px;
  }
`
export const QuestionHeader = styled.div`
  display: flex;
  justify-content: initial;
  margin-bottom: 15px;
`
export const QuestionNumber = styled.div`
  position: relative;
  display: block;
  color: #74809D;
`
export const QuestionInstructions = styled.div`
  margin-bottom: 5px;
  position: relative;
  font-weight: none !important;
`

export const QuestionNumberFont = styled.div`
  font-size: 16px;
  font-weight: bold;
  border: 1px solid transparent;
  border-radius: 25px;
  padding: 5px 15px 5px 15px;
  margin-right: 15px;
  color: #fff;
  background-color: #514FFF;
  display: inline;
`

export const QuestionAction = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #000000;

  .ant-btn-danger {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
  }
`

export const QuestionText = styled.div`
  margin: 15px 0;
`
