import styled from '@emotion/styled'

export const Container = styled.div`
  background: #EEEEEE;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  margin: 0 auto;
  max-width: 90rem;
  width: 100%;
`
