import http, { updateToken } from '../../utils/http2'
import { mapHeadersToData } from '../../utils/httpHelpers'

const organizationEndpoint = '/organizations'

export const fetchOrganization = (query = {}) => {
  updateToken()
  const { page, pageSize, search } = query
  const params = {
    'q[name_cont]': search,
    page: page || 1,
    per: pageSize || 1000
  }
  return http
    .get(organizationEndpoint, { params })
    .then(mapHeadersToData('x-total-count', 'x-page'))
}

export const createOrganization = data => {
  updateToken()
  const req = {
    organization: data
  }

  return http
    .post(organizationEndpoint, req)
    .then(res => res.data).catch(error => error.data)
}
