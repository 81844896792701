import React from 'react'
import PropTypes from 'prop-types'
import { Card } from './styles/Card.styles'

const ContainerCard = props => {
  const { children } = props

  return (
    <Card {...props}>
      {children}
    </Card>
  )
}

ContainerCard.propTypes = {
  children: PropTypes.node
}

export default ContainerCard
