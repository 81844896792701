/* eslint-disable */
import React, { memo } from "react";

import {
    TableCell,
    Checkbox,
} from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

function TableCellMemo({
    key,
    studentId,
    semanticField,
    month,
    field,
    checked,
    area,
    handleCheckbox,
    paddingCell,
    checkedFieldColors,
    showEditButton,
    handleEditObservation
}) {
    return (
        <TableCell
            key={key}
            padding="none"
            className={paddingCell}
            size="small"
            align="center"
        >
            <Checkbox
                checked={checked}
                onClick={() => handleCheckbox(area, month, studentId, field, semanticField, key)}
                style={{ color: checkedFieldColors[field] }}
            />
            {showEditButton  && (
               <IconButton onClick={handleEditObservation}>
                <EditOutlinedIcon/>
               </IconButton>
            )}
        </TableCell>
    );
}

const areEqual = (prevProps, nextProps) => {
    // Manter assim por mais que pareça bronco, loucuras do JS
    return (
        prevProps.checked === nextProps.checked &&
        prevProps.key === nextProps.key
    );
};

export default memo(TableCellMemo, areEqual);
