import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  INFO_USER,
  INFO_USER_SUCCESS,
  INFO_USER_FAILURE,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPLOAD_SPREADSHEET,
  UPLOAD_SPREADSHEET_SUCCESS,
  UPLOAD_SPREADSHEET_FAILURE,
  FETCH_PLAN_EXPIRATION_DATE,
  FETCH_PLAN_EXPIRATION_DATE_SUCCESS,
  FETCH_PLAN_EXPIRATION_DATE_FAILURE,
  CHECK_SUBSCRIPTION_STATUS,
  CHECK_SUBSCRIPTION_STATUS_SUCCESS,
  CHECK_SUBSCRIPTION_STATUS_FAILURE,
  HANDLE_TRILHAS_LOGIN,
  HANDLE_TRILHAS_LOGIN_SUCCESS,
  HANDLE_TRILHAS_LOGIN_FAILURE
} from './constants'

export const createUser = user => ({
  type: CREATE_USER,
  payload: user
})
export const createUserSuccess = () => ({
  type: CREATE_USER_SUCCESS
})

export const createUserFailure = error => ({
  type: CREATE_USER_FAILURE,
  payload: error
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user
})

export const updateUserSuccess = () => ({
  type: UPDATE_USER_SUCCESS
})

export const updateUserFailure = error => ({
  type: UPDATE_USER_FAILURE,
  payload: error
})

export const fetchUser = id => ({
  type: FETCH_USER,
  payload: id
})

export const fetchUserSuccess = user => ({
  type: FETCH_USER_SUCCESS,
  payload: user
})

export const fetchUserFailure = error => ({
  type: FETCH_USER_FAILURE,
  payload: error
})

export const infoUser = () => ({
  type: INFO_USER
})

export const infoUserSuccess = user => ({
  type: INFO_USER_SUCCESS,
  payload: user
})

export const infoUserFailure = error => ({
  type: INFO_USER_FAILURE,
  payload: error
})

export const fetchPlanExpirationDate = () => ({
  type: FETCH_PLAN_EXPIRATION_DATE
})

export const fetchPlanExpirationDateSuccess = date => ({
  type: FETCH_PLAN_EXPIRATION_DATE_SUCCESS,
  payload: date
})

export const fetchPlanExpirationDateFailure = error => ({
  type: FETCH_PLAN_EXPIRATION_DATE_FAILURE,
  payload: error
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user
})

export const deleteUserFailure = error => ({
  type: DELETE_USER_FAILURE,
  payload: error
})

export const fetchUsers = (title, page) => ({
  type: FETCH_USERS,
  payload: { title, page }
})

export const fetchUsersSuccess = users => ({
  type: FETCH_USERS_SUCCESS,
  payload: users
})

export const fetchUsersFailure = error => ({
  type: FETCH_USERS_FAILURE,
  payload: error
})

export const  uploadSpreadsheet = (file) => ({
  type: UPLOAD_SPREADSHEET,
  payload: file,
});

export const uploadSpreadsheetSuccess = () => ({
  type: UPLOAD_SPREADSHEET_SUCCESS
})

export const uploadSpreadsheetFailure = error => ({
  type: UPLOAD_SPREADSHEET_FAILURE,
  payload: error
})

export const checkSubscriptionStatus = () => ({
  type: CHECK_SUBSCRIPTION_STATUS,
})

export const checkSubscriptionStatusSuccess = subscriptionStatus => ({
  type: CHECK_SUBSCRIPTION_STATUS_SUCCESS,
  payload: subscriptionStatus,
})

export const checkSubscriptionStatusFailure = error => ({
  type: CHECK_SUBSCRIPTION_STATUS_FAILURE,
  payload: error,
})

export const handleTrilhasLogin = (email) => ({
  type: HANDLE_TRILHAS_LOGIN,
  payload: email
})

export const handleTrilhasLoginSuccess = () => ({
  type: HANDLE_TRILHAS_LOGIN_SUCCESS,
})

export const handleTrilhasLoginFailure = error => ({
  type: HANDLE_TRILHAS_LOGIN_FAILURE,
  payload: error,
})
