import {
  READ_GRADE,
  READ_GRADE_SUCCESS,
  READ_GRADE_FAILURE,
  UPDATE_GRADE,
  UPDATE_GRADE_SUCCESS,
  UPDATE_GRADE_FAILURE
} from './constants'

export const readGrade = request => ({
  type: READ_GRADE,
  payload: request
})

export const readGradeSuccess = data => ({
  type: READ_GRADE_SUCCESS,
  payload: data
})

export const readGradeFailure = error => ({
  type: READ_GRADE_FAILURE,
  payload: error
})

export const updateGrade = request => ({
  type: UPDATE_GRADE,
  payload: request
})

export const updateGradeSuccess = data => ({
  type: UPDATE_GRADE_SUCCESS,
  payload: data
})

export const updateGradeFailure = error => ({
  type: UPDATE_GRADE_FAILURE,
  payload: error
})
