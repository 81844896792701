import React from 'react'
import PropTypes from 'prop-types'

import ValidatedSharedInput from './ValidatedSharedInput'
import { InputNumber } from 'antd'
import { Container, Label, Icon } from './styles/TemplateSubGroupInput.styles'

import questionIndentationIcon from '../../assets/question-indentation.svg'

const TemplateQuestionIndentationInput = ({
  label,
  field,
  form,
  HelpComponent,
  unit,
  ratio
}) => {
  return (
    <ValidatedSharedInput
      touched={form.touched[field.name]}
      error={form.errors[field.name]}
      label={label}
      HelpComponent={HelpComponent}
    >
      <Container>
        <Icon component={questionIndentationIcon} />
        <Label>Enunciado: </Label>
        <InputNumber
          className='template-question-indentation-input'
          formatter={value => `${Number(value).toFixed(2)} ${unit}`}
          defaultValue={0}
          maxLength={8}
          max={12}
          min={0}
          step={0.10 * ratio}
          parser={value => value.replace(/[^0-9.]/g, '')}
          onChange={value => form.setFieldValue(field.name, value.toString())}
          value={field.value ? Number(field.value) : 0}
        />
      </Container>
    </ValidatedSharedInput>
  )
}

TemplateQuestionIndentationInput.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  HelpComponent: PropTypes.func,
  unit: PropTypes.string,
  ratio: PropTypes.number
}

TemplateQuestionIndentationInput.defaultProps = {
  ratio: 1,
  unit: 'cm'
}

export default TemplateQuestionIndentationInput
