import {
  SEND_FEEDBACK,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAILURE,
  SET_FEEDBACK_SENT
} from './constants'

export const sendFeedback = feedback => ({
  type: SEND_FEEDBACK,
  payload: feedback
})

export const sendFeedbackSuccess = () => ({
  type: SEND_FEEDBACK_SUCCESS
})

export const sendFeedbackFailure = error => ({
  type: SEND_FEEDBACK_FAILURE,
  payload: error
})

export const setFeedbackSent = sent => ({
  type: SET_FEEDBACK_SENT,
  payload: sent
})
