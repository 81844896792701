import {
  FETCH_FILES,
  FETCH_FILES_SUCCESS,
  FETCH_FILES_FAILURE,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAILURE,
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE
} from './constants'

export const fetchFiles = query => ({
  type: FETCH_FILES,
  payload: query
})

export const fetchFilesSuccess = files => ({
  type: FETCH_FILES_SUCCESS,
  payload: files
})

export const fetchFilesFailure = error => ({
  type: FETCH_FILES_FAILURE,
  payload: error
})

export const downloadFile = id => ({
  type: DOWNLOAD_FILE,
  payload: id
})

export const downloadFileSuccess = () => ({
  type: DOWNLOAD_FILE_SUCCESS
})

export const downloadFileFailure = error => ({
  type: DOWNLOAD_FILE_FAILURE,
  payload: error
})

export const deleteFile = id => ({
  type: DELETE_FILE,
  payload: id
})

export const deleteFileSuccess = files => ({
  type: DELETE_FILE_SUCCESS,
  payload: files
})

export const deleteFileFailure = error => ({
  type: DELETE_FILE_FAILURE,
  payload: error
})
