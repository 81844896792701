import React from "react";

import { MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";

const CompanySelect = ({ company, companies, handleCompany, style }) => {
  return (
    <FormControl fullWidth className={style}>
      <InputLabel>Selecione a Escola</InputLabel>
      <Select fullWidth value={company} onChange={handleCompany}>
        {companies &&
          companies.map((c) => <MenuItem value={c.Id}>{c.Name}</MenuItem>)}
      </Select>
    </FormControl>
  );
};

export default CompanySelect;
