import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Select, Spin } from 'antd'
import { getClasses } from '../../store/auth/services'

function SelectGradesToExam ({ onChange, disabled = false }) {
  const [options, setOptions] = useState([])
  const [allGrades, setAllGrades] = useState([])
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const [quantity, setQuantity] = useState(0)

  function loadOptions() {
    setLoading(true);
  
    getClasses().then(classes => {
      if (classes && classes.Classes) {
        const uniqueClasses = Array.from(new Set(classes.Classes));
        const options = uniqueClasses.map(j => (
          <Select.Option key={j} value={j} title={j}>
            {j}
          </Select.Option>
        ));
        
        setOptions(options);
        setAllGrades(uniqueClasses);
        setQuantity(classes.Classes.length);
      }
      setLoading(false);
    });
  }

  function handleChange (value) {
    if (value && value.length && value.includes('all')) {
      if (value.length === allGrades.length + 1) {
        onChange([])
        setSelected([])
      } else {
        onChange(allGrades)
        setSelected(allGrades)
      }
    } else {
      onChange(value)
      setSelected(value)
    }
  }

  useEffect(() => {
    loadOptions()
  }, [])

  return (
    <Select
      disabled={disabled}
      mode={'multiple'}
      className='select-input'
      value={selected}
      onChange={value => handleChange(value)}
      showSearch
      onSearch={value => loadOptions()}
      style={{ width: '300px' }}
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      notFoundContent={loading ? <Spin spinning size='small' /> : 'Nada encontrado'}
      placeholder={'Turma(s)'}
      loading={loading}
      allowClear
    >
      {
        quantity !== 0 ? <Select.Option
          value={'all'}
          title={'Todas'}
        >
          {'Todas'}
        </Select.Option> : null
      }
      {options}
    </Select>

  )
}

SelectGradesToExam.propTypes = {
  onChange: PropTypes.func
}

export default SelectGradesToExam
