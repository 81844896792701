/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'

import TemplateFrame from '../frame/TemplateFrame'
import { Modal, Button } from 'antd'
import { TemplateModalStyle } from './styles/Modal.styles'

const TemplateModal = ({
  loading,
  onCancel,
  template,
  visible
}) => {
  const backButton = (
    <Button
      htmlType='button'
      onClick={onCancel}
    >
      Voltar
    </Button>
  )

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={backButton}
      css={TemplateModalStyle}
      destroyOnClose
    >
      {template &&
        <TemplateFrame
          loading={loading}
          url={template.url}
        />
      }
    </Modal>
  )
}

TemplateModal.propTypes = {
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  template: PropTypes.object,
  visible: PropTypes.bool.isRequired
}

export default TemplateModal
