import {
  CLEAR_ORGANIZATION_MODULE,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAILURE
} from './constants'

export const clearOrganizationModule = () => ({
  type: CLEAR_ORGANIZATION_MODULE
})
export const fetchOrganization = query => ({
  type: FETCH_ORGANIZATION,
  payload: query
})

export const fetchOrganizationSuccess = organization => ({
  type: FETCH_ORGANIZATION_SUCCESS,
  payload: organization
})

export const fetchOrganizationFailure = error => ({
  type: FETCH_ORGANIZATION_FAILURE,
  payload: error
})
