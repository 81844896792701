import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CompanyForm from '../../components/form/CompanyForm'
import { Spin } from 'antd'
import { Col, Row } from '../../components/table/styles/Table.styles'
import { Title } from '../../components/form/styles/Form.styles'
import { Page } from '../styles/Page.styles'

const NewCompanyPage = ({
  loading
}) => {
  return (
    <Page>
      <Row center>
        <Col center size={5}><Title>Cadastrar Empresa</Title></Col>
      </Row>
      <Spin spinning={loading}>
        <CompanyForm />
      </Spin>
    </Page>
  )
}

NewCompanyPage.propTypes = {
  loading: PropTypes.bool
}

const mapStateToProps = ({ files }) => ({
  loading: files.loading
})

export default connect(mapStateToProps)(NewCompanyPage)
