import React, { useState } from "react";
import PropTypes from "prop-types";
import { Checkbox, TextField, Tooltip } from "@material-ui/core";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function TreeItem({
  option,
  selected,
}) {
  const [expand, setExpand] = useState(true);

  return (
    <React.Fragment>
      {
        expand ?
          <KeyboardArrowUpIcon onClick={() => setExpand(false)}/> :
          <KeyboardArrowDownIcon onClick={() => setExpand(true)} />
      }
      <Checkbox
        style={{ marginRight: 8 }}
        checked={selected}
        color="primary"
      />
      <Tooltip title={option.description} placement="right">
        <span>{option.label}</span>
      </Tooltip>
    </React.Fragment>
  );
}

TreeItem.propTypes = {
  options: PropTypes.object,
  selected: PropTypes.bool,
};

export default TreeItem;