/* eslint react/prop-types: 0 */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { Form } from 'formik'

const formStyle = css`
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  padding: .5rem 1.5rem;
  .ant-input-number,
  .ant-calendar-picker { width: 100%; }
`

const loginFormStyle = css`
  ${formStyle};
  max-width: 30rem;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  @media only screen and (max-width: 600px) {
    .ant-radio-button-wrapper { border-left: 1px solid #d9d9d9; }
  }
`

const registerFormStyle = css`
  ${formStyle};
  margin-top: 2rem;
  max-width: 30rem;
  @media only screen and (max-width: 600px) {
    .ant-radio-button-wrapper { border-left: 1px solid #d9d9d9; }
  }
`

export const StyledForm = props => (
  <Form {...props} css={formStyle}>
    {props.children}
  </Form>
)

export const StyledLoginForm = props => (
  <Form {...props} css={loginFormStyle}>
    {props.children}
  </Form>
)

export const StyledRegisterForm = props => (
  <Form {...props} css={registerFormStyle}>
    {props.children}
  </Form>
)

export const Title = styled.h2`
  font-size: 200%;
  color: #514FFF;
`

export const Subtitle = styled.h2`
  font-size: 120%;
  color: "black";
  margin-bottom: 20px;
`

export const Label = styled.span`
  display: block;
  width: 100%;
`

export const LabelErro = styled.span`
  color: red;
  font-weight: bold;
`

export const GroupLabel = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  padding-bottom: .25rem;
  color: #514FFF;
`

export const FeedbackGroup = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 21rem;
  > *:not(:last-child) { margin-bottom: 1rem; }
`

export const FeedbackButtonGroup = styled.div`
  > *:not(:last-child) { margin-bottom: 1rem; }
`

export const SubGroup = styled.div`
  .ant-row.ant-form-item.input {
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
  }
`

export const SubGroupLabel = styled.p`
  font-size: 1rem;
`

export const FlexGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .ant-row.ant-form-item.input { margin: 0; }
  > * {
    margin-bottom: 1rem;
    ${props => props.flex && `flex: ${props.flex};`}
    @media only screen and (min-width: 901px) {
      width: 24%;
      .ant-select-selection-selected-value {
        ${props => !props.flex && 'width: 4.8rem;'}
      }
      :not(:last-child) { margin-right: ${4 / 3}%; }
    }
    @media only screen and (min-width: 601px) and (max-width: 900px) {
      :nth-of-type(2n + 1) { margin-right: 2%; }
      width: 49%;
    }
    @media only screen and (max-width: 600px) {
      margin-right: 0;
      width: 100%;
      ${props => props.flex && `flex: unset;`}
    }
  }
`
export const Container = styled.div`
  border: 1px solid transparent;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 15%);
`
export const A = styled.a`
  color: #514FFF;
`
