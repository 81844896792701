import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import CustomAlertModal from "../../modal/CustomAlertModal";

const FinishButton = ({
  area,
  studentsResult,
  isSemanticFieldSelected,
  conditionalFields,
}) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const handleFinishButton = () => {
    if (isAllStudentsChecked()) {
      if (area === "Writing" && !isSemanticFieldSelected) {
        setAlertMessage("Selecione um campo semântico antes de finalizar.");
        setAlertSeverity("warning");
      } else {
        setAlertMessage("Todas as alterações foram salvas.");
        setAlertSeverity("success");
      }
    } else {
      setAlertMessage(
        "Marque uma opção para cada estudante antes de finalizar."
      );
      setAlertSeverity("warning");
    }
    setOpenAlert(true);
  };

  const isAllStudentsChecked = () => {
    let studentsChecked = studentsResult.flatMap((student) => {
      let checked = Object.entries(student[area])
        .flatMap(([key, value]) => {
          if (!conditionalFields.includes(key) && value) {
            return key;
          }
        })
        .filter(Boolean);

      return checked;
    });

    if (studentsChecked.length === studentsResult.length) return true;
    else return false;
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleFinishButton}
        style={{
          marginTop: "20px",
        }}
      >
        Finalizar
      </Button>
      <CustomAlertModal
        open={openAlert}
        handleClose={handleCloseAlert}
        message={alertMessage}
        severity={alertSeverity}
      />
    </>
  );
};

FinishButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FinishButton;
