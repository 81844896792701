import React from 'react'
import { notification, Icon } from 'antd'
import { store } from '../store/configureStore'
import { resendEmail } from '../store/auth/actions'

export class ResendEmailNotification {
  static email = ''

  static open = email => {
    ResendEmailNotification.email = email
    notification.open({
      key: 'email-notification',
      message: 'Confirme seu email para continuar',
      description: ResendEmailNotification.description(),
      icon: <Icon type='mail' style={{ color: '#108ee9' }} />,
      duration: null
    })
  }

  static close = () => {
    notification.close('email-notification')
  }

  static description = () => {
    return (
      <p>Caso não tenha recebido nosso email de confirmação clique {ResendEmailNotification.resendLink} para reenviar.</p>
    )
  }

  static resendLink = (
    <a
      href='/login'
      onClick={e => ResendEmailNotification.handleResend(e)}
    >
      aqui
    </a>
  )

  static handleResend = e => {
    e.preventDefault()
    store.dispatch(resendEmail(ResendEmailNotification.email))
  }
}
