import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import DefaultHelp from '../help/DefaultHelp'
import HeavyHelp from '../help/HeavyHelp'
import DocmHelp from '../help/DocmHelp'
import OdtHelp from '../help/OdtHelp'
import DocHelp from '../help/DocHelp'

const getInputHelpComponent = type => ({
  'default': DefaultHelp,
  'heavy': HeavyHelp,
  'application/vnd.ms-word.document.macroEnabled.12': DocmHelp,
  'application/vnd.oasis.opendocument.text': OdtHelp,
  'application/msword': DocHelp
}[type] || DefaultHelp)

class InputHelpModal extends Component {
  static propTypes = {
    type: PropTypes.string,
    onClose: PropTypes.func
  }

  static defaultProps = {
    onClose: () => null
  }

  state = {
    visible: !!this.props.type
  }

  componentDidUpdate (prevProps) {
    const { type } = this.props
    const { type: prevType } = prevProps

    type && prevType !== type && this.openModal()
  }

  render () {
    const { visible } = this.state
    const { type } = this.props
    const Component = getInputHelpComponent(type)

    return (
      <Modal
        title='Ajuda'
        visible={visible}
        footer={<Button type='primary' onClick={this.closeModal}>Ok</Button>}
        onCancel={this.closeModal}
      >
        <Component />
      </Modal>
    )
  }

  openModal = () => {
    this.setState({ visible: true })
  }

  closeModal = () => {
    const { onClose, type } = this.props
    this.setState({ visible: false }, () => onClose(type))
  }
}

export default InputHelpModal
