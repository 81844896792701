import React from 'react'
import PropTypes from 'prop-types'

import { TEMPLATE_QUESTION_NUMBERING_OPTIONS } from '../../utils/constants'

import { Select } from 'antd'
import ValidatedSharedInput from './ValidatedSharedInput'
import { Container, Label, Icon } from './styles/TemplateSubGroupInput.styles'

import questionNumberingIcon from '../../assets/question-numbering-icon.svg'

const TemplateQuestionNumberingInput = ({
  label,
  field,
  form,
  HelpComponent
}) => {
  const options = TEMPLATE_QUESTION_NUMBERING_OPTIONS.map(option =>
    <Select.Option
      key={option.value}
      value={option.value}
    >
      {option.label}
    </Select.Option>
  )

  return (
    <ValidatedSharedInput
      touched={form.touched[field.name]}
      error={form.errors[field.name]}
      label={label}
      HelpComponent={HelpComponent}
    >
      <Container>
        <Icon component={questionNumberingIcon} />
        <Label>Numeração: </Label>
        <Select
          className='template-question-numbering-input'
          placeholder='Numeração'
          dropdownMatchSelectWidth={false}
          onChange={value => form.setFieldValue(field.name, value)}
          value={field.value}
        >
          {options}
        </Select>
      </Container>
    </ValidatedSharedInput>
  )
}

TemplateQuestionNumberingInput.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  HelpComponent: PropTypes.func
}

export default TemplateQuestionNumberingInput
