import React from "react";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { normalizeError } from "../../utils/errorHelpers";
import { Icon, message, notification } from "antd";
import { removeToken, setToken } from "../../utils/http2";
import { ResendEmailNotification } from "../../utils/notificationHelpers";
import history from "../../routes/history";

import * as actions from "./actions";
import * as types from "./constants";
import * as services from "./services";
import * as servicesUser from "../users/services";

export function* getRoles() {
  try {
    let response = yield call(services.getRoles);
    console.log("response", response);
    yield put(actions.getRolesSuccess(response));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao Buscar Perfis");
    message.error(errorInfo.message);
    yield put(actions.getRolesFailure(errorInfo));
  }
}

export function* createClasses({ payload }) {
  try {
    let response = yield call(services.createClasses, payload);
    console.log("response", response);
    yield put(actions.createClassesSuccess(response));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao criar classe");
    message.error(errorInfo.message);
    yield put(actions.createClassesFailure(errorInfo));
  }
}

export function* getClasses({ payload }) {
  try {
    let response = yield call(services.getClasses, payload);
    if (response.Classes) {
      response.Classes = response.Classes.toSorted();
    }
    yield put(actions.getClassesSuccess(response));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao criar classe");
    message.error(errorInfo.message);
    yield put(actions.getClassesFailure(errorInfo));
  }
}

export function* manageLogo({ payload }) {
  try {
    const logoInfo = yield call(services.manageLogo, payload);
    console.log(logoInfo);
    yield put(actions.manageLogoSuccess(logoInfo));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao gerenciar Logo");
    message.error(errorInfo.message);
    yield put(actions.manageLogoFailure(errorInfo));
  }
}

export function* login({ payload }) {
  try {
    // removeToken()
    const user = yield call(services.login, payload);
    if (user) {
      setToken(user.access_token);
      let response = yield call(servicesUser.infoUser);
      console.log(response);
      if (response.Role === "Student") {
        const errorInfo = normalizeError(
          "",
          "Entre na área de estudante para realizar o login!"
        );
        message.error(errorInfo.message);
        yield put(actions.loginFailure(errorInfo));
      } else {
        if (response.Role === "Guest") {
          history.push('/result-exams')
        }
        yield put(actions.loggedUserSuccess(response));
        yield put(actions.loginSuccess(user));
      }
    } else {
      const userFail = yield call(services.loginFail, payload); // returns client id if payment is pending, else null
      if (userFail) {
        const errorInfo = normalizeError(
          "",
          "Pagamento pendente, redirecionando..."
        );
        message.error(errorInfo.message);
        yield put(actions.loginFailure(errorInfo));
        window.location.href =
          process.env.STRIPE_URL +
          `?prefilled_email=${payload.username}&client_reference_id=${userFail}`;
      } else {
        const errorInfo = normalizeError("", "Falha ao efetuar Login");
        message.error(errorInfo.message);
        yield put(actions.loginFailure(errorInfo));
      }
    }
  } catch (error) {
    console.log(error);
    const errorInfo = normalizeError(error, "Falha ao efetuar Login");
    errorInfo.message === "Antes de continuar, confirme a sua conta."
      ? ResendEmailNotification.open(payload.username)
      : message.error(errorInfo.message);
    yield put(actions.loginFailure(errorInfo));
  }
}

export function* signup({ payload }) {
  try {
    // removeToken()
    const user = yield call(services.signup, payload);
    if (user && user.userId) {
      yield put(actions.signupSuccess(user.userId));
      yield put(
        actions.login({
          grant_type: payload.grant_type,
          username: payload.email,
          password: payload.password1,
        })
      );
    } else {
      const errorInfo = normalizeError(
        "",
        'Usuário já cadastrado. Clique "Já tenho cadastro"'
      );
      message.error(errorInfo.message);
      yield put(actions.signupFailure(errorInfo));
    }
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao comunicar com servidor");
    message.error(errorInfo.message);
    yield put(actions.signupFailure(errorInfo));
  }
}

export function* checkoutPayment({ payload }) {
  try {
    // removeToken()
    const paymentStatus = yield call(services.checkoutPayment, payload);
    if (paymentStatus) {
      if (paymentStatus === "paid") {
        yield put(actions.checkPaymentSuccess());
      } else {
        const errorInfo = normalizeError("", "Pagamento não efetuado");
        message.error(errorInfo.message);
        yield put(actions.checkPaymentFailure(errorInfo));
      }
    } else {
      const errorInfo = normalizeError("", "Status de pagamento não recebido");
      message.error(errorInfo.message);
      yield put(actions.checkPaymentFailure(errorInfo));
    }
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao comunicar com servidor");
    message.error(errorInfo.message);
    yield put(actions.checkPaymentFailure(errorInfo));
  }
}

export function* studentLogin({ payload }) {
  try {
    // removeToken()
    const user = yield call(services.login, payload);
    console.log("student login");
    console.log(user);
    setToken(user.access_token);
    let response = yield call(servicesUser.infoUser);
    console.log(response);
    if(response.Role === "Student"){
      yield put(actions.studentLoginSuccess({ ...user, ...response }));
    } else {
      const errorInfo = normalizeError("", "Login não autorizado. Entre com uma conta de estudante.");
      message.error(errorInfo.message);
      yield put(actions.studentLoginFailure(errorInfo));
    }
    
  } catch (error) {
    console.log(error);
    const errorInfo = normalizeError(error, "Falha ao efetuar Login");
    message.error(errorInfo.message);
    yield put(actions.studentLoginFailure(errorInfo));
  }
}

export function* logout({ payload }) {
  try {
    removeToken();
    // history.push('/login')
    yield call(services.logout, payload);
    yield put(actions.logoutSuccess());
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao efetuar Logout");
    message.error(errorInfo.message);
    yield put(actions.logoutFailure(errorInfo));
  }
}

export function* register({ payload }) {
  try {
    yield call(services.register, payload);
    message.success(
      "Registro efetuado com sucesso! Aguarde e-mail de confirmação de ativação"
    );
    yield put(actions.registerSuccess());
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao efetuar Registro");
    message.error(errorInfo.message);
    yield put(actions.registerFailure(errorInfo));
  }
}

export function* registerStudent({ payload }) {
  try {
    yield call(services.registerStudent, payload);
    message.success("Registro efetuado com sucesso!");
    yield put(actions.registerStudentSuccess());
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao efetuar Registro");
    message.error(errorInfo.message);
    yield put(actions.registerStudentFailure(errorInfo));
  }
}

export function* activateUser({ payload }) {
  try {
    yield call(services.activateUser, payload);
    message.success("Usuário Ativado com sucesso");
    yield put(actions.activateUserSuccess());
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao Ativar Usuário");
    message.error(errorInfo.message);
    yield put(actions.activateUserFailure(errorInfo));
  }
}

export function* inactivateUser({ payload: id }) {
  try {
    yield call(services.inactivateUser, id);
    message.success("Usuário Inativado com sucesso");
    yield put(actions.inactivateUserSuccess());
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao Inativar Usuário");
    message.error(errorInfo.message);
    yield put(actions.inactivateUserFailure(errorInfo));
  }
}

export function* inviteUsers({ payload: emails }) {
  try {
    yield call(services.inviteUsers, emails.EmailList);
    message.success("Usuário(s) Convidado(s) com sucesso");
    yield put(actions.inviteUsersSuccess());
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao Convidar Usuários");
    message.error(errorInfo.message);
    yield put(actions.inviteUsersFailure(errorInfo));
  }
}

export function* inviteStudent({ payload: emails }) {
  try {
    yield call(services.inviteStudent, emails.EmailList);
    message.success("Usuário(s) Convidado(s) com sucesso");
    yield put(actions.inviteStudentSuccess());
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao Convidar Usuários");
    message.error(errorInfo.message);
    yield put(actions.inviteStudentFailure(errorInfo));
  }
}

export function* rejectUser({ payload: id }) {
  try {
    yield call(services.rejectUser, id);
    message.success("Usuário Rejeitado com sucesso");
    yield put(actions.rejectUserSuccess());
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao Rejeitar Usuário");
    message.error(errorInfo.message);
    yield put(actions.rejectUserFailure(errorInfo));
  }
}

export function* resendEmail({ payload }) {
  try {
    message.loading("Enviando email...", 0);
    yield call(services.resendEmail, payload);
    message.destroy();
    message.success("Email reenviado com sucesso");
    yield put(actions.resendEmailSuccess());
  } catch (error) {
    const errorInfo = normalizeError(
      error,
      "Falha ao reenviar email de confirmação"
    );
    message.destroy();
    message.error(errorInfo.message);
    yield put(actions.resendEmailFailure(errorInfo));
  }
}

export function* recoverPassword({ payload }) {
  try {
    yield call(services.recoverPassword, payload);
    yield put(actions.recoverPasswordSuccess());
    notification.open({
      message: "Recuperação de senha",
      description:
        "Email para recuperação de senha enviado. Verifique sua caixa de entrada para continuar.",
      icon: <Icon type="mail" style={{ color: "#108ee9" }} />,
      duration: null,
    });
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao recuperar senha");
    message.error(errorInfo.message);
    yield put(actions.recoverPasswordFailure(errorInfo));
  }
}

export function* changePassword({ payload }) {
  try {
    yield call(services.changePassword, payload);
    yield put(actions.changePasswordSuccess());
    history.push("/login");
    message.success("Senha alterada com sucesso");
  } catch (error) {
    console.log("error", error);
    const errorInfo = normalizeError(error, "Falha ao alterar senha");
    message.error(errorInfo.message);
    yield put(actions.changePasswordFailure(errorInfo));
  }
}

// Watchers
export function* watchManageLogo() {
  yield takeLatest(types.MANAGE_LOGO, manageLogo);
}

export function* watchLogin() {
  yield takeLatest(types.LOGIN, login);
}

export function* watchSignup() {
  yield takeLatest(types.SIGNUP, signup);
}

export function* watchCheckoutPayment() {
  yield takeLatest(types.CHECKOUT_PAYMENT, checkoutPayment);
}

export function* watchStudentLogin() {
  yield takeLatest(types.STUDENT_LOGIN, studentLogin);
}

export function* watchLogout() {
  yield takeLatest(types.LOGOUT, logout);
}

export function* watchRegister() {
  yield takeLatest(types.REGISTER, register);
}

export function* watchRegisterStudent() {
  yield takeLatest(types.REGISTER_STUDENT, registerStudent);
}

export function* watchActivateUser() {
  yield takeLatest(types.ACTIVATE_USER, activateUser);
}

export function* watchRejectUser() {
  yield takeLatest(types.REJECT_USER, rejectUser);
}

export function* watchInactiveUser() {
  yield takeLatest(types.INACTIVATE_USER, inactivateUser);
}

export function* watchResendEmail() {
  yield takeLatest(types.RESEND_EMAIL, resendEmail);
}

export function* watchinviteUsers() {
  yield takeLatest(types.INVITE_USERS, inviteUsers);
}

export function* watchinviteStudent() {
  yield takeLatest(types.INVITE_STUDENT, inviteStudent);
}

export function* watchRecoverPassword() {
  yield takeLatest(types.RECOVER_PASSWORD, recoverPassword);
}

export function* watchGetRoles() {
  yield takeLatest(types.GET_ROLES, getRoles);
}

export function* watchCreateClasses() {
  yield takeLatest(types.CREATE_CLASSES, createClasses);
}

export function* watchGetClasses() {
  yield takeLatest(types.GET_CLASSES, getClasses);
}

export function* watchChangePassword() {
  yield takeLatest(types.CHANGE_PASSWORD, changePassword);
}

export default function*() {
  yield all([
    watchManageLogo(),
    watchLogin(),
    watchSignup(),
    watchCheckoutPayment(),
    watchStudentLogin(),
    watchLogout(),
    watchRegister(),
    watchRegisterStudent(),
    watchActivateUser(),
    watchInactiveUser(),
    watchRejectUser(),
    watchResendEmail(),
    watchinviteUsers(),
    watchinviteStudent(),
    watchRecoverPassword(),
    watchChangePassword(),
    watchGetRoles(),
    watchCreateClasses(),
    watchGetClasses(),
  ]);
}
