/* eslint-disable */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon, message } from 'antd'
// import { Link } from 'react-router-dom'
import { Title, Container, successIconStyle, Subtitle, A} from './styles/Message.styles'
import { checkoutPayment } from '../../store/auth/actions'
import { normalizeError } from '../../utils/errorHelpers'


const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error,
  loaded: !auth.loading,
})


const mapDispatchToProps = dispatch =>
  bindActionCreators({
    checkoutPayment
  }, dispatch)


const CheckoutMessage = ({
  loading,
  isValid,
  checkoutPayment,
  error,
  loaded
}) => {
    let sessionSuccess = true;
    let sessionError = false;

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const sessionId = query.get('sessionId');
        sessionError = false;
        sessionSuccess = true;
    
        if (sessionId) {
            checkoutPayment(sessionId);
        } else {
          // mostrar mensagem de erro
            const errorInfo = normalizeError('', 'Comunicação com Stripe falhou');
            message.error(errorInfo);
            loading = false;
            loaded = true;
            sessionError = true;
            sessionSuccess = false;
        }
        
    }, []);

    return (
        <div>
            { (loaded) &&
             (sessionSuccess) && (Object.keys(error).length === 0 && error.constructor === Object) &&
            <Container>
              <Icon type='check-circle' theme='filled' css={successIconStyle} />
              <Title>Obrigado!</Title>
              <Subtitle>Seu pagamento foi registrado!</Subtitle>
              <div className={'col-sm-12'} style={{ justifyContent: 'center', display: 'flex', marginTop: '16px' }}>
                <A
                  href='/login'
                >
                Login
                </A>
              </div>
            </Container>}

            { (loaded) &&
             (sessionSuccess) && (Object.keys(error).length !== 0) &&
            <Container>
             <Icon type='close-circle' theme='filled' css={successIconStyle} />
            <Title>Sinto muito!</Title>
            <Subtitle>Seu pagamento ainda não foi confirmado!</Subtitle>
            </Container>}

            { loading &&
             (sessionSuccess) &&
            <Container>
                <Icon type='loading' css={successIconStyle} />
                <Title>Aguarde alguns instantes</Title>
                <Subtitle>Seu pagamento está sendo verificado</Subtitle>
            </Container>}

            { sessionError &&
            <Container>
                <Icon type='close-circle' css={successIconStyle} />
                <Title>Erro</Title>
                <Subtitle>Erro de comunicação com Stripe</Subtitle>
            </Container>}
        </div>
    )
}

CheckoutMessage.propTypes = {
  loading: PropTypes.bool,
  isValid: PropTypes.bool,
  sessionError: PropTypes.bool
}

const enhance = connect(mapStateToProps, mapDispatchToProps)


export default enhance(CheckoutMessage)