import React from "react";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { AssignmentReturned } from "@material-ui/icons";
import CustomizedProgressBars from "../progress/Progress";
import { Typography } from "antd";

const ExamReportTable = ({
  rows = [],
  schoolId,
  turmaName,
  questionAns,
  correctPercent,
  totalStudents,
  handleOpenStudentExam,
  handleBoletim,
  style,
}) => {
  
  const calculateOverallCorrectPercentage = (schoolId, studentId) => {
    let totalCorrect = 0;
    let totalTotal = 0;

    Object.values(correctPercent[schoolId]).forEach((turma) => {
      if (turma.hasOwnProperty(studentId)) {
        totalCorrect += turma[studentId].correct;
        totalTotal += turma[studentId].total;
      }
    });
    
    return totalTotal > 0 ? totalCorrect / totalTotal : 0;
  };

  if (rows.length === 0 || !rows[0].questions) {
    return <div>No data available</div>;
  }

  return (
    <Table
      size="small"
      id={schoolId + turmaName}
      style={{ margin: "20px 0 20px 0" }}
    >
      <TableBody>
        <TableRow key="">
          <TableCell component="th" scope="row">
            <div style={{ width: 400 }}>Questões</div>
          </TableCell>
          {Object.keys(rows[0].questions).map((key) => (
            <TableCell align="right" padding="none" className={style} key={key}>
              <div
                style={{
                  height: "24px",
                  width: "24px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {parseInt(key) + 1}
              </div>
            </TableCell>
          ))}
          <TableCell component="th" scope="row">
            <div style={{ width: 100 }}>Total (%)</div>
          </TableCell>
        </TableRow>

        {rows.map((row) => (
          <TableRow key={row.name}>
            <TableCell
              component="th"
              scope="row"
              padding="none"
              className={style}
            >
              <Button onClick={() => handleOpenStudentExam(row.id)}>
                {row.name}
              </Button>
              <IconButton size="small" onClick={() => handleBoletim(row.id)}>
                <AssignmentReturned />
              </IconButton>
            </TableCell>
            {Object.entries(row.questions).map(([key, value]) => (
              <TableCell
                align="right"
                padding="none"
                className={style}
                size="small"
                key={key}
              >
                <Tooltip title={row.name + "; " + value.tags.join(", ")}>
                  <div
                    style={{
                      backgroundColor: value.correct ? "#ACFAB0" : "#FC695E",
                      height: "24px",
                      width: "24px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {value.alternative}
                  </div>
                </Tooltip>
              </TableCell>
            ))}

            <TableCell component="th" scope="row">
              <CustomizedProgressBars
                label
                color={"#3de048"}
                percent={
                  calculateOverallCorrectPercentage(schoolId, row.id)
                }
              />
            </TableCell>
          </TableRow>
        ))}
        
        <TableRow key="end">
          <TableCell component="th" scope="row">
            <div>Resultado Questões (%)</div>
          </TableCell>
          {Object.keys(questionAns).map((key, idx) => (
            <TableCell
              align="right"
              component="th"
              scope="row"
              padding="none"
              className={style}
              key={key}
            >
              <Tooltip
                title={
                  <div style={{ width: 200 }}>
                    <Typography style={{ color: "white" }}>
                      {rows[0].questions[idx].tags.join(", ")}
                    </Typography>
                    <br />
                    <Typography style={{ color: "#FFFFFF" }}>
                      A:{" "}
                      <CustomizedProgressBars
                        color={
                          rows[0].questions[idx].correctAlternative.includes("A")
                            ? "#3de048"
                            : "#e0483d"
                        }
                        percent={questionAns[idx]["A"] / totalStudents}
                      />
                    </Typography>
                    <Typography style={{ color: "#FFFFFF" }}>
                      B:{" "}
                      <CustomizedProgressBars
                        color={
                          rows[0].questions[idx].correctAlternative.includes("B")
                            ? "#3de048"
                            : "#e0483d"
                        }
                        percent={questionAns[idx]["B"] / totalStudents}
                      />
                    </Typography>
                    <Typography style={{ color: "#FFFFFF" }}>
                      C:{" "}
                      <CustomizedProgressBars
                        color={
                          rows[0].questions[idx].correctAlternative.includes("C")
                            ? "#3de048"
                            : "#e0483d"
                        }
                        percent={questionAns[idx]["C"] / totalStudents}
                      />
                    </Typography>
                    <Typography style={{ color: "#FFFFFF" }}>
                      D:{" "}
                      <CustomizedProgressBars
                        color={
                          rows[0].questions[idx].correctAlternative.includes("D")
                            ? "#3de048"
                            : "#e0483d"
                        }
                        percent={questionAns[idx]["D"] / totalStudents}
                      />
                    </Typography>
                    {questionAns[idx]["E"] > 0 && (
                      <Typography style={{ color: "#FFFFFF" }}>
                        E:{" "}
                        <CustomizedProgressBars
                          color={
                            rows[0].questions[idx].correctAlternative.includes("E")
                              ? "#3de048"
                              : "#e0483d"
                          }
                          percent={questionAns[idx]["E"] / totalStudents}
                        />
                      </Typography>
                    )}
                  </div>
                }
              >
                <div>
                  <CustomizedProgressBars
                    withoutTooltip
                    hasProgressBar={false}
                    label
                    color={"#3de048"}
                    percent={
                      rows[0].questions[idx].correctAlternative
                        ? rows[0].questions[idx].correctAlternative.reduce(
                            (psum, a) => psum + questionAns[idx][a],
                            0
                          ) / totalStudents
                        : 0
                    }
                  />
                </div>
              </Tooltip>
            </TableCell>
          ))}

          <TableCell component="th" scope="row">
            <div></div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ExamReportTable;

