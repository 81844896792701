import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "left",
    },
    description: {
        textAlign: "left",
        marginLeft: "128px",
        width: "156px",
        borderWidth: 1,
        borderColor: 'black',
        padding: 2,
    },
    xyz: {
        textAlign: "left",
        marginLeft: "0px",
        width: "156px",
        borderWidth: 1,
        borderColor: 'black',
        padding: 2,
    },
});

const TableHeader = ({ header }) => {
    return (
        <Fragment>
            <View style={styles.row} key={0}>
                <Text style={styles.description}>{header.prova}</Text>
                <Text style={styles.xyz}>{header.nota}</Text>
            </View>
        </Fragment>
    );
};

export default TableHeader;
