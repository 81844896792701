import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import history from '../routes/history'
import { download } from '../utils/fileHelpers'
import { deleteTemplate, fetchTemplates } from '../store/templates/actions'

import ActionList from '../components/list/ActionList'
import ContainerCard from '../components/card/ContainerCard'
import TemplateModal from '../components/modal/TemplateModal'
import { List, Spin, Tooltip, Button } from 'antd'
import { makeStyles } from '@material-ui/core/styles'
import { Content } from './styles/DetailsPage.styles'

import { Row, Col, Header, Empty, Pagination, Search, HeaderBar, HeaderLeft, HeaderRight, Name, OrganizationName } from './styles/Layouts.styles'
import TemplateUploadInputLayout from '../components/input/TemplateUploadInputLayout'
import { Page } from './styles/Page.styles'

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))

const Layouts = ({ deleteTemplate, fetchTemplates, templates, loading }) => {
  const [template, setTemplate] = useState()
  const [search, setSearch] = useState('')
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const isHeaderOpen = Boolean(anchorEl)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const isMobileHeaderOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileHeaderClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleQuestionHeaderClose = () => {
    setAnchorEl(null)
    handleMobileHeaderClose()
  }

  useEffect(() => {
    fetchTemplates({ page: 1, pageSize: 10 })
  }, [])

  const renderSearch = type => (
    type === 'desktop' ? <><Search
      className={classes.sectionDesktop}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      open={isHeaderOpen}
      onClose={handleQuestionHeaderClose}
      type={type}
      onChange={e => setSearch(e.target.value)}
      onSearch={search => fetchTemplates({ page: Number(templates.page), pageSize: 10, search })}
      placeholder='Buscar'
      value={search}
    />
      <Button onClick={() => fetchTemplates({ page: Number(templates.page), pageSize: 10, search: '' })} type='default' shape='round' style={{ marginLeft: '5px' }}>Limpar Pesquisa</Button>
    </> : <><Search
      className={classes.sectionMobile}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileHeaderOpen}
      onClose={handleMobileHeaderClose}
      mode='horizontal'
      type={type}
      onChange={e => setSearch(e.target.value)}
      onSearch={search => fetchTemplates({ page: Number(templates.page), pageSize: 10, search })}
      placeholder='Buscar'
      value={search}
    /><><Button onClick={() => fetchTemplates({ page: Number(templates.page), pageSize: 10, search: '' })} type='default' shape='round' style={{ marginLeft: '5px' }}>Limpar</Button>
    </>
    </>
    // <Search
    //   type={type}
    //   onChange={e => setSearch(e.target.value)}
    //   onSearch={search => fetchTemplates({ page: Number(templates.page), pageSize: 10, search })}
    //   placeholder='Buscar'
    //   value={search}
    // />
  )

  const header = (
    <Row
      className={classes.sectionDesktop}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isHeaderOpen}
      onClose={handleQuestionHeaderClose}
    >
      <Col size={4}><Header>NOME</Header></Col>
      <Col center size={2}><Header>ORGANIZAÇÃO</Header></Col>
      <Col center size={3}><Header>DATA</Header></Col>
      <Col center size={3}><Header>AÇÕES</Header></Col>
    </Row>
  )

  const mobileHeader = (
    <Row
      className={classes.sectionMobile}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileHeaderOpen}
      onClose={handleMobileHeaderClose}
      mode='horizontal'
    >
      <Col size={3}><Header>NOME</Header></Col>
      <Col size={5}><Header>AÇÕES</Header></Col>
    </Row>
  )

  const renderName = name => (
    <Tooltip
      title={name}
      placement='topLeft'
    >
      <Name>
        {name}
      </Name>
    </Tooltip>
  )

  const renderOrganizationName = organizationName => {
    const name = organizationName || 'Não há vínculo'

    return (
      <Tooltip
        title={name}
        placement='topLeft'
      >
        <OrganizationName disabled={!organizationName}>
          {name}
        </OrganizationName>
      </Tooltip>
    )
  }

  const renderDate = date => {
    const localeDate = new Date(date).toLocaleDateString()
    return (
      <Tooltip
        title={localeDate}
        placement='topLeft'
      >
        {localeDate}
      </Tooltip>
    )
  }

  const renderActions = (item) => {
    return (
      <ActionList
        // onView={() => setTemplate(item)}
        onEdit={() => history.push(`/templates/${item.id}/edit`)}
        onDownload={() => download(item.file_url, item.name)}
        onDelete={() => deleteTemplate(item.id)}
      />
    )
  }

  const renderItem = item => (
    <List.Item>
      <Row>
        <Col size={4}>{renderName(item.name)}</Col>
        <Col center size={2}>{renderOrganizationName(item.organization_name)}</Col>
        <Col center size={2}>{renderDate(item.created_at)}</Col>
        <Col center>{renderActions(item)}</Col>
      </Row>
    </List.Item>
  )

  const pagination = templates.total > 10 && (
    <Pagination
      current={Number(templates.page)}
      total={Number(templates.total)}
      onChange={page => fetchTemplates({ page, pageSize: 10, search })}
    />
  )

  const empty = (
    <Empty
      description='Nenhum arquivo encontrado'
      image={Empty.PRESENTED_IMAGE_DEFAULT}
    />
  )

  const templateModal = (
    <TemplateModal
      style={{ width: '100%', maxWidth: '1200px' }}
      template={template}
      visible={!!template}
      onCancel={() => setTemplate(null)}
    />
  )

  return (
    <Page>
      <Content style={{ width: '100%', maxWidth: '1200px' }}>
        <Spin spinning={loading} >
          <HeaderBar>
            <HeaderLeft style={{ width: '100%' }} className={classes.sectionDesktop}>
              <Row style={{ display: 'flex' }} >
                <Col style={{ flex: '80%', marginLeft: '-30px' }} ><Row>{renderSearch('desktop')}</Row></Col>
                <Col style={{ flex: '20%' }} ><TemplateUploadInputLayout /></Col>
              </Row>
            </HeaderLeft>
            <HeaderLeft style={{ width: '70%' }} className={classes.sectionMobile}>
              <Col style={{ display: 'flex', justifyContent: 'center' }} >
                <TemplateUploadInputLayout />
              </Col>
            </HeaderLeft>
            <HeaderRight>
              {/* <Button type='primary' shape='round' className='tagButton'>Questões na organização: {questionsCompany}</Button> */}
            </HeaderRight>
          </HeaderBar>
          <Col className={classes.sectionMobile}>
            {renderSearch('mobile')}
          </Col>
          <br />
          {header}
          {mobileHeader}
          <ContainerCard
            padding={0}
            shadowYOffset={5}
          >
            <List
              dataSource={templates}
              locale={{ emptyText: empty }}
              renderItem={renderItem}
            />
          </ContainerCard>
          {pagination}
          {templateModal}
        </Spin>
      </Content>
    </Page>
  )
}

Layouts.propTypes = {
  loading: PropTypes.bool,
  deleteTemplate: PropTypes.func.isRequired,
  fetchTemplates: PropTypes.func.isRequired,
  templates: PropTypes.array
}

const mapStateToProps = ({ templates }) => ({
  templates: templates.templates,
  loading: templates.loading
})

const mapDispatchToPtops = dispatch =>
  bindActionCreators({
    deleteTemplate,
    fetchTemplates
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToPtops)(Layouts)
