import React from "react";
import MaterialButton from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";

export const Button = ({
  active = true,
  fullWidth = false,
  label,
  type,
  handleClick,
  className,
}) =>
  active && (
    <Grid item>
      <MaterialButton
        className={className}
        fullWidth={fullWidth}
        type={type}
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        {label}
      </MaterialButton>
    </Grid>
  );
