import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { Col, Row } from "../../components/table/styles/Table.styles";
import { Title } from "../../components/form/styles/Form.styles";
import { Page } from "../styles/Page.styles";
import { Button } from "../../components-refactor/button/Button";
import { TextField } from "../../components-refactor/textfield/TextField";
import { Select } from "../../components-refactor/select/Select";

import { getClasses } from "../../store/auth/actions";
import { readGrade, updateGrade } from "../../store/grades/actions";
import history from "../../routes/history";

const useStyles = makeStyles((theme) => ({
  text: {
    width: "90vw",
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(96),
    },
  },
  button: {
    margin: theme.spacing(2),
  },
}));

const EditUserPage = (props) => {
  const classes = useStyles();
  const [turma, setTurma] = useState(undefined);
  const [turno, setTurno] = useState(undefined);
  const [serie, setSerie] = useState(undefined);

  useEffect(() => {
    props.getClasses();
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (turma) {
      props.updateGrade({
        Turma: turma,
        Turno: turno,
        Serie: serie,
      });
      history.push("/users");
    } else {
      window.alert("Preencha o campo turma")
    }
  };

  const buildClassesOptions = (options) =>
    options && options.length > 0
      ? options.map((option) => ({
          id: option,
          title: option,
        }))
      : [];

  const handleField = (setFunc, evt) => {
    const newValue = evt.target.value;
    setFunc(newValue);
  };

  return (
    <Page>
      <Row center>
        <Col center size={5}>
          <Title>Editar Turma</Title>
        </Col>
      </Row>

      <br />

      <form onSubmit={handleSubmit}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Select
            label="Turma"
            loaded={props.classes}
            options={buildClassesOptions(props.classes)}
            className={classes.text}
            onInputChange={(evt) => handleField(setTurma, evt)}
          />
          <TextField
            className={classes.text}
            name="turno"
            label="Turno"
            defaultValue={turno}
            onChange={(evt) => handleField(setTurno, evt)}
          />
          <Select
            label="Série"
            loaded={props.classes}
            options={buildClassesOptions([
              "1EF",
              "2EF",
              "3EF",
              "4EF",
              "5EF",
              "6EF",
              "7EF",
              "8EF",
              "9EF",
              "1EM",
              "2EM",
              "3EM"
            ])}
            className={classes.text}
            onInputChange={(evt) => handleField(setSerie, evt)}
          />
          <Button type="submit" label="Editar Turma" />
        </Grid>
      </form>
    </Page>
  );
};

const mapStateToProps = ({ grades, auth }) => ({
  grade: grades.grade,
  classes: auth.classes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClasses,
      readGrade,
      updateGrade,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditUserPage);
