import React from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as yup from 'yup'
import get from 'lodash/get'
import { withFormik, Field } from 'formik'
import { createTemplate } from '../../store/templates/actions'
import { TEMPLATE_RATIOS } from '../../utils/constants'

import AdminPermission from '../permission/AdminPermission'
import TemplateDraggerInput from '../input/TemplateDraggerInput'
import TextInput from '../input/TextInput'
import OrganizationInput from '../input/OrganizationInput'
import TemplateFontFamilyInput from '../input/TemplateFontFamilyInput'
import TemplateFontSizeInput from '../input/TemplateFontSizeInput'
import TemplateQuestionNumberingInput from '../input/TemplateQuestionNumberingInput'
import TemplateQuestionAlternativeInput from '../input/TemplateQuestionAlternativeInput'
import TemplateQuestionIndentationInput from '../input/TemplateQuestionIndentationInput'
import TemplateAnswerIndentationInput from '../input/TemplateAnswerIndentationInput'
import TemplateSpacingBeforeQuestionInput from '../input/TemplateSpacingBeforeQuestionInput'
import TemplateSpacingAfterQuestionInput from '../input/TemplateSpacingAfterQuestionInput'
import TemplatePreview from '../preview/TemplatePreview'
import SubmitButton from '../button/SubmitButton'
import { Spin, Icon } from 'antd'
import { StyledForm, SubGroup, SubGroupLabel, FlexGroup } from './styles/Form.styles'

const handleSubmit = (values, formikBag) => {
  const { templateId, createTemplate } = formikBag.props
  createTemplate({ ...values, templateId })
}

const validationSchema = props => {
  return yup.object().shape({
    name: yup.string().required(),
    organization_id: yup.string(),
    font: yup.string().required(),
    font_size: yup.string().required(),
    numbering_type: yup.string().required(),
    alternative_type: yup.string().required(),
    indentation_question: yup.string().required(),
    indentation_answer: yup.string().required(),
    spacing_before_question: yup.string().required(),
    spacing_after_question: yup.string().required()
  })
}

const defaultValues = {
  name: '',
  font: 'Arial',
  font_size: '12',
  numbering_type: '##.\t',
  alternative_type: 'a)\t',
  indentation_question: '0',
  indentation_answer: '0',
  spacing_before_question: '12',
  spacing_after_question: '0'
}

const mapPropsToValues = () => defaultValues

const TemplateForm = ({
  isValid,
  loading,
  templateId,
  templateFile,
  validation,
  values
}) => {
  const isOk = validation.status === 'OK'

  const templateDraggerTitle = loading
    ? 'Processando...'
    : templateId && templateFile && isOk
      ? templateFile.name
      : 'Clique ou arraste (DOCX)'

  const templateDraggerIcon = loading
    ? 'loading'
    : templateId && templateFile && isOk
      ? 'paper-clip'
      : 'file-word'

  const ratio = get(TEMPLATE_RATIOS, [values.font, values.numbering_type, values.font_size], 1)

  return (
    <Spin spinning={loading}>
      <StyledForm>
        <TemplateDraggerInput title={templateDraggerTitle}>
          <Icon
            rotate={!loading && !templateId && 20}
            type={templateDraggerIcon}
          />
        </TemplateDraggerInput>
        <FlexGroup flex={1}>
          <Field
            name='name'
            label='Nome'
            component={TextInput}
          />
          <AdminPermission>
            <Field
              name='organization_id'
              label='Organização'
              component={OrganizationInput}
            />
          </AdminPermission>
        </FlexGroup>
        <FlexGroup>
          <SubGroup>
            <SubGroupLabel>Fonte</SubGroupLabel>
            <Field
              name='font'
              component={TemplateFontFamilyInput}
            />
            <Field
              name='font_size'
              component={TemplateFontSizeInput}
            />
          </SubGroup>
          <SubGroup>
            <SubGroupLabel>Estilo</SubGroupLabel>
            <Field
              name='numbering_type'
              component={TemplateQuestionNumberingInput}
            />
            <Field
              name='alternative_type'
              component={TemplateQuestionAlternativeInput}
            />
          </SubGroup>
          <SubGroup>
            <SubGroupLabel>Indentação</SubGroupLabel>
            <Field
              name='indentation_question'
              component={TemplateQuestionIndentationInput}
              ratio={ratio}
              unit='id'
            />
            <Field
              name='indentation_answer'
              component={TemplateAnswerIndentationInput}
              ratio={ratio}
              unit='id'
            />
          </SubGroup>
          <SubGroup>
            <SubGroupLabel>Espaçamento do Enunciado</SubGroupLabel>
            <Field
              name='spacing_before_question'
              component={TemplateSpacingBeforeQuestionInput}
            />
            <Field
              name='spacing_after_question'
              component={TemplateSpacingAfterQuestionInput}
            />
          </SubGroup>
        </FlexGroup>
        <TemplatePreview
          values={values}
          ratio={ratio}
        />
        <SubmitButton
          disabled={!isValid || !(templateId && templateFile && isOk)}
          loading={loading}
        >
          Enviar
        </SubmitButton>
      </StyledForm>
    </Spin>
  )
}

TemplateForm.propTypes = {
  isValid: PropTypes.bool,
  loading: PropTypes.bool,
  templateId: PropTypes.string,
  templateFile: PropTypes.object,
  validation: PropTypes.object,
  values: PropTypes.object
}

const mapStateToProps = ({ templates }) => ({
  loading: templates.loading,
  templateId: templates.id,
  templateFile: templates.file,
  validation: templates.validation
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    createTemplate
  }, dispatch)

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit,
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues
  })
)

export default enhance(TemplateForm)
