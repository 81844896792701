import createReducer from '../createReducer'
import {
  CLEAR_OUTPUT_MODULE,
  CLEAR_OUTPUT_ERROR,
  PROCESS_OUTPUT,
  PROCESS_OUTPUT_SUCCESS,
  PROCESS_OUTPUT_FAILURE,
  PROCESS_OUTPUT_CANCEL,
  SET_HIDE_WARNINGS,
  FETCH_OUTPUT,
  FETCH_OUTPUT_SUCCESS,
  FETCH_OUTPUT_FAILURE
} from './constants'

const initialState = {
  outputs: [],
  output: {},
  hideWarnings: false,
  loading: false,
  error: {}
}

export default createReducer(initialState, {
  [CLEAR_OUTPUT_MODULE]: () => initialState,
  [CLEAR_OUTPUT_ERROR]: (state, action) => ({
    ...state,
    error: initialState.error
  }),
  [PROCESS_OUTPUT]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [PROCESS_OUTPUT_SUCCESS]: (state, action) => ({
    ...state,
    outputs: action.payload,
    loading: false,
    error: initialState.error
  }),
  [PROCESS_OUTPUT_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [PROCESS_OUTPUT_CANCEL]: (state, action) => ({
    ...state,
    loading: false
  }),
  [SET_HIDE_WARNINGS]: (state, action) => ({
    ...state,
    hideWarnings: action.payload
  }),
  [FETCH_OUTPUT]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [FETCH_OUTPUT_SUCCESS]: (state, action) => ({
    ...state,
    output: action.payload,
    loading: false,
    error: initialState.error
  }),
  [FETCH_OUTPUT_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  })
})
