import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Container = styled.div`
  margin: 0 2.5rem;
  text-align: center;
`

export const iconStyle = css`
  color: #1890ff;
  font-size: 5rem;
  margin: 2rem 0 1rem 0;
`

export const successIconStyle = css`
  ${iconStyle}
  color: #00a854;
`

export const Title = styled.p`
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: .5rem;
`

export const Subtitle = styled.p`
  font-size: 1.1rem;
`
export const A = styled.a`
  color: #514FFF;
`