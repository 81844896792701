import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { handleToken } from './middlewares'
import { rootReducer, rootSaga } from './index'
import sentry from '../utils/sentry'

// Configure Store
const composeEnhancers = compose || window.__REDUX_DEVTOOLS_EXTENSION__

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const sagaMiddleware = createSagaMiddleware({ onError: sentry.captureException })

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware, handleToken)
)

export const store = createStore(persistedReducer, {}, enhancer)
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
