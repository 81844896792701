import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateResultRW } from "../../../store/exams/actions";

import { MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";
import TextFieldDialog from "../../../components/modal/TextFieldDialog";
import { message } from "antd";

const SemanticFieldSelect = ({
  setOverallSemanticField,
  studentsResult,
  month,
  resultRW,
  setResultRW,
  updateResultRW,
  setIsSemanticFieldSelected,
  style,
}) => {
  const [semanticField, setSemanticField] = useState("");
  const [otherSemanticField, setOtherSemanticField] = useState("");
  const [openOtherSemanticField, setOpenOtherSemanticField] = useState(false);

  const [semanticFieldsList, setSemanticFieldsList] = useState([
    "Animais",
    "Frutas",
    "Material escolar",
    "Corpo",
    "Família",
    "Comidas",
    "Esportes",
    "Brinquedos",
    "Móveis",
    "Plantas",
    "Outro",
  ]);

  useEffect(() => {
    if (month && resultRW && resultRW[month]) {
      const students = Object.entries(resultRW[month]);
      const currentSemanticField = students[0][1]["SemanticField"];
      if (currentSemanticField) {
        if (!semanticFieldsList.includes(currentSemanticField)) {
          setSemanticFieldsList([...semanticFieldsList, currentSemanticField]);
          addNewSemanticField(currentSemanticField);
        }
        setOverallSemanticField(currentSemanticField);
        setSemanticField(currentSemanticField);
        setIsSemanticFieldSelected(true);
      } else {
        setSemanticField("");
        setOverallSemanticField("");
        setOtherSemanticField("");
      }
    }
  }, [month, resultRW]);

  const handleSemanticFields = (e) => {
    const option = e.target.value;
    if (option !== "Outro") {
      setSemanticField(option);
      setOverallSemanticField(option);
      setIsSemanticFieldSelected(true);
      studentsResult.forEach((student) => {
        const studentData = { ...student, SemanticField: option };
        const resultRW = setResultRW(month, student.StudentId, studentData);
        updateResultRW(resultRW, studentData);
        message.info("Salvando marcação...");
      });
    } else {
      setOpenOtherSemanticField(true);
    }
  };

  const handleOtherSemanticFieldChange = (e) => {
    setOtherSemanticField(e.target.value);
  };

  const handleSaveOtherSemanticField = () => {
    if (otherSemanticField) {
      studentsResult.map((student) => {
        const studentData = { ...student, SemanticField: otherSemanticField };
        const resultRW = setResultRW(month, student.StudentId, studentData);
        updateResultRW(resultRW, studentData);
        message.info("Salvando marcação...");
      });

      addNewSemanticField(otherSemanticField);
      setSemanticField(otherSemanticField);
      setOverallSemanticField(otherSemanticField);
    }

    closeOtherSemanticField();
  };

  const closeOtherSemanticField = () => {
    setOpenOtherSemanticField(false);
  };

  const addNewSemanticField = (newField) => {
    const newList = [...semanticFieldsList.slice(0, -1), newField, "Outro"];
    setSemanticFieldsList(newList);
  };

  return (
    <>
      <FormControl fullWidth className={style}>
        <InputLabel>Selecione o campo semântico</InputLabel>
        <Select fullWidth value={semanticField} onChange={handleSemanticFields}>
          {semanticFieldsList.map((d) => (
            <MenuItem value={d}>{d}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextFieldDialog
        open={openOtherSemanticField}
        closeDialog={closeOtherSemanticField}
        textFieldValue={otherSemanticField}
        handleChange={handleOtherSemanticFieldChange}
        handleSave={handleSaveOtherSemanticField}
        title={"Insira o campo semântico:"}
        label={"Campo Semântico"}
      />
    </>
  );
};

SemanticFieldSelect.propTypes = {
  updateResultRW: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams }) => ({
  resultRW: exams.resultRW,
  updateRW: exams.updateRW,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateResultRW,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SemanticFieldSelect);
