/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearExamModule, fetchSelected } from "../store/exams/actions";

import QuestionBlock from "../components/question/QuestionBlock";

import { Button, Spin } from "antd";

import { Page } from "./styles/Page.styles";
import { Content } from "./styles/DetailsPage.styles";

function ListQuestionsConfigPage({
  examId,
  clearExamModule,
  fetchSelected,
  edit,
  loading,
  editar,
  selected,
  isSuperAdmin,
}) {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [tags, setTags] = useState([]);
  const [loadQuestions, setLoadQuestions] = useState(false);

  function handleSuffleQuestions(array) {
    let newArray = extractQuestions(array);
    setSelectedQuestions(newArray);
  }

  useEffect(() => {
    clearExamModule();
    if (examId) {
      fetchSelected(examId);
    }
  }, []);

  useEffect(() => {
    if (
      selected !== undefined &&
      selected[0] !== undefined &&
      selected[0].Questions !== undefined &&
      selected[0].Questions.length > 0
    ) {
      setTags(extractTags([selected[0]]));
      setSelectedQuestions(selected[0].Questions);
      setLoadQuestions(true);
    }
  }, [selected]);

  function extractQuestions(array) {
    let newArray = [];
    if (array) {
      for (let i = 0; i < array.length; i++) {
        newArray.push(array[i].question);
      }
    }

    return newArray;
  }

  function updateSelected(array) {
    setSelectedQuestions(array);
  }

  function extractTags(exams) {
    let tagList = [];
    if (exams !== undefined) {
      for (let x = 0; x < exams.length; x++) {
        if (exams[x].Questions !== undefined && exams[x].Questions !== null) {
          for (let y = 0; y < exams[x].Questions.length; y++) {
            for (let z = 0; z < exams[x].Questions[y].Tags.length; z++) {
              tagList.push(exams[x].Questions[y].Tags[z]);
            }
          }
        }
      }
    }

    return [...new Set(tagList)];
  }

  function updateCorrectAnswer(question) {
    let updateSelectedQuestions = selectedQuestions.map((quest) => {
      if (quest.Id === question.Id) {
        return question;
      }
      return quest;
    });

    setSelectedQuestions(updateSelectedQuestions);
  }

  function updateQuestionType(question, type) {
    if (question !== "undefined") {
      let updateSelectedQuestions = selectedQuestions.map((quest) => {
        if (quest.Id === question.Id) {
          return { ...quest, Type: type };
        }
        return quest;
      });

      setSelectedQuestions(updateSelectedQuestions);
    }
  }

  function removeModal(array) {
    setSelectedQuestions(array);
  }

  return (
    <Page>
      <Spin spinning={loading}>
        {selected.length > 0 && (
          <Content>
            <br />
            {/* Tags:
            {selected[0].Tags !== null && selected[0].Tags.map((tag, i) => {
              return (<Button key={i} type='primary' shape='round' className='tagButton'
                style={{ marginLeft: '5px' }}>{tag}</Button>)
            })
            } */}
            {loadQuestions && (
              <QuestionBlock
                isSuperAdmin={isSuperAdmin}
                loading={loading}
                selectedQuestions={selectedQuestions}
                loadQuestions
                tags={tags}
                exam={selected[0]}
                // eslint-disable-next-line
                onShuffle={handleSuffleQuestions}
                changeTypeInline
                examId={examId}
                // eslint-disable-next-line
                updateSelected={updateSelected}
                // eslint-disable-next-line
                updateCorrectAnswer={updateCorrectAnswer}
                // eslint-disable-next-line
                updateType={updateQuestionType}
                removeModal={removeModal}
                justView
                exams={selected}
                pickedExams={selected}
                editable={false}
                editView
                sufflable
                onlyModal
                createView={false}
                view
                disableSelect
              />
            )}
          </Content>
        )}
      </Spin>
    </Page>
  );
}

ListQuestionsConfigPage.propTypes = {
  examId: PropTypes.string,
  edit: PropTypes.bool,
  editar: PropTypes.bool,
  selected: PropTypes.array,
  loading: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  clearExamModule: PropTypes.func,
  fetchSelected: PropTypes.func,
};

const mapStateToProps = ({ exams, auth }) => ({
  examId: exams.id,
  edit: exams.editQuestions,
  selected: exams.selected,
  editar: exams.editar,
  loading: exams.loading,
  resolutions: exams.resolutions,
  isSuperAdmin: auth.loggedUser.Role === "SuperUser" || auth.loggedUser.Role === "Super",
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearExamModule,
      fetchSelected,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListQuestionsConfigPage);
