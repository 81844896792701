export const CLEAR_OUTPUT_MODULE = 'outputs:CLEAR_OUTPUT_MODULE'
export const CLEAR_OUTPUT_ERROR = 'outputs:CLEAR_OUTPUT_ERROR'

export const PROCESS_OUTPUT = 'outputs:PROCESS_OUTPUT'
export const PROCESS_OUTPUT_SUCCESS = 'outputs:PROCESS_OUTPUT_SUCCESS'
export const PROCESS_OUTPUT_FAILURE = 'outputs:PROCESS_OUTPUT_FAILURE'
export const PROCESS_OUTPUT_CANCEL = 'outputs:PROCESS_OUTPUT_CANCEL'

export const SET_HIDE_WARNINGS = 'outputs:SET_HIDE_WARNINGS'

export const FETCH_OUTPUT = 'outputs:FETCH_OUTPUT'
export const FETCH_OUTPUT_SUCCESS = 'outputs:FETCH_OUTPUT_SUCCESS'
export const FETCH_OUTPUT_FAILURE = 'outputs:FETCH_OUTPUT_FAILURE'
