import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableContainer,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { Print } from "@material-ui/icons";
import ExamReportTable from "../reports/ExamReportTable";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paddingCell: {
    "&.MuiTableCell-paddingNone": {
      padding: "8px 8px 4px 8px",
    },
  },
}));

const CreateTurmaTable = ({
  schoolId,
  turmaName,
  correctPercent,
  schoolName,
  totalStudents,
  selectedExamTitle,
  tableRows,
  questionAns,
  showAdaptedExamTable,
  adaptedExamSchoolId,
  adaptedExamTurmaName,
  adaptedExamRows,
  adaptedExamQuestionAns,
  adaptedExamCorrectPercent,
  adaptedExamTotalStudents,
  adaptedExamName,
  handleOpenStudentExam,
  handleBoletim,
  printDocument,
}) => {
  const [rows, setRows] = useState(tableRows);
  const [sortTable, setSortTable] = useState("");
  const [adaptedTableRows, setAdaptedTableRows] = useState(adaptedExamRows);
  const classes = useStyles();

  const handleSortTable = (e) => {
    const order = e.target.value;
    let studentsGrades = {};
  
    if (turmaName === "Todas as Turmas") {
      if (correctPercent[schoolId] && correctPercent[schoolId].data) {
        Object.keys(correctPercent[schoolId]).forEach((turma) => {
          if (turma !== "data") {
            Object.keys(correctPercent[schoolId][turma]).forEach((studentId) => {
              if (!studentsGrades[studentId]) {
                studentsGrades[studentId] = { correct: 0, total: 0 };
              }
              studentsGrades[studentId].correct += correctPercent[schoolId][turma][studentId].correct;
              studentsGrades[studentId].total += correctPercent[schoolId][turma][studentId].total;
            });
          }
        });
      } else {
        console.error("No data found for 'Todas as Turmas'");
      }
    } else {
      studentsGrades = correctPercent[schoolId][turmaName] || {};
    }
  
    if (order === "Nota") {
      const sortedRows = sortByGrade(rows, studentsGrades);
      if (!sortedRows.length) {
        console.error("Sorted rows are empty or undefined");
      }
      setRows(sortedRows);
      if (showAdaptedExamTable && adaptedTableRows.length > 0) {
        let studentsAdaptedExamGrades;
  
        if (adaptedExamTurmaName === "Todas as Turmas") {
          if (adaptedExamCorrectPercent[adaptedExamSchoolId] && adaptedExamCorrectPercent[adaptedExamSchoolId].data) {
            Object.keys(adaptedExamCorrectPercent[adaptedExamSchoolId]).forEach((turma) => {
              if (turma !== "data") {
                Object.keys(adaptedExamCorrectPercent[adaptedExamSchoolId][turma]).forEach((studentId) => {
                  if (!studentsAdaptedExamGrades[studentId]) {
                    studentsAdaptedExamGrades[studentId] = { correct: 0, total: 0 };
                  }
                  studentsAdaptedExamGrades[studentId].correct += adaptedExamCorrectPercent[adaptedExamSchoolId][turma][studentId].correct;
                  studentsAdaptedExamGrades[studentId].total += adaptedExamCorrectPercent[adaptedExamSchoolId][turma][studentId].total;
                });
              }
            });
          } else {
            console.error("No adapted exam data found for 'Todas as Turmas'");
          }
        } else {
          studentsAdaptedExamGrades = adaptedExamCorrectPercent[adaptedExamSchoolId][adaptedExamTurmaName] || {};
        }
  
        const sortedAdaptedRows = sortByGrade(adaptedTableRows, studentsAdaptedExamGrades);
        if (!sortedAdaptedRows.length) {
          console.error("Sorted adapted rows are empty or undefined");
        }
        setAdaptedTableRows(removeUndefinedRow(sortedAdaptedRows));
      }
    } else {
      setRows(sortByName(rows));
      if (showAdaptedExamTable && adaptedTableRows.length > 0) {
        setAdaptedTableRows(sortByName(adaptedTableRows));
      }
    }
    setSortTable(order);
  };

  const sortByGrade = (students, studentsGrades) => {
    const studentsIdAndGrade = Object.entries(studentsGrades)
      .filter(([key, _]) => key !== "data")
      .map(([key, value]) => ({
        id: key,
        grade: value.total > 0 ? value.correct / value.total : 0,
      }))
      .sort((a, b) => b.grade - a.grade);
  
    const sortedStudents = studentsIdAndGrade
      .map(({ id }) => students.find((student) => student.id === id))
      .filter((student) => student !== undefined); 
  
    return sortedStudents;
  };

  const sortByName = (students) => {
    return students.sort((a, b) => a.name.localeCompare(b.name));
  };

  const removeUndefinedRow = (rows) => {
    const tabelRows = rows.filter((item) => item !== undefined);
    return tabelRows;
  };

  return (
    <TableContainer component={Paper}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={8}>
          <IconButton onClick={() => printDocument(schoolId + turmaName)}>
            <Print />
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ mr: 3 }}>
            <FormControl fullWidth>
              <InputLabel>Ordenar por: </InputLabel>
              <Select value={sortTable} onChange={handleSortTable}>
                <MenuItem key="name" value="Nome">
                  Nome
                </MenuItem>
                <MenuItem key="grade" value="Nota">
                  Nota
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <div id={schoolId + turmaName}>
        <Table size="small" id={schoolId + turmaName}>
          <Grid
            container
            spacing={1}
            direction="column"
            style={{ margin: "10px 0 0 10px" }}
          >
            <Grid item>
              <Typography
                variant="p"
                sx={{ fontWeight: "medium", fontSize: 18 }}
              >
                Escola: {schoolName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="p"
                sx={{ fontWeight: "medium", fontSize: 18 }}
              >
                Turma: {turmaName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="p"
                sx={{ fontWeight: "medium", fontSize: 18 }}
              >
                Prova: {selectedExamTitle}
              </Typography>
            </Grid>
          </Grid>
        </Table>
        <ExamReportTable
          rows={rows}
          schoolId={schoolId}
          turmaName={turmaName}
          questionAns={questionAns}
          correctPercent={correctPercent}
          totalStudents={totalStudents}
          handleOpenStudentExam={handleOpenStudentExam}
          handleBoletim={handleBoletim}
          style={classes.paddingCell}
        />

        {showAdaptedExamTable &&
          adaptedExamSchoolId &&
          adaptedExamTurmaName &&
          adaptedTableRows.length > 0 && (
            <>
              <Box sx={{ mt: 7, ml: 2 }}>
                <Typography
                  variant="p"
                  sx={{ fontWeight: "medium", fontSize: 18 }}
                >
                  Prova Adaptativa: {adaptedExamName}
                </Typography>
              </Box>
              <ExamReportTable
                rows={adaptedTableRows}
                schoolId={adaptedExamSchoolId}
                turmaName={adaptedExamTurmaName}
                questionAns={adaptedExamQuestionAns}
                correctPercent={adaptedExamCorrectPercent}
                totalStudents={adaptedExamTotalStudents}
                handleOpenStudentExam={handleOpenStudentExam}
                handleBoletim={handleBoletim}
                style={classes.paddingCell}
              />
            </>
          )}
      </div>
    </TableContainer>
  );
};

export default CreateTurmaTable;
