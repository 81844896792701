import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  clearExamModule,
  classifyExam,
  fetchClassifications,
  fetchRecognized,
  uploadExamSuccess,
} from "../store/exams/actions";
import { infoUser } from "../store/users/actions";

import { Spin, Progress } from "antd";
import useWindowSize from "../utils/useWindowSize";
import LabelStudio from "../components/labelstudio/LabelStudio";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#514fff",
    },
  },
});

function ClassificationPage({
  selected,
  user,
  clearExamModule,
  infoUser,
  classifyExam,
  fetchClassifications,
  fetchRecognized,
  uploadExamSuccess,
}) {
  const windowSize = useWindowSize();
  const [progress, setProgress] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [loadQuestions, setLoadQuestions] = useState(false);
  const [classify, setClassify] = useState(false);
  const [renderMore, setRenderMore] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [edit, setEdit] = useState(false);
  const [waiting, setWaiting] = useState(true);
  const [mustUpdate, setMustUpdate] = useState(true);
  const [images, setImages] = useState(undefined);
  const [timer, setTimer] = useState(undefined);
  const [examId, setExamId] = useState(undefined);
  const [examClassification, setExamClassification] = useState(undefined);
  const [loggedInUserId, setLoggedInUserId] = useState(undefined);
  
  useEffect(() => {
    // Get url params
    infoUser();
    clearExamModule();
    let url = new URL(window.location.href);
    let urlExamId = url.searchParams.get("examId");
    let scholarship = url.searchParams.get("scholarship");
    let editing = url.searchParams.get("edit") === "true";

    // Get window size
    if (windowSize.width < 480) {
      setIsMobile(true);
    }

    if (editing) {
      /* If page in editing mode inform
      that questions is already loaded
      and get classifications from backend */
      setLoadQuestions(true);
      fetchClassifications(urlExamId, scholarship);
    } else {
      /* If page is not in editing mode:
      reset images and ask to backend for classifications
      every 3 seconds until all classifications be gotted */
      setImages(undefined);

      if (waiting && url.pathname === "/classification") {
        const intervalId = setInterval(() => {
          /* Inform that classifications are loaded or aren't loaded
          get classifications every 3 seconds until be loaded */
          setRenderMore(true);
          fetchRecognized(urlExamId);

          let auxUrl = new URL(window.location.href);
          if (auxUrl.pathname !== "/classification") {
            clearInterval(intervalId);
          }
        }, 3000);

        setWaiting(false);
        setTimer(intervalId);
      }
    }
    setExamId(urlExamId);
    setEdit(editing);
  }, []);

  useEffect(() => {
    if (user && user.Id) {
      setLoggedInUserId(user.Id);
    }
  }, [user]);

  /* When page be unmounted, clean timer */
  useEffect(() => () => clearInterval(timer), []);

  /* Mount images object with classifications data */
  const imagesBuilder = (classifications) => {
    let images = classifications.map(
      (s) =>
        s.Processed &&
        s.Processed.map((im) => {
          let regions = im.predictions.map((bouding) => ({
            from_name: "tag",
            to_name: "img",
            source: "$image",
            type: "rectanglelabels",
            value: {
              height: (100 * (bouding.xyxy[3] - bouding.xyxy[1])) / im.height,
              width: (100 * (bouding.xyxy[2] - bouding.xyxy[0])) / im.width,
              x: (100 * bouding.xyxy[0]) / im.width,
              y: (100 * bouding.xyxy[1]) / im.height,
              rectanglelabels: [bouding.label],
              rotation: 0,
            },
          }));

          regions = [].concat.apply([], regions);

          return {
            id: Math.random(),
            data: {
              image: im.url_image,
            },
            annotations: [
              {
                result: regions,
              },
            ],
            predictions: [],
          };
        })
    );

    return [].concat.apply([], images);
  };

  /* Update progress and loading status */
  const loadingWorker = () => {
    if (
      (!loadQuestions || edit) &&
      selected &&
      selected.length > 0 &&
      selected[0].Step &&
      selected[0].Total
    ) {
      let processed = selected[0].Step;
      let total = selected[0].Total;

      let percent = Math.ceil((100 * processed) / total);

      const limit = 0;
      if (percent > limit) {
        setProgress(percent);
      } else {
        if (progress < limit) {
          setProgress(progress + 1);
        }
      }

      if (processed === total) {
        setLoadQuestions(true);
      }
    }
  };

  /* If has classifications, build images, update images and allow classifications */
  const startSelected = (selected) => {
    if (
      selected &&
      selected.length > 0 &&
      selected[0] &&
      selected[0].Processed
    ) {
      let images = imagesBuilder(selected);
      setImages(images);
      setClassify(true);
    }
  };

  /* Update loading and update images after get new classifications */
  const renderMoreSelected = (selected) => {
    loadingWorker();
    setRenderMore(false);

    if (images === undefined) {
      startSelected(selected);
    } else if (selected && selected.length > 0) {
      setClassify(true);

      const moreImages = imagesBuilder(selected);
      if (moreImages.length > 0) {
        setImages(images.concat(moreImages.slice(images.length)));
      }
    }
  };

  /* Find classification by image path */
  const getDefaultImg = (path) => {
    for (var i = 0; i < selected.length; i++) {
      for (var j = 0; j < selected[i].Processed.length; j++) {
        if (selected[i].Processed[j].url_image === path) {
          return { Processed: selected[i].Processed[j], Selected: selected[i] };
        }
      }
    }
  };

  /* Classify if is mobile */
  const exitMobile = () => {
    selected[0]["Editing"] = false;
    selected[0]["UserId"] = loggedInUserId;
    if (selected[0].Step === selected[0].Total) {
      classifyExam(selected);
    }
  };

  /* Build prediction object */
  const buildPredictions = (defaultProcessed, result) => {
    const predictions = result.map((r) => {
      return {
        label: r.value.rectanglelabels[0],
        conf: 1,
        xyxy: [
          (r.value.x * defaultProcessed.width) / 100,
          (r.value.y * defaultProcessed.height) / 100,
          ((r.value.x + r.value.width) * defaultProcessed.width) / 100,
          ((r.value.y + r.value.height) * defaultProcessed.height) / 100,
        ],
      };
    });

    return predictions;
  };

  /* Build processed object */
  const buildProcessed = (id, imgs) =>
    // eslint-disable-next-line
    imgs
      .map((im) => {
        const defaultImg = getDefaultImg(im.data.image);
        const defaultProcessed = defaultImg["Processed"];

        if (defaultImg["Selected"]["ExamId"] === id) {
          const preds = buildPredictions(
            defaultProcessed,
            im.annotations[0].result
          );
          const isEqual = comparePredictions(
            defaultProcessed.predictions,
            preds
          );

          return {
            predictions: preds,
            translated: defaultProcessed.translated,
            width: defaultProcessed.width,
            height: defaultProcessed.height,
            url_image: im.data.image,
            modified: !isEqual,
          };
        }
      })
      .filter((p) => p);

  /* Build Selected object */
  const buildSelected = (s, images) => ({
    Id: s["Id"],
    ExamId: s["ExamId"],
    FileName: s["FileName"],
    UserId: loggedInUserId,
    Url: s["Url"],
    Title: s["Title"] || "",
    Editing: edit,
    Processed: buildProcessed(s["ExamId"], images),
  });

  /* Compre predictions */
  const comparePredictions = (pred1, pred2) => {
    return (
      pred1.map((p) => p.xyxy.map((i) => i.toFixed())).join("") ===
      pred2.map((p) => p.xyxy.map((i) => i.toFixed())).join("")
    );
  };

  /* Update image object */
  const updateImage = (annotations) => {
    images[activeStep].annotations[0].result = annotations;
    setImages(images);

    const examClassification = selected.map((s) => buildSelected(s, images));
    setExamClassification(examClassification);

    if (activeStep === selected[0].Total - 1) {
      window.alert("Todas as páginas foram atualizadas!");
      setMustUpdate(false);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const changePage = (step) => {
    if (mustUpdate) {
      window.alert("Atualize suas modificações!");
    } else {
      setActiveStep(step);
      setMustUpdate(true);
    }
  };

  /* Redirect to list-questions page */
  const redirect = () => {
    if (mustUpdate) {
      window.alert("Atualize suas modificações!");
    } else {
      classifyExam(examClassification);
    }
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {console.log("im", selected, images)}
      {classify ? images && isMobile && exitMobile() : startSelected(selected)}
      {renderMore && renderMoreSelected(selected)}
      {(!loadQuestions || isMobile) && (
        <div
          style={{
            width: "256px",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Spin
            spinning
            tip={
              images === undefined
                ? "Carregando a primeira página..."
                : "Carregando as demais páginas..."
            }
          />
          <Progress percent={progress} />
        </div>
      )}
      {images && (
        <div>
          <LabelStudio
            labelConfig={`<View display='inline'>
              <RectangleLabels canRotate='false' name='tag' toName='img'>
                <Label value='figura'/>
                <Label value='alternativa'/>
                <Label value='enunciado'/>
                <Label value='questao'/>
                <Label value='gabarito' background="red"/>
                <Label value='resolucao' background='green'/>
              </RectangleLabels>
              <Image name='img' value='$image' zoomBy='1.5'/>
            </View>
          `}
            images={images[activeStep]}
            updateImage={updateImage}
            activeStep={activeStep}
          />
          <MobileStepper
            position="static"
            variant="progress"
            steps={images.length}
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={() => changePage(activeStep + 1)}
                disabled={activeStep === images.length - 1}
              >
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={() => setActiveStep(activeStep - 1)}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
              </Button>
            }
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <MuiThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "16px" }}
                disabled={activeStep < images.length - 1 || !loadQuestions}
                onClick={() => redirect()}
              >
                Finalizar
              </Button>
            </MuiThemeProvider>
          </div>
        </div>
      )}
    </div>
  );
}

ClassificationPage.propTypes = {
  selected: PropTypes.array,
  classifyExam: PropTypes.func,
  clearExamModule: PropTypes.func,
  infoUser: PropTypes.func,
  fetchClassifications: PropTypes.func,
  fetchRecognized: PropTypes.func,
  uploadExamSuccess: PropTypes.func,
};

const mapStateToProps = ({ exams, users }) => ({
  selected: exams.selected,
  user: users.infoUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearExamModule,
      infoUser,
      classifyExam,
      fetchClassifications,
      fetchRecognized,
      uploadExamSuccess,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationPage);
