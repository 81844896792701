import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { handleChange } from '../../utils/formHelpers'

class SelectSubject extends Component {
  state = {
    initialValue: this.props.initialValue,
    loading: false,
    timeout: false,
    rolesOptions: [],
    input: ''
  }

  static propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    user: PropTypes.object,
    initialValue: PropTypes.string
  }

  rolesOptions () {
    let selected = []
    let subjects = [
        "Matemática",
        "Química",
        "Biologia",
        "História",
        "Física",
        "Geografia",
        "Filosofia",
        "Sociologia",
        "Língua Portuguesa",
        "Inglês",
        "Espanhol"
    ];

    subjects.map(subject => {
        let option = 
            <Select.Option
                key={subject}
                value={subject}
                title={subject} 
            >
               {subject} 
            </Select.Option>
        selected.push(option);
        return option
    })
    this.setState({ rolesOptions: selected })
  }

  componentDidMount () {
    this.rolesOptions()
  }

  componentDidUpdate (prevProps) {
    const { initialValue } = this.props
    prevProps.initialValue !== initialValue && this.setState({ initialValue })
  }

  render () {
    const {
      initialValue,
      // loading,
      rolesOptions
    } = this.state
    const {
      // label,
      field,
      form
      // // search
    } = this.props

    return (
      <div>
        <Select
          className='select-input'
          value={initialValue || (field.value !== null ? field.value : undefined)}
          onChange={value => this.setState({ initialValue: '' }, () => handleChange(form, field, value))}
          showSearch
          style={{ width: '100%' }}
          placeholder={'Disciplina'}
          allowClear
          disabled={this.props.disabled}
        >
          {rolesOptions}
        </Select>
      </div>
    )
  }
}

export default SelectSubject;
