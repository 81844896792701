/* eslint-disable */
import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import ContainerCard from '../../card/ContainerCard'
import TagBlock from '../../tag/TagBlock'

import { Box } from "@material-ui/core";

import { renderQuestionNumber } from '../../../utils/questionHelpers'
import renderHTML from 'react-render-html'
import DOMPurify from 'dompurify'

import { List } from 'antd'
import {
  Row,
  QuestionHeader,
  QuestionNumber,
  QuestionText,
  QuestionInstructions,
  QuestionNumberFont
} from './styles'
import useWindowSize from '../../../utils/useWindowSize'

function QuestionResultCard ({ examId, question, editable = false, deletable = false, onDelete, isSelectable = false, onCheck, selected = [], handleAddTag, handleRemoveTag,
  numberQuestion = 0, editView = false, createView = false, onlyModal = false, checked = true, onShuffle, totalChecked = false, onChange, correctAnswer, forceDescheck, questionTags, master = false, similarStatement }) {
  const [typeChoice, setTypeChoice] = useState(undefined)
  const [statement, setStatement] = useState('')
  const [tags, setTags] = useState([])
  const similarQid = []
  const windowSize = useWindowSize()

  similarQid.push(similarStatement)

  useEffect(() => {
    if (question) {
      setStatement(question.Statement)
    }
  }, [selected])

  useEffect(() => {
    if (question !== undefined) {
      setTypeChoice(question.Type)
    }
  }, [question, editView])

  useEffect(() => {
    if (forceDescheck !== undefined) {
      onCheckboxChangeForce()
    }
  }, [forceDescheck])

  useEffect(() => {
    if (!master) {
      tags.concat(questionTags)
    }
    setTags(tags)
  }, [tags, questionTags, master])

  useEffect(() => {
    let initialTags = (question !== undefined && question.Tags !== undefined && question.Tags !== null) ? question.Tags : []
    if (!master) {
      initialTags.concat(questionTags)
    }
    setTags(initialTags)
  }, [questionTags, master])

  function correctImageSize(text) {
    text = text.replace(/<img /g, '<img style="max-width: 100%;"');
    return (
      '<span style="color: #000000;font-size: 21px;font-family: Arial;">' +
      text +
      "</span>"
    );
  }

  const renderItem = item => (
    <>
      {question.Type !== 'Dissertative' && (<List.Item>
        <Row>
          {<input style={{ marginTop: '8px' }} type='radio' name={question.Id} disabled checked={item.Order === question.StudentAnswer} />}
          <span style={{ marginTop: '6px' }} >{item.Order})&nbsp;</span><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(correctImageSize(item.Description.replace('<p>', '').replace('<span style="color: #000000;font-size: 21px;font-family: Arial;"></span></p>', ''))) }} />
        </Row>
      </List.Item>)
      }
    </>
  )

  const onCheckboxChangeForce = () => {
    let newValue = false
    onCheck(newValue, question)
  }

  function closableTag () {
    return (isSelectable || (createView || editView)) && !onlyModal
  }

  function addTagQuestion (tag) {
    handleAddTag(question.Id, tag)
  }

  function removeTagQuestion (tag) {
    handleRemoveTag(question.Id, tag)
  }

  const renderGabarito = () => {
    if (question.Alternatives && question.Alternatives.length > 0) {
      const gab = question.Alternatives.filter(e => e.Correct === true).map(e => e.Order).join(', ');

      const correct = gab.includes(question.StudentAnswer);

      return (<span style={{ color: correct ? '#009900' : '#990000' }}>Gabarito: {gab}</span>)
    }
  }

  return (
    <>
      {question !== undefined &&
      <ContainerCard>
        <QuestionHeader>
          <QuestionNumber style={{ width: '100%' }}>
            <QuestionInstructions>
              {question !== null && question !== undefined && question.Instructions !== null && renderHTML(correctImageSize(question.Instructions))}
            </QuestionInstructions>
            <QuestionNumberFont>QUESTÃO {renderQuestionNumber(numberQuestion)}</QuestionNumberFont>
          </QuestionNumber>

        </QuestionHeader>
        <Box
          sx={{
            width: '100%',
            overflowWrap: 'break-word',
          }}
        >
          <QuestionText>
          {question.ParentStatements && question.ParentStatements.length > 0 && 
            question.ParentStatements.map((content) => (
                renderHTML(correctImageSize(content))
            ))
          }
          {renderHTML(correctImageSize(statement))}
        </QuestionText>
        </Box>
        <Row>
          {
            (question.Alternatives.length > 0 && (typeChoice === 'Multiple Choice' || typeChoice === 'MultipleChoice')) &&
            <List
              dataSource={question.Alternatives}
              renderItem={renderItem}
            />
          }
        </Row>
        { (editView || createView) && !onlyModal &&
        <Row>
          <TagBlock master={master} questionTags={questionTags} initial={tags} addTag={addTagQuestion} removeTag={removeTagQuestion} canAdd={closableTag} closable={closableTag} />
        </Row>
        }
        {renderGabarito()}
      </ContainerCard>
      }
    </>
  )
}

QuestionResultCard.propTypes = {
  addTag: PropTypes.func.isRequired,
  removeTag: PropTypes.func,
  onShuffle: PropTypes.func,
  totalChecked: PropTypes.bool,
  master: PropTypes.bool,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  handleAddTag: PropTypes.func,
  handleRemoveTag: PropTypes.func,
  forceDescheck: PropTypes.any,
  questionTags: PropTypes.any,
  correctAnswer: PropTypes.func,
  similarStatement: PropTypes.array
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
  }, dispatch)

export default connect(null, mapDispatchToProps)(QuestionResultCard)
