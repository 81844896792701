import React, { useEffect } from 'react'
import StudentForm from '../../components/form/StudentForm'
import { Page } from '../styles/Page.styles'
import { Col, Row } from '../../components/table/styles/Table.styles'
import { Title } from '../../components/form/styles/Form.styles'
import { Spin } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchCompanyBasic } from '../../store/companies/actions'
import { Container, Content, Group } from '../../components/template/styles/HeaderTemplate.styles'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo.svg'

const StudentPage = ({
  loading,
  match,
  fetchCompanyBasic,
  company
}) => {
  useEffect(() => {
    fetchCompanyBasic(match.params.id)
  }, [match])
  return (
    <Container>
      <Content>
        <Group>
          <Link to='#'>
            <Logo width='100%' height='100%' />
          </Link>
        </Group>
      </Content>
      <Page>
        <Row center>
          <Col center size={5}><Title>Registro de Estudante</Title></Col>
        </Row>
        <Spin spinning={loading}>
          <StudentForm company={company} />
        </Spin>
      </Page>
    </Container>
  )
}

StudentPage.propTypes = {
  loading: PropTypes.bool,
  match: PropTypes.object,
  company: PropTypes.object,
  fetchCompanyBasic: PropTypes.func
}

const mapStateToProps = ({ files, companies }) => ({
  loading: files.loading,
  company: companies.company
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchCompanyBasic
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StudentPage)
