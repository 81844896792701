/* eslint-disable */
import React from "react";
import renderHTML from "react-render-html";

import MuiCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Button, Grid, Typography } from "@material-ui/core";

import AlternativeList from "../../list/AlternativeList";

function VisualizarQuestao({
  questao,
  correctImageSize,
  checkAlternativa,
  salvarQuestao,
  disableEdicao = false,
}) {
  const operacoesAlternativa = {
    SUBIR: -1,
    DESCER: 1,
  };

  const getPosition = (order) => order.charCodeAt() - "A".charCodeAt();

  const moverAlternativa = (q, order, operation) => {
    // Calcula a antiga e a nova posição no array
    const position = getPosition(order);
    const newPosition = position + operation;

    // Verifica se está tudo ok com a nova posição
    const lastIndex = q.Alternatives.length - 1;
    if (newPosition > lastIndex || newPosition < 0) {
      return;
    }

    // Troca as alternativas de lugar
    const auxAlternativa = { ...q.Alternatives[position] };

    q.Alternatives[position].Description =
      q.Alternatives[newPosition].Description;
    q.Alternatives[position].Correct = q.Alternatives[newPosition].Correct;

    q.Alternatives[newPosition].Description = auxAlternativa.Description;
    q.Alternatives[newPosition].Correct = auxAlternativa.Correct;

    salvarQuestao(q);
  };

  const handleUpAlternative = (q, order) => {
    // A operação aqui é SUBIR uma posição
    moverAlternativa(q, order, operacoesAlternativa.SUBIR);
  };
  const handleDownAlternative = (q, order) => {
    // A operação aqui é DESCER uma posição
    moverAlternativa(q, order, operacoesAlternativa.DESCER);
  };

  const emptyAlternative = (position) => ({
    Order: String.fromCharCode(65 + position),
    Correct: false,
    Description: "",
  });

  const reorderAlternatives = (alternatives, startPosition, alternativesLength) => {
    for (let i = startPosition; i < alternativesLength; i++) {
      alternatives[i].Order = String.fromCharCode(65 + i);
    }
    return alternatives;
  };

  const handleAdd = (q, order, isDissertative = false) => {
    if (isDissertative) {
      q.Type = 'Multiple Choice'
      q.Alternatives = [emptyAlternative(0)]
    } else {
      const position = getPosition(order);
      const lastIndex = q.Alternatives.length - 1;
      if (position > lastIndex) return;

      const newPosition = position + 1;
      const empAlternative = emptyAlternative(newPosition);

      q.Alternatives.splice(newPosition, 0, empAlternative);
      q.Alternatives = reorderAlternatives(q.Alternatives, newPosition + 1, q.Alternatives.length);
    }
    salvarQuestao(q);
  };

  const handleDelete = (q, order) => {
    const position = getPosition(order);
    const lastIndex = q.Alternatives.length - 1;
    if (position > lastIndex) return;

    q.Alternatives.splice(position, 1);
    q.Alternatives = reorderAlternatives(q.Alternatives, position, q.Alternatives.length);

    salvarQuestao(q);
  };

  return (
    <Grid>
    <Grid container>
      <Typography style={{ wordBreak: 'break-word' }}>
        {renderHTML(correctImageSize(questao.Statement))}
      </Typography>
    </Grid>
    <Grid container>
      <Grid item>
        {questao.Alternatives.map((alternative) => (
          <Grid container>
            <Grid item>
              {!disableEdicao && <AlternativeList
                onUp={() => handleUpAlternative(questao, alternative.Order)}
                onDown={() =>
                  handleDownAlternative(questao, alternative.Order)
                }
                onAdd={() => handleAdd(questao, alternative.Order)}
                onDelete={() => handleDelete(questao, alternative.Order)}
              />}
            </Grid>
            <Grid container>
              <FormControlLabel
                control={
                  <MuiCheckbox
                    color="primary"
                    disabled={disableEdicao}
                    checked={alternative.Correct}
                    onChange={checkAlternativa(questao, alternative)}
                  />
                }
                label={`${alternative.Order})`}
              />
              <Typography style={{ wordBreak: 'break-word' }}>
                {renderHTML(correctImageSize(alternative.Description))}
              </Typography>
            </Grid>
          </Grid>
        ))}
        {
          questao.Alternatives.length === 0 && questao.Type === 'Dissertative' ? (
            <Grid item>
              {!disableEdicao && <Button onClick={() => handleAdd(questao, "", true)}>Adicionar Alternativa</Button>}
            </Grid>
          ) : null
        }
      </Grid>
    </Grid>
  </Grid>
);
}

export default VisualizarQuestao;
