import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Empty as AntdEmpty, Input, Pagination as AntdPagination } from 'antd'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  width: 100%;
`

export const Col = styled.div`
  flex: ${props => props.size || 1};
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.center && 'text-align: center;'}
  ${props => props.left && 'text-align: left;'}
  ${props => props.right && 'text-align: right;'}
  margin-top: 10px;
  white-space: nowrap;
`

export const Header = styled.p`
  display: inline-block;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  white-space: normal;
`

export const Empty = styled(AntdEmpty)`
  margin: 2rem auto;
`

export const Pagination = styled(AntdPagination)`
  margin-top: 2rem;
  text-align: center;
`

export const Search = styled(Input.Search)`
  input { border-radius: 9999px; }
  ${props => props.type === 'desktop' && css`
    margin-left: 10%;
    max-width: 65%;
    @media only screen and (max-width: 600px) { display: none; }
  `}
  ${props => props.type === 'mobile' && css`
    margin-bottom: .5rem;
    @media only screen and (min-width: 601px) { display: none; }
  `}
`
