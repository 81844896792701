/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import ValidatedSharedInput from './ValidatedSharedInput';
import { FormInput } from './styles/Input.styles';

const PhoneInput = ({
  label,
  field,
  form,
  HelpComponent,
  style
}) => {
  const formatPhoneNumber = (value) => {
    const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
  
    let formattedValue = '';
  
    if (numericValue.length === 10) {
      formattedValue = numericValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else {
      formattedValue = numericValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }
  
    return formattedValue;
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const formattedValue = formatPhoneNumber(value);
    form.setFieldValue(field.name, formattedValue);
  };

  const getError = () => {
    const { name, value } = field;
    const { touched, errors } = form;
    const showError = touched[name] && errors[name];

    if (showError) {
      return errors[name];
    }

    return undefined;
  };

  const error = getError();

  return (
    <ValidatedSharedInput
      touched={form.touched[field.name]}
      error={error}
      label={label}
      style={style}
      HelpComponent={HelpComponent}
    >
      <FormInput
        {...field}
        className="phone-input"
        prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="Telefone"
        autoComplete="off"
        value={field.value || ''}
        onChange={handleInputChange}
        onBlur={field.onBlur}
      />
    </ValidatedSharedInput>
  );
};

PhoneInput.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  HelpComponent: PropTypes.node
};

export default PhoneInput;
