import axiosInstance, { setToken } from '../utils/http2'
import { get } from 'lodash'
import { logout } from '../store/auth/actions'
import { TOKEN_EXPIRATION_TIME } from '../utils/constants'
import { message } from 'antd'

// TODO: Set the token on signup too
export const handleToken = store => next => action => {
  const isRehydrate = action.type === 'persist/REHYDRATE'
  let rehydrateToken = get(action, 'payload.access_token')
  if (rehydrateToken === undefined) { get(action, 'payload.data.token') }
  if (rehydrateToken === undefined) { get(action, 'payload.auth.data.token') }

  let token = axiosInstance.defaults.headers.common.Authorization
  if (token === '' || token === undefined || token === null) {
    token = window.sessionStorage.getItem('token')
  }
  if (isRehydrate && rehydrateToken) {
    setToken(rehydrateToken, true)
    token = 'Bearer ' + rehydrateToken
  }

  const tokenSetTime = JSON.parse(window.sessionStorage.getItem('token:settime'))
  const now = Date.now()

  if (isRehydrate && token && now >= tokenSetTime + TOKEN_EXPIRATION_TIME) {
    // removeToken()
    message.info('Sua sessão expirou! Faça o login novamente')
    return next(logout())
  }

  next(action)
}
