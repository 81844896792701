import http, { updateToken } from '../../utils/http2'
import { mapHeadersToData } from '../../utils/httpHelpers'

const templatesEndpoint = '/templates'
const organizationsEndpoint = '/organizations'

export const fetchTemplates = (query = {}) => {
  updateToken()
  const { page, pageSize, search } = query
  const params = {
    'q[name_cont]': search,
    page: page || 1,
    per: pageSize || 1000
  }
  return http
    .get(templatesEndpoint, { params })
    .then(mapHeadersToData('x-total-count', 'x-page'))
}

export const uploadTemplate = (file, base64) => {
  updateToken()
  const req = {
    template: {
      name: file.name,
      file_name: file.name,
      file_encoded: base64
    }
  }
  return http
    .post(templatesEndpoint, req)
    .then(res => res.data).catch(error => error.data)
}

export const validateTemplate = id => {
  updateToken()
  const params = {
    raw: true
  }
  return http
    .get(`${templatesEndpoint}/${id}/validate`, { params })
    .then(res => res.data).catch(error => error.data)
}

export const createTemplate = async data => {
  updateToken()
  if (data.organization_id && data.organization_id.includes('new:')) {
    const name = data.organization_id.slice(4)

    const organization = await createOrganization({ name })
    data.organization_id = organization.id
  }

  return editTemplate({ ...data, id: data.templateId })
}

export const editTemplate = async data => {
  updateToken()
  if (data.organization_id && data.organization_id.includes('new:')) {
    const name = data.organization_id.slice(4)

    const organization = await createOrganization({ name })
    data.organization_id = organization.id
  }

  const req = {
    template: data
  }

  return http
    .put(`${templatesEndpoint}/${data.id}`, req)
    .then(res => res.data).catch(error => error.data)
}

export const deleteTemplate = id => {
  updateToken()
  return http
    .delete(`${templatesEndpoint}/${id}`)
    .then(res => res.data).catch(error => error.data)
}

export const fetchOrganizations = (query = {}) => {
  updateToken()
  const { page, pageSize, search } = query
  const params = {
    'q[name_cont]': search,
    page: page || 1,
    per: pageSize || 1000
  }
  return http
    .get(organizationsEndpoint, { params })
    .then(mapHeadersToData('x-total-count', 'x-page'))
}

export const createOrganization = data => {
  updateToken()
  const req = {
    organization: data
  }

  return http
    .post(organizationsEndpoint, req)
    .then(res => res.data).catch(error => error.data)
}

export const fetchTemplate = id => {
  updateToken()
  return http
    .get(`${templatesEndpoint}/${id}`)
    .then(res => res.data).catch(error => error.data)
}
