import React from 'react'
import PropTypes from 'prop-types'
import { StyledAnimation } from './styles/Animation.styles'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

const Animation = props => {
  const {
    type,
    timeout,
    children
  } = props

  return (
    <StyledAnimation {...props} >
      <ReactCSSTransitionGroup
        transitionName={type}
        transitionAppear
        transitionAppearTimeout={timeout}
        transitionEnterTimeout={timeout}
        transitionLeaveTimeout={timeout}
      >
        {children}
      </ReactCSSTransitionGroup>
    </StyledAnimation>
  )
}

Animation.propTypes = {
  type: PropTypes.oneOf(['fade']),
  timing: PropTypes.oneOf(['ease', 'linear', 'ease-in', 'ease-out', 'ease-in-out']),
  timeout: PropTypes.number,
  children: PropTypes.node
}

Animation.defaultProps = {
  type: 'fade',
  timing: 'ease-out',
  timeout: 700
}

export default Animation
