/* eslint-disable */
import React from "react";

import { Dialog, Grid, makeStyles, Typography } from "@material-ui/core";
import YoutubeEmbed from "../youtube/YoutubeEmbed";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "90%",
  },
  grid: {
    width: "100%",
    margin: 0,
  },
  title: {
    fontWeight: 600,
  }
}));

const HelpModal = ({ open, handleClose }) => {
  const classes = useStyles();

  const VideoComponent = ({ title, embedId }) => (
    <Grid item container justifyContent="center" alignItems="center" direction="column">
      <Typography className={classes.title} variant="h6" color="primary">
        {title}
      </Typography>
      <YoutubeEmbed embedId={embedId} />
    </Grid>
  )

  return (
    <Dialog fullWidth open={open} onClose={handleClose} maxWidth={"md"}>
      <Grid className={classes.grid} container direction="column" spacing={2}>
        <Grid item container justifyContent="center">
          <Typography variant="h4">Ajuda</Typography>
        </Grid>
        <VideoComponent title="UPLOAD" embedId="gAWm0Mpadts" />
        <VideoComponent title="MONTAR PROVA" embedId="kGLgauGlfCg" />
        <VideoComponent title="LISTAR => EDITAR" embedId="tzmCju15Vrc" />
        <VideoComponent title="LISTAR => DOWNLOAD" embedId="UyzM7tsXwgs" />
        <VideoComponent title="LISTAR => GERAR LINK (PROVA ONLINE)" embedId="AvEFk54159g" />
        <VideoComponent title="LISTAR => DIAGRAMAR" embedId="zLyYKTQgRk0" />
        <VideoComponent title="RESULTADOS" embedId="Gte__SKSL2U" />
      </Grid>
    </Dialog>
  );
};

export default HelpModal;
