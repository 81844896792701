import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { download } from '../../utils/fileHelpers'
import { deleteFile, fetchFiles } from '../../store/files/actions'

import ActionList from '../list/ActionList'
import ContainerCard from '../card/ContainerCard'
import OutputModal from '../modal/OutputModal'
import { List, Spin, Tooltip } from 'antd'
import { Row, Col, Header, Empty, Pagination, Search } from './styles/Table.styles'

const FilesTable = ({ deleteFile, fetchFiles, files, hideWarnings, loading }) => {
  const [outputId, setOutputId] = useState()
  const [search, setSearch] = useState('')

  useEffect(() => {
    fetchFiles()
  }, [])

  const renderSearch = type => (
    <Search
      type={type}
      onChange={e => setSearch(e.target.value)}
      onSearch={search => fetchFiles({ page: Number(files.page), search })}
      placeholder='Buscar'
      value={search}
    />
  )

  const header = (
    <Row>
      <Col size={4}><Header>nome</Header>{renderSearch('desktop')}</Col>
      <Col center size={2}><Header>data</Header></Col>
      <Col center><Header>ações</Header></Col>
    </Row>
  )

  const renderName = name => (
    <Tooltip
      title={name}
      placement='topLeft'
    >
      {name}
    </Tooltip>
  )

  const renderDate = date => {
    const localeDate = new Date(date).toLocaleDateString()
    return (
      <Tooltip
        title={localeDate}
        placement='topLeft'
      >
        {localeDate}
      </Tooltip>
    )
  }

  const renderActions = item => item && (
    <ActionList
      // onView={() => setOutputId(item.id)}
      onDownload={() => download(item.files_url, item.name)}
      onDelete={() => deleteFile(item.id)}
    />
  )

  const renderItem = item => (
    <List.Item>
      <Row>
        <Col size={4}>{renderName(item.name)}</Col>
        <Col center size={2}>{renderDate(item.created_at)}</Col>
        <Col center>{renderActions(item)}</Col>
      </Row>
    </List.Item>
  )

  const pagination = files.total > 10 && (
    <Pagination
      current={Number(files.page)}
      total={Number(files.total)}
      onChange={page => fetchFiles({ page, search })}
    />
  )

  const empty = (
    <Empty
      description='Nenhum arquivo encontrado'
      image={Empty.PRESENTED_IMAGE_DEFAULT}
    />
  )

  const outputModal = (
    <OutputModal
      output={files.find(file => file.id === outputId)}
      visible={!!outputId}
      onCancel={() => setOutputId(null)}
    />
  )

  return (
    <Spin spinning={loading}>
      {renderSearch('mobile')}
      {header}
      <ContainerCard
        padding={0}
        shadowYOffset={5}
      >
        <List
          dataSource={files}
          locale={{ emptyText: empty }}
          renderItem={renderItem}
        />
      </ContainerCard>
      {pagination}
      {outputModal}
    </Spin>
  )
}

FilesTable.propTypes = {
  deleteFile: PropTypes.func.isRequired,
  fetchFiles: PropTypes.func.isRequired,
  files: PropTypes.array,
  hideWarnings: PropTypes.bool,
  loading: PropTypes.bool
}

const mapStateToProps = ({ files, outputs }) => ({
  files: files.items,
  hideWarnings: outputs.hideWarnings,
  loading: files.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    deleteFile,
    fetchFiles
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FilesTable)
