import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import history from "../../routes/history";

import { Col, Row } from "../../components/table/styles/Table.styles";
import { Title } from "../../components/form/styles/Form.styles";
import { Button } from "../../components-refactor/button/Button";
import { TextField } from "../../components-refactor/textfield/TextField";

import { Page } from "../styles/Page.styles";

import { createClasses } from "../../store/auth/actions";

const useStyles = makeStyles((theme) => ({
  text: {
    width: "90vw",
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(96),
    },
  },
  button: {
    margin: theme.spacing(2),
  },
}));

const NewUserPage = (props) => {
  const classes = useStyles();
  const [name, setName] = useState("");

  const handleName = (evt) => {
    const newValue = evt.target.value;
    setName(newValue);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    props.createClasses({
      Name: name,
    });
    history.push("/users");
  };

  return (
    <Page>
      <Row center>
        <Col center size={5}>
          <Title>Nova Turma</Title>
        </Col>
      </Row>
      <br />
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <TextField
            className={classes.text}
            name="Name"
            label="Nome"
            defaultValue={name}
            onChange={handleName}
          />
          <Button type="submit" label="Criar Turma" />
        </Grid>
      </form>
    </Page>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createClasses,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(NewUserPage);
