import React from "react";

import {
    MenuItem,
    Select,
    InputLabel,
    FormControl,
  } from "@material-ui/core";

const MonthSelect = ({ month, handleMonth, style }) => {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  return (
    <FormControl fullWidth className={style}>
      <InputLabel>Selecione o Mês</InputLabel>
      <Select fullWidth value={month} onChange={handleMonth}>
        {months.map((d) => (
          <MenuItem value={d}>{d}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MonthSelect;
