export const FETCH_FILES = 'files:FETCH_FILES'
export const FETCH_FILES_SUCCESS = 'files:FETCH_FILES_SUCCESS'
export const FETCH_FILES_FAILURE = 'files:FETCH_FILES_FAILURE'

export const DOWNLOAD_FILE = 'files:DOWNLOAD_FILE'
export const DOWNLOAD_FILE_SUCCESS = 'files:DOWNLOAD_FILE_SUCCESS'
export const DOWNLOAD_FILE_FAILURE = 'files:DOWNLOAD_FILE_FAILURE'

export const DELETE_FILE = 'files:DELETE_FILE'
export const DELETE_FILE_SUCCESS = 'files:DELETE_FILE_SUCCESS'
export const DELETE_FILE_FAILURE = 'files:DELETE_FILE_FAILURE'
