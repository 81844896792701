/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Pagination, Spin } from 'antd'
import QuestionHeader from '../../question/QuestionHeader'
import QuestionResultCard from '../../question/QuestionResultCard'

import { deleteQuestion, setQuestions, setExamId, deleteTagExam, addTagExam } from '../../../store/exams/actions'
import { deleteTag, addTag } from '../../../store/questions/actions'

import { QuestionList } from './styles'
import WarningModal from '../../modal/WarningModal'
import { Label } from '../../modal/styles/Modal.styles'
// import similarStatement from '../../../pages/SelectQuestionsPage.js'

function QuestionResultBlock ({ 
  exams = [], 
  editable = false, 
  sufflable = false, 
  onShuffle,
  isSelectable = false, 
  totalSelected, 
  view = false, 
  onlyModal = false,
  editView = false, 
  createView = true, 
  loadQuestions = false, 
  tags = [], 
  selectedQuestions = [], 
  examId = undefined,
  onDelete, 
  updateSelected, 
  updateCorrectAnswer, 
  updateType, 
  isSuperAdmin = false, 
  removeModal, 
  exam, 
  justView = false, 
  addTagExam, 
  deleteTag, 
  addTag, 
  deleteTagExam, 
  similarStatement}) {
  const [questions, setQuestions] = useState([])
  const [pagination, setPagination] = useState({})
  const [filterByList, setFilterByList] = useState(true)
  const [displayQuestions, setDisplayQuestions] = useState([])
  const [selected, setSelected] = useState([])
  const [totalChecked, setTotalChecked] = useState(false)
  const [load, setLoad] = useState(true)
  const [forceDescheck, setForceDescheck] = useState(undefined)
  const [inactiveQuestions, setInactiveQuestions] = useState([])
  const [isVisible, setIsVisible] = useState(editView)
  const [infoModal, setInfoModal] = useState(false)

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    let fromUpload = query.get('fromUpload')
    if (fromUpload === 'true') {
      setInfoModal(true)
    }
    if (view === true) {
      if (exams.length > 0) {
        organizeQuestions(exams)
      } else if (exams.Questions !== undefined && exams.Questions.length > 0) {
        let obj = exams
        exams = []
        exams.push(obj)
        organizeQuestions(exams)
      }
    } else {
      if (exams.length > 0) {
        organizeQuestions(exams)
      }
    }
  }, [exams])

  useEffect(() => {
    if (editView) {
      organizeQuestions(exams)
      handleCheckAllLoad()
    }
    setLoad(false)
  }, [])

  function handleChange (value) {
    value === 'list' ? setFilterByList(true) : setFilterByList(false)
    if (!editView) {
      value === 'list' ? setDisplayQuestions(questions) : setDisplayQuestions([displayQuestions[0]])
    } else {
      value === 'list' ? setQuestions(displayQuestions) : setQuestions([displayQuestions[0]])
    }
  }

  function handlePagination (page, pageSize) {
    setPagination({ ...pagination, current: page })
    console.log('questions', questions, displayQuestions)
    if (!editView) {
      setDisplayQuestions([questions[page - 1]])
    } else {
      setQuestions([displayQuestions[page - 1]])
    }
  }

  function extractQuestions(array) {
    let arrayResponse = [];
    if (array) {
      let arrayAux = [];
      let arrayTitleQuestion = [];
      const examQuestions = array.map(exam => {
        if (exam.Questions !== null && exam.Questions !== undefined) {
          return exam.Questions
            .filter(q => !arrayAux.includes(q.Id) && !arrayTitleQuestion.includes(q.Statement))
            .map(question => {
              arrayTitleQuestion.push(question.Statement);
              arrayAux.push(question.Id);
              console.log({ examId: exam.Id, question });
              return { examId: exam.Id, question };
            });
        } else {
          return {};
        }
      });
      
      const flatQuestions = [].concat(...examQuestions);
      flatQuestions.sort((a, b) => a.question.Number - b.question.Number);
      return flatQuestions;
    }
  
    return arrayResponse;
  }

  function organizeQuestions (array) {
    let newQuestions = extractQuestions(array)
    setDisplayQuestions(newQuestions)
    setQuestions(newQuestions)
    setPagination({ current: 1, total: newQuestions.length, pageSize: 1 })
  }

  function handleCheckbox (checked, question) {
    setForceDescheck(undefined)
    if (checked) {
      const newInactiveQuestions = inactiveQuestions.filter(sel => sel.Id !== question.Id)
      setInactiveQuestions(newInactiveQuestions)
      setSelected([...selected, question])
      updateSelected([...selected, question])
    } else {
      const newSelected = selected.filter(sel => sel.Id !== question.Id)

      // if (editView) { setSelected(newSelected) }
      // if (editView) {
      setSelected(newSelected)
      updateSelected(newSelected)
      // }
      setInactiveQuestions([...inactiveQuestions, question])
    }
  }

  function handleCheckAll (e) {
    setForceDescheck(undefined)
    // let extracted = extractQuestions(pickedExams)
    let newArray = []
    const allQuestions = displayQuestions.map(item => {
      return item.question
    })
    if (e !== undefined && e.target !== undefined) {
      setTotalChecked(e.target.checked)
      if (e.target.checked === true) {
        newArray = allQuestions
      } else {
        handleDesCheckAll()
      }
    } else {
      setTotalChecked(true)
      newArray = selected.filter(q => {
        const questionsIds = allQuestions.map(qu => qu.Id)
        return questionsIds.includes(q.Id)
      })
    }
    setSelected(newArray)
    updateSelected(newArray)
    setDisplayQuestions(displayQuestions)
    setQuestions(questions)
  }

  function handleDesCheckAll () {
    let newArray = []
    setTotalChecked(false)
    setSelected(newArray)
    updateSelected(newArray)
    setForceDescheck(true)
    // setForceDescheck(undefined)
  }

  function handleCheckAllLoad () {
    // let extracted = extractQuestions(pickedExams)
    const allQuestions = displayQuestions.map(item => {
      return item.question
    })

    setTotalChecked(true)
    setSelected(allQuestions)
    updateSelected(allQuestions)
  }

  function onRandom (number) {
    let elements = []
    let maxNumber = number > questions.length ? questions.length : number

    const array = questions.map(item => {
      return item.question
    })

    for (let i = 0; i < maxNumber; i++) {
      let elementChoosen = Math.floor(Math.random() * array.length)
      let picked = array[elementChoosen]
      array.splice(elementChoosen, 1)
      let exists = elements.find(e => e.Id === picked.Id)
      if (exists === null || exists === undefined) {
        elements.push(picked)
      }
    }

    setSelected(elements)
  }

  useEffect(() => {
    if (isSelectable) {
      totalSelected(selected)
    }
  }, [selected])

  function closeModal () {
    const allQuestions = questions.map(item => {
      return item.question
    })
    removeModal(allQuestions)
    updateSelected(allQuestions)
    setSelected(allQuestions)
    setIsVisible(false)
  }

  return (
    <>
      <Spin spinning={load}>
        {
          editView && infoModal && <WarningModal visible={isVisible} onCancel={closeModal} message={'Algumas imagens podem demorar um pouco a serem exibidas pelo fato de estarem sendo carregadas!'} />
        }
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Label>Acertos: {questions.filter(q => q.question.IsCorrect === true).length} de {questions.length}</Label>
        </div>
        <QuestionHeader onlyModal={onlyModal} total={questions.length} selecionadas={editView ? selectedQuestions.length : selected.length}
          editable={editable} onDesCheck={handleDesCheckAll} onChange={handleChange} onCheck={handleCheckAll}
          onRandom={onRandom} editView={editView} createView={createView} />
        {
          onlyModal === true && questions.length > 0 && questions.map((item, i) => {
            let numberQuestion = i + 1
            return (
              <QuestionList key={numberQuestion}>
                <QuestionResultCard totalChecked={totalChecked} tags={item.Tags ? item.Tags : []}onlyModal={onlyModal} examId={item.examId} editView={editView} createView={createView}
                  numberQuestion={numberQuestion} selected={selected} question={item.question} editable={editable} isSelectable={false} forceDescheck={forceDescheck}
                  onCheck={handleCheckbox} correctAnswer={updateCorrectAnswer} />
              </QuestionList>)
          })
        }
        {!filterByList && Object.entries(pagination).length !== 0 &&
        <Pagination
          current={Number(pagination.current)}
          total={Number(pagination.total)}
          pageSize={Number(pagination.pageSize)}
          onChange={handlePagination}
        />}
      </Spin>
    </>
  )
}

QuestionResultBlock.propTypes = {
  exams: PropTypes.any,
  exam: PropTypes.any,
  editable: PropTypes.bool,
  justView: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  sufflable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  onShuffle: PropTypes.func,
  onCheck: PropTypes.func,
  onDesCheck: PropTypes.func,
  setExamId: PropTypes.func,
  addTagExam: PropTypes.func,
  deleteTagExam: PropTypes.func,
  addTag: PropTypes.func,
  deleteTag: PropTypes.func,
  resolutions: PropTypes.array,
  tags: PropTypes.array,
  loading: PropTypes.bool,
  changeTypeInline: PropTypes.bool,
  onDelete: PropTypes.func,
  deleteQuestion: PropTypes.func,
  updateSelected: PropTypes.func,
  removeModal: PropTypes.func,
  updateType: PropTypes.func,
  similarStatement: PropTypes.array
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    deleteQuestion,
    setQuestions,
    setExamId,
    deleteTag,
    addTag,
    addTagExam,
    deleteTagExam
  }, dispatch)

export default connect(null, mapDispatchToProps)(QuestionResultBlock)
