import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import history from '../../routes/history'
import * as yup from 'yup'
import { updateUser } from '../../store/users/actions'
import { withFormik, Field } from 'formik'

import { Form, Spin } from 'antd'
import EmailInput from '../input/EmailInput'
import TextInput from '../input/TextInput'
import SubmitButton from '../button/SubmitButton'
import { GroupLabel, StyledRegisterForm, Container } from './styles/Form.styles'
import PasswordInput from '../input/PasswordInput'
import { StyledTitle } from '../../pages/styles/ListExamsPage.styles'
import { fetchPlanExpirationDate } from "../../store/users/actions";

const validationSchema = props => {
  return yup.object().shape({
    Name: yup.string().required(),
    Email: yup.string().email().required(),
    Password: yup.string().min(6),
    PasswordConfirmation: yup.string().min(6).equals(yup.ref('Password'), 'Senhas não conferem')
  })
}

const mapPropsToValues = ({ initialValues }) => {
  if (!initialValues || !Object.keys(initialValues).length) return initialValues
  return {
    ...initialValues
  }
}

const DadosForm = ({
  isValid,
  loading,
  user,
  validation,
  updateUser,
  values,
  planInfo,
  fetchPlanExpirationDate
}) => {

  useEffect(()=>{
    fetchPlanExpirationDate();
  },[]);

  return (
    <StyledRegisterForm>
      <Spin spinning={loading}>
        { planInfo &&
          <Container>
            <GroupLabel>Informações do Plano</GroupLabel>
              <StyledTitle>Situação: Trial</StyledTitle>
              <br/>
              <StyledTitle>Data de expiração: {planInfo}</StyledTitle>
          </Container>
        }
        <Container>
          <GroupLabel>Informações Básicas</GroupLabel>
          <StyledTitle>Nome:</StyledTitle>
          <Field
            name='Name'
            component={TextInput}
            icon='user'
          />
          <StyledTitle>Email:</StyledTitle>
          <Field
            name='Email'
            component={EmailInput}
          />
        </Container>
        <Container>
          <GroupLabel>Segurança</GroupLabel>
          <StyledTitle>Senha:</StyledTitle>
          <Field
            name='Password'
            component={PasswordInput}
          />
          <StyledTitle>Confirmação de Senha:</StyledTitle>
          <Field
            name='PasswordConfirmation'
            component={PasswordInput}
          />
        </Container>
        <Form.Item>
          <SubmitButton
            disabled={!isValid}
            loading={loading}
          >
            Salvar Dados
          </SubmitButton>
        </Form.Item>
      </Spin>
    </StyledRegisterForm >
  )
}

DadosForm.propTypes = {
  isValid: PropTypes.bool,
  loading: PropTypes.bool,
  validation: PropTypes.object,
  values: PropTypes.object,
  user: PropTypes.object,
  updateUser: PropTypes.func,
  fetchPlanExpirationDate: PropTypes.func.isRequired
}

const mapStateToProps = ({ users }) => ({
  user: users.infoUser,
  loading: users.loading,
  validation: users.validation,
  form: users.form,
  planInfo: users.planExpirationDate
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    updateUser,
    fetchPlanExpirationDate
  }, dispatch)

const handleSubmit = (values, formikBag) => {
  if (values.Profile === undefined || values.Profile === null) {
    values.Profile = values.Profife
  }
  formikBag.props.updateUser(values)
  history.push('/upload')
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit,
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues
  })
)

export default enhance(DadosForm)
