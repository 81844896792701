/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Tag } from 'antd'

import { Container, TagPanel, TagButton } from './styles'
import { StyledInput } from '../../modal/styles/Modal.styles'
import useWindowSize from '../../../utils/useWindowSize'

function TagBlock ({ initial, addTag, removeTag, closable = true, canAdd = true, questionTags = [], master = false, editView = false }) {
  const [tags, setTags] = useState([])
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const windowSize = useWindowSize()

  useEffect(() => {
    setTags(initial)
  }, [initial, questionTags, master, editView])

  function handleClose (removedTag) {
    removeTag(removedTag)
    if (!master) {
      let newTags = tags.filter(t => t !== removedTag)
      setTags(newTags)
    }
  }

  function showInput () {
    setInputVisible(true)
  }

  function handleInputChange (e) {
    setInputValue(e.target.value)
  }

  function handleInputConfirm () {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      let updTags = [...tags, inputValue]
      var unico = updTags.filter(function (elem, index, self) {
        return index === self.indexOf(elem)
      })
      unico = unico.sort()
      setTags(unico)
      addTag(inputValue)
    }
    setInputValue('')
    setInputVisible(false)
  }

  return (
    <>
      <Container>
        <TagPanel style={{ width: windowSize.width - 120, maxWidth: '928px' }}>
          {tags.map((tag, index) => (
            <Tag key={index} closable={closable} onClose={() => handleClose(tag)} className='ant-btn-primary ant-btn-round' color='#1890ff' >
              {tag}
            </Tag>
          ))}
          {closable && inputVisible && (
            <StyledInput
              type='text'
              size='small'
              style={{ width: 78 }}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          )}
          {closable && !inputVisible && <TagButton type='primary' shape='circle' onClick={showInput} color='#514FFF'>+</TagButton>}
        </TagPanel>
      </Container>
    </>
  )
}

export default TagBlock
