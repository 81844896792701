import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { AnimatedSwitch } from 'react-router-transition'
import reverse from 'lodash/reverse'

import { privateRoutes, studentRoutes, publicRoutes, notLoggedRoutes } from './paths'
import RouteWithTemplate from './RouteWithTemplate'
import LoginPage from '../pages/LoginPage'
import StudentLoginPage from '../pages/StudentLoginPage'
import NotFoundPage from '../pages/NotFoundPage'

const mapStateToProps = state => {
  return {
    isLogged: state.auth.isLogged,
    isStudent: state.auth.isStudent
  }
}

const Routes = ({ isLogged, isStudent }) => {
  const defaultPrivateRoute = privateRoutes.find(route => route.default)
  // const defaultStudentRoute = studentRoutes.find(route => route.default)

  const setRoute = route =>
    route.template ? (
      <RouteWithTemplate {...route} key={route.path} exact />
    ) : (
      <Route key={route.path} {...route} exact />
    )

  /**
   * This preserves the path for redirect to wished page after login
   * @param {*} route
   */
  const setPrivateRoute = route => {
    return setRoute({
      ...route,
      template: isLogged ? route.template : undefined,
      component: isLogged ? route.component : LoginPage
    })
  }

  const setStudentRoute = route => {
    return setRoute({
      ...route,
      template: isStudent ? route.template : undefined,
      component: isStudent ? route.component : StudentLoginPage
    })
  }

  const setRedirect = route => (
    <Redirect
      key={route.path}
      from={route.path}
      to={defaultPrivateRoute.path}
      exact
    />
  )

  // const setStudentRedirect = route => (
  //   <Redirect
  //     key={route.path}
  //     from={route.path}
  //     to={defaultStudentRoute.path}
  //     exact
  //   />
  // )

  const routesPrecedence = [
    privateRoutes.map(setPrivateRoute),
    notLoggedRoutes.map(isLogged ? setRedirect : setRoute),
    studentRoutes.map(setStudentRoute),
    publicRoutes.map(setRoute)
  ]

  const routes = isLogged
    ? routesPrecedence
    : reverse(routesPrecedence)

  return (
    <AnimatedSwitch
      atActive={{ opacity: 1 }}
      atEnter={{ opacity: 0.4 }}
      atLeave={{ opacity: 0.4 }}
      className='switch-wrapper'
      runOnMount
    >
      {routes}
      <Route component={NotFoundPage} />
    </AnimatedSwitch>
  )
}

Routes.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  isStudent: PropTypes.bool.isRequired
}

export default withRouter(connect(mapStateToProps)(Routes))
