import { get } from 'lodash'

const statusErrors = {
  100: 'Continuar',
  101: 'Mudando protocolos',
  102: 'Processamento',
  122: 'Pedido URI muito longo',
  201: 'Criado',
  202: 'Aceito',
  203: 'Não autorizado',
  204: 'Nenhum conteúdo',
  205: 'Reset',
  206: 'Conteúdo parcial',
  207: 'Status Multi',
  300: 'Múltipla escolha',
  301: 'Movido',
  302: 'Encontrado',
  303: 'Consulte Outros',
  304: 'Não modificado',
  305: 'Use Proxy',
  306: 'Proxy Switch',
  307: 'Redirecionamento temporário',
  308: 'Redirecionamento permanente',
  400: 'Requisição inválida',
  401: 'Não autorizado',
  402: 'Pagamento necessário',
  403: 'Proibido',
  404: 'Não encontrado',
  405: 'Método não permitido',
  406: 'Não Aceitável',
  407: 'Autenticação de proxy necessária',
  408: 'Tempo de requisição esgotou',
  409: 'Conflito geral',
  410: 'Se foi',
  411: 'Comprimento necessário',
  412: 'Pré-condição falhou',
  413: 'Entidade de solicitação muito grande',
  414: 'Pedido-URI muito longo',
  415: 'Tipo de mídia não suportado',
  416: 'Solicitação de Faixa Não Satisfatória',
  417: 'Falha na expectativa',
  418: 'Eu sou um bule de chá',
  422: 'Entidade improcessável',
  423: 'Fechado',
  424: 'Falha de Dependência',
  425: 'Coleção não ordenada',
  426: 'Upgrade Obrigatório',
  450: 'Bloqueado pelo Controle de Pais do Windows',
  499: 'Cliente fechou Pedido',
  500: 'Erro interno do servidor',
  501: 'Não implementado',
  502: 'Gateway Ruim',
  503: 'Serviço indisponível',
  504: 'Tempo de Gateway esgotado',
  505: 'Versão HTTP não suportada'
}

const messageErrors = {
  'Network Errors': 'Erro de rede',
  'email ou senha inválida.': 'Email e/ou Senha Inválida',
  'Antes de continuar, confirme a sua conta.': 'Antes de continuar, confirme a sua conta.'
}

const timeoutErrorStatuses = [408, 503, 504]

export const normalizeError = (error, genericMessage) => {
  console.log('the error', error)
  const errorMessage = error.response
    ? get(error, 'response.data.error')
    : error.message

  const status = error.response
    ? error.response.status
    : error.status
  //
  // const errorMessage = error.error
  //   ? error.error_description
  //   : genericMessage
  //
  // const status = error
  //   ? error.status
  //   : 400

  const message = messageErrors[errorMessage] || statusErrors[status] || genericMessage

  const isTimeout = timeoutErrorStatuses.includes(status)

  const isCoreError = status === 'failure'

  return { message, status, isTimeout, isCoreError }
}
