/* eslint-disable */
import React, { useEffect, useState, useMemo, useRef } from "react";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MuiButton from "@material-ui/core/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";

import katex from "katex";
window.katex = katex;
import "katex/dist/katex.css";

import ReactQuill from "react-quill";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import EditableMathExample from "./ReactMathQuill";

import ImageUploader from "quill-image-uploader";

const useStyles = makeStyles((theme) => ({
  altEditor: {
    maxWidth: "100%",
    overflowX: "auto",
    padding: "10px",
  },
  box: {
    maxWidth: "930px",
    "@media (max-width: 768px)": {
      maxWidth: "685px",
    },
    "@media (max-width: 425px)": {
      maxWidth: "360px",
    },
    "@media (max-width: 375px)": {
      maxWidth: "312px",
    },
    "@media (max-width: 320px)": {
      maxWidth: "260px",
    },
  },
}));

function EditarQuestao({
  questao,
  editing,
  setEditing,
  correctImageSize,
  salvarQuestao,
  sendImagesAPI,
}) {
  const [edicao, setEdicao] = useState(undefined);
  const [openMathEditor, setOpenMathEditor] = useState(false);

  const alternativesRefs = useRef([]);
  const cursorPositionRef = useRef(0);
  const latexRef = useRef("");
  const quillRef = useRef(null);
  const selectedQuillEditor = useRef(null);

  const classes = useStyles();

  useEffect(() => {
    if (questao) {
      if (questao.Id in editing) {
        setEdicao(editing[questao.Id]);
      } else {
        setEdicao(questao);
        editing[questao.Id] = questao;
        setEditing(editing);
      }
    }
  });

  const handleOpen = () => {
    setOpenMathEditor(true);
  };

  const handleClose = () => {
    setOpenMathEditor(false);
  };

  ReactQuill.Quill.register({ "modules/imageUploader": ImageUploader }, true);

  // Quando a página carrega, ele muda o tamanho da fonte
  if (document.getElementsByClassName("ql-editor")[0] !== undefined) {
    for (const element of document.getElementsByClassName("ql-editor")) {
      element.style.fontSize = "20px";
    }
  }

  const colors = [
    "#000000",
    "#e60000",
    "#ff9900",
    "#ffff00",
    "#008a00",
    "#0066cc",
    "#9933ff",
    "#ffffff",
    "#facccc",
    "#ffebcc",
    "#ffffcc",
    "#cce8cc",
    "#cce0f5",
    "#ebd6ff",
    "#bbbbbb",
    "#f06666",
    "#ffc266",
    "#ffff66",
    "#66b966",
    "#66a3e0",
    "#c285ff",
    "#888888",
    "#a10000",
    "#b26b00",
    "#b2b200",
    "#006100",
    "#0047b2",
    "#6b24b2",
    "#444444",
    "#5c0000",
    "#663d00",
    "#666600",
    "#003700",
    "#002966",
    "#3d1466",
    "#514FFF",
  ];
  const formats = [
    "bold",
    "italic",
    "underline",
    "script",
    "color",
    "background",
    "image",
    "link",
    "video",
    "formula",
  ];
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ script: "sub" }, { script: "super" }],
    [{ color: colors }],
    [{ background: colors }],
    ["image"],
    ["link"],
    ["video"],
    ["formula"],
  ];

  const formula = (ref) => {
    if (ref) {
      selectedQuillEditor.current = ref;
      cursorPositionRef.current = ref.getEditor().getSelection().index;
      handleOpen();
    }
  };
  const moduleBuilder = (ref) => ({
    toolbar: {
      container: toolbarOptions,
      handlers: {
        formula: () => {
          formula(alternativesRefs.current[ref]);
        },
      },
    },
    imageUploader: {
      upload: async (file) => {
        return sendImagesAPI(file);
      },
    },
  });

  const alternativesModules = (idx) => {
    if (edicao) {
      const altModules = [
        moduleBuilder(idx),
        ...edicao.Alternatives.map(() => moduleBuilder(idx)),
      ];

      return altModules[idx];
    }
  };
  const statementModules = {
    toolbar: {
      container: toolbarOptions,
      handlers: {
        formula: () => {
          formula(quillRef.current);
        },
      },
    },
    imageUploader: {
      upload: async (file) => {
        return sendImagesAPI(file);
      },
    },
  };

  const cleanText = (text) =>
    text
      .replaceAll(
        '<span  style="color: #000000;font-size: 21px;font-family: Arial;">',
        ""
      )
      .replaceAll(
        '<span style="color: #000000;font-size: 21px;font-family: Arial;">',
        ""
      )
      .replaceAll(
        '<span style="color: #000000;font-size: 21px;font-family: Arial;"></span>',
        ""
      );

  const handleLatexChange = (newLatex) => {
    latexRef.current = newLatex;
  };

  const handleInsertFormula = () => {
    if (selectedQuillEditor.current) {
      selectedQuillEditor.current.editor.insertEmbed(
        cursorPositionRef.current,
        "formula",
        latexRef.current
      );
      selectedQuillEditor.current
        .getEditor()
        .setSelection(cursorPositionRef.current + 1, 0);
      setOpenMathEditor(false);
    }
  };

  const renderEditStatement = useMemo(
    () =>
      edicao ? (
        <ReactQuill
          id={edicao.Id}
          ref={quillRef}
          style={{ maxWidth: "100%", padding: "10px" }}
          theme="snow"
          modules={statementModules}
          formats={formats}
          value={correctImageSize(cleanText(edicao.Statement))}
        />
      ) : null,
    [edicao]
  );

  const renderEditAlternatives = useMemo(
    () =>
      edicao
        ? edicao.Alternatives.map((alternativa, index) => (
            <Box className={classes.box}>
              <ReactQuill
                id={edicao.Id + "-" + alternativa.Order}
                theme="snow"
                modules={alternativesModules(index)}
                formats={formats}
                value={correctImageSize(cleanText(alternativa.Description))}
                ref={(el) => (alternativesRefs.current[index] = el)}
                className={classes.altEditor}
              />
            </Box>
          ))
        : null,
    [edicao]
  );

  function saveAlternatives(question) {
    if (question.Alternatives && question.Alternatives.length) {
      question.Alternatives.map((item) => {
        const alternativa = document
          .getElementById(question.Id + "-" + item.Order)
          .getElementsByClassName("ql-editor")[0];
        const updatedAlternativa =
          '<span style="color: #000000;font-size: 21px;font-family: Arial;">' +
          alternativa.innerHTML +
          "</span>";
        item.Description = updatedAlternativa;
        return null;
      });
    }
    return question;
  }

  function saveQuestionStatement(q) {
    q = saveAlternatives(q);
    const div1 = document
      .getElementById(q.Id)
      .getElementsByClassName("ql-editor")[0];
    const updatedContents =
      '<span style="color: #000000;font-size: 21px;font-family: Arial;">' +
      div1.innerHTML +
      "</span>";
    q.Statement = updatedContents;
    return q;
  }

  const handleSalvar = (q) => () => {
    q = saveQuestionStatement(q);
    salvarQuestao(q);
  };

  return (
    <main>
      {edicao && (
        <Grid>
          <Grid container justifyContent="center">
            <MuiButton
              variant="contained"
              color="primary"
              onClick={handleSalvar(edicao)}
            >
              Salvar
            </MuiButton>
          </Grid>
          <Grid container>
            {renderEditStatement}
            <Modal open={openMathEditor} onClose={handleClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "70%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12}>
                    <EditableMathExample
                      onLatexChange={(newLatex) => handleLatexChange(newLatex)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleInsertFormula}
                    >
                      Inserir
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </Grid>
          <br />
          <br />
          <Grid container>
            <Grid item>
              {edicao.Type !== "Dissertative" && (
                <Grid>
                  {renderEditAlternatives}
                  <br />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </main>
  );
}

export default EditarQuestao;
