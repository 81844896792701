import axios from 'axios'
// import { normalize } from './jsonApiHelpers'
import { store } from '../store/configureStore'
import { logout } from '../store/auth/actions'
import { getHash } from './hashHelpers'
// import { get } from 'lodash'

const { SERVER_ENV } = process.env

const apiHosts = {
  'production': 'https://api.simulie.com',
  'staging': 'https://api.simulie-user-content.com',
  'demo': 'https://api.simulie-user-content.com',
  'development': 'https://api.simulie-user-content.com',
  'localhost': 'http://localhost:61765'
}

const apiHost = SERVER_ENV ? apiHosts[SERVER_ENV] : apiHosts['staging']

let instance = axios.create({
  baseURL: `${apiHost}`,
  headers: { 'Accept-Language': `pt-BR` }
})

const responseSuccess = res => {
  if (res.data !== null) {
    const token = res.data.access_token
    // console.log('token', token)
    if (token) res.data.token = token
    return res
  } else {
    return res
  }
}

const responseError = err => {
  const { baseURL, url } = err.config

  const isUnauthorized = err.response !== undefined && err.response.status === 401
  const isLogin = url === `${baseURL}/login`

  isUnauthorized && !isLogin && store.dispatch(logout())
  return Promise.reject(err)
}

instance.interceptors.response.use(responseSuccess, responseError)

export const setRenderToken = () => {
  instance.defaults.headers.common['X-RENDER-TOKEN'] = getHash()
}

export const removeRenderToken = () => {
  delete instance.defaults.headers.common['X-RENDER-TOKEN']
}

export const setToken = (token, isRehydrate) => {
  // console.log('setToken', token)
  window.sessionStorage.setItem('token', token)
  instance.defaults.headers.common.Authorization = 'Bearer ' + token
  !isRehydrate && window.sessionStorage.setItem('token:settime', Date.now())
}

export const updateToken = () => {
  let token = window.sessionStorage.getItem('token')
  instance = axios.create({
    baseURL: `${apiHost}`
  })
  instance.interceptors.response.use(responseSuccess, responseError)
  instance.defaults.headers.common.Authorization = 'Bearer ' + token
  setRenderToken()
}

export const removeToken = () => {
  window.sessionStorage.removeItem('persist:root')
  window.sessionStorage.removeItem('token:settime')
  // sessionStorage.removeItem('token')
  delete instance.defaults.headers.common.Authorization
}

export default instance
