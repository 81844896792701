/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from "../../routes/history";
import { fetchUsers, infoUser } from "../../store/users/actions";
import { deleteUser } from "../../store/users/services";
import { activateUser, rejectUser } from "../../store/auth/services";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import ActionList from "../../components/list/ActionList";
import ContainerCard from "../../components/card/ContainerCard";
import { makeStyles } from "@material-ui/core/styles";

import { List, Spin, Tooltip } from "antd";
import { StyledButton } from "../../components/modal/styles/Modal.styles";
import {
  Row,
  Col,
  Header,
  Empty,
  Pagination,
  Search,
  HeaderRight,
  StyledListItem,
  StyledTitle,
} from "../styles/ListExamsPage.styles";

import { Button } from "../../components-refactor/button/Button";

import { Page } from "../styles/Page.styles";
import { Content } from "../styles/DetailsPage.styles";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  buttonBar: {
    padding: theme.spacing(2)
  },
}));

function ListUserPage({ fetchUsers, infoUser, users, total, loading, user }) {
  let [search, setSearch] = useState("");
  let [userInfo, setUserInfo] = useState("");
  let [current, setCurrent] = useState(1);
  let [loadingPage, setLoadingPage] = useState(loading);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isHeaderOpen = Boolean(anchorEl);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileHeaderOpen = Boolean(mobileMoreAnchorEl);
  let [openInformationDialog, setOpenInformationDialog] = useState(false);

  const handleMobileHeaderClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleQuestionHeaderClose = () => {
    setAnchorEl(null);
    handleMobileHeaderClose();
  };

  useEffect(() => {
    infoUser();
    fetchUsers("", current);
  }, [current]);

  useEffect(() => {
    setTimeout(function() {
      console.log("users", users);
    });
    setLoadingPage(false);
  }, [users]);

  function handleEditAction(companyId) {
    history.push(`/users/${companyId}/edit`);
  }

  function handleNewAction() {
    history.push("/users/new");
  }

  function handleNewStudentAction() {
    history.push("/users/new");
  }

  function handleNewClass() {
    history.push("/users/new/class");
  }

  function handleEditClass() {
    history.push("/users/edit/class");
  }

  async function handleDeleteAction(userId) {
    setLoadingPage(true);
    await deleteUser(userId);
    window.location.reload();
  }

  async function handleActiveAction(userId) {
    setLoadingPage(true);
    await activateUser(userId);
    window.location.reload();
  }

  async function handleRejectAction(userId) {
    setLoadingPage(true);
    await rejectUser(userId);
    setTimeout(function() {
      window.location.reload();
    });
  }

  const handleCloseInformationDialog = () => {
    setOpenInformationDialog(false);
  };

  async function handleInformationsAction(user) {
    setUserInfo(user);
    setOpenInformationDialog(true);
  }

  const renderSearch = (type) => (
    <Search
      type={type}
      onChange={(e) => setSearch(e.target.value)}
      onSearch={(search) => fetchUsers(search, 1)}
      placeholder="Buscar"
      value={search}
    />
  );

  const novoAluno = (name) => (
    <HeaderRight>
      <StyledButton
        type="primary"
        shape="round"
        onClick={handleNewStudentAction}
      >
        {name}
      </StyledButton>
    </HeaderRight>
  );

  const header = (
    <Row
      className={classes.sectionDesktop}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isHeaderOpen}
      onClose={handleQuestionHeaderClose}
    >
      <Col size={4}>
        <Header>NOME</Header>
      </Col>
      <Col size={4}>
        <Header>EMAIL</Header>
      </Col>
      <Col size={4}>
        <Header>PERFIL</Header>
      </Col>
      <Col size={4}>
        <Header>ESCOLA</Header>
      </Col>
      <Col center>
        <Header>AÇÕES</Header>
      </Col>
    </Row>
  );

  const mobileHeader = (
    <Row
      className={classes.sectionMobile}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileHeaderOpen}
      onClose={handleMobileHeaderClose}
      mode="horizontal"
    >
      <Col size={5}>
        <Header>NOME</Header>
      </Col>
      <Col size={3}>
        <Header>AÇÕES</Header>
      </Col>
    </Row>
  );

  const renderName = (name) => (
    <Tooltip title={name} placement="topLeft">
      {name}
    </Tooltip>
  );

  const renderActions = (item, type) => {
    if (type === "mobile") {
      if (item.Id !== user.Id && user.Role !== "Teacher") {
        return (
          <ActionList
            item={item}
            onInformation={() => handleInformationsAction(item)}
            onEdit={() => handleEditAction(item.Id)}
            onDelete={() => handleDeleteAction(item.Id)}
            onActive={() => handleActiveAction(item.Id)}
            onReject={() => handleRejectAction(item.Id)}
          />
        );
      } else {
        return (
          <ActionList
            onInformation={() => handleInformationsAction(item)}
            onEdit={() => handleEditAction(item.Id)}
          />
        );
      }
    } else {
      if (item.Id !== user.Id && user.Role !== "Teacher") {
        return (
          <ActionList
            item={item}
            onEdit={() => handleEditAction(item.Id)}
            onDelete={() => handleDeleteAction(item.Id)}
            onActive={() => handleActiveAction(item.Id)}
            onReject={() => handleRejectAction(item.Id)}
          />
        );
      } else {
        return <ActionList onEdit={() => handleEditAction(item.Id)} />;
      }
    }
  };

  const renderItem = (item) => (
    <StyledListItem>
      <Row
        className={classes.sectionDesktop}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isHeaderOpen}
        onClose={handleQuestionHeaderClose}
      >
        <Col size={4}>{renderName(item.Name)}</Col>
        <Col size={4}>{renderName(item.Email)}</Col>
        <Col size={4}>{renderName(item.Profile)}</Col>
        <Col size={4}>{renderName(item.CompanyName)}</Col>
        <Col center>{renderActions(item, "desktop")}</Col>
      </Row>
      <Row
        className={classes.sectionMobile}
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileHeaderOpen}
        onClose={handleMobileHeaderClose}
        mode="horizontal"
      >
        <Col size={5}>{renderName(item.Name)}</Col>
        <Col size={3}>{renderActions(item, "mobile")}</Col>
      </Row>
    </StyledListItem>
  );

  const onChangePagination = (e) => {
    fetchUsers(search, e);
    setCurrent(e);
  };

  const isRole = (tmpUser, role) => tmpUser != null && tmpUser.Role === role;

  const isNotRole = (tmpUser, role) => tmpUser != null && tmpUser.Role !== role;

  const pagination = (
    <Pagination current={current} total={total} onChange={onChangePagination} />
  );

  const empty = (
    <Empty
      description="Nenhum usuário encontrado"
      image={Empty.PRESENTED_IMAGE_DEFAULT}
    />
  );

  return (
    <Page>
      <Content style={{ width: "100%", maxWidth: "1200px" }}>
        <Dialog
          open={openInformationDialog}
          onClose={handleCloseInformationDialog}
          aria-labelledby="information-dialog-title"
          aria-describedby="information-dialog-description"
        >
          <DialogTitle id="information-dialog-title">
            <StyledTitle>
              <strong>{"Informações do Usuário"}</strong>
            </StyledTitle>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="information-dialog-description">
              <Row>
                <div className={"col-sm-12"}>
                  <StyledTitle>
                    <label>
                      <strong>Nome:</strong> {renderName(userInfo.Name)}
                    </label>
                  </StyledTitle>
                </div>
              </Row>
              <br />
              <Row>
                <div className={"col-sm-12"}>
                  <StyledTitle>
                    <label>
                      <strong>Perfil:</strong> {renderName(userInfo.Profile)}
                    </label>
                  </StyledTitle>
                </div>
              </Row>
              <br />
              <Row>
                <div className={"col-sm-12"}>
                  <StyledTitle>
                    <label>
                      <strong>Escola:</strong>
                      {renderName(userInfo.CompanyName)}
                    </label>
                  </StyledTitle>
                </div>
              </Row>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <StyledButton
              onClick={handleCloseInformationDialog}
              color="primary"
            >
              Fechar
            </StyledButton>
          </DialogActions>
        </Dialog>
        <Row>
          <Grid container>
            {renderSearch("mobile")}
            <Grid className={classes.buttonBar} container spacing={1} direction="row" justifyContent="space-between">
              <Button
                active={isNotRole(user, "SuperUser") && isNotRole(user, "Super") && isNotRole(user, "Teacher")}
                label="Novo Usuário"
                handleClick={handleNewStudentAction}
              />
              <Button
                active={isRole(user, "SuperUser") || isRole(user, "Super") && isNotRole(user, "Teacher")}
                label="Novo Usuário"
                handleClick={handleNewAction}
              />
              <Button
                active={isNotRole(user, "SuperUser") && isNotRole(user, "Super") && isNotRole(user, "Teacher")}
                label="Nova Turma"
                handleClick={handleNewClass}
              />
              <Button
                active={isNotRole(user, "SuperUser") && isNotRole(user, "Super") && isNotRole(user, "Teacher")}
                label="Editar Turma"
                handleClick={handleEditClass}
              />
            </Grid>
          </Grid>
        </Row>
        <br />
        <Spin spinning={loadingPage}>
          {header}
          {mobileHeader}

          <ContainerCard padding={0} shadowYOffset={5}>
            <List
              dataSource={users}
              locale={{ emptyText: empty }}
              renderItem={renderItem}
            />
          </ContainerCard>
        </Spin>

        {pagination}
      </Content>
    </Page>
  );
}

ListUserPage.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  infoUser: PropTypes.func.isRequired,
  users: PropTypes.array,
  user: PropTypes.object,
  loading: PropTypes.bool,
  total: PropTypes.number,
};

const mapStateToProps = ({ users }) => ({
  user: users.infoUser,
  users: users.usersList.users,
  total: users.usersList.total,
  loading: users.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      infoUser,
      fetchUsers,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListUserPage);
