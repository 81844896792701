import styled from 'styled-components'
import { Menu } from 'antd'

export const StyledMenuItem = styled(Menu.Item)`
  &.ant-menu-item{
    border: 3px solid #514fff !important;
    border-radius: 25px;  
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px !important;
    :hover{
      color: #000 !important;
      border-color: #FBA661 !important;
    }
    :active{
      background-color: #eee;
    }
  }

  a{
    color: #000;
    :hover{
      color: #000;
      text-decoration: none;
    }
  }
  &.ant-menu-item-selected {
    background-color: #fff !important;
    
  }
`
export const StyledMenuSubMenu = styled(Menu.SubMenu)`
  .ant-menu-item, .ant-menu-submenu-title {
    color: #000;
    :hover {
      color: #000 !important;
    }
  }
 &.ant-menu-submenu:hover {
    color: #514fff !important;
    border-bottom: none;
  }
  &.ant-menu-submenu:active {
    color: #514fff !important;
    border-bottom: none;
  }
  &.ant-menu-submenu-active{
    border-bottom: none !important;
  }
  &.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    
    color: #514fff !important;
  }
  .ant-menu-item-divider {
    height: 1px;
    overflow: hidden;
    line-height: 0;
    background-color: #ff0000 !important;
  }
`
export const StyledSubMenu = styled(Menu.SubMenu)`
  &.ant-menu-item{
    display: flex;
    justify-content: center;
  }
  .ant-menu-submenu-title{
    border: 3px solid #514fff !important;
    border-radius: 25px;  
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px !important;
    :hover{
      color: #000 !important;
      border-color: #FBA661 !important;
    }
    :active{
      background-color: #eee;
    }
  }
`
