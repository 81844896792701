export const LOGIN = 'auth:LOGIN'
export const LOGIN_SUCCESS = 'auth:LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'auth:LOGIN_FAILURE'

export const STUDENT_LOGIN = 'auth:STUDENT_LOGIN'
export const STUDENT_LOGIN_SUCCESS = 'auth:STUDENT_LOGIN_SUCCESS'
export const STUDENT_LOGIN_FAILURE = 'auth:STUDENT_LOGIN_FAILURE'
export const SIGNUP = 'auth:SIGNUP'
export const SIGNUP_SUCCESS = 'auth:SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'auth:SIGNUP_FAILURE'

export const LOGOUT = 'auth:LOGOUT'
export const LOGOUT_SUCCESS = 'auth:LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'auth:LOGOUT_FAILURE'

export const REGISTER = 'auth:REGISTER'
export const REGISTER_SUCCESS = 'auth:REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'auth:REGISTER_FAILURE'

export const REGISTER_STUDENT = 'auth:REGISTER_STUDENT'
export const REGISTER_STUDENT_SUCCESS = 'auth:REGISTER_STUDENT_SUCCESS'
export const REGISTER_STUDENT_FAILURE = 'auth:REGISTER_STUDENT_FAILURE'

export const ACTIVATE_USER = 'auth:ACTIVATE_USER'
export const ACTIVATE_USER_SUCCESS = 'auth:ACTIVATE_USER_SUCCESS'
export const ACTIVATE_USER_FAILURE = 'auth:ACTIVATE_USER_FAILURE'

export const RESEND_EMAIL = 'auth:RESEND_EMAIL'
export const RESEND_EMAIL_SUCCESS = 'auth:RESEND_EMAIL_SUCCESS'
export const RESEND_EMAIL_FAILURE = 'auth:RESEND_EMAIL_FAILURE'

export const RECOVER_PASSWORD = 'auth:RECOVER_PASSWORD'
export const RECOVER_PASSWORD_SUCCESS = 'auth:RECOVER_PASSWORD_SUCCESS'
export const RECOVER_PASSWORD_FAILURE = 'auth:RECOVER_PASSWORD_FAILURE'

export const CHANGE_PASSWORD = 'auth:CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'auth:CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'auth:CHANGE_PASSWORD_FAILURE'

export const CHECKOUT_PAYMENT = 'auth:CHECKOUT_PAYMENT'
export const CHECK_PAYMENT_SUCCESS = 'auth:CHECK_PAYMENT_SUCCESS'
export const CHECK_PAYMENT_FAILURE = 'auth:CHECK_PAYMENT_FAILURE'

export const INACTIVATE_USER = 'auth:INACTIVATE_USER'
export const INACTIVATE_USER_SUCCESS = 'auth:INACTIVATE_USER'
export const INACTIVATE_USER_FAILURE = 'auth:INACTIVATE_USER'

export const REJECT_USER = 'auth:REJECT_USER'
export const REJECT_USER_SUCCESS = 'auth:REJECT_USER_SUCCESS'
export const REJECT_USER_FAILURE = 'auth:REJECT_USER_FAILURE'

export const INVITE_USERS = 'auth:INVITE_USERS'
export const INVITE_USERS_SUCCESS = 'auth:INVITE_USERS_SUCCESS'
export const INVITE_USERS_FAILURE = 'auth:INVITE_USERS_FAILURE'

export const INVITE_STUDENT = 'auth:INVITE_STUDENT'
export const INVITE_STUDENT_SUCCESS = 'auth:INVITE_STUDENT_SUCCESS'
export const INVITE_STUDENT_FAILURE = 'auth:INVITE_STUDENT_FAILURE'

export const GET_ROLES = 'auth:GET_ROLES'
export const GET_ROLES_FAILURE = 'auth:GET_ROLES_FAILURE'
export const GET_ROLES_SUCCESS = 'auth:GET_ROLES_SUCCESS'

export const CREATE_CLASSES = 'auth:CREATE_CLASSES'
export const CREATE_CLASSES_FAILURE = 'auth:CREATE_CLASSES_FAILURE'
export const CREATE_CLASSES_SUCCESS = 'auth:CREATE_CLASSES_SUCCESS'

export const GET_CLASSES = 'auth:GET_CLASSES'
export const GET_CLASSES_FAILURE = 'auth:GET_CLASSES_FAILURE'
export const GET_CLASSES_SUCCESS = 'auth:GET_CLASSES_SUCCESS'

export const LOGGED_USER = 'auth:LOGGED_USER'

export const MANAGE_LOGO = 'auth:MANAGE_LOGO'
export const MANAGE_LOGO_SUCCESS = 'auth:MANAGE_LOGO_SUCCESS'
export const MANAGE_LOGO_FAILURE = 'auth:MANAGE_LOGO_FAILURE'

