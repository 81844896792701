import { getHash } from './hashHelpers'
import { message } from 'antd'
import MD5 from 'crypto-js/md5'
import latin1 from 'crypto-js/enc-latin1'
import hex from 'crypto-js/enc-hex'

export const createUniquefileName = file => {
  const timestamp = Date.now()
  const fileName = `${timestamp}_${getHash()}_${file.name}`
    .trim()
    .substr(0, 64)
    .replace(/[^\x20-\x7E]/g, '')
  return new File(
    [file],
    fileName,
    { type: file.type })
}

export const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const download = (payload, fileName) => {
  if (!payload) return message.error('Falha ao baixar arquivo')
  const download = url => {
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.append(link)
    link.click()
    link.remove()
  }

  Array.isArray(payload) ? payload.forEach(download) : download(payload)
}

export const hashFile = file => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = e => {
        const result = e.target.result
        const parsed = latin1.parse(result)
        const md5 = MD5(parsed)
        const hash = md5.toString(hex)
        resolve(hash)
      }
      reader.readAsBinaryString(file)
    } catch (err) {
      reject(err)
    }
  })
}
