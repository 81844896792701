import React, { Fragment } from 'react'
import './config/ReactotronConfig'
import { hot } from 'react-hot-loader'
import { Router } from 'react-router-dom'
import history from './routes/history'
import Routes from './routes/Routes'
// import ErrorWatcher from './components/watcher/ErrorWatcher'
import './App.css'

const App = () => (
  <Fragment>
    <Router history={history}>
      <Routes />
    </Router>
    {/* <ErrorWatcher /> */}
  </Fragment>
)

export default hot(module)(App)
