import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import { normalizeError } from '../../utils/errorHelpers'
import { message } from 'antd'

import * as actions from './actions'
import * as types from './constants'
import * as outputTypes from '../outputs/constants'
import * as services from './services'

export function * fetchFiles ({ payload }) {
  try {
    const files = yield call(services.fetchFiles, payload)
    yield put(actions.fetchFilesSuccess(files))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao obter arquivos')
    message.error(errorInfo.message)
    yield put(actions.fetchFilesFailure(errorInfo))
  }
}

export function * downloadFile ({ payload }) {
  try {
    const file = yield call(services.downloadFile, payload)
    message.success('Arquivo baixado com sucesso')
    yield put(actions.downloadFileSuccess(file))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao baixar arquivo')
    message.error(errorInfo.message)
    yield put(actions.downloadFileFailure(errorInfo))
  }
}

export function * deleteFile ({ payload: id }) {
  try {
    yield call(services.deleteFile, id)
    message.success('Arquivo deletado com sucesso')
    yield put(actions.deleteFileSuccess())
    const query = yield select(store => store.files.query)
    yield put(actions.fetchFiles(query))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao deletar arquivo')
    message.error(errorInfo.message)
    yield put(actions.deleteFileFailure(errorInfo))
  }
}

export function * updateFile ({ payload }) {
  try {
    const files = yield select(store => store.files.items)
    const index = files.findIndex(file => file.id === payload.id)
    const newFiles = [...files]
    newFiles[index] = payload
    yield put(actions.fetchFilesSuccess(newFiles))
  } catch (error) {
    console.error(error)
  }
}

// Watchers
export function * watchFetchFiles () {
  yield takeLatest(types.FETCH_FILES, fetchFiles)
}

export function * watchDownloadFile () {
  yield takeLatest(types.DOWNLOAD_FILE, downloadFile)
}

export function * watchDeleteFile () {
  yield takeLatest(types.DELETE_FILE, deleteFile)
}

export function * watchFetchOutputSuccess () {
  yield takeLatest(outputTypes.FETCH_OUTPUT_SUCCESS, updateFile)
}

export default function * () {
  yield all([
    watchFetchFiles(),
    watchDownloadFile(),
    watchDeleteFile(),
    watchFetchOutputSuccess()
  ])
}
