import { all } from 'redux-saga/effects'
// import { all, call, select, takeLatest, put } from 'redux-saga/effects'
// import { normalizeError } from '../../utils/errorHelpers'
// import { fileToBase64 } from '../../utils/fileHelpers'
// import { getLocalFile, setLocalFile } from '../../utils/idb'
// import { message } from 'antd'
// import history from '../../routes/history'
//
// import * as actions from './actions'
// import * as examActions from '../exams/actions'
// import * as types from './constants'
// import * as services from './services'

// faz upload
// fazer upload na nova api
export function * uploadDocument ({ payload: file }) {
  // try {
  //   const preloaded = yield getLocalFile(file, 'document').catch(console.error)
  //   if (!preloaded) {
  //     const base64 = yield fileToBase64(file)
  //     var res = yield call(services.uploadDocument, file, base64)
  //   }
  //
  //   yield put(examActions.uploadExam(file))
  //
  //   const id = (res && res.id) || preloaded.id
  //   yield put(actions.uploadDocumentSuccess(id))
  //   yield put(actions.validateDocument({ id, file }))
  // } catch (error) {
  //   const errorInfo = normalizeError(error, 'Falha ao processar documento')
  //   message.error(errorInfo.message)
  //   yield put(actions.uploadDocumentFailure(errorInfo))
  // }
}

export function * validateDocument ({ payload: { id, file } }) {
  // try {
  //   const validations = yield select(state => state.documents.validations)
  //   const cachedValidation = validations.find(validation => validation.document.id === id)
  //   if (cachedValidation) return yield put(actions.validateDocumentSuccess(validations))
  //
  //   const validation = yield call(services.validateDocument, id)
  //   validation.document = { id, file }
  //   yield put(actions.validateDocumentSuccess([...validations, validation]))
  //
  //   if (validation.status === 'OK') {
  //     // TODO: Reactivate route page based on warnings after revision module has been implemented
  //     validation.questionSummary.warnings.length > 0
  //       ? history.push('/revision')
  //       : history.push('/exam')
  //     // history.push('/output')
  //     console.log('file', file)
  //     id && file && setLocalFile(file, 'document', id).catch(console.error)
  //   } else {
  //     message.error('Documento inválido')
  //   }
  // } catch (error) {
  //   const errorInfo = normalizeError(error, 'Falha ao processar documento')
  //   message.error(errorInfo.message)
  //   yield put(actions.validateDocumentFailure(errorInfo))
  // }
}

// Watchers
export function * watchUploadDocument () {
  // yield takeLatest(types.UPLOAD_DOCUMENT, uploadDocument)
}

export function * watchValidateDocument () {
  // yield takeLatest(types.VALIDATE_DOCUMENT, validateDocument)
}

export default function * () {
  yield all([
    watchUploadDocument(),
    watchValidateDocument()
  ])
}
