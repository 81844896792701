import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";

const styles = StyleSheet.create({
  tableContainer: {
    marginTop: "16px",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

const ItemsTable = ({ header, data }) => (
  <View style={styles.tableContainer}>
    <TableHeader header={header} />
    <TableRow items={data.items} />
    {/*<TableFooter items={data.items} />*/}
  </View>
);

export default ItemsTable;
