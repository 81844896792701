import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { uploadExam, clearExamModule } from "../../store/exams/actions";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

const ImageUploadButton = ({
  buttonText,
  file,
  onInputChange,
  disabled = false,
}) => {
  const classes = useStyles();

  return (
    <div>
      <input
        className={classes.input}
        accept="image/*"
        type="file"
        id="icon-button-file"
        onChange={onInputChange}
        disabled={disabled}
      />
      <label htmlFor="icon-button-file">
        <Button color="primary" component="span" disabled={disabled}>
          {buttonText}
        </Button>
      </label>
      {file && " " + file.name}
    </div>
  );
};

ImageUploadButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  // uploadDocument: PropTypes.func.isRequired,
  uploadExam: PropTypes.func.isRequired,
  clearExamModule: PropTypes.func.isRequired,
  // j: PropTypes.func.isRequired
};

const mapStateToProps = ({ exams }) => ({
  loading: exams.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadExam,
      clearExamModule,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadButton);
