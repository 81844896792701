import http from "../../utils/http2";

const readGradeEndpoint = "/grade/read";
const updateGradeEndpoint = "/grade/update";

export const readGrade = (request = {}) => {
  const { turma } = request;

  let url = "?turma=";
  if (turma)
    url += `${turma}`;

  return http
    .get(`${readGradeEndpoint}${url}`)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const updateGrade = (request = {}) => {
  const { Turma, Turno, Serie } = request;

  return http
    .post(updateGradeEndpoint, { Turma, Turno, Serie })
    .then((res) => res.data)
    .catch((error) => error.data);
};
