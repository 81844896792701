import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from "antd";

import {
  fetchOnlineExam,
  ansQuestion,
  startOnlineExam,
  endOnlineExam,
  getVideosLoaded,
} from "../store/exams/actions";

import history from "../routes/history";
import { Col, Page, Row, Title } from "./styles/Page.styles";
import { QuestionList } from "../components/question/QuestionBlock/styles";
import QuestionOnlineCard from "../components/question/QuestionOnlineCard";
import useWindowSize from "../utils/useWindowSize";
import { StyledButton } from "../components/modal/styles/Modal.styles";
import {
  StyledTitle,
  Pagination,
  PaginationDiv,
  PaginationItem,
  StyledInputNumber,
} from "./styles/ListExamsPage.styles";

function ProfessorExamPage({
  fetchOnlineExam,
  ansQuestion,
  startOnlineExam,
  endOnlineExam,
  getVideosLoaded,
  exams,
  startedExam,
  endExam,
  showResult,
  error,
  alternativeChecked,
  loadingQuest,
  loadingVideos,
}) {
  const [numberQuestion, setNumberQuestion] = useState(0);
  const [provisoryNumberQuestion, setProvisoryNumberQuestion] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [timer, setTimer] = useState(false);
  const [time, setTime] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [start, setStart] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const [end, setEnd] = useState(false);
  const [endTime, setEndTime] = useState(false);
  const [finalized, setFinalized] = useState(false);
  const [examId, setExamId] = useState(undefined);
  const [studentId, setStudentId] = useState(undefined);
  const [loadedExams, setLoadedExams] = useState(false);
  const [fetchExams, setFetchExams] = useState(false);
  const [showResultButton, setShowResultButton] = useState(false);
  const windowSize = useWindowSize();

  const startTest = async () => {
    await startOnlineExam(examId, studentId);
    // console.log(start)
    // setStart(true)
  };

  const goBack = async () => {
    history.go(-1);
  };

  const handleQuestions = async (q) => {
    console.log(q);
    setAnswers(answers.map((obj) => q.find((o) => o.Id === obj.Id) || obj));
    if (q && q.length > 0) {
      var ans = {
        StudentId: studentId,
        ExamId: examId,
        QuestionId: q[0].Id,
        AlternativeOrder: q[0].Alternatives.find((el) => el.Correct === true)
          .Order,
      };

      await ansQuestion(ans);
    }
  };

  const getResult = () => {
    history.push(
      "/result-exams/individual?examId=" + examId + "&studentId=" + studentId
    );
  };

  useEffect(() => {
    if (!seconds) return;

    const intervalId = setInterval(() => {
      setSeconds(seconds - 1);
      setTime(secondsToTime(seconds - 1));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const tmpExamId = query.get("examId");
    const tmpStudentId = query.get("studentId");
    setExamId(tmpExamId);
    setStudentId(tmpStudentId);
  }, []);

  useEffect(() => {
    setFinalized(endExam);

    if (
      error.message === "Você já terminou a prova" ||
      error.message === "Gabarito ainda não disponível"
    ) {
      setShowResultButton(true);
    }

    if (showResult && !redirected) {
      history.push("result-exam/individual?examId=" + examId);
      setRedirected(true);
    }
  });

  useEffect(() => {
    if (
      answers.length === 0 &&
      exams &&
      exams.selected &&
      exams.selected.Questions &&
      exams.selected.QuestionsAnswer &&
      !loadedExams
    ) {
      setLoadedExams(true);
      var questionsList = [];
      for (let i = 0; i < exams.selected.QuestionsAnswer.length; i++) {
        // eslint-disable-next-line
        exams.selected.Questions.map((e) => {
          if (exams.selected.QuestionsAnswer[i].QuestionId === e.Id) {
            // eslint-disable-next-line
            e.Alternatives.map((e) => {
              if (e.Order === exams.selected.QuestionsAnswer[i].Answer) {
                e.Correct = true;
              }
            });
            questionsList.push(e);
          }
        });
      }
      // setAnswers(exams.selected.Questions)
      setAnswers(questionsList);
    }
    setStart(startedExam);
    if (startedExam && !fetchExams) {
      setFetchExams(true);
      fetchOnlineExam(examId, studentId);
    }
    if (exams.selected.TimeLeft && timer === false) {
      let times = exams.selected.TimeLeft.split(".")[0].split(":");
      setSeconds(
        parseInt(times[0]) * 60 * 60 +
          parseInt(times[1]) * 60 +
          parseInt(times[2])
      );
      setTimer(true);
    }
  });

  useEffect(() => {
    if (!(startedExam && !fetchExams) && loadingVideos && examId) {
      getVideosLoaded(examId);
    }
  }, [startedExam, fetchExams, loadingVideos, examId]);

  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisorForMinutes = secs % (60 * 60);
    let minutes = Math.floor(divisorForMinutes / 60);

    let divisorForSeconds = divisorForMinutes % 60;
    let seconds = Math.ceil(divisorForSeconds);

    if (hours === 0 && minutes === 0 && seconds === 0) {
      setEnd(true);
      setEndTime(true);
      endOnlineExam(examId, studentId);
    }

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };

  const handleFinalization = async () => {
    const ans = answers.map((obj) => {
      for (let i = 0; i < obj.Alternatives.length; i++) {
        if (obj.Alternatives[i].Correct === true) {
          return true;
        }
      }
      return false;
    });
    console.log(ans);
    if (ans.includes(false)) {
      alert("Preencha todas as questões!");
    } else {
      console.log(finalized);
      setEnd(true);
      setSeconds(undefined);
      endOnlineExam(examId, studentId);
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      goTo();
    }
  };

  const goTo = () => {
    provisoryNumberQuestion !== "-" &&
      provisoryNumberQuestion !== "" &&
      provisoryNumberQuestion > 0 &&
      provisoryNumberQuestion < answers.length &&
      setNumberQuestion(provisoryNumberQuestion);
  };

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return originalElement;
    } else if (type === "next") {
      return originalElement;
    } else {
      if (answers[originalElement.props.children - 1]) {
        for (
          let i = 0;
          i < answers[originalElement.props.children - 1].Alternatives.length;
          i++
        ) {
          if (
            answers[originalElement.props.children - 1].Alternatives[i]
              .Correct === true
          ) {
            return <PaginationItem>{originalElement}</PaginationItem>;
          }
        }
      }
    }
    return originalElement;
  };

  return (
    <Page style={{ display: "flex", alignItems: "center" }}>
      <Row>
        {exams.selected.Exam && exams.selected.Exam.LogoUrl && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={exams.selected.Exam.LogoUrl}
              alt="Logo"
              style={{ height: "64px" }}
            />{" "}
          </div>
        )}
      </Row>
      <Row>
        <Col>
          <Title>Prova Online</Title>
        </Col>
      </Row>
      {endTime ? (
        <div>O tempo de prova acabou. Suas respostas foram enviadas!</div>
      ) : finalized ? (
        <div
          style={{
            paddingTop: "24px",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
          }}
        >
          Prova Finalizada!
        </div>
      ) : loadingVideos ? (
        "Carregando... Atualize a página após alguns minutos."
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!start && exams && exams.selected && (
            <div>
              <StyledButton
                style={{ marginRight: "12px" }}
                onClick={() => goBack()}
              >
                Voltar
              </StyledButton>
              <StyledButton onClick={() => startTest()}>Iniciar</StyledButton>
            </div>
          )}
          {exams && start && answers[numberQuestion] && exams.resolutions ? (
            <div style={{ width: windowSize.width, maxWidth: "1200px" }}>
              {/* <div style={{ maxWidth: '80%' }}>
                  {exams.selected.Exam && exams.selected.Exam.Instructions && 'Instruções:' + exams.selected.Exam.Instructions}
                </div> */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {time && (
                  <div>
                    <StyledTitle>
                      Tempo Restante: {time.h}:{time.m}:{time.s}
                    </StyledTitle>
                  </div>
                )}
                <StyledButton onClick={() => handleFinalization()}>
                  Finalizar
                </StyledButton>
              </div>
              <br />
              <QuestionList key={numberQuestion}>
                <QuestionOnlineCard
                  onlyModal={false}
                  examId={answers[numberQuestion].Id}
                  numberQuestion={numberQuestion + 1}
                  correctAnswer={handleQuestions}
                  question={answers[numberQuestion]}
                  createView
                  isSelectable
                  checkAns={alternativeChecked}
                  loadingQuest={loadingQuest}
                />
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  size={6}
                >
                  <PaginationDiv>
                    <Pagination
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      current={numberQuestion + 1}
                      total={answers.length * 10}
                      itemRender={itemRender}
                      onChange={(e) => {
                        setProvisoryNumberQuestion(e - 1);
                        setNumberQuestion(e - 1);
                      }}
                    />
                    <div style={{ margin: "10px 0 0 10px" }}>
                      <StyledButton onClick={() => handleFinalization()}>
                        Finalizar
                      </StyledButton>
                    </div>
                  </PaginationDiv>
                  <div
                    style={{
                      paddingTop: "24px",
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "center",
                    }}
                  >
                    <StyledTitle>Página:</StyledTitle>
                    <StyledInputNumber
                      style={{ marginLeft: "8px" }}
                      onKeyDown={_handleKeyDown}
                      onChange={(e) => setProvisoryNumberQuestion(e - 1)}
                    />
                    <StyledButton style={{ marginLeft: "8px" }} onClick={goTo}>
                      Ir
                    </StyledButton>
                  </div>
                </Col>
              </QuestionList>
            </div>
          ) : start ? (
            fetchExams && !(error && error.message) ? (
              "Carregando..."
            ) : (
              "Prova não encontrada"
            )
          ) : (
            ""
          )}
        </div>
      )}
      {showResultButton || (end && finalized) ? (
        <div>
          <br />
          <Button onClick={getResult}>Gabarito da Prova</Button>
        </div>
      ) : (
        ""
      )}
    </Page>
  );
}

ProfessorExamPage.propTypes = {
  fetchOnlineExam: PropTypes.func,
  ansQuestion: PropTypes.func,
  startOnlineExam: PropTypes.func,
  endOnlineExam: PropTypes.func,
  exams: PropTypes.array,
  startedExam: PropTypes.bool,
  showResult: PropTypes.bool,
  endExam: PropTypes.bool,
  error: PropTypes.object,
  loadingQuest: PropTypes.bool,
  alternativeChecked: PropTypes.bool,
};

const mapStateToProps = ({ exams }) => ({
  exams: exams,
  startedExam: exams.startedExam,
  showResult: exams.showResult,
  endExam: exams.endExam,
  error: exams.error,
  loadingQuest: exams.loading,
  alternativeChecked: exams.alternativeChecked,
  loadingVideos: exams.loadingVideos,
  // loading: templates.loading
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchOnlineExam,
      ansQuestion,
      startOnlineExam,
      endOnlineExam,
      getVideosLoaded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfessorExamPage);
