// import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { setHideWarnings } from '../../store/outputs/actions'

// import { Checkbox } from 'antd'

const WarningsCheckbox = ({ hideWarnings, setHideWarnings }) => {
  return (
    ''
    // <Checkbox
    //   onChange={e => setHideWarnings(e.target.checked)}
    //   checked={hideWarnings}
    // >
    //   Ocultar alertas
    // </Checkbox>
  )
}

WarningsCheckbox.propTypes = {
  hideWarnings: true,
  setHideWarnings: PropTypes.func.isRequired
}

const mapStateToProps = ({ outputs }) => ({
  hideWarnings: outputs.hideWarnings
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setHideWarnings
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WarningsCheckbox)
