import React from "react";
import { Box } from "@material-ui/core";

const GoogleForms = ({ link }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "650px",
        maxHeight: "800px",
        mt: 5,
      }}
    >
      <iframe
        src={link}
        width={"100%"}
        height={"100%"}
        title="Feedback Form"
        style={{ border: "none" }}
      >
        Carregando…
      </iframe>
    </Box>
  );
};

export default GoogleForms;
