import React, { Fragment, useEffect } from 'react'

import history from '../routes/history'

const NotFoundPage = () => {
  useEffect(() => {
    alert('not found')
    history.push('/upload')
  }, [])

  return <Fragment />
}

export default NotFoundPage
