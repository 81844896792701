import { all, call, takeLatest, put } from "redux-saga/effects";
import { normalizeError } from "../../utils/errorHelpers";
import { message } from "antd";

import * as actions from "./actions";
import * as types from "./constants";
import * as services from "./services";

export function* readGrade({ payload }) {
  try {
    const result = yield call(services.readGrade, payload);
    yield put(actions.readGradeSuccess(result));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao ler turma");
    message.error(errorInfo.message);
    yield put(actions.readGradeFailure(errorInfo));
  }
}

export function* updateGrade({ payload }) {
  try {
    const result = yield call(services.updateGrade, payload);
    yield put(actions.updateGradeSuccess(result));
  } catch (error) {
    const errorInfo = normalizeError(error, "Falha ao ler turma");
    message.error(errorInfo.message);
    yield put(actions.updateGradeFailure(errorInfo));
  }
}

export function* watchReadGrade() {
  yield takeLatest(types.READ_GRADE, readGrade);
}

export function* watchUpdateGrade() {
  yield takeLatest(types.UPDATE_GRADE, updateGrade);
}

export default function*() {
  yield all([
    watchReadGrade(),
    watchUpdateGrade()
  ]);
}
