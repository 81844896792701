import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Dropzone, { useDropzone } from 'react-dropzone';

import { fetchUser } from '../../store/users/actions'
import { manageLogo } from '../../store/auth/actions';
import { sendImages as sendImagesAPI } from '../../store/exams/services'

import { Spin } from 'antd'
import { Col, Row } from '../../components/table/styles/Table.styles'
import { Container, GroupLabel, StyledRegisterForm, Title } from '../../components/form/styles/Form.styles'
import { Page } from '../styles/Page.styles'
import DadosForm from '../../components/form/DadosForm'
import { StyledTitle } from '../styles/ListExamsPage.styles';
import SubmitButton from '../../components/button/SubmitButton';
import { Button } from 'antd';
import { StyledSwitch } from '../../components/modal/styles/Modal.styles';

const dropzoneStyles = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '10px',
  cursor: 'pointer',
  margin: '10px'
};

const dropzoneActiveStyles = {
  ...dropzoneStyles,
  backgroundColor: '#f0f0f0',
};

const buttonStyle = {
  backgroundColor: '#514fff',
  borderColor: '#514fff',
  borderRadius: '25px',
  color: '#fff',
}

const MyUserPage = ({
  fetchUser,
  selected,
  loading,
  match,
  superLogoUrl,
  isLogoActive,
  secondaryLogoUrl,
  isSecondaryLogoActive,
  manageLogo,
  loadingLogo
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const [secondaryImageUrl, setSecondaryImageUrl] = useState("");
  const [savedUrl, setSavedUrl] = useState("");

  useEffect(() => {
    fetchUser(match.params.id);
  }, [match])

  const onDrop = useCallback(async imageFiles => {
    const imageUrlS3 = await sendImagesAPI(imageFiles[0]);
    setImageUrl(imageUrlS3);
  }, []);

  const onDropSecondaryLogo = useCallback(async imageFiles => {
    const imageUrlS3 = await sendImagesAPI(imageFiles[0]);
    setSecondaryImageUrl(imageUrlS3);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 1,
  });

  const handleSaveLogo = () => {
    const payload = {
      logoUrl: imageUrl,
      switchNow: false,
      isPrimaryLogo: true
    }
    manageLogo(payload);
    setSavedUrl(imageUrl);
    setImageUrl("");
  };

  const handleSaveSecondaryLogo = () => {
    const payload = {
      logoUrl: secondaryImageUrl,
      switchNow: false,
      isPrimaryLogo: false
    }
    manageLogo(payload);
    setSecondaryImageUrl("");
  };

  const handleSwitchLogo = () => {
    const payload = {
      logoUrl: "",
      switchNow: true,
      isPrimaryLogo: true
    }
    manageLogo(payload);
  };

  const handleSwitchSecondaryLogo = () => {
    const payload = {
      logoUrl: "",
      switchNow: true,
      isPrimaryLogo: false
    }
    manageLogo(payload);
  };

  return (
    <Page>
      <Row center>
        <Col center size={3}><Title>Atualizar Meus Dados</Title></Col>
      </Row>
      { (selected.Profile === "Super" ||
        selected.Profile === "Master") &&
        <>
          <StyledRegisterForm >
            <Container>
              <GroupLabel>Logo Principal Personalizada</GroupLabel>
              <StyledTitle>Adicionar logo à esquerda</StyledTitle>
              <div {...getRootProps()} style={isDragActive ? dropzoneActiveStyles : dropzoneStyles} >
                <input {...getInputProps()} />
                {isDragActive ?
                  <Title style={{ fontSize: '100%' }}>Soltar logo aqui</Title>
                  :
                  <Title style={{ fontSize: '100%' }}>Arraste e solte aqui, ou clique para carregar sua logo!</Title>
                }
              </div>
              { imageUrl !== "" &&
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Button style={buttonStyle} type='primary' shape='round' onClick={handleSaveLogo}>Salvar Logo</Button>
                  <img
                    src={imageUrl}
                    alt='Uploaded Image'
                    style={{ maxWidth: '100px', maxHeight: '100px', margin: '20px', }}
                  />
                </div>
              }
            </Container>
            <Spin spinning={loadingLogo} >
              { superLogoUrl !== "" &&
              <Container>
                <GroupLabel>Ativar/Desativar Logo Principal</GroupLabel>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <StyledSwitch
                    onChange={handleSwitchLogo}
                    checked={isLogoActive}
                  />
                  <img
                    src={superLogoUrl}
                    alt='Uploaded Image'
                    style={{ maxWidth: '100px', maxHeight: '100px', margin: '20px', }}
                  />
                </div>
              </Container>
              }
            </Spin>
          </StyledRegisterForm>

          <StyledRegisterForm >
            <Container>
              <GroupLabel>Logo Secundária Personalizada</GroupLabel>
              <StyledTitle>Adicionar logo à direita</StyledTitle>
              <Dropzone onDrop={acceptedFiles => onDropSecondaryLogo(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()} style={isDragActive ? dropzoneActiveStyles : dropzoneStyles} >
                    <input {...getInputProps()} />
                    {isDragActive ?
                      <Title style={{ fontSize: '100%' }}>Soltar logo aqui</Title>
                      :
                      <Title style={{ fontSize: '100%' }}>Arraste e solte aqui, ou clique para carregar sua logo!</Title>
                    }
                  </div>
                )}
              </Dropzone>
              { secondaryImageUrl !== "" &&
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Button style={buttonStyle} type='primary' shape='round' onClick={handleSaveSecondaryLogo}>Salvar Logo</Button>
                  <img
                    src={secondaryImageUrl}
                    alt='Uploaded Image'
                    style={{ maxWidth: '100px', maxHeight: '100px', margin: '20px', }}
                  />
                </div>
              }
            </Container>
            <Spin spinning={loadingLogo} >
              { secondaryLogoUrl !== "" &&
              <Container>
                <GroupLabel>Ativar/Desativar Logo Secundária</GroupLabel>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <StyledSwitch
                    onChange={handleSwitchSecondaryLogo}
                    checked={isSecondaryLogoActive}
                  />
                  <img
                    src={secondaryLogoUrl}
                    alt='Uploaded Image'
                    style={{ maxWidth: '100px', maxHeight: '100px', margin: '20px', }}
                  />
                </div>
              </Container>
              }
            </Spin>
          </StyledRegisterForm>
        </>
      }
      <Spin spinning={loading}>
        <DadosForm initialValues={selected}/>
      </Spin>
    </Page>
  )
}

MyUserPage.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  selected: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  superLogoUrl: PropTypes.string,
  isLogoActive: PropTypes.bool,
  secondaryLogoUrl: PropTypes.string,
  isSecondaryLogoActive: PropTypes.bool,
  manageLogo: PropTypes.func,
  loadingLogo: PropTypes.bool,
};

const mapStateToProps = ({ users, auth }) => ({
  selected: users.user,
  loading: users.loading,
  superLogoUrl: auth.loggedUser.LogoUrl,
  isLogoActive: auth.loggedUser.IsLogoActive,
  secondaryLogoUrl: auth.loggedUser.SecondaryLogoUrl,
  isSecondaryLogoActive: auth.loggedUser.IsSecondaryLogoActive,
  loadingLogo: auth.loading,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchUser,
    manageLogo,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyUserPage)
