/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withFormik, Field } from 'formik'
import { Form } from 'antd'
// import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { StyledLoginForm, Title, A } from './styles/Form.styles'
import SubmitButton from '../button/SubmitButton'
import EmailInput from '../input/EmailInput'
import PasswordInput from '../input/PasswordInput'
import { login } from '../../store/auth/actions'

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    login
  }, dispatch)

const handleSubmit = (values, formikBag) => {
  values.grant_type = 'password'
  formikBag.props.login(values)
}

const validationSchema = props => {
  return yup.object().shape({
    username: yup.string().email().required(),
    password: yup.string().required()
  })
}

const handleForgotPassword = delegate => e => {
  e.preventDefault()
  delegate()
}

const LoginForm = ({
  loading,
  isValid,
  onForgotPassword
}) => {
  return (
    <div>
      <StyledLoginForm>
        <div className={'col-sm-12'}>
          <Field
            name='username'
            style={{ width: '100%', marginTop: '15px' }}
            component={EmailInput}
          />
        </div>
        <div className={'col-sm-12'}>
          <Field
            name='password'
            component={PasswordInput}
          />
        </div>
        <div className={'col-sm-12'}>
          <Form.Item>
            <SubmitButton
              disabled={!isValid}
              loading={loading}
            >
            Entrar
            </SubmitButton>
            {/* <Link to='/register'>Registrar</Link> */}
            <A
              href='/'
              onClick={handleForgotPassword(onForgotPassword)}
              style={{ float: 'right' }}
            >
            Esqueci a senha
            </A>
          </Form.Item>
        </div>
      </StyledLoginForm>
      {/* <div className={'col-sm-12'} style={{ justifyContent: 'center', display: 'flex', marginTop: '16px' }}>
        <A
          href='/signup'
        >
          Cadastre-se
        </A>
      </div> */}
    </div>
  )
}

LoginForm.propTypes = {
  loading: PropTypes.bool,
  isValid: PropTypes.bool,
  onForgotPassword: PropTypes.func
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit,
    validationSchema
  })
)

export default enhance(LoginForm)
