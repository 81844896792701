import http2, { updateToken } from '../../utils/http2'

export const addTag = (questionId, tag) => {
  updateToken()
  return http2
    .put(`/question/${questionId}/tag/${tag}`)
    .then(res => res.data).catch(error => error.data)
}

export const listQuestions = (companyId, userId) => {
  updateToken()
  let url = `/question/count`
  if (companyId !== undefined && companyId !== '') {
    url += `?companyId=` + companyId
  }
  if (userId !== undefined && userId !== ''){
    url += `&userId=` + userId
  }
  url += `&status=active`
  return http2
    .get(url)
    .then(res => res.data).catch(error => error.data)
}

export const deleteTag = (questionId, tag) => {
  updateToken()
  return http2
    .delete(`/question/${questionId}/tag/${tag}`)
    .then(res => res.data).catch(error => error.data)
}

export const changeToDissertative = (questionId) => {
  updateToken()
  return http2
    .put(`/question/${questionId}/change-to-dissetative`)
    .then(res => res.data).catch(error => error.data)
}

export const getTags = (value) => {
  updateToken();
  return http2
    .get(`/question/tags-feature`, { params: { value } })
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const getDescriptionInTree = ({ questionId, treeId, treeIdHabilities }) => {
  updateToken();
  let queryUrl = `/question/descriptionInTree?questionId=${questionId}`;
  if(treeId) queryUrl += `&treeId=${treeId}`;
  if(treeIdHabilities) queryUrl += `&treeHabilityId=${treeIdHabilities}`;
  return http2
    .get(queryUrl)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const updateMatrixKnowledgeId = (data) => {
  updateToken();
  return http2
    .post('/question/updateKnowledgeInQuestion', data)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const updateMatrixHabilityId = (data) => {
  updateToken();
  return http2
    .post('/question/updateHabilityInQuestion', data)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const addComment = (examId, questionId, comment) => {
  updateToken();
  return http2
    .post('/question/add-comment', { examId, questionId, comment })
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const getComment = (examId, questionId) => {
  updateToken();
  return http2
    .get(`/question/get-comment?examId=${examId}&questionId=${questionId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error('Error fetching comment:', error);
      throw error;
    });
};

export const addSolution = (examId, questionId, solution) => {
  updateToken();
  return http2
    .post('/question/add-solution', { examId, questionId, solution })
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const getSolution = (examId, questionId) => {
  updateToken();
  return http2
    .get(`/question/get-solution?examId=${examId}&questionId=${questionId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error('Error fetching comment:', error);
      throw error;
    });
};