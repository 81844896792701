import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import history from '../routes/history'

import { fetchIndividualResultExam, fetchStudentResultExam, setExamId, searchExamTag, setCanEdit, clearExamModule } from '../store/exams/actions'
import { listQuestions } from '../store/questions/services'
import { infoUser } from '../store/users/actions'

import ActionList from '../components/list/ActionList'
import ContainerCard from '../components/card/ContainerCard'

import { StyledButton } from '../components/modal/styles/Modal.styles'
import { List, Tooltip } from 'antd'
import {
  HeaderBar,
  HeaderLeft,
  Row,
  Col,
  Header,
  Empty,
  Pagination,
  Search
} from './styles/ListExamsPage.styles'

import { Page } from './styles/Page.styles'
import { Content } from './styles/DetailsPage.styles'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))

function StudentResultsPage ({ fetchIndividualResultExam, fetchStudentResultExam, exams, total, loading, setExamId, setCanEdit, searchExamTag, clearExamModule, infoUser, user }) {
  const classes = useStyles()
  let [search, setSearch] = useState('')
  let [current, setCurrent] = useState(1)
  let [list, setList] = useState([])
  let [listTotal, setListTotal] = useState(undefined)

  useEffect(() => {
    infoUser()
    clearExamModule()
  }, [])

  useEffect(() => {
    if (user !== null && user !== undefined) {
      console.log('user.CompanyId', user.CompanyId)
      // Se for super admin, nesta tela, lista todas as provas
      // Se for master ou docente, lista as provas da escola
      fetchIndividualResultExam('', 1, undefined, undefined, 10)
    }
  }, [user])

  function handleViewAction (examId) {
    history.push('/student/result-exam/individual?examId=' + examId)
  }

  const searchExams = (search) => {
    setListTotal(undefined)
    setList([])
    if (user !== null && user !== undefined) {
      fetchIndividualResultExam(search, 1, undefined, undefined, 10)
      if (search === '') {
        setSearch('')
      }
    }
  }

  const renderSearch = type => (
    <>
      <Search
        type={type}
        onChange={e => setSearch(e.target.value)}
        onSearch={search => searchExams(search)}
        placeholder='Buscar'
        value={search}
      />
      <StyledButton onClick={() => searchExams('')} type='default' shape='round' className='tagButton' style={{ marginLeft: '5px' }}>Limpar Pesquisa</StyledButton>
    </>
  )

  const header = (
    <Row>
      <Col size={6}><Header>TÍTULO</Header></Col>
      <Col size={2}><Header>AÇÕES</Header></Col>
    </Row>
  )

  const renderName = name => (
    <Tooltip
      title={name}
      placement='topLeft'
    >
      {name}
    </Tooltip>
  )

  const renderActions = (item, user) => {
    if (item.IsSimulieDatabase === true && user.Role !== 'SuperUser') {
      return (<ActionList
        onView={() => handleViewAction(item.OnlineExamId)}
      />)
    } else {
      return (<ActionList
        onView={() => handleViewAction(item.OnlineExamId)}
      />)
    }
  }

  const renderItem = item => (
    <List.Item>
      {console.log(item)}
      <Row>
        <Col size={6}>{renderName(item.Title)}</Col>
        <Col size={2} >{renderActions(item, user)}</Col>
      </Row>
    </List.Item>
  )

  const onChangePagination = e => {
    fetchIndividualResultExam('', e, undefined, undefined, 10)
    setListTotal(undefined)
    setList([])
    setCurrent(e)
  }

  const pagination = (
    <Pagination
      current={current}
      total={listTotal === undefined ? total : listTotal}
      onChange={onChangePagination}
    />
  )

  const empty = (<Empty
    description='Nenhum arquivo encontrado!'
    image={Empty.PRESENTED_IMAGE_DEFAULT}
  />)

  const formatDate = (date) => {
    let d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = d.getFullYear()

    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }

    if (isNaN(year)) {
      return date
    }

    return [day, month, year].join('-')
  }

  const filterList = e => {
    if (e && e.length >= 0) {
      return e.map(element => {
        element.StartAt = formatDate(element.StartAt)
        element.StopAt = formatDate(element.StopAt)
        return element
      })
    }

    return e
  }

  return (
    <Page>
      <Content style={{ width: '100%', maxWidth: '1200px' }}>
        <br />
        <br />
        {header}
        <ContainerCard
          padding={0}
          shadowYOffset={5}
        >
          <List
            dataSource={(list.length > 0 && exams.length > 0) ? filterList(list) : filterList(exams)}
            locale={{ emptyText: empty }}
            renderItem={renderItem}
          />
        </ContainerCard>
        {pagination}
      </Content>
    </Page>
  )
}

StudentResultsPage.propTypes = {
  fetchIndividualResultExam: PropTypes.func.isRequired,
  fetchStudentResultExam: PropTypes.func.isRequired,
  user: PropTypes.object,
  exams: PropTypes.array,
  loading: PropTypes.bool,
  total: PropTypes.number,
  setExamId: PropTypes.func.isRequired,
  setCanEdit: PropTypes.func.isRequired,
  clearExamModule: PropTypes.func,
  searchExamTag: PropTypes.func.isRequired,
  infoUser: PropTypes.func.isRequired
}

const mapStateToProps = ({ exams, questions, users }) => ({
  user: users.infoUser,
  exams: exams.examsList !== undefined ? exams.examsList.exams : [],
  total: exams.examsList !== undefined ? exams.examsList.total : 0,
  loading: exams.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchIndividualResultExam,
    fetchStudentResultExam,
    infoUser,
    clearExamModule,
    setExamId,
    setCanEdit,
    searchExamTag
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StudentResultsPage)
