import {
  CLEAR_OUTPUT_MODULE,
  CLEAR_OUTPUT_ERROR,
  PROCESS_OUTPUT,
  PROCESS_OUTPUT_SUCCESS,
  PROCESS_OUTPUT_FAILURE,
  PROCESS_OUTPUT_CANCEL,
  SET_HIDE_WARNINGS,
  FETCH_OUTPUT,
  FETCH_OUTPUT_SUCCESS,
  FETCH_OUTPUT_FAILURE
} from './constants'

export const clearOutputModule = () => ({
  type: CLEAR_OUTPUT_MODULE
})

export const clearOutputError = () => ({
  type: CLEAR_OUTPUT_ERROR
})

export const processOutput = () => ({
  type: PROCESS_OUTPUT
})

export const processOutputSuccess = output => ({
  type: PROCESS_OUTPUT_SUCCESS,
  payload: output
})

export const processOutputFailure = error => ({
  type: PROCESS_OUTPUT_FAILURE,
  payload: error
})

export const processOutputCancel = () => ({
  type: PROCESS_OUTPUT_CANCEL
})

export const setHideWarnings = hideWarnings => ({
  type: SET_HIDE_WARNINGS,
  payload: hideWarnings
})

export const fetchOutput = id => ({
  type: FETCH_OUTPUT,
  payload: id
})

export const fetchOutputSuccess = output => ({
  type: FETCH_OUTPUT_SUCCESS,
  payload: output
})

export const fetchOutputFailure = error => ({
  type: FETCH_OUTPUT_FAILURE,
  payload: error
})
