import React from 'react'
import RegisterForm from '../components/form/RegisterForm'
import { Page } from './styles/Page.styles'

const RegisterPage = () => (
  <Page>
    <RegisterForm />
  </Page>
)

export default RegisterPage
