import createReducer from '../createReducer'
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  STUDENT_LOGIN,
  STUDENT_LOGIN_SUCCESS,
  STUDENT_LOGIN_FAILURE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAILURE,
  INACTIVATE_USER,
  INACTIVATE_USER_SUCCESS,
  INACTIVATE_USER_FAILURE,
  REJECT_USER,
  REJECT_USER_SUCCESS,
  REJECT_USER_FAILURE,
  INVITE_USERS,
  INVITE_USERS_SUCCESS,
  INVITE_USERS_FAILURE,
  RESEND_EMAIL,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAILURE,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHECKOUT_PAYMENT,
  CHECK_PAYMENT_SUCCESS,
  CHECK_PAYMENT_FAILURE,
  GET_ROLES,
  GET_ROLES_FAILURE,
  GET_ROLES_SUCCESS,
  CREATE_CLASSES,
  CREATE_CLASSES_FAILURE,
  CREATE_CLASSES_SUCCESS,
  GET_CLASSES,
  GET_CLASSES_FAILURE,
  GET_CLASSES_SUCCESS,
  LOGGED_USER,
  MANAGE_LOGO,
  MANAGE_LOGO_SUCCESS,
  MANAGE_LOGO_FAILURE
} from './constants'

const initialState = {
  isLogged: false,
  isStudent: false,
  wasRegisteredSuccessfully: false,
  user: {},
  loggedUser: {},
  loading: false,
  roles: [],
  classes: {},
  loadingPasswordRecovery: false,
  error: {},
}

export default createReducer(initialState, {
  [LOGIN]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [LOGIN_SUCCESS]: (state, action) => ({
    ...state,
    isLogged: true,
    loading: false,
    user: action.payload,
    error: initialState.error
  }),
  [LOGIN_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    user: initialState.user,
    error: action.payload
  }),
  [STUDENT_LOGIN]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [STUDENT_LOGIN_SUCCESS]: (state, action) => ({
    ...state,
    isStudent: true,
    loading: false,
    user: action.payload,
    loggedUser: action.payload,
    error: initialState.error
  }),
  [STUDENT_LOGIN_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    user: initialState.user,
    error: action.payload
  }),
  [SIGNUP]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [SIGNUP_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    user: action.payload,
    error: initialState.error
  }),
  [SIGNUP_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    user: initialState.user,
    error: action.payload
  }),
  [LOGGED_USER]: (state, action) => ({
    ...state,
    loggedUser: action.payload,
    isLogged: true,
    loading: false,
  }),
  [MANAGE_LOGO]: (state, action) => ({
    ...state,
    loading: true,
  }),
  [MANAGE_LOGO_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    loggedUser: {
      ...state.loggedUser,
      LogoUrl: action.payload.logoUrl,
      IsLogoActive: action.payload.isLogoActive,
      SecondaryLogoUrl: action.payload.secondaryLogoUrl,
      IsSecondaryLogoActive: action.payload.isSecondaryLogoActive,
    }
  }),
  [MANAGE_LOGO_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [LOGOUT]: (state, action) => ({
    ...state,
    isLogged: false,
    isStudent: false,
    loading: false,
    user: initialState.user,
    error: initialState.error
  }),
  [LOGOUT_SUCCESS]: (state, action) => ({
    ...state
  }),
  [LOGOUT_FAILURE]: (state, action) => ({
    ...state
  }),
  [REGISTER]: (state, action) => ({
    ...state,
    wasRegisteredSuccessfully: initialState.wasRegisteredSuccessfully,
    loading: true,
    error: initialState.error
  }),
  [REGISTER_SUCCESS]: (state, action) => ({
    ...state,
    wasRegisteredSuccessfully: true,
    loading: false,
    error: initialState.error
  }),
  [REGISTER_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [ACTIVATE_USER]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [ACTIVATE_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [ACTIVATE_USER_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [INACTIVATE_USER]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [INACTIVATE_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [INACTIVATE_USER_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [REJECT_USER]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [REJECT_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [REJECT_USER_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [INVITE_USERS]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [INVITE_USERS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [INVITE_USERS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [RESEND_EMAIL]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [RESEND_EMAIL_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [RESEND_EMAIL_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [RECOVER_PASSWORD]: (state, action) => ({
    ...state,
    loadingPasswordRecovery: true,
    error: initialState.error
  }),
  [RECOVER_PASSWORD_SUCCESS]: (state, action) => ({
    ...state,
    loadingPasswordRecovery: false,
    error: initialState.error
  }),
  [RECOVER_PASSWORD_FAILURE]: (state, action) => ({
    ...state,
    loadingPasswordRecovery: false,
    error: action.payload
  }),
  [CHANGE_PASSWORD]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [CHANGE_PASSWORD_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [CHANGE_PASSWORD_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [CHECKOUT_PAYMENT]: (state, action) => ({
    ...state,
    loading: true,
    user: action.payload
  }),
  [CHECK_PAYMENT_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [CHECK_PAYMENT_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
    user: initialState.user
  }),
  [GET_ROLES]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [GET_ROLES_SUCCESS]: (state, action) => ({
    ...state,
    roles: action.payload,
    loading: false,
    error: initialState.error
  }),
  [GET_ROLES_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [CREATE_CLASSES]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [CREATE_CLASSES_SUCCESS]: (state, action) => ({
    ...state,
    roles: action.payload,
    loading: false,
    error: initialState.error
  }),
  [CREATE_CLASSES_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [GET_CLASSES]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [GET_CLASSES_SUCCESS]: (state, action) => ({
    ...state,
    classes: action.payload,
    loading: false,
    error: initialState.error
  }),
  [GET_CLASSES_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  })
})
