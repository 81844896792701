import React from "react";
import { Page, Document, View, Text, StyleSheet } from "@react-pdf/renderer";
import ItemsTable from "./ItemsTable";

const styles = StyleSheet.create({
    page: {
        fontSize: 11,
        flexDirection: "column",
        borderTop: '1px solid #EEE',
        paddingTop: 8, paddingBottom: 8, textAlign: "center"
    },
});

const Table = ({ data }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={{ textAlign: "left", marginLeft: "128px", marginTop: "16px"}}>
                <Text>{data.nome}</Text>
            </View>
            <ItemsTable header={data.header} data={data.data} />
        </Page>
    </Document>
);

export default Table;
