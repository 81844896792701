import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { renderQuestionNumber, renderQuestionPosition, renderQuestionWarning } from '../../utils/questionHelpers'
import get from 'lodash/get'
import { setActiveWarningIndex } from '../../store/revisions/actions'

import Backdrop from '../backdrop/Backdrop'
import { Collapse, Icon } from 'antd'
import { Container, Header, Warning, WarningLabel, WarningContent, WarningType } from './styles/DocumentWarnings.styles'

class DocumentWarnings extends Component {
  static propTypes = {
    activeWarningIndex: PropTypes.number.isRequired,
    setActiveWarningIndex: PropTypes.func.isRequired,
    warnings: PropTypes.array
  }

  state = {
    isOpen: false
  }

  render () {
    const {
      isOpen
    } = this.state
    const {
      warnings,
      activeWarningIndex
    } = this.props

    if (!warnings || warnings.length === 0) return null

    return (
      <Backdrop
        onClick={this.toggleCollapse}
        show={isOpen}
      >
        <Container>
          <Collapse
            activeKey={isOpen ? '0' : '-1'}
            bordered={false}
            onChange={this.toggleCollapse}
          >
            <Collapse.Panel header={this.renderHeader(warnings)}>
              {this.renderWarnings(warnings, activeWarningIndex)}
            </Collapse.Panel>
          </Collapse>
        </Container>
      </Backdrop>
    )
  }

  renderHeader = warnings => {
    return (
      <Header>
        <Icon
          type='warning'
          theme='twoTone'
          twoToneColor='#e67e22'
        />
        <span>Identificamos <b>{warnings.length}</b> problema{warnings.length !== 1 && 's'}.</span>
      </Header>
    )
  }

  renderWarnings = (warnings, activeWarningIndex) => {
    return warnings.map((warning, i) =>
      <Warning
        active={i === activeWarningIndex}
        key={i}
        onClick={() => this.handleWarningClick(warning, i)}
      >
        <WarningLabel>questão {renderQuestionNumber(warning.questionNumber) || '??'}</WarningLabel>
        <WarningContent>
          {this.renderPosition(warning.position)}
          {warning.warnings.map(this.renderWarning)}
        </WarningContent>
      </Warning>
    )
  }

  renderWarning = (warning, i) => {
    return (
      <WarningType key={i}>
        <Icon
          type='warning'
          theme='twoTone'
          twoToneColor='#dd4d41'
          style={{ marginRight: '.5rem' }}
        />
        {renderQuestionWarning(warning)}
      </WarningType>
    )
  }

  renderPosition = position => {
    return position &&
      <p>
        <Icon
          type='environment'
          theme='twoTone'
          twoToneColor='#ffb30b'
          style={{ marginRight: '.5rem' }}
        />
        {`O problema está ${renderQuestionPosition(position)}.`}
      </p>
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleWarningClick = (warning, i) => {
    this.setState({ isOpen: false })
    this.props.setActiveWarningIndex(i)
  }
}

const mapStateToProps = ({ documents, revisions }) => ({
  activeWarningIndex: revisions.activeWarningIndex,
  warnings: get(documents.validations.find(validation => validation.document.id === documents.id), 'questionSummary.warnings')
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setActiveWarningIndex
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DocumentWarnings)
