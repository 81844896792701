/* eslint-disable */
import React, { useEffect, useState } from "react";

import MuiButton from "@material-ui/core/Button";
import MuiCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { IconButton, Dialog, DialogContent, DialogTitle, Grid, Tooltip, Typography} from "@material-ui/core";
import { makeStyles } from '@mui/styles';
import Tags from "../../tags/Tags";
import QuestionLink from "../QuestionLink";
import { Spin } from "antd";
import { getDescriptionInTree, getComment, getSolution } from "../../../store/questions/services";
import { TYPE_OF_EDUCATION, getTreeInfo } from "../QuestionFilterHeader/constant";
import { connect } from "react-redux";
import CommentIcon from '@material-ui/icons/Comment';
import InfoIcon from '@mui/icons-material/Info';
import EditMatrixKnowledgeAndHabilityModal from "../../modal/EditMatrixKnowledgeAndHabilityModal";
import CommentModal from "../../modal/CommentModal";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { QuestionAnswer } from "@material-ui/icons";
import renderHTML from "react-render-html";

const useStyles = makeStyles({
  typography: {
    color: "#fff"
  }
})

function QuestionBar({
  editar,
  question,
  examId,
  numberQuestion = 0,
  disableSelect = false,
  disableEdicao = false,
  disableBotaoEdicao = false,
  disableComment = false,
  checkQuestao,
  handleEditar,
  removerTag,
  adicionarTag,
  changeContent,
  contentOptions,
  showContent,
  matrixEF,
  matrixEM,
  habilitiesOption,
  treeIdEF,
  treeIdEM,
  treeIdHabilities,
  scholarship,
  blockibutton,
  onAddComment,
  onAddSolution
}) {
  const [treeDescription, setTreeDescription] = useState("");
  const [matrixHability, setMatrixHability] = useState(null);
  const [matrixKnowledge, setMatrixKnowledge] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalEditMatrix, setModalEditMatrix] = useState(false);
  const [updateMatrix, setUpdateMatrix] = useState(false);
  const [starting, setStarting] = useState(true);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [solutionModalOpen, setSolutionModalOpen] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [solutionText, setSolutionText] = useState('');

  const actuallyScholarship = question.Scholarship || scholarship;

  const classes = useStyles();

  useEffect(() => {
    if ((treeDescription !== '' && treeDescription !== null) || (!treeIdEF && !treeIdEM && !treeIdHabilities) || loading) return;
    setLoading(true);
    handleKnowledgeInTree()
      .then(response => {
        if (response) {
          if (response.matrixKnowledge) setMatrixKnowledge(response.matrixKnowledge);
          if (response.matrixHability) setMatrixHability(response.matrixHability);
          setTreeDescription(handleAllDescriptionInTree(response));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [question, treeIdEF, treeIdEM, treeIdHabilities]);

  useEffect(() => {
    if (starting) {
      question.UpdateMatrix = false;
      setStarting(false);
    }
  }, [starting]);

  useEffect(() => {
    console.log("treeDescription", treeDescription, matrixHability, matrixKnowledge)
  }, [treeDescription, matrixHability, matrixKnowledge])

  const handleAllDescriptionInTree = (response) => {
    let completedDescription = "";
    if (question.Tags && question.Tags.length > 0) {
      completedDescription += `Dificuldade: ${question.Tags[0]}`;
    }
    const matrixKnowledge = response.matrixKnowledge;
    const matrixHability = response.matrixHability;
    if (matrixKnowledge) {
      for (let i = 1; i <= 10; i++) {
        if (matrixKnowledge["Level" + i]) {
          if (completedDescription !== "") {
            completedDescription += " - ";
          }
          completedDescription += matrixKnowledge["Level" + i];
        }
      }
    }
    if (matrixHability && matrixHability["Level2"]) {
      completedDescription += " - Habilidade ";
      completedDescription += matrixHability["Level2"];
    }
    if (question.Metadata) {
      completedDescription += completedDescription == "" ? "" : " - ";
      completedDescription += question.Metadata;
    }
    return completedDescription;
  };

  const handleKnowledgeInTree = async () => {
    const questionId = question.Id;
    let treeId = null;
    const scholarshipSelected = scholarship || question.Scholarship || "EM";
    treeId = getTreeInfo(scholarshipSelected, { treeIdEF, treeIdEM }).treeId;
    const response = await getDescriptionInTree({ questionId, treeId, treeIdHabilities });
    return response;
  };

  const tipoQuestao = (alternatives, content) => {
    if (content && content.length > 0) {
      return "Conteúdo Vinculado";
    }
    if (alternatives > 0) {
      return "Objetiva";
    }
    return "Dissertativa";
  };

  const questionOrContent = (type, content) => {
    if (content && content.length > 0) {
      return "Conteúdo";
    }
    return "Questão";
  };

  const hasContentDisable =
    question.Content && question.Content.length > 0 ? true : false;

  const validateContent = (content, options) => {
    if (options) {
      const validated = options.filter((option) => content.includes(option.Id));
      if (validated) return validated.map((v) => v.Id);
    }

    return [];
  };

  const updateMatrixKnowledgeAndHability = (newMatrixResponse) => {
    setTreeDescription(handleAllDescriptionInTree(newMatrixResponse));
  };

  const openEditMatrixModal = () => {
    const disabledOption = disableEdicao || disableBotaoEdicao;
    if (disabledOption) return;
    setModalEditMatrix(!modalEditMatrix);
  };

  const setUpdateMatrixInBack = (e, newValue) => {
    question.UpdateMatrix = newValue;
    setUpdateMatrix(newValue);
  };

  const [openAnswer, setOpenAnswer] = useState(false);
  const handleOpenAnswer = () => {
    setOpenAnswer(true);
  }

  const handleSetComment = (comment) => {
    setCommentText(comment);
    setCommentModalOpen(true);
  };

  const handleGetComment = async (questionId) => { 
    setLoading(true);
    getComment(examId, questionId)
      .then(response => {
        if (response) {
          handleSetComment(response);   
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCommentButtonClick = () => {
    handleGetComment(question.Id);
    setCommentModalOpen(true);
  };

  const handleCommentModalClose = () => {
    setCommentModalOpen(false);
  };

  const handleSaveComment = () => {
    onAddComment(question.Id, commentText);
    setCommentModalOpen(false);
  };

  const handleSetSolution = (solution) => {
    setSolutionText(solution);
    setSolutionModalOpen(true);
  };

  const handleGetSolution = async (questionId) => { 
    setLoading(true);
    getSolution(examId, questionId)
      .then(response => {
        if (response) {
          handleSetSolution(response);   
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSolutionButtonClick = () => {
    handleGetSolution(question.Id);
    setSolutionModalOpen(true);
  };

  const handleSolutionModalClose = () => {
    setSolutionModalOpen(false);
  };

  const handleSaveSolution = () => {
    onAddSolution(question.Id, solutionText);
    setSolutionModalOpen(false);
  };

  return (
    <Grid>
      <Tags
        tags={question.Tags}
        disableEdicao={disableEdicao}
        removerTag={removerTag}
        adicionarTag={adicionarTag}
      />
      <QuestionLink
        id={question.Id}
        content={validateContent(question.Content, contentOptions)}
        disableEdicao={disableBotaoEdicao}
        disableSelect={question.Alternatives.length > 0 ? true : false}
        changeContent={changeContent}
        options={contentOptions}
      />
      <br />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid container item justifyContent="center" xs={6} md={2}>
          <Typography>
            {questionOrContent(question.Type, question.Content)}{" "}
            {numberQuestion}
          </Typography>
        </Grid>
        <Grid container item justifyContent="center" xs={6} md={2}>
          <Typography>
            {tipoQuestao(question.Alternatives.length, question.Content)}
          </Typography>
        </Grid>
        <Grid container item justifyContent="center" xs={6} md={2}>
          <FormControlLabel
            control={
              <MuiCheckbox
                color="primary"
                disabled={editar || disableSelect || hasContentDisable}
                checked={question.Selected || false}
                onChange={checkQuestao(question)}
              />
            }
            label="Selecionar"
          />
        </Grid>
        <Grid container item justifyContent="center" xs={6} md={2}>
          <Spin spinning={loading}>
            <Tooltip title={treeDescription ? treeDescription : ""}>
              <IconButton
                disabled={blockibutton}
                onClick={openEditMatrixModal}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Spin>

          <div>
      {!disableComment && (
        <IconButton onClick={handleCommentButtonClick}>
          <CommentIcon />
        </IconButton>
      )}
      <IconButton onClick={handleSolutionButtonClick}>
        <LightbulbIcon />
      </IconButton>

      <CommentModal
        title="Comentário"
        placeholder="Insira seu comentário aqui..."
        commentModalOpen={commentModalOpen}
        handleModalClose={handleCommentModalClose}
        handleSave={handleSaveComment}
        text={commentText}
        setText={setCommentText}
      />

      <CommentModal
        title="Solução"
        placeholder="Insira sua solução aqui..."
        commentModalOpen={solutionModalOpen}
        handleModalClose={handleSolutionModalClose}
        handleSave={handleSaveSolution}
        text={solutionText}
        setText={setSolutionText}
      />
    </div>

          {
            matrixEF.length > 0 && matrixEM.length > 0 && habilitiesOption.length > 0 ?
              <EditMatrixKnowledgeAndHabilityModal
                visible={modalEditMatrix}
                questionId={question.Id}
                matrixKnowledge={matrixKnowledge}
                treeKnowledge={getTreeInfo(actuallyScholarship, { matrixEF, matrixEM }).matrix}
                matrixHability={matrixHability}
                treeHability={habilitiesOption}
                scholarship={actuallyScholarship}
                onCancel={() => setModalEditMatrix(false)}
                onSave={updateMatrixKnowledgeAndHability}
              /> : <></>
          }

        </Grid>
        {question && question.Solution ? (
          <Grid container item justifyContent="center" xs={6} md={2}>
            <IconButton
              onClick={handleOpenAnswer}
            >
              <QuestionAnswer />
            </IconButton>
            <Dialog open={openAnswer} onClose={() => setOpenAnswer(false)}>
              <DialogTitle>
                Resolução
              </DialogTitle>
              <DialogContent>
                <Typography>
                  {renderHTML(question.Solution)}
                </Typography>
              </DialogContent>
            </Dialog>
          </Grid>
        ) : null}
        {/* <Grid container item justifyContent="center" xs={6} md={2}>
          <ToggleButtonGroup
            size="small"
            value={updateMatrix}
            exclusive
            onChange={setUpdateMatrixInBack}
            aria-label="text alignment"
          >
            <ToggleButton value={true} aria-label="left aligned" disabled={disableEdicao || disableBotaoEdicao}>
              <Tooltip title={"Atualizar competência automaticamente"}>
                <UpdateIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={false} aria-label="right aligned" disabled={disableEdicao || disableBotaoEdicao}>
              <Tooltip title={"Não atualizar competência automaticamente"}>
                <UpdateDisabledIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid> */}

        <Grid container item justifyContent="center" xs={6} md={2}>
          {editar ? (
            <Grid>
              <MuiButton
                disabled={disableEdicao}
                variant="contained"
                onClick={handleEditar}
              >
                Cancelar
              </MuiButton>
            </Grid>
          ) : (
            <MuiButton
              disabled={disableEdicao || disableBotaoEdicao}
              variant="contained"
              onClick={handleEditar}
            >
              Editar
            </MuiButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = ({ exams }) => ({
  matrixEF: exams.matrixEF,
  matrixEM: exams.matrixEM,
  habilitiesOption: exams.habilities,
  treeIdEF: exams.treeIdEF,
  treeIdEM: exams.treeIdEM,
  treeIdHabilities: exams.treeIdHabilities
});

export default connect(mapStateToProps)(QuestionBar);
