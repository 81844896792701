import { store } from '../store/configureStore'

export const findOutput = (documentId, templateId) => {
  const { outputs } = store.getState().outputs

  return outputs.find(output =>
    output.document.id === documentId &&
    output.template.id === templateId
  )
}
