import React from 'react'
import PropTypes from 'prop-types'

import { TEMPLATE_FONT_FAMILIES } from '../../utils/constants'

import { Select } from 'antd'
import ValidatedSharedInput from './ValidatedSharedInput'
import { Container, Label, Icon } from './styles/TemplateSubGroupInput.styles'

import fontFamilyIcon from '../../assets/font-family-icon.svg'

const TemplateFontFamilyInput = ({
  label,
  field,
  form,
  HelpComponent
}) => {
  const options = TEMPLATE_FONT_FAMILIES.map(option =>
    <Select.Option
      key={option.value}
      value={option.value}
    >
      {option.label}
    </Select.Option>
  )

  return (
    <ValidatedSharedInput
      touched={form.touched[field.name]}
      error={form.errors[field.name]}
      label={label}
      HelpComponent={HelpComponent}
    >
      <Container>
        <Icon component={fontFamilyIcon} />
        <Label>Nome: </Label>
        <Select
          className='template-font-family-input'
          placeholder='Nome'
          dropdownMatchSelectWidth={false}
          onChange={value => form.setFieldValue(field.name, value)}
          value={field.value}
        >
          {options}
        </Select>
      </Container>
    </ValidatedSharedInput>
  )
}

TemplateFontFamilyInput.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  HelpComponent: PropTypes.func
}

export default TemplateFontFamilyInput
