import createReducer from '../createReducer'
import {
  CLEAR_QUESTION_MODULE,
  ADD_TAG,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAILURE,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAILURE,
  CHANGE_TO_DISSERTATIVE,
  CHANGE_TO_DISSERTATIVE_SUCCESS,
  CHANGE_TO_DISSERTATIVE_FAILURE,
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
  ADD_COMMENT,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAILURE
} from './constants'

const initialState = {
  id: null,
  tags: [],
  loading: false,
  error: {},
  questionsCompany: [],
  loadingTags: false,
  optionsTags: [],
}

export default createReducer(initialState, {
  [CLEAR_QUESTION_MODULE]: () => initialState,
  [ADD_TAG]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload
  }),
  [ADD_TAG_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    tags: [...state.tags, action.payload],
    error: initialState.error
  }),
  [ADD_TAG_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [DELETE_TAG]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload
  }),
  [DELETE_TAG_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    tags: [...state.tags, action.payload],
    error: initialState.error
  }),
  [DELETE_TAG_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [GET_QUESTIONS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [GET_QUESTIONS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    questionsCompany: action.payload,
    error: initialState.error
  }),
  [GET_QUESTIONS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [CHANGE_TO_DISSERTATIVE]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [CHANGE_TO_DISSERTATIVE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [CHANGE_TO_DISSERTATIVE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [GET_TAGS]: (state, action) => ({
    ...state,
    loadingTags: true,
    error: null,
  }),
  [GET_TAGS_SUCCESS]: (state, action) => ({
    ...state,
    optionsTags: action.payload,
    loadingTags: false,
    error: null,
  }),
  [GET_TAGS_FAILURE]: (state, action) => ({
    ...state,
    loadingTags: false,
    error: action.payload,
  }),
  [ADD_COMMENT]: (state, action) => ({
    ...state,
    loading: true,
    error: action.payload
  }),
  [ADD_COMMENT_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    tags: [...state.tags, action.payload],
    error: initialState.error
  }),
  [ADD_COMMENT_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
})
