import createReducer from '../createReducer'
import {
  CLEAR_DOCUMENT_MODULE,
  UPLOAD_DOCUMENT,
  // SET_FILE,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  VALIDATE_DOCUMENT,
  VALIDATE_DOCUMENT_SUCCESS,
  VALIDATE_DOCUMENT_FAILURE
} from './constants'

const initialState = {
  id: null,
  file: null,
  savedFile: null,
  validations: [],
  loading: false,
  error: {}
}

export default createReducer(initialState, {
  [CLEAR_DOCUMENT_MODULE]: () => initialState,
  // [SET_FILE]: (state, action) => ({
  //   ...state,
  //   savedFile: action.payload,
  //   loading: false,
  //   error: initialState.error
  // }),
  [UPLOAD_DOCUMENT]: (state, action) => ({
    ...state,
    id: initialState.id,
    file: action.payload,
    loading: true,
    error: initialState.error
  }),
  [UPLOAD_DOCUMENT_SUCCESS]: (state, action) => ({
    ...state,
    id: action.payload,
    loading: false,
    error: initialState.error
  }),
  [UPLOAD_DOCUMENT_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [VALIDATE_DOCUMENT]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [VALIDATE_DOCUMENT_SUCCESS]: (state, action) => ({
    ...state,
    validations: action.payload,
    loading: false,
    error: initialState.error
  }),
  [VALIDATE_DOCUMENT_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  })
})
