import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Input from 'antd/lib/input'
import TextArea from 'antd/lib/input/TextArea'
import { Button, Checkbox, DatePicker, Switch, TimePicker, Select } from 'antd'

export const OutputModalStyle = css`
  height: 95vh;
  width: calc(95vh / ${Math.sqrt(2)}) !important;
  margin: 0 auto;
  padding: 0;
  top: 2.5vh;
  @media only screen and (max-width: 768px) {
    height: 95vh;
    width: 95vw !important;
  }
  .ant-modal {
    &-content {
      margin: 0 auto;
      height: 100%;
      width: 100%;  
    }
    &-body {
      position: relative;
      height: calc(95vh - 52px);
      > div:first-of-type { height: 100%; }
    }
    &-footer {
      height: 52px;
    }
  }
`

export const TemplateModalStyle = css`
  height: 95vh;
  width: calc(95vh / ${Math.sqrt(2)}) !important;
  margin: 0 auto;
  padding: 0;
  top: 2.5vh;
  @media only screen and (max-width: 768px) {
    height: 95vh;
    width: 95vw !important;
  }
  .ant-modal {
    &-content {
      margin: 0 auto;
      height: 100%;
      width: 100%;  
    }
    &-body {
      position: relative;
      height: calc(95vh - 52px);
      > div:first-of-type { height: 100%; }
    }
    &-footer {
      height: 52px;
    }
  }
`
export const QuestionsModalStyle = css`
  max-width: 1024px;
  width: 96vw !important;
  margin: 0 auto;
  padding: 0;
`

export const EditMatrixKnowledgeAndHabilityModalStyle = css`
  max-width: 800px;
  width: 96vw !important;
  margin: 0 auto;
  padding: 0;
`

export const SectionTitle = styled.p`
  text-align: start;
  margin-top: 10px;
  margin-bottom: 50px;
  font-weight: 500;
`

export const dropdown = {
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export const Label = styled.div`
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 18px;
  color: #514FFF;
`
export const StyledInput = styled(Input)`
  border-radius: 25px;
  border-color: #514fff !important;
  :hover{
    border-width: 2px !important;
  }
  &.ant-input:focus {
  border-color: #514fff;
  border-width: 2px !important;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  }
`
export const StyledSelect = styled(Select)`
  .ant-select-selection {
    border-radius: 25px;
    border-color: #514fff !important;
    :hover{
      border-width: 2px !important;
    }
    :active {
      border-color: #514fff;
    }
}
`
export const StyledDatePicker = styled(DatePicker)`
  .ant-calendar-picker-input.ant-input {
    border-radius: 25px;
    border-color: #514fff !important;
    :hover{
      border-width: 3px !important;
    }
  }
`
export const StyledTimePicker = styled(TimePicker)`
  .ant-time-picker-input {
    border-radius: 25px;
    border-color: #514fff !important;
    :hover{
      border-width: 3px !important;
    }
  }
`
export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-color: #514fff !important;
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #514fff !important;
    background-color: #514fff !important;
    align-items: center !important;
    justify-content: center !important;
    
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    margin-left:1.2px;
  }
`
export const StyledButton = styled(Button)`
  background-color: #514fff;
  border-color: #514fff;
  color: #fff;
  border-radius: 25px;
  margin-top: 10px;
  :hover {
    background-color: #514fff;
    border-color: #FBA661;
    border-width: 3px;
    color: #fff;
  }
  &.ant-btn:focus{
    background-color: #514fff;
    color: #fff;
    border-color: transparent;
    :hover{
      border-color: #FBA661;
      border-width: 3px;
    }
  }
`
export const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #514fff !important;
  }
`
export const StyledTextArea = styled(TextArea)`
  border-color: #514fff;
  border-radius: 25px;
  :hover{
    border-width: 3px;
    border-color: #514fff;
  }
  :focus{
    border-width: 3px;
    border-color: #514fff;
    -webkit-box-shadow: none;
          box-shadow: none;
  }
`
export const QuestionCardContainer = styled.div`
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const QuestionTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

export const QuestionStatement = styled.div`
  font-weight: normal; /* Change to normal font weight */
  margin-bottom: 8px;
`;

export const RemoveButton = styled(StyledButton)`
  cursor: pointer;
  margin-top: 8px;
  justify-self: start;
`;