import { all, call, takeLatest, put } from 'redux-saga/effects'
import { normalizeError } from '../../utils/errorHelpers'

import { message } from 'antd'

import * as actions from './actions'
import * as types from './constants'
import * as services from './services'

export function * fetchOrganization ({ payload }) {
  try {
    // const auth = yield select(state => state.auth)

    // // console.log(auth)

    const organization = yield call(services.fetchOrganization, payload)
    yield put(actions.fetchOrganizationSuccess(organization))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao obter organizações')
    message.error(errorInfo.message)
    yield put(actions.fetchOrganizationFailure(errorInfo))
  }
}

// Watchers

export function * watchFetchOrganization () {
  yield takeLatest(types.FETCH_ORGANIZATION, fetchOrganization)
}

export default function * () {
  yield all([
    watchFetchOrganization()
  ])
}
