import createReducer from '../createReducer'
import {
  SEND_FEEDBACK,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAILURE,
  SET_FEEDBACK_SENT
} from './constants'

const initialState = {
  wasFeedbackSent: false,
  loading: false,
  error: {}
}

export default createReducer(initialState, {
  [SEND_FEEDBACK]: (state, action) => ({
    ...state,
    wasFeedbackSent: initialState.wasFeedbackSent,
    loading: true,
    error: initialState.error
  }),
  [SEND_FEEDBACK_SUCCESS]: (state, action) => ({
    ...state,
    wasFeedbackSent: true,
    loading: false,
    error: initialState.error
  }),
  [SEND_FEEDBACK_FAILURE]: (state, action) => ({
    ...state,
    wasFeedbackSent: false,
    loading: false,
    error: action.payload
  }),
  [SET_FEEDBACK_SENT]: (state, action) => ({
    ...state,
    wasFeedbackSent: action.payload
  })
})
