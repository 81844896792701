import styled from '@emotion/styled'

export const StyledBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9998;

  .fade-appear,
  .fade-enter,
  .fade-leave.fade-leave-active {
    position: relative;
    opacity: 0.01;
  }

  .fade-appear.fade-appear-active,
  .fade-enter.fade-enter-active,
  .fade-leave {
    transition: all 0.4s ease-out;
    opacity: 1;
  }
`

export const StyledChildren = styled.div`
  * {
    ${props => props.visible && 'z-index: 9999;'}
  }
`
