import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Content, Group } from './styles/HeaderTemplate.styles'

import Logo from '../../assets/logo.svg'
import HeaderNavigation from './HeaderNavigation'

const HeaderTemplate = ({
  superLogoUrl,
  isLogoActive,
  secondaryLogoUrl,
  isSecondaryLogoActive,
  user,
}) => (
  <Container>
    <Content>
      <Group>
        <Link to={(user && user.Role !== 'Guest') ? '/list-exams' : '/result-exams'}>
          { superLogoUrl !== "" && isLogoActive
            ? 
            <img
              src={superLogoUrl}
              style={{ width: '100%', height: '100%', maxHeight: '100px'}}
            />
            : 
            <Logo width='100%' height='100%' />             
          }
        </Link>
      </Group>
      <HeaderNavigation />
      { secondaryLogoUrl !== "" && isSecondaryLogoActive &&
        <Group>
          <Link to={(user && user.Role !== 'Guest') ? '/list-exams' : '/result-exams'}>
              <img
                src={secondaryLogoUrl}
                style={{ width: '100%', height: '100%', maxHeight: '100px'}}
              />  
          </Link>
        </Group>
      }
    </Content>
  </Container>
)

const mapStateToProps = ({ auth }) => ({
  user: auth.loggedUser,
  superLogoUrl: auth.loggedUser.LogoUrl ? auth.loggedUser.LogoUrl : "",
  isLogoActive: auth.loggedUser.IsLogoActive ? auth.loggedUser.IsLogoActive : false,
  secondaryLogoUrl: auth.loggedUser.SecondaryLogoUrl ? auth.loggedUser.SecondaryLogoUrl : "",
  isSecondaryLogoActive: auth.loggedUser.IsSecondaryLogoActive ? auth.loggedUser.IsSecondaryLogoActive : false,
})

export default connect(mapStateToProps)(HeaderTemplate)

