/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tags: {
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  text: {
    width: theme.spacing(30),
  },
}));

function QuestionLink({
  id,
  changeContent,
  content = [],
  disableEdicao = false,
  disableSelect = false,
  options = [],
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    changeContent(event.target.value);
  };

  const handleClick = () => {
    if (disableSelect) {
      window.alert(
        "Remova as alternativas para poder vincular à outra questão"
      );
    }
  };

  return (
    <Grid className={classes.tags} container>
      {disableEdicao ? null : (
        <FormControl>
          <InputLabel>Conteúdo vinculado à</InputLabel>
          <Select
            multiple
            value={content}
            className={classes.text}
            onChange={handleChange}
            onClick={handleClick}
            disabled={disableSelect}
            renderValue={(selected) =>
              selected
                .map((s) => options.find((op) => op.Id === s).Number)
                .join(", ")
            }
          >
            {options &&
              options
                .filter((t) => t.Id !== id)
                .map((t) => (
                  <MenuItem key={t.Id} value={t.Id}>
                    <Checkbox checked={content.indexOf(t.Id) > -1} />
                    <ListItemText primary={"Questão " + t.Number} />
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
}

QuestionLink.propTypes = {
  optionsTags: PropTypes.array,
};

export default QuestionLink;
