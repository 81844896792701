/* eslint react/prop-types: 0 */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

const animations = {
  fade: ({ timeout, timing }) => css`
    .fade-appear,
    .fade-enter,
    .fade-leave.fade-leave-active {
      position: relative;
      opacity: 0.01;
      top: -1rem;
    }

    .fade-appear.fade-appear-active,
    .fade-enter.fade-enter-active,
    .fade-leave {
      transition: all ${timeout / 1000}s ${timing};
      opacity: 1;
      top: 0;
    }
  `
}

export const StyledAnimation = props => (
  <div css={animations[props.type](props)}>
    {props.children}
  </div >
)
