import { css } from '@emotion/core'

export const IconStyle = css`
  cursor: pointer;
`

export const InformationIconStyle = css`
  ${IconStyle};
  border: 1px solid transparent;
  color: #fff;
  border-radius: 25px;
  padding: 3px;
  background-color: #787878;
`

export const ViewIconStyle = css`
  ${IconStyle};
  color: #fff;
  border: 1px solid transparent;
  border-radius: 25px;
  padding: 3px;
  background-color: #4C4AC2;
`
export const LinkIconStyle = css`
  ${IconStyle};
  color: #fff;
  border: 1px solid transparent;
  border-radius: 25px;
  padding: 3px;
  background-color: #FF7C11;
`

export const DiagramarIconStyle = css`
  ${IconStyle};
  color: #fff;
  border: 1px solid transparent;
  border-radius: 25px;
  padding: 3px;
  background-color: #1890ff;
`

export const EditIconStyle = css`
  ${IconStyle};
  color: #FFF;
  border: 1px solid transparent;
  border-radius: 25px;
  padding: 3px;
  background-color: #8A4CEC;
  `

export const DownloadIconStyle = css`
  ${IconStyle};
  color: #FFF;
  border: 1px solid transparent;
  border-radius: 25px;
  padding: 3px;
  background-color: #F22786;
  `

export const DeleteIconStyle = css`
  ${IconStyle};
  color: #FFF;
  border: 1px solid transparent;
  border-radius: 25px;
  padding: 3px;
  background-color: #F80000;
`
