/* eslint no-template-curly-in-string: 0 */
import * as Yup from 'yup'

export default () => {
  Yup.setLocale({
    mixed: {
      notType: 'Valor inválido',
      required: 'Campo é obrigatório'
    },
    string: {
      email: 'Email inválido',
      min: 'Campo deve ter ao menos ${min} caracteres'
    }
  })

  Yup.addMethod(Yup.string, 'equals', (ref, msg) => {
    return Yup.mixed().test({
      name: 'equals',
      exclusive: false,
      message: msg || '${path} deve ser igual a ${reference}',
      params: {
        reference: ref.path
      },
      test: function (value: any) {
        return value === this.resolve(ref)
      }
    })
  })
}
