/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import {
  fetchExams,
  fetchExamMatrixEF,
  fetchExamMatrixEM,
  fetchExamHabilities,
  setQuestions,
  fetchSelected,
  clearExamModule,
  searchSelected,
} from "../store/exams/actions";
import { fetchCompanies } from "../store/companies/actions";
import { makeStyles } from "@material-ui/core/styles";

import { changeToDissertative } from "../store/questions/actions";
import QuestionFilterHeader from "../components/question/QuestionFilterHeader";
import QuestionBlock from "../components/question/QuestionBlock";

import { Content } from "./styles/SelectQuestionsPage.styles";
import * as services from "../store/exams/services";
import { listQuestions } from "../store/questions/services";

import { infoUser } from "../store/users/actions";
import { Page } from "./styles/Page.styles";
import { LEVEL_OF_QUESTION, TYPE_OF_EDUCATION, TYPE_OF_TEST } from "../components/question/QuestionFilterHeader/constant";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function SelectQuestionsPage({
  exams = undefined,
  fetchExams,
  fetchExamMatrixEF,
  fetchExamMatrixEM,
  fetchExamHabilities,
  searchSelected,
  updateId,
  examId,
  fetchSelected,
  setQuestions,
  selected,
  resolutions,
  loggedUser,
  clearExamModule,
  infoUser,
  user,
  cartQuestions,
  isSuperAdmin,
  changeToDissertative,
  fetchCompanies,
  companies,
  matrixEF,
  matrixEM,
  habilitiesOption,
  treeIdEF,
  treeIdEM,
  treeIdHabilities,
  currentPage,
  totalPages,
  totalDocs,
  ItensPerPage,
  batching,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isMenuOpen = Boolean(anchorEl);
  const [tags, setTags] = useState([]);
  const [showExams, setShowExams] = useState([]);
  const [pickedExams, setPickedExams] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [loadQuestions, setLoadQuestions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [similarStatement, setSimilar] = useState([]);
  // const { Option } = Select
  let [questionsCompany, setQuestionsCompany] = useState("...");
  let [questionsLoading, setQuestionsLoading] = useState(false);

  // filtros
  const source = { TODAS: 0, ESCOLA: 1, SIMULIE: 2, MINHAS: 3 };
  const tiposQuestoes = { TODAS: 0, OBJETIVAS: 1, DISCURSIVAS: 2 };
  const [showCompanySelect, setShowCompanySelect] = useState(false);
  const [scholarship, setScholarship] = useState(TYPE_OF_EDUCATION.EM.slug);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  useEffect(() => {
    // TODO - Já adicionei a chamada do info user pra voce
    infoUser();
    clearExamModule();
    if (!isSuperAdmin) {
      getExams(undefined, loggedUser.CompanyId, false);
    } else {
      getExams(undefined, undefined, false);
    }
    setLoading(false);
  }, []);
  useEffect(() => {
    // TODO - Já adicionei a chamada do info user pra voce
    if (isSuperAdmin) {
      fetchCompanies("", 1);
    }
  }, [isSuperAdmin]);

  useEffect(() => {
    async function fetchData() {
      if (user !== null) {
        if (exams !== undefined && exams !== null) {
          let sum = 0;
          let examsArrayId = [];
          for (let x = 0; x < exams.length; x++) {
            examsArrayId.push(exams[x].Id);
          }
          let newExamsArrayId = await services.fetchSelected(examsArrayId);
          if (newExamsArrayId !== undefined) {
            for (let j = 0; j < newExamsArrayId.length; j++) {
              if (newExamsArrayId[j].Questions !== undefined) {
                sum += parseInt(newExamsArrayId[j].Questions.length);
              }
            }
            setQuestionsCompany(sum);
          } else {
            if (exams.length === 0) {
              setQuestionsCompany(sum);
            } else {
              let count = await listQuestions(user.CompanyId);
              setQuestionsCompany(count);
            }
          }
        }
      }
    }
    // fetchData();
  }, [exams, user]); // Or [] if effect doesn't need props or state

  useEffect(() => {
    if (!selected) return;
    if (selected.length > 0) {
      setLoadQuestions(true);
    }

    if (selected.length == 0) {
      setLoadQuestions(false);
    }

    setQuestionsLoading(false);
    setShowExams(selected);
    setPickedExams(selected);
  }, [exams, selected]);

  useEffect(() => {
    if (matrixEF.length > 0) return;
    fetchExamMatrixEF(loggedUser.CompanyId);
  }, []);

  useEffect(() => {
    if (matrixEM.length > 0) return;
    fetchExamMatrixEM(loggedUser.CompanyId);
  }, []);

  function extractTags(exams) {
    let tagList = [];
    if (exams !== undefined) {
      for (let x = 0; x < exams.length; x++) {
        if (exams[x].Questions !== undefined && exams[x].Questions !== null) {
          for (let y = 0; y < exams[x].Questions.length; y++) {
            for (let z = 0; z < exams[x].Questions[y].Tags.length; z++) {
              tagList.push(exams[x].Questions[y].Tags[z]);
            }
          }
        }
      }
    }
    return [...new Set(tagList)];
  }

  function handleSelect(values) {
    setLoadQuestions(false);
    setAllChecked(false);
    setTags(extractTags(values));
    setShowExams(values);
    setPickedExams(values);
    // setLoadQuestions(true)
  }

  function handleTags(value) {
    if (value.length === 0) {
      setShowExams(pickedExams);
      setAllChecked(false);
    } else {
      const examsList = pickedExams.map((exam) => {
        let tempExam = Object.assign({}, exam);
        let tempQuestions = Object.assign([], tempExam.Questions);

        tempExam.Questions = tempQuestions.reduce((elements, question) => {
          let searchedElement = value.find((val) =>
            question.Tags.includes(val)
          );

          if (searchedElement) {
            return [...elements, question];
          }

          return elements;
        }, []);

        return tempExam;
      });

      setShowExams(examsList);
    }
  }

  function handleLoadQuestions(
    filterByQuestion,
    educationType,
    tipoProva,
    tipoQuestao,
    provas,
    selectedNodesInTree,
    year,
    habilities,
    questionTags,
    treeId,
    treeIdHabilities,
    page,
    limit,
    searchStatement
  ) {
    let provaIds;
    let reqTipoProva;
    let reqTipoQuestao;
    let tags;
    let reqEducationType;
    let queryTree;
    let queryHabilityTree;
    let querySearchStatement;

    if (filterByQuestion) {
      provaIds = [];
      reqTipoProva = TYPE_OF_TEST.Todas;
      reqTipoQuestao = tipoQuestao;
      tags = questionTags === LEVEL_OF_QUESTION.Todas ? [] : questionTags;
      reqEducationType = educationType;
      queryTree = selectedNodesInTree;
      queryHabilityTree = habilities;
      querySearchStatement = searchStatement;
    } else {
      provaIds = provas.map((prova) => prova.Id);
      reqTipoProva = tipoProva;
      reqTipoQuestao = tiposQuestoes.TODAS;
      tags = [];
      reqEducationType = "All";
      queryTree = [];
      queryHabilityTree = [];
      querySearchStatement = "";
    }
    searchSelected(
      reqEducationType,
      reqTipoProva,
      reqTipoQuestao,
      provaIds,
      tags,
      year,
      queryTree,
      queryHabilityTree,
      treeId,
      treeIdHabilities,
      page,
      limit,
      true,
      querySearchStatement
    );
    if (page == 1) setQuestionsLoading(true);
  }

  function onSelectType(event) {
    setLoading(true);
    const type = event.target.value;
    const examsList = pickedExams.map((exam) => {
      let tempExam = Object.assign({}, exam);
      let tempQuestions = Object.assign([], tempExam.Questions);

      tempExam.Questions = tempQuestions.reduce((elements, question) => {
        let searchedElement = true;
        if (type === "objetivas") {
          searchedElement = question.Type === "Multiple Choice";
        } else if (type === "discursivas") {
          searchedElement = question.Type !== "Multiple Choice";
        }

        if (searchedElement) {
          return [...elements, question];
        }

        return elements;
      }, []);

      return tempExam;
    });
    setLoading(false);
    setShowExams(examsList);
  }

  function handleTotalQuestions(questions) {
    setSelectedQuestions(questions);
  }

  function handleSearch(value) {
    setLoadQuestions(false);
    fetchExams(value, 1, value, 99);
    // setSelectedQuestions([])
    // setLoadQuestions(true)
  }

  function extractQuestions(array) {
    let newArray = [];
    if (array) {
      for (let i = 0; i < array.length; i++) {
        newArray.push(array[i].question);
      }
    }

    return newArray;
  }

  function handleSuffleQuestions(array) {
    let newArray = extractQuestions(array);
    let arrayAux = [...newArray];
    for (let i = arrayAux.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * i);
      let temp = arrayAux[i];
      arrayAux[i] = arrayAux[j];
      arrayAux[j] = temp;
    }
    setSelectedQuestions(arrayAux);
  }

  function handleDelete(array) {
    let newArray = extractQuestions(array);
    setSelectedQuestions(newArray);
  }

  function handleCheck(array) {
    setSelectedQuestions(array);
  }

  function handleDesCheck(question) {
    let array = selected.filter((q) => q !== question.Id);
    setSelectedQuestions(array);
  }

  function updateSelected(array) {
    setSelectedQuestions(array);
    checkSimilarQuestions(array);
  }
  function checkSimilarQuestions(array) {
    let stripedHtml = [];
    let questionsWords = [];
    let getSimilarQuestID = [];
    let hasSimilar = false;
    for (let i = 0; i < array.length; i++) {
      stripedHtml[i] = array[i].Statement.replace(/<[^>]+>/g, "");
      questionsWords[i] = stripedHtml[i].split(" ");
    }

    for (let x = 0; x < stripedHtml.length; x++) {
      for (let y = x + 1; y <= stripedHtml.length - 1; y++) {
        if (
          inRange(
            questionsWords[x].length,
            questionsWords[y].length - 5,
            questionsWords[y].length + 5
          )
        ) {
          console.log("Questoes similares");
          let numEqualWords = 0;

          for (let w = 0; w < questionsWords[x].length; w++) {
            let repeatedWords = [];
            for (let z = 0; z < questionsWords[y].length; z++) {
              if (questionsWords[x][w] === questionsWords[y][z]) {
                repeatedWords.push(questionsWords[x][w]);
                let sameWordCount = 0;
                for (let p = 0; p < repeatedWords.length; p++) {
                  if (questionsWords[x][w] === repeatedWords[p]) {
                    sameWordCount = sameWordCount + 1;
                  }
                }
                if (sameWordCount < 2) {
                  numEqualWords = numEqualWords + 1;
                }
              }
            }
          }
          if (numEqualWords > questionsWords[x].length - 5) {
            console.log("Existem questoes similares");
            if (!hasSimilar) {
              window.alert("Existem questoes similares selecionadas");
              hasSimilar = true;
            }
            getSimilarQuestID.push({
              firstQuestionId: array[x].Id,
              similarQuetionId: array[y].Id,
            });
          }
        } else {
          console.log("Questoes diferentes");
        }
      }
    }
    console.log(questionsWords[1]);
    setSimilar(getSimilarQuestID);
  }

  function inRange(x, min, max) {
    return (x - min) * (x - max) <= 0;
  }

  function getExams(userId, companyId, isSimulieDatabase, examType = "Todas", examStatus) {
    fetchExams("", 1, undefined, 100, userId, companyId, isSimulieDatabase, examType, "", examStatus);
  }

  async function onSelectCompany(companyId) {
    await getExams(undefined, companyId, false);
  }

  function onSelectSource(value) {
    let examStatus = "Active";
    let examType = value;
    let userId;
    let companyId;
    let isSimulieDatabase;

    if (examType === "Rascunho") {
      examStatus = "Draft";
      examType = "Escola";
    }

    if (examType === "Aprovadas") {
      examType = "Escola";
    }

    if (isSuperAdmin) {
      switch (value) {
        case source.TODAS:
          setShowCompanySelect(false);
          userId = undefined;
          isSimulieDatabase = false;
          companyId = undefined;
          getExams(userId, companyId, isSimulieDatabase, value, examStatus);
          break;
        case source.ESCOLA:
          setShowCompanySelect(true);
          break;
        default:
          break;
      }
    } else {
      switch (value) {
        case source.TODAS:
          userId = undefined;
          isSimulieDatabase = false;
          companyId = [loggedUser.CompanyId];
          break;
        case source.SIMULIE:
          userId = undefined;
          isSimulieDatabase = true;
          companyId = [loggedUser.CompanyId];
          break;
        case source.MINHAS:
          userId = loggedUser.Id;
          isSimulieDatabase = false;
          companyId = [loggedUser.CompanyId];
          break;
        default:
          break;
      }
      getExams(userId, companyId, isSimulieDatabase, examType, examStatus);
    }
  }

  function updateCorrectAnswer(question) {
    const examsList = pickedExams.map((exam) => {
      let newExam = exam;
      newExam.Questions.map((q) => {
        if (q.Id === question.Id) {
          return question;
        }

        return q;
      });

      return newExam;
    });

    setPickedExams(examsList);
  }

  async function updateType(question, type) {
    // ATUALIZA A QUESTAO DIRETAMENTE NO ENDPOINT
    await changeToDissertative(question.Id);

    // AQUI SERIA PARA MODIFICAR NO SALVAR E NOS ELEMENTOS DA TELA
    const examsList = pickedExams.map((exam) => {
      let newExam = exam;
      newExam.Questions.map((q) => {
        if (q.Id === question.Id) {
          q.Type = type;
        }

        return q;
      });

      return newExam;
    });

    setPickedExams(examsList);
  }

  return (
    <Page>
      <Content>
        {selected !== null &&
          selected !== undefined &&
          selected.Id !== undefined && (
            <span>
              <h4>Editando prova {selected.Title}</h4>
              <br />
            </span>
          )}
        <QuestionFilterHeader
          onSelectExam={handleSelect}
          exams={exams}
          checkAll={allChecked}
          onLoadQuestions={handleLoadQuestions}
          onSearchExam={handleSearch}
          onSelectType={onSelectType}
          isSuperAdmin={isSuperAdmin}
          sourceOptions={source}
          tiposQuestoes={tiposQuestoes}
          onSelectSource={onSelectSource}
          showCompanySelect={showCompanySelect}
          onSelectCompany={onSelectCompany}
          tags={tags}
          questionsLoading={questionsLoading}
          handleTags={handleTags}
          companies={companies}
          fetchExams={fetchExams}
          matrixEF={matrixEF}
          matrixEM={matrixEM}
          habilitiesOption={habilitiesOption}
          treeIdEF={treeIdEF}
          treeIdEM={treeIdEM}
          treeIdHabilities={treeIdHabilities}
          fetchExamHabilities={fetchExamHabilities}
          companyId={loggedUser.CompanyId}
          scholarship={scholarship}
          setScholarship={setScholarship}
          currentPage={currentPage}
          totalPages={totalPages}
          totalDocs={totalDocs}
          ItensPerPage={ItensPerPage}
          batching={batching}
          cartQuestions={cartQuestions}
        />
        <br />
        <div className={classes.sectionDesktop}>
          {loadQuestions && (
            <QuestionBlock
              type="desktop"
              questionsCompany={questionsCompany}
              updateId={updateId}
              open={isMenuOpen}
              anchorEl={anchorEl}
              onClose={handleMenuClose}
              keepMounted
              className={classes.sectionDesktop}
              isSuperAdmin={isSuperAdmin}
              updateSelected={updateSelected}
              view={false}
              createView
              selectedQuestions={selectedQuestions}
              cartQuestions={cartQuestions}
              loadQuestions
              sufflable
              tags={tags}
              onCheck={handleCheck}
              onDesCheck={handleDesCheck}
              editView={false}
              justView={false}
              exam={selected}
              onDelete={handleDelete}
              onShuffle={handleSuffleQuestions}
              exams={showExams}
              changeTypeInline={false}
              editable
              isSelectable
              totalSelected={handleTotalQuestions}
              checkAll={allChecked}
              pickedExams={pickedExams}
              updateType={updateType}
              updateCorrectAnswer={updateCorrectAnswer}
              similarStatement={similarStatement}
              scholarship={scholarship}
              setScholarship={setScholarship}
            />
          )}
        </div>
        <div className={classes.sectionMobile}>
          {loadQuestions && (
            <QuestionBlock
              type="mobile"
              questionsCompany={questionsCompany}
              updateId={updateId}
              open={isMobileMenuOpen}
              anchorEl={mobileMoreAnchorEl}
              keepMounted
              onClose={handleMobileMenuClose}
              className={classes.sectionDesktop}
              isSuperAdmin={isSuperAdmin}
              updateSelected={updateSelected}
              view={false}
              createView
              selectedQuestions={selectedQuestions}
              cartQuestions={cartQuestions}
              loadQuestions
              sufflable
              tags={tags}
              onCheck={handleCheck}
              onDesCheck={handleDesCheck}
              editView={false}
              justView={false}
              exam={selected}
              onDelete={handleDelete}
              onShuffle={handleSuffleQuestions}
              exams={showExams}
              changeTypeInline={false}
              editable
              isSelectable
              totalSelected={handleTotalQuestions}
              checkAll={allChecked}
              pickedExams={pickedExams}
              updateType={updateType}
              updateCorrectAnswer={updateCorrectAnswer}
              similarStatement={similarStatement}
              scholarship={scholarship}
              setScholarship={setScholarship}
            />
          )}
        </div>
      </Content>
    </Page>
  );
}

SelectQuestionsPage.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  exams: PropTypes.array,
  companies: PropTypes.array,
  examId: PropTypes.string,
  selected: PropTypes.any,
  resolutions: PropTypes.any,
  infoUser: PropTypes.func,
  fetchExams: PropTypes.func.isRequired,
  fetchExamMatrixEF: PropTypes.func.isRequired,
  fetchExamMatrixEM: PropTypes.func.isRequired,
  fetchExamHabilities: PropTypes.func.isRequired,
  searchSelected: PropTypes.func,
  fetchCompanies: PropTypes.func,
  clearExamModule: PropTypes.func.isRequired,
  fetchSelected: PropTypes.func,
  setQuestions: PropTypes.func.isRequired,
  loggedUser: PropTypes.any,
  isSuperAdmin: PropTypes.bool,
  changeToDissertative: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams, questions, auth, users, companies }) => ({
  exams: exams.examsList !== undefined ? exams.examsList.exams : [],
  total: exams.examsList !== undefined ? exams.examsList.total : 0,
  selected: exams.selected,
  resolutions: exams.resolutions,
  editar: exams.editar,
  examId: exams.id,
  loading: exams.loading || questions.loading,
  user: users.infoUser,
  userData: users.user,
  loggedUser: auth.loggedUser,
  companies: companies.companiesList.companies,
  isSuperAdmin:
    auth.loggedUser.Role === "SuperUser" || auth.loggedUser.Role === "Super",
  updateId: exams.updateId,
  matrixEF: exams.matrixEF,
  matrixEM: exams.matrixEM,
  habilitiesOption: exams.habilities,
  treeIdEF: exams.treeIdEF,
  treeIdEM: exams.treeIdEM,
  treeIdHabilities: exams.treeIdHabilities,
  currentPage: exams.currentPage,
  totalPages: exams.totalPages,
  totalDocs: exams.totalDocs,
  ItensPerPage: exams.ItensPerPage,
  batching: exams.batching,
  cartQuestions: exams.cartQuestions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      infoUser,
      clearExamModule,
      fetchSelected,
      fetchExams,
      fetchExamMatrixEF,
      fetchExamMatrixEM,
      fetchExamHabilities,
      searchSelected,
      setQuestions,
      changeToDissertative,
      fetchCompanies,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectQuestionsPage);
