import React from 'react'
import PropTypes from 'prop-types'

import { Button, Spin } from 'antd'
import { Header, Container } from './styles/TemplateFrame.styles'

const TemplateFrame = ({
  url,
  loading
}) => {
  const iframe = (
    <iframe
      title='Modelo'
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
      frameBorder='0'
    />
  )

  const downloadButton = (
    <Button
      disabled={loading}
      href={url}
      icon='download'
      shape='round'
      size='large'
      style={{ marginBottom: '.5rem' }}
      type='primary'
    >
      Baixar Modelo
    </Button>
  )

  return (
    <Container>
      <Header center>
        {downloadButton}
      </Header>
      {loading ? <Spin /> : iframe}
    </Container>
  )
}

TemplateFrame.propTypes = {
  loading: PropTypes.bool,
  url: PropTypes.string
}

export default TemplateFrame
