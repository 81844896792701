import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import { normalizeError } from '../../utils/errorHelpers'
import { fileToBase64 } from '../../utils/fileHelpers'
// import { getLocalFile, setLocalFile } from '../../utils/idb'
import { message } from 'antd'
import history from '../../routes/history'

import * as actions from './actions'
import * as types from './constants'
import * as services from './services'

export function * fetchTemplates ({ payload }) {
  try {
    const templates = yield call(services.fetchTemplates, payload)
    yield put(actions.fetchTemplatesSuccess(templates))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao obter modelos')
    message.error(errorInfo.message)
    yield put(actions.fetchTemplatesFailure(errorInfo))
  }
}

export function * uploadTemplate ({ payload: file }) {
  try {
    // const preloaded = yield getLocalFile(file, 'template').catch(console.error)
    // if (!preloaded) {
    const base64 = yield fileToBase64(file)
    var res = yield call(services.uploadTemplate, file, base64)
    // }

    const id = (res && res.id) /* || preloaded.id */
    yield put(actions.uploadTemplateSuccess(id))
    yield put(actions.validateTemplate({ id, file }))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao processar modelo')
    message.error(errorInfo.message)
    yield put(actions.uploadTemplateFailure(errorInfo))
  }
}

export function * validateTemplate ({ payload }) {
  try {
    const {
      id
      // file
    } = payload
    const validation = yield call(services.validateTemplate, id)
    yield put(actions.validateTemplateSuccess(validation))

    if (validation.status === 'OK') {
      history.push('/templates/new')
      // id && file && setLocalFile(file, 'template', id).catch(console.error)
    } else {
      message.error('Modelo inválido')
    }
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao processar modelo')
    message.error(errorInfo.message)
    yield put(actions.validateTemplateFailure(errorInfo))
  }
}

export function * createTemplate ({ payload }) {
  try {
    yield call(services.createTemplate, payload)
    message.success('Modelo criado com sucesso')
    history.push('/templates')
    yield put(actions.createTemplateSuccess())
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao criar modelo')
    message.error(errorInfo.message)
    yield put(actions.createTemplateFailure(errorInfo))
  }
}

export function * deleteTemplate ({ payload: id }) {
  try {
    yield call(services.deleteTemplate, id)
    message.success('Modelo deletado com sucesso')
    yield put(actions.deleteTemplateSuccess())
    const query = yield select(store => store.templates.query)
    yield put(actions.fetchTemplates(query))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao deletar modelo')
    message.error(errorInfo.message)
    yield put(actions.deleteTemplateFailure(errorInfo))
  }
}

export function * fetchOrganizations ({ payload }) {
  try {
    // console.log('template organizations')
    const organizations = yield call(services.fetchOrganizations, payload)
    yield put(actions.fetchOrganizationsSuccess(organizations))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao obter organizações')
    message.error(errorInfo.message)
    yield put(actions.fetchOrganizationsFailure(errorInfo))
  }
}

export function * fetchTemplate ({ payload }) {
  try {
    const template = yield call(services.fetchTemplate, payload)
    yield put(actions.fetchTemplateSuccess(template))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao obter modelo')
    message.error(errorInfo.message)
    yield put(actions.fetchTemplateFailure(errorInfo))
  }
}

export function * editTemplate ({ payload }) {
  try {
    const template = yield call(services.editTemplate, payload)
    message.success('Modelo editado com sucesso')
    history.push('/templates')
    yield put(actions.editTemplateSuccess(template))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao editar modelo')
    message.error(errorInfo.message)
    yield put(actions.editTemplateFailure(errorInfo))
  }
}

// Watchers
export function * watchFetchTempaltes () {
  yield takeLatest(types.FETCH_TEMPLATES, fetchTemplates)
}

export function * watchUploadTemplate () {
  yield takeLatest(types.UPLOAD_TEMPLATE, uploadTemplate)
}

export function * watchValidateTemplate () {
  yield takeLatest(types.VALIDATE_TEMPLATE, validateTemplate)
}

export function * watchCreateTemplate () {
  yield takeLatest(types.CREATE_TEMPLATE, createTemplate)
}

export function * watchDeleteTemplate () {
  yield takeLatest(types.DELETE_TEMPLATE, deleteTemplate)
}

export function * watchFetchOrganizations () {
  yield takeLatest(types.FETCH_ORGANIZATIONS, fetchOrganizations)
}

export function * watchFetchTemplate () {
  yield takeLatest(types.FETCH_TEMPLATE, fetchTemplate)
}

export function * watchEditTemplate () {
  yield takeLatest(types.EDIT_TEMPLATE, editTemplate)
}

export default function * () {
  yield all([
    watchFetchTempaltes(),
    watchUploadTemplate(),
    watchValidateTemplate(),
    watchCreateTemplate(),
    watchDeleteTemplate(),
    watchFetchOrganizations(),
    watchFetchTemplate(),
    watchEditTemplate()
  ])
}
