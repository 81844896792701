/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { infoUser } from "../../../store/users/actions";

import { Spin } from "antd";
import MuiSelect from "@material-ui/core/Select";
import { FormControl, IconButton, InputLabel, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { Autocomplete, TreeItem } from "@material-ui/lab";

import ContainerCard from "../../card/ContainerCard";
import { Container } from "../../template/styles/HeaderNavigation.styles";

import { Row, SectionSeparator, SectionTitle, dropdown } from "./styles";
import PropTypes from "prop-types";
import { StyledButton } from "../../modal/styles/Modal.styles";
import { Checkbox, Grid, TextField } from "@material-ui/core";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";

import { TYPE_OF_EDUCATION, MAP_SCHOLARSHIP_TO_TYPE_OF_TEST, TYPE_OF_TEST, LEVEL_OF_QUESTION, getTreeInfo } from "./constant";
import "./styles.css";
import DropdownTree from "./components/DropdownTree";
import ReactDropdownTree from "./components/ReactDropdownTree/ReactDropDownTree";
import { Tune } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  selectFiltesButton: {
    border: "solid 1px #514FFF",
    borderEndEndRadius: "20px",
    borderEndStartRadius: "20px",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    height: "35px",
    variant: "contained"
  }
}))

function QuestionFilterHeader({
  onSelectExam,
  onLoadQuestions,
  onSearchExam,
  onSelectType,
  isSuperAdmin,
  sourceOptions,
  tiposQuestoes,
  onSelectSource,
  showCompanySelect,
  onSelectCompany,
  handleTags,
  loading,
  questionsCompany,
  questionsLoading,
  fetchExams,
  companies = [],
  exams = undefined,
  matrixEF,
  matrixEM,
  habilitiesOption,
  fetchExamHabilities,
  companyId,
  scholarship,
  setScholarship,
  treeIdEF,
  treeIdEM,
  treeIdHabilities,
  currentPage,
  totalPages,
  totalDocs,
  ItensPerPage,
  batching,
  cartQuestions,
  updateCartQuestions,
  user,
  infoUser
}) {
  const [disableLoad, setDisableLoad] = useState(true);
  const [optionFiltro, setOptionFiltro] = useState([]);
  const [selectedType, setSelectedType] = useState(TYPE_OF_TEST.Aprovadas);
  const [tipoDasQuestoes, setTipoDasQuestoes] = useState(tiposQuestoes.TODAS);
  const [selectedEducationType, setSelectedEducationType] = useState(TYPE_OF_EDUCATION.EF2.slug);
  const [provasSelecionadas, setProvasSelecionadas] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [selectedNodesInTree, setSelectedNodesInTree] = useState([]);
  const [habilities, setHabilities] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterByQuestion, setFilterByQuestion] = useState(true);
  const [searchStatement, setSearchStatement] = useState("");
  const [questionLevel, setQuestionLevel] = useState(LEVEL_OF_QUESTION.Todas);
  const [tags, setTags] = useState([LEVEL_OF_QUESTION.Todas]);
  const [inputTags, setInputTags] = useState('');

  const autocompleteRef = useRef(null);

  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState([]);
  const startYear = 2000;
  const years = Array.from(new Array(currentYear - startYear + 1), (val, index) => startYear + index);

  const classes = useStyles();

  useEffect(()=>{
    if (user && user.Role === "Teacher" && user.DisableFilterByQuestion){
      setFilterByQuestion(false);
    }
  },[user])

  useEffect(() => {
    if (selectedEducationType !== TYPE_OF_EDUCATION.EM.slug) return;
    if (habilitiesOption.length > 0) return;
    fetchExamHabilities(companyId);
  }, [selectedEducationType, habilitiesOption]);

  useEffect(() => {
    if (selectedType !== sourceOptions.TODAS || provasSelecionadas.length > 0) {
      setDisableLoad(false);
    } else {
      setDisableLoad(true);
    }
  }, [selectedType, provasSelecionadas]);

  useEffect(() => {
    setSelectedNodesInTree([]);
  }, [selectedEducationType]);

  useEffect(() => {
    if (!batching || !currentPage || !totalPages || currentPage >= totalPages) return;

    if (currentPage >= 30) return;
    let treeId = getTreeInfo(selectedEducationType, { treeIdEF, treeIdEM, matrixEF, matrixEM }).treeId;

    onLoadQuestions(
      filterByQuestion,
      selectedEducationType,
      selectedType,
      tipoDasQuestoes,
      provasSelecionadas,
      selectedNodesInTree,
      year,
      habilities,
      tags,
      treeId,
      treeIdHabilities,
      currentPage + 1,
      10,
      searchStatement
    );
  }, [batching, currentPage, totalPages]);

  function loadOptions() {
    let options = [];

    if (isSuperAdmin) {
      options.push(
        <MenuItem key={sourceOptions.TODAS} value={sourceOptions.TODAS}>
          Todas
        </MenuItem>
      );
      options.push(
        <MenuItem key={sourceOptions.ESCOLA} value={sourceOptions.ESCOLA}>
          Escola
        </MenuItem>
      );
    } else {
      options.push(
        <MenuItem key={sourceOptions.TODAS} value={sourceOptions.TODAS}>
          Todas
        </MenuItem>
      );
      options.push(
        <MenuItem key={sourceOptions.MINHAS} value={sourceOptions.MINHAS}>
          Minhas
        </MenuItem>
      );
    }
    setOptionFiltro(options);
  }

  function handleChangeSource(event) {
    const value = event.target.value;
    onSelectSource(value);
    setSelectedType(value);
  }

  const handleTipoDasQuestoes = (event) => {
    const type = event.target.value;
    setTipoDasQuestoes(type);
  };

  const handleSelectLevel = (event) => {
    setQuestionLevel(event.target.value);
    if (tags.includes('Todas')) {
      setTags(oldTags => oldTags.filter(tag => tag !== 'Todas'));
    }
  };

  const handleInputTagsChange = (e) => {
    setInputTags(e.target.value);
  };

  const handleLoadQuestions = () => {
    let treeId = getTreeInfo(selectedEducationType, { treeIdEF, treeIdEM, matrixEF, matrixEM }).treeId;
    let currentTags = inputTags.split(',').map(tag => tag.trim()).filter(tag => tag);
    if (questionLevel !== LEVEL_OF_QUESTION.Todas) {
      currentTags.push(questionLevel);
    }
    setTags(currentTags);
    onLoadQuestions(
      filterByQuestion,
      selectedEducationType,
      selectedType,
      tipoDasQuestoes,
      provasSelecionadas,
      selectedNodesInTree,
      year,
      habilities,
      currentTags,
      treeId,
      treeIdHabilities,
      1,
      10,
      searchStatement
    );
  };

  const handleChangePeriodoEscolar = (e) => {
    setSelectedEducationType(e.target.value);
    setScholarship(e.target.value);
    onSelectSource(TYPE_OF_TEST.Aprovadas);
    setSelectedType(TYPE_OF_TEST.Aprovadas);
  };

  useEffect(() => {
    const button = document.querySelector('.MuiAutocomplete-popupIndicator');
    if (button) {
      button.addEventListener('click', handleButtonClick);
    }

    return () => {
      if (button) {
        button.removeEventListener('click', handleButtonClick);
      }
    };
  }, [open]);

  const handleFetchExams = (event) => {
    const newSearchString = event.target.value;
    setSearchString(newSearchString);
    fetchExams(newSearchString);
  };

  const handleSearchChange = (event, value) => {
    setOpen(value !== '' || provasSelecionadas.length > 0);
    setProvasSelecionadas(value)
  };

  const handleSearchBlur = () => {
    setOpen(false);
  };

  const handleTextFieldFocus = () => {
    setOpen(true);
  };

  const handleButtonClick = () => {
    setOpen(!open);
  };


  const handleSelectedNodes = (currentNodes, selectedNodes) => {
    setSelectedNodesInTree(selectedNodes);
  }

  const handleSelectedHabilities = (currentNodes, selectedNodes) => {
    setHabilities(selectedNodes);
  }

  useEffect(() => {
    loadOptions();
  }, [isSuperAdmin]);

  const sectionId = "primary-search-account";

  const renderTree = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={<div style={{ color: "#000", textAlign: "start" }}>{nodes.name}</div>}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  const handleFilterByQuestion = () => {
    setFilterByQuestion(!filterByQuestion);
    setProvasSelecionadas([]);
  }

  return (
    <Container>
      <div>
        <Spin
          id={sectionId}
          spinning={exams === undefined || questionsLoading}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <ContainerCard centered centerText marginTop={2}>
            {exams && (
              <>
                <>
                  <Grid container direction="column">
                    <Grid container justifyContent="space-between" direction="row" alignItems="center">
                      <SectionTitle>{filterByQuestion ? "Selecione o(s) filtro(s) para as suas questões" : "Selecione o(s) filtro(s) para a sua prova"}</SectionTitle>
                      {(user && user.DisableFilterByQuestion !== true) &&
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                          <Button
                            className={classes.selectFiltesButton}
                            onClick={handleFilterByQuestion}
                            variant={filterByQuestion? 'contained' : 'outlined'}
                          >
                            Questões
                          </Button>
                          <Button
                            className={classes.selectFiltesButton}
                            onClick={handleFilterByQuestion}
                            variant={filterByQuestion?  'outlined' : 'contained'}
                          >
                            Provas
                          </Button>
                        </ButtonGroup>
                      }     
                    </Grid>

                {
                  filterByQuestion ? (
                    <>
                      <FormControl>
                        <InputLabel>Tipo das Questões</InputLabel>
                        <MuiSelect
                          value={tipoDasQuestoes}
                          defaultValue={tipoDasQuestoes}
                          onChange={handleTipoDasQuestoes}
                          MenuProps={dropdown}
                        >
                          <MenuItem value={tiposQuestoes.TODAS}>Todas</MenuItem>
                          <MenuItem value={tiposQuestoes.OBJETIVAS}>Objetivas</MenuItem>
                          <MenuItem value={tiposQuestoes.DISCURSIVAS}>Discursivas</MenuItem>
                        </MuiSelect>
                      </FormControl>
                      <br />

                      <FormControl>
                        <InputLabel>Nível das Questões</InputLabel>
                        <MuiSelect
                          defaultValue={tags}
                          onChange={handleSelectLevel}
                          MenuProps={dropdown}
                        >
                          <MenuItem key={LEVEL_OF_QUESTION.Todas} value={LEVEL_OF_QUESTION.Todas}>Todas</MenuItem>
                          <MenuItem key={LEVEL_OF_QUESTION.Fácil} value={LEVEL_OF_QUESTION.Fácil}>Fácil</MenuItem>
                          <MenuItem key={LEVEL_OF_QUESTION.Médio} value={LEVEL_OF_QUESTION.Médio}>Médio</MenuItem>
                          <MenuItem key={LEVEL_OF_QUESTION.Difícil} value={LEVEL_OF_QUESTION.Difícil}>Difícil</MenuItem>
                        </MuiSelect>
                      </FormControl>
                      <br />

                      <FormControl>
                        <InputLabel>Segmento das Questões</InputLabel>
                        <MuiSelect
                          defaultValue={selectedEducationType}
                          onChange={handleChangePeriodoEscolar}
                          MenuProps={dropdown}
                        >
                          {Object.keys(TYPE_OF_EDUCATION).map((education) =>
                            <MenuItem value={TYPE_OF_EDUCATION[education].slug}>{TYPE_OF_EDUCATION[education].label}</MenuItem>
                          )}
                        </MuiSelect>
                      </FormControl>
                      <br />

                      {
                        matrixEF && selectedEducationType === TYPE_OF_EDUCATION.EF2.slug ?
                          <>
                            <FormControl style={{ width: "100%" }}>
                              <ReactDropdownTree
                                className="mdl-demo"
                                inlineSearchInput
                                data={matrixEF}
                                texts={{ placeholder: "Matriz Pedagógica", inlineSearchPlaceholder: "Pesquisar" }}
                                showPartiallySelected
                                keepTreeOnSearch
                                onChange={handleSelectedNodes}
                                value={selectedNodesInTree}
                              />
                            </FormControl>
                            <br />
                          </> : <></>
                      }

                      {
                        matrixEM && selectedEducationType === TYPE_OF_EDUCATION.EM.slug ?
                          <>
                            <FormControl>
                              <ReactDropdownTree
                                className="mdl-demo"
                                inlineSearchInput
                                data={matrixEM}
                                texts={{ placeholder: "Matriz Pedagógica", inlineSearchPlaceholder: "Pesquisar" }}
                                showPartiallySelected
                                keepTreeOnSearch
                                onChange={handleSelectedNodes}
                                value={selectedNodesInTree}
                              />
                            </FormControl>
                            <br />
                          </> : <></>
                      }

                      {
                        habilitiesOption && selectedEducationType === TYPE_OF_EDUCATION.EM.slug ?
                          <>
                            <FormControl>
                              <ReactDropdownTree
                                className="mdl-demo"
                                inlineSearchInput
                                data={habilitiesOption}
                                texts={{ placeholder: "Habilidades", inlineSearchPlaceholder: "Pesquisar" }}
                                showPartiallySelected
                                keepTreeOnSearch
                                onChange={handleSelectedHabilities}
                                value={habilities}
                              />
                            </FormControl>
                            <br />
                          </> : <></>
                      }
                    </>
                  ) : (
                    <>
                      <FormControl>
                        <InputLabel>Tipo da Prova *</InputLabel>
                        <MuiSelect
                          value={selectedType}
                          defaultValue={selectedType}
                          onChange={handleChangeSource}
                          MenuProps={dropdown}
                        >
                          {
                            MAP_SCHOLARSHIP_TO_TYPE_OF_TEST["EM"].map((testType) =>
                              (testType !== "VestibularesCE" || companyId === "6838c79b-f7af-4e30-b522-0617b9c98dfb") ? <MenuItem value={testType}>{TYPE_OF_TEST[testType]}</MenuItem> : null
                            )
                          }
                        </MuiSelect>
                      </FormControl>
                      <br />

                      {/* {
                        selectedEducationType === TYPE_OF_EDUCATION.EM.slug && selectedType !== TYPE_OF_TEST.Autorais ?
                          <>
                            <FormControl>
                              <InputLabel>Ano</InputLabel>
                              <MuiSelect
                                value={year}
                                multiple
                                MenuProps={dropdown}
                                onChange={(e) => setYear(e.target.value)}
                                renderValue={(selected) => selected.join(', ')}
                              >
                                {years.map((year) => (
                                  <MenuItem key={year} value={year}>
                                    {year}
                                  </MenuItem>
                                ))}
                              </MuiSelect>
                            </FormControl>
                            <br />
                          </>
                          : <></>
                      } */}

                      {
                        selectedType !== TYPE_OF_TEST.Autorais ?
                          <>
                            <div ref={autocompleteRef}>
                              <Autocomplete
                                multiple
                                options={exams}
                                getOptionLabel={(option) => option["Title"]}
                                onChange={handleSearchChange}
                                onFocus={handleTextFieldFocus}
                                open={open}
                                onBlur={handleSearchBlur}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <Checkbox
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                      color="primary"
                                    />
                                    {option["Title"]}
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label=""
                                    placeholder={provasSelecionadas.length > 0 ? "" : "Provas"}
                                    id="autocomplete"
                                    value={searchString}
                                    onChange={handleFetchExams}
                                  />
                                )}
                                clearOnBlur={false}
                              />
                            </div>
                            <br />
                          </> : <></>
                      }
                    </>
                  )
                }
                {filterByQuestion ? 
                  <>
                  <TextField
                      variant="outlined"
                      placeholder="Buscar por tags"
                      onChange={handleInputTagsChange}
                      style={{ marginBottom: "20px" }}
                      value={inputTags}
                    />
                    <TextField
                      variant="outlined"
                      placeholder="Buscar por trecho do enunciado"
                      onChange={(e) => setSearchStatement(e.target.value)}
                      value={searchStatement}
                    />
                  </> : <></>
                }

              </Grid>

            <Row style={{ justifyContent: "flex-end" }}>
              <StyledButton
                type="primary"
                shape="round"
                disabled={disableLoad}
                onClick={handleLoadQuestions}
              >
                Carregar questões
              </StyledButton>
            </Row>
          </>
        </>
            )}
      </ContainerCard>
      <br />
    </Spin>
      </div >
    </Container >
  );
}

QuestionFilterHeader.propTypes = {
  exams: PropTypes.array,
  companies: PropTypes.array,
  onLoadQuestions: PropTypes.func,
  onSelectExam: PropTypes.func,
  questionsCompany: PropTypes.any,
  questionsLoading: PropTypes.any,
  onSearchExam: PropTypes.func,
  onSelectType: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
  loading: PropTypes.bool,
  sourceOptions: PropTypes.object,
  onSelectSource: PropTypes.func,
  showCompanySelect: PropTypes.bool,
  onSelectCompany: PropTypes.func,
  tags: PropTypes.array,
  handleTags: PropTypes.func,
  matrixEF: PropTypes.array,
  matrixEM: PropTypes.array,
  habilitiesOption: PropTypes.array,
  fetchExamHabilities: PropTypes.func,
  companyId: PropTypes.string,
  scholarship: PropTypes.string,
  setScholarship: PropTypes.func,
};

const mapStateToProps = ({ auth }) => ({
  user: auth.loggedUser,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    infoUser,
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(QuestionFilterHeader);
