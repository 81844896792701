import styled from '@emotion/styled'

export const Container = styled.div`
  margin-top: 57px;
`

export const WarnText = styled.div`
  color: #74809D;
  font-weight: bold;
  font-size: 24px;
  width: 234px;
  text-align: center;
  margin: auto;
  margin-bottom: 41px;
`

export const BgIcon = styled.div`
  background-color: #E67E22;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  padding-left: 8px;
  padding-top: 4px;
  margin: auto;
`
