export const CREATE_COMPANY = 'companies:CREATE_COMPANY'
export const CREATE_COMPANY_SUCCESS = 'companies:CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_FAILURE = 'companies:CREATE_COMPANY_FAILURE'

export const EDIT_COMPANY = 'companies:EDIT_COMPANY'
export const EDIT_COMPANY_SUCCESS = 'companies:EDIT_COMPANY_SUCCESS'
export const EDIT_COMPANY_FAILURE = 'companies:EDIT_COMPANY_FAILURE'

export const FETCH_COMPANY = 'companies:FETCH_COMPANY'
export const FETCH_COMPANY_SUCCESS = 'companies:FETCH_COMPANY_SUCCESS'
export const FETCH_COMPANY_FAILURE = 'companies:FETCH_COMPANY_FAILURE'

export const FETCH_COMPANY_GRADES = 'companies:FETCH_COMPANY_GRADES'
export const FETCH_COMPANY_GRADES_SUCCESS = 'companies:FETCH_COMPANY_GRADES_SUCCESS'
export const FETCH_COMPANY_GRADES_FAILURE = 'companies:FETCH_COMPANY_GRADES_FAILURE'

export const FETCH_COMPANY_BASIC = 'companies:FETCH_COMPANY_BASIC'
export const FETCH_COMPANY_BASIC_SUCCESS = 'companies:FETCH_COMPANY_BASIC_SUCCESS'
export const FETCH_COMPANY_BASIC_FAILURE = 'companies:FETCH_COMPANY_BASIC_FAILURE'

export const FETCH_COMPANIES = 'companies:FETCH_COMPANIES'
export const FETCH_COMPANIES_SUCCESS = 'companies:FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_FAILURE = 'companies:FETCH_COMPANIES_FAILURE'

export const DELETE_COMPANY = 'companies:DELETE_COMPANY'
export const DELETE_COMPANY_SUCCESS = 'companies:DELETE_COMPANY_SUCCESS'
export const DELETE_COMPANY_FAILURE = 'companies:DELETE_COMPANY_FAILURE'
