import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Spin, message } from "antd";

import {
  fetchOnlineExam,
  ansQuestion,
  startOnlineExam,
  endOnlineExam,
  fetchStudentIndividualResultExam,
  getVideosLoaded,
} from "../store/exams/actions";

import history from "../routes/history";
import { Col, Page, Row, Title } from "./styles/Page.styles";
import { QuestionList } from "../components/question/QuestionBlock/styles";
import QuestionOnlineCard from "../components/question/QuestionOnlineCard";
import useWindowSize from "../utils/useWindowSize";
import { StyledButton } from "../components/modal/styles/Modal.styles";
import {
  StyledTitle,
  Pagination,
  PaginationDiv,
  PaginationItem,
  StyledInputNumber,
} from "./styles/ListExamsPage.styles";
import { fetchOnlineExamById } from "../store/exams/services";

function OpenExamPage({
  fetchOnlineExam,
  fetchStudentIndividualResultExam,
  ansQuestion,
  startOnlineExam,
  endOnlineExam,
  getVideosLoaded,
  exams,
  startedExam,
  endExam,
  showResult,
  error,
  userOpen,
  alternativeChecked,
  loadingQuest,
  loadingVideos,
  loadingEndOnlineExam,
}) {
  const [numberQuestion, setNumberQuestion] = useState(0);
  const [provisoryNumberQuestion, setProvisoryNumberQuestion] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [timer, setTimer] = useState(false);
  const [time, setTime] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [start, setStart] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const [end, setEnd] = useState(false);
  const [endTime, setEndTime] = useState(false);
  const [finalized, setFinalized] = useState(false);
  const [examId, setExamId] = useState(undefined);
  const [loadedExams, setLoadedExams] = useState(false);
  const [fetchExams, setFetchExams] = useState(false);
  const [examSelected, setExamSelected] = useState(null);
  const [showResultButton, setShowResultButton] = useState(false);
  const [tempQuestions, setTempQuestion] = useState([]);
  const [finishing, setFinishing] = useState(false);
  const [savingSingleAnswer, setSavingSingleAnswer] = useState(false);
  const windowSize = useWindowSize();
  const isOpen = true;
  const [temporaryUserId, setTemporaryUserId] = useState(undefined);
  const [showAlternative, setShowAlternatives] = useState(true);

  const startTest = async () => {
    await startOnlineExam(examId, undefined, isOpen);
    // console.log(start)
    // setStart(true)
  };

  const goBack = async () => {
    history.go(-1);
  };

  const handleQuestions = async (q) => {
    console.log(q);
    setSavingSingleAnswer(true);
    message.info("Salvando sua resposta ...", 3);
    setTempQuestion([...answers]);
    const updatedAnswers = answers.map((obj) => q.find((o) => o.Id === obj.Id) || obj);
    setAnswers(updatedAnswers);
    if (q && q.length > 0) {
      var ans = {
        ExamId: examId,
        StudentId: userOpen.userId,
        QuestionId: q[0].Id,
        AlternativeOrder: q[0].Alternatives.find((el) => el.Correct === true)
          .Order,
      };
      await ansQuestion(ans, isOpen);
    }
  };

  const getResult = () => {
    fetchStudentIndividualResultExam(examId, temporaryUserId);
  };

  const getExamById = async () => {
    if(!exams.examsList.exams) {
      const getExam = await fetchOnlineExamById(examId);
      setExamSelected(getExam);
    } else if(exams.examsList.exams) {
      const examFound = exams.examsList.exams.find((item) => item.Id === examId);
      if(examFound) {
        setExamSelected(examFound);
      }
    }
  };

  useEffect(() => {
    if(!examSelected) return;
    const removeAlternatives = examSelected.RemoveAlternatives ? true : false;
    setShowAlternatives(!removeAlternatives);
  }, [examSelected])

  useEffect(() => {
    if(!savingSingleAnswer || loadingQuest) return;
    if(alternativeChecked && !loadingQuest) {
      message.success("Resposta salva", 3);
    }
    else if(!alternativeChecked && !loadingQuest) {
      setAnswers(tempQuestions);
      message.error("Você está com problemas de conexão. Verifique sua internet e se persistir contacte seu professor/coordenador", 7);
    }
    setSavingSingleAnswer(false);
  }, [savingSingleAnswer, alternativeChecked, loadingQuest]);

  useEffect(() => {
    if(!finishing) return;
    if(!endExam && loadingEndOnlineExam) {
      message.info("Salvando suas respostas", 6);
    } else if(endExam && !loadingEndOnlineExam) {
      message.success("Suas respostas foram salvas");
      setEnd(true);
      setSeconds(undefined);
      setFinishing(false);
    } else if(!endExam && !loadingEndOnlineExam) {
      message.error("Erro ao salvar as respostas, confira sua conexão de internet e tente novamente");
      setFinishing(false);
    }
  }, [endExam, loadingEndOnlineExam]);

  useEffect(() => {
    if(!start && !examSelected && examId) {
      getExamById();
    }
  }, [examId, exams, examSelected]);

  useEffect(() => {
    if (!seconds) return;

    const intervalId = setInterval(() => {
      setSeconds(seconds - 1);
      setTime(secondsToTime(seconds - 1));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds]);

  useEffect(() => {
    setFinalized(endExam);
    if (userOpen !== null) setTemporaryUserId(userOpen.userId);
    const query = new URLSearchParams(window.location.search);
    const newId = query.get("examId");
    setExamId(newId);

    if (
      error.message === "Você já terminou a prova" ||
      error.message === "Gabarito ainda não disponível"
    ) {
      setShowResultButton(true);
    }

    if (showResult && !redirected) {
      history.push(
        "/open-result-exam/individual?examId=" +
          examId +
          "&studentId=" +
          temporaryUserId +
          "&isOpen=1"
      );
      setRedirected(true);
    }
  });

  useEffect(() => {
    if (
      answers.length === 0 &&
      exams &&
      exams.selected &&
      exams.selected.Questions &&
      exams.selected.QuestionsAnswer &&
      !loadedExams
    ) {
      setLoadedExams(true);
      var questionsList = [];
      for (let i = 0; i < exams.selected.QuestionsAnswer.length; i++) {
        // eslint-disable-next-line
        exams.selected.Questions.map((e) => {
          if (exams.selected.QuestionsAnswer[i].QuestionId === e.Id) {
            // eslint-disable-next-line
            e.Alternatives.map((e) => {
              if (e.Order === exams.selected.QuestionsAnswer[i].Answer) {
                e.Correct = true;
              }
            });
            questionsList.push(e);
          }
        });
      }
      // setAnswers(exams.selected.Questions)
      setAnswers(questionsList);
    }
    setStart(startedExam);
    if (startedExam && !fetchExams) {
      setFetchExams(true);
      fetchOnlineExam(examId, userOpen.userId, isOpen);
    }

    if (exams.selected.TimeLeft && timer === false) {
      let times = exams.selected.TimeLeft.split(".")[0].split(":");
      setSeconds(
        parseInt(times[0]) * 60 * 60 +
          parseInt(times[1]) * 60 +
          parseInt(times[2])
      );
      setTimer(true);
    }
  });

  useEffect(() => {
    if (!(startedExam && !fetchExams) && loadingVideos && examId) {
      getVideosLoaded(examId);
    }
  }, [startedExam, fetchExams, loadingVideos, examId]);

  const secondsToTime = async (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisorForMinutes = secs % (60 * 60);
    let minutes = Math.floor(divisorForMinutes / 60);

    let divisorForSeconds = divisorForMinutes % 60;
    let seconds = Math.ceil(divisorForSeconds);

    if (hours === 0 && minutes === 0 && seconds === 0) {
      setFinishing(true);
      await endOnlineExam(examId, userOpen.userId, isOpen);
      setEndTime(true);
    }

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };

  const handleFinalization = async () => {
    const ans = answers.map((obj) => {
      for (let i = 0; i < obj.Alternatives.length; i++) {
        if (obj.Alternatives[i].Correct === true) {
          return true;
        }
      }
      return false;
    });
    console.log(ans);
    if (ans.includes(false)) {
      alert("Preencha todas as questões!");
    } else {
      setFinishing(true);
      await endOnlineExam(examId, userOpen.userId, isOpen);
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      goTo();
    }
  };

  const goTo = () => {
    provisoryNumberQuestion !== "-" &&
      provisoryNumberQuestion !== "" &&
      provisoryNumberQuestion > 0 &&
      provisoryNumberQuestion < answers.length &&
      setNumberQuestion(provisoryNumberQuestion);
  };

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return originalElement;
    } else if (type === "next") {
      return originalElement;
    } else {
      if (answers[originalElement.props.children - 1]) {
        for (
          let i = 0;
          i < answers[originalElement.props.children - 1].Alternatives.length;
          i++
        ) {
          if (
            answers[originalElement.props.children - 1].Alternatives[i]
              .Correct === true
          ) {
            return <PaginationItem>{originalElement}</PaginationItem>;
          }
        }
      }
    }
    return originalElement;
  };

  const examInfoRender = () => {
    if (examSelected) {
      return (
        <div>
          {examSelected.Title && <p>Título: {examSelected.Title}</p>}
          {examSelected.Instructions && <p>Instruções: {examSelected.Instructions}</p>}
          {examSelected.Duration && <p>Duração: {examSelected.Duration}</p>}
          {examSelected.StartAt && <p>Início: {examSelected.StartAt}</p>}
          {examSelected.StopAt && <p>Fim: {examSelected.StopAt}</p>}
        </div>
      );
    }
  };

  return (
    <Page style={{ display: "flex", alignItems: "center" }}>
      <Row>
        {exams.selected.Exam && exams.selected.Exam.LogoUrl && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={exams.selected.Exam.LogoUrl}
              alt="Logo"
              style={{ height: "64px" }}
            />{" "}
          </div>
        )}
      </Row>
      <Row>
        <Col>
          <Title>Prova Online</Title>
        </Col>
      </Row>
      {endTime ? (
        <div>O tempo de prova acabou. Suas respostas foram enviadas!</div>
      ) : finalized ? (
        <div
          style={{
            paddingTop: "24px",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
          }}
        >
          Prova Finalizada!
        </div>
      ) : loadingVideos ? (
        "Carregando... Atualize a página após alguns minutos."
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!start && exams && exams.selected && (
            <div>
              {examInfoRender()}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <StyledButton
                  style={{ marginRight: "12px" }}
                  onClick={() => goBack()}
                >
                  Voltar
                </StyledButton>
                <Spin spinning={loadingQuest}>
                  <StyledButton data-testid="iniciar" onClick={() => startTest()}>
                    Iniciar
                  </StyledButton>
                </Spin>
              </div>
            </div>
          )}
          {exams && start && answers[numberQuestion] && exams.resolutions ? (
            <div style={{ width: windowSize.width, maxWidth: "1200px" }}>
              {/* <div style={{ maxWidth: '80%' }}>
                  {exams.selected.Exam && exams.selected.Exam.Instructions && 'Instruções:' + exams.selected.Exam.Instructions}
                </div> */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {time && (
                  <div>
                    <StyledTitle>
                      Tempo Restante: {time.h}:{time.m}:{time.s}
                    </StyledTitle>
                  </div>
                )}
                <StyledButton disabled={savingSingleAnswer} onClick={() => handleFinalization()}>
                  Finalizar
                </StyledButton>
              </div>
              <br />
              <QuestionList key={numberQuestion}>
                <QuestionOnlineCard
                  onlyModal={false}
                  examId={answers[numberQuestion].Id}
                  numberQuestion={numberQuestion + 1}
                  correctAnswer={handleQuestions}
                  question={answers[numberQuestion]}
                  createView
                  isSelectable
                  checkAns={alternativeChecked}
                  loadingQuest={loadingQuest}
                  showAlternatives={showAlternative}
                />
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  size={6}
                >
                  <PaginationDiv>
                    <Pagination
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      current={numberQuestion + 1}
                      total={answers.length * 10}
                      itemRender={itemRender}
                      onChange={(e) => {
                        setProvisoryNumberQuestion(e - 1);
                        setNumberQuestion(e - 1);
                      }}
                    />
                    <div style={{ margin: "10px 0 0 10px" }}>
                      <StyledButton disabled={savingSingleAnswer} onClick={() => handleFinalization()}>
                        Finalizar
                      </StyledButton>
                    </div>
                  </PaginationDiv>
                  <div
                    style={{
                      paddingTop: "24px",
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "center",
                    }}
                  >
                    <StyledTitle>Página:</StyledTitle>
                    <StyledInputNumber
                      style={{ marginLeft: "8px" }}
                      onKeyDown={_handleKeyDown}
                      onChange={(e) => setProvisoryNumberQuestion(e - 1)}
                    />
                    <StyledButton style={{ marginLeft: "8px" }} onClick={goTo}>
                      Ir
                    </StyledButton>
                  </div>
                </Col>
              </QuestionList>
            </div>
          ) : start ? (
            fetchExams && !(error && error.message) ? (
              "Carregando..."
            ) : (
              "Prova não encontrada"
            )
          ) : (
            ""
          )}
        </div>
      )}
      {showResultButton || (end && finalized) ? (
        <div>
          <br />
          <Button onClick={getResult}>Gabarito da Prova</Button>
        </div>
      ) : (
        ""
      )}
    </Page>
  );
}

OpenExamPage.propTypes = {
  fetchOnlineExam: PropTypes.func,
  ansQuestion: PropTypes.func,
  startOnlineExam: PropTypes.func,
  endOnlineExam: PropTypes.func,
  fetchStudentIndividualResultExam: PropTypes.func,
  exams: PropTypes.object,
  startedExam: PropTypes.bool,
  showResult: PropTypes.bool,
  endExam: PropTypes.bool,
  error: PropTypes.object,
  userOpen: PropTypes.object,
  loadingQuest: PropTypes.bool,
  alternativeChecked: PropTypes.bool,
  loadingEndOnlineExam: PropTypes.bool,
};

const mapStateToProps = ({ exams }) => ({
  exams: exams,
  startedExam: exams.startedExam,
  showResult: exams.showResult,
  endExam: exams.endExam,
  error: exams.error,
  userOpen: exams.user,
  loadingQuest: exams.loading,
  alternativeChecked: exams.alternativeChecked,
  loadingVideos: exams.loadingVideos,
  loadingEndOnlineExam: exams.loadingEndOnlineExam,
  // loading: templates.loading
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchOnlineExam,
      ansQuestion,
      startOnlineExam,
      endOnlineExam,
      fetchStudentIndividualResultExam,
      getVideosLoaded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OpenExamPage);
