/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Modal } from 'antd'
import QuestionBlock from '../question/QuestionBlock'
import { QuestionsModalStyle, StyledButton } from './styles/Modal.styles'
const QuestionsModal = ({
  onShuffle,
  selected,
  questions,
  onCancel,
  onDelete,
  visible,
  examId,
  loading,
  createView = false,
  editView = true
}) => {
  const backButton = (
    <StyledButton
      htmlType='button'
      onClick={onCancel}
    >
      Voltar
    </StyledButton>
  )

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={backButton}
      css={QuestionsModalStyle}
      destroyOnClose
    >
      <QuestionBlock disableSelect loading={loading} deletable loadQuestions sufflable isSelectable={false} exams={[{ Id: examId, Questions: questions }]} selected={selected}
        pickedExams={selected} editable={false} editView={false} createView={false} onlyModal />
    </Modal>
  )
}

QuestionsModal.propTypes = {
  examId: PropTypes.string,
  selected: PropTypes.any,
  onShuffle: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  questions: PropTypes.array,
  loading: PropTypes.bool,
  createView: PropTypes.bool,
  editView: PropTypes.bool,
  visible: PropTypes.bool.isRequired
}

const mapStateToProps = ({ outputs, exams }) => ({
  examId: exams.id,
  hideWarnings: outputs.hideWarnings,
  loading: outputs.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsModal)
