/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearExamModule, fetchSelected } from "../store/exams/actions";
import { infoUser } from "../store/users/actions";
import { ChildButton, FloatingMenu } from "react-floating-button-menu";
import {
  ButtonHolder,
  FloatButtons,
} from "../pages/styles/SelectQuestionsPage.styles";
import * as Icons from "@material-ui/icons";

import QuestionBlock from "../components/question/QuestionBlock";

import history from "../routes/history";
import { updateExam } from "../store/exams/services";

import { Page } from "./styles/Page.styles";
import { Content } from "./styles/DetailsPage.styles";
import useWindowSize from "../utils/useWindowSize";
import { Spin, Tabs, Progress } from "antd";

function ListQuestionPage({
  examId,
  clearExamModule,
  infoUser,
  fetchSelected,
  edit,
  loading,
  loadingFetchSelected,
  editar,
  selected,
  answers,
  isSuperAdmin,
}) {
  const [selectedExams, setSelectedExams] = useState([]);
  const [tags, setTags] = useState([]);
  const [loadQuestions, setLoadQuestions] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [saveAll, setSaveAll] = useState(false);
  const [progress, setProgress] = useState(0);
  const [scholarship, setScholarship] = useState(null);
  const windowSize = useWindowSize();

  function changeElementByIndex(array, newElement, chooseIndex) {
    return array.map((element, index) => {
      if (index.toString() === chooseIndex) {
        element.Questions = newElement;
      }
      return element;
    });
  }

  function handleSuffleQuestions(array) {
    let newArray = extractQuestions(array);
    setSelectedExams(changeElementByIndex(selectedExams, newArray, tabIndex));
  }

  function removeModal(array) {
    setSelectedExams(changeElementByIndex(selectedExams, array, tabIndex));
  }

  useEffect(() => {
    infoUser();
    clearExamModule();
  }, []);

  useEffect(() => {
    if (
      !loadingFetchSelected &&
      (selected === undefined ||
        selected[0] === undefined ||
        selected[0].Questions === undefined ||
        selected[0].Total !== selected[0].Processed)
    ) {
      setTimeout(() => {
        let url = new URL(window.location.href);
        let urlExamId = url.searchParams.getAll("examId");
        if (urlExamId) {
          fetchSelected(urlExamId);
        }
      }, 4000);
    }
  }, [loadingFetchSelected]);

  useEffect(() => {
    if (
      !isSuperAdmin &&
      selected &&
      selected[0] &&
      selected[0].IsSimulieDatabase
    ) {
      history.push("/list-exams");
    }
  }, [isSuperAdmin, selected]);

  useEffect(() => {
    if (
      selected !== undefined &&
      selected[0] !== undefined &&
      selected[0].Questions !== undefined &&
      selected[0].Questions.length > 0
    ) {
      if (selected[0].Total === selected[0].Processed) {
        setLoadQuestions(true);
      }

      if (selected[0].Tags !== null && selected[0].Tags !== undefined) {
        setTags([...tags, ...selected.map((s) => s.Tags)]);
      }

      if (selected !== selectedExams) {
        console.log(selectedExams);
        setSelectedExams(selected);
      }

      if (selected.length > 1) {
        setSaveAll(true);
      }
    }

    if (selected && selected.length > 0 && selected[0].Total) {
      let processed = selected.reduce((a, b) => a + b.Processed, 0);
      let total = selected.reduce((a, b) => a + b.Total, 0);

      let percent = Math.ceil((100 * processed) / total);

      const limit = 0;
      if (percent > limit) {
        setProgress(percent);
      } else {
        if (progress < limit) {
          setProgress(progress + 1);
        }
      }
    }
  }, [selected]);

  useEffect(() => {
    if (
      selectedExams !== undefined &&
      selectedExams.length > 0 &&
      selectedExams[0].Questions !== undefined &&
      selectedExams[0].Questions.length > 0
    ) {
      const firstQuestion = selectedExams[0].Questions[0];
      if (firstQuestion && firstQuestion.Scholarship !== undefined) {
        setScholarship(firstQuestion.Scholarship);
        console.log("Scholarship:", scholarship)
      }
    }
  }, [selectedExams]);

  function extractQuestions(array) {
    let newArray = [];
    if (array) {
      for (let i = 0; i < array.length; i++) {
        newArray.push(array[i].question);
      }
    }

    return newArray;
  }

  function updateSelected(array) {
    console.log(array);
  }

  function updateCorrectAnswer(question) {
    let updateSelectedQuestions = selectedExams[tabIndex].Questions.map(
      (quest) => {
        if (quest.Id === question.Id) {
          return question;
        }
        return quest;
      }
    );

    console.log(updateSelectedQuestions);
    setSelectedExams(
      changeElementByIndex(selectedExams, updateSelectedQuestions, tabIndex)
    );
  }

  const toggleModalSave = () => {
    console.log(selectedExams);

    let exams = selectedExams.map((questions, selectedIndex) => {
      let questionsList = questions.Questions.map((qs, qsIndex) => {
        return {
          Id: qs.Id,
          Number: qsIndex + 1,
          Alternatives: qs.Alternatives,
          Statement: qs.Statement,
          Type:
            qs.Type !== undefined ? qs.Type.replace(" ", "") : "MultipleChoice",
          Status: "Active",
          Tags: qs.Tags,
        };
      });
      console.log(questionsList);

      return {
        Title: selected[selectedIndex].Title,
        Questions: questionsList,
        Tags: selected[selectedIndex].Tags,
      };
    });

    console.log(exams);

    exams.map(async (exam, index) => {
      await updateExam(selectedExams[index].Id, exam);
    });
    history.push("/list-exams");
  };

  function updateQuestionType(question, type) {
    if (question !== "undefined") {
      let updateSelectedQuestions = selectedExams[tabIndex].Questions.map(
        (quest) => {
          if (quest.Id === question.Id) {
            return { ...quest, Type: type };
          }
          return quest;
        }
      );

      setSelectedExams(
        changeElementByIndex(selectedExams, updateSelectedQuestions, tabIndex)
      );
    }
  }

  function updateTitle(title) {
    let updateSelectedQuestions = selectedExams.map((e, index) => {
      if (tabIndex === index.toString()) {
        e.Title = title;
      }
      return e;
    });

    setSelectedExams(updateSelectedQuestions);
  }

  function updateTagsExam(tag) {
    console.log(tag);
    let updateSelectedQuestions = selectedExams.map((e, index) => {
      if (tabIndex === index.toString()) {
        if (e.Tags && e.Tags.length > -1) {
          e.Tags = [...e.Tags, tag];
        } else {
          e.Tags = [tag];
        }
      }
      return e;
    });

    setSelectedExams(updateSelectedQuestions);
  }

  const { TabPane } = Tabs;

  return (
    <Page>
      {answers && answers["Answers"] ? (
        <p style={{ textAlign: "center" }}>
          Gabarito:{" "}
          {answers["Answers"].map((value, idx) => `${idx + 1}: ${value}; `)}
        </p>
      ) : null}
      {((selected && selected.length > 0) ||
        (selectedExams && selectedExams.length > 0)) && (
          <Content
            style={{
              display: "flex",
              justifyContent: "center",
              width: windowSize,
              maxWidth: "976px",
            }}
          >
            {selectedExams.map((s, index) => (
              <QuestionBlock
                isSuperAdmin={isSuperAdmin}
                loading={loading}
                selectedExams={selectedExams[index].Questions}
                loadQuestions
                answers={answers}
                stillLoading={!loadQuestions}
                tags={s.Tags ? s.Tags : []}
                exam={s}
                onShuffle={handleSuffleQuestions}
                changeTypeInline
                examId={s.Id}
                updateSelected={updateSelected}
                updateCorrectAnswer={updateCorrectAnswer}
                updateType={updateQuestionType}
                updateTitle={updateTitle}
                removeModal={removeModal}
                showSave={!saveAll}
                updateTagsExam={updateTagsExam}
                exams={[s]}
                pickedExams={s}
                editable={edit}
                editView
                sufflable
                createView={false}
                scholarship={scholarship}
                view
              />
            ))}
          </Content>
        )}
      <Spin spinning={!loadQuestions} tip="Carregando...">
        {saveAll && (
          <FloatButtons>
            <ButtonHolder>
              <FloatingMenu
                slideSpeed={500}
                direction="right"
                spacing={8}
                isOpen
              >
                <ChildButton
                  icon={<Icons.Save style={{ fontSize: 20, color: "white" }} />}
                  background="green"
                  onClick={toggleModalSave}
                  size={60}
                />
              </FloatingMenu>
            </ButtonHolder>
          </FloatButtons>
        )}
      </Spin>
      {!loadQuestions && (
        <div style={{ width: "256px", alignSelf: "center" }}>
          <Progress percent={progress} />
        </div>
      )}
    </Page>
  );
}

ListQuestionPage.propTypes = {
  examId: PropTypes.string,
  edit: PropTypes.bool,
  editar: PropTypes.bool,
  selected: PropTypes.array,
  answers: PropTypes.array,
  loading: PropTypes.bool,
  loadingFetchSelected: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  clearExamModule: PropTypes.func,
  infoUser: PropTypes.func,
  fetchSelected: PropTypes.func,
};

const mapStateToProps = ({ exams, auth }) => ({
  examId: exams.id,
  edit: exams.editQuestions,
  selected: exams.selected,
  answers: exams.answers,
  updateId: exams.updateId,
  editar: exams.editar,
  loading: exams.loading,
  loadingFetchSelected: exams.loadingFetchSelected,
  resolutions: exams.resolutions,
  isSuperAdmin:
    auth.loggedUser.Role === "SuperUser" || auth.loggedUser.Role === "Super",
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      infoUser,
      clearExamModule,
      fetchSelected,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListQuestionPage);
