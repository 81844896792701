import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  video: {
    width: '100%',
    overflow: "hidden",
    paddingBottom: "56.25%",
    position: "relative",
    height: 0,
    "& > iframe": {
      left: 0,
      top: 0,
      height: "100%",
      width: "100%",
      position: "absolute",
    },
  },
}));

const YoutubeEmbed = ({ embedId }) => {
  const classes = useStyles();

  return (
    <div className={classes.video}>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}?loop=1&listType=playlist&rel=0`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Youtube"
      />
    </div>
  );
};

export default YoutubeEmbed;
