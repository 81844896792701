import React from "react";
import {
    TableCell,
    TableRow,
  } from "@material-ui/core";
import CustomizedProgressBars from "../../progress/Progress";

const ProgressBar = ({area, fields, labels, style, sizeResult, countResult, conditionalFields, checkedFieldColors }) => {

  return (
    <TableRow key={"last"}>
      <TableCell
        component="th"
        scope="row"
        padding="none"
        className={style}
      ></TableCell>
      {(fields).map(
        (f) =>
          !conditionalFields.includes(f) && (
            <TableCell
              padding="none"
              className={style}
              size="small"
              align="center"
            >
              <CustomizedProgressBars
                consolidacaoValue={sizeResult}
                label
                labelBottom={""}
                vertical
                tooltip={labels[f]}
                color={checkedFieldColors[f]}
                progressHeight={64}
                percent={
                  countResult[area][f] / sizeResult
                }
                rawNumber={countResult[area][f]}
                position="center"
                showRawNumber
              />
            </TableCell>
          )
      )}
    </TableRow>
  );
};

export default ProgressBar;
