import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import history from '../routes/history'

const mapStateToProps = ({ auth }) => ({
  isLogged: auth.isLogged,
  loggedUser: auth.loggedUser
})

class PublicPage extends Component {
  static propTypes = {
    isLogged: PropTypes.bool,
    loggedUser: PropTypes.object
  }

  componentDidMount () {
    // console.log('isLogged', this.props.isLogged)
    this.props.isLogged
      ? (this.props.loggedUser ? history.push('/list-exams') : '')
      : history.push('/login')
  }

  render () {
    return (
      <Fragment />
    )
  }
}

export default connect(mapStateToProps)(PublicPage)
