import styled from '@emotion/styled'

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: auto;
  margin-bottom: 5px;
`

export const TotalQuestions = styled.div`
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  color: #3577EF;
`

export const TotalQuestionsSelected = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  font-weight: bold;
  color: darkgreen;
`
export const Shuffle = styled.div`
 margin-bottom: 15px
`

export const DisplayStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: #3577EF !important;
`

export const Item = styled.div`
  display: flex;
  justify-content: left;

  .ant-select-selection {
    height: 36px;
  }

/*
  .ant-select-selection {
    border-radius: 20px;
    width: 400px;
  }

  .ant-btn-round {
    font-size: 14px;
    font-weight: bold;
  }
  */
`

export const Col = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SelectedDiv = styled.div`
  height: 32px;
  display: flex;
  justify-content: right;
  align-items: center;
  max-width: 490px;
  
`

export const FloatText = styled.div`
  position: fixed;
  bottom: 64px;
  right: 32px;
`
