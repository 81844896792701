import React from 'react'
import PropTypes from 'prop-types'
import { StyledButton } from './styles/SubmitButton.styles'

const SubmitButton = props => {
  return (
    <StyledButton
      type='primary'
      shape='round'
      htmlType='submit'
      {...props}
    >
      {props.children}
    </StyledButton>
  )
}

SubmitButton.propTypes = {
  children: PropTypes.node.isRequired
}

export default SubmitButton
