import styled from '@emotion/styled'

export const Container = styled.div`
  background-color: #fff;
  padding: 2rem;
  margin-bottom: 1rem;
  border: 1px solid #d7e1f8;
`

export const Label = styled.p`
  font-size: 1rem;
`

export const Wording = styled.div`
  color: #000 !important;
  font-family: ${props => props.font || 'Arial'};
  font-size: ${props => props.fontSize || 12}pt;
`

export const Heading = styled.div`
  margin-bottom: 1em;
  margin-top: ${props => props.spacingBefore || 12}pt;
  margin-bottom: ${props => props.spacingAfter || 0}pt;
  padding-left: ${props => props.indentation || 0}cm;
  text-indent: -${props => props.indentation || 0}cm;
`

export const Question = styled.span`
`

export const Alternative = styled.p`
  margin: 0;
  margin-left: ${props => props.indentation || 0}cm;
`
