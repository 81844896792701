import { css } from '@emotion/core'

export const IconStyle = css`
  cursor: pointer;
`

export const UploadImageIconStyle = css`
  ${IconStyle};
  color: #808080;
`

export const UpIconStyle = css`
  ${IconStyle};
  color: #808080;
`

export const DownIconStyle = css`
  ${IconStyle};
  color: #808080;
  `

export const DeleteIconStyle = css`
  ${IconStyle};
  color: #808080;
`

export const AddIconStyle = css`
  ${IconStyle};
  color: #808080;
`
