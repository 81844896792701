import React from 'react'
import { render } from 'react-dom'
import { ErrorBoundary } from './utils/sentry'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
// import * as serviceWorker from './serviceWorker'
import { LocaleProvider } from 'antd'
import ptBR from 'antd/lib/locale-provider/pt_BR'
import 'moment/locale/pt-br'
import { store, persistor } from './store/configureStore'
import { staleCache } from './utils/cache'
import yupConfig from './config/yupConfig'
import App from './App'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { createTheme, MuiThemeProvider } from '@material-ui/core'

// Config
staleCache()
yupConfig()

const theme = createTheme({
  palette: {
    primary: {
      main: '#514fff'
    },
  }
});

render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <LocaleProvider locale={ptBR}>
          <MuiThemeProvider theme={theme}>
            <App />
          </MuiThemeProvider>
        </LocaleProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
)

// serviceWorker.register()
// serviceWorker.unregister()
