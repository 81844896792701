import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { renderQuestionNumber, renderQuestionPosition, renderQuestionWarning } from '../../utils/questionHelpers'
import get from 'lodash/get'
import { setActiveWarningIndex } from '../../store/revisions/actions'

import { Button, Tabs } from 'antd'
import ContainerCard from '../card/ContainerCard'
import { StyledWarningsNav, WarningLabel, WarningType } from './styles/DocumentWarnings.styles'

const renderLeftButton = (activeKey, setActiveWarningIndex) => {
  const prevKey = Math.max(Number(activeKey) - 1, 0)
  return (
    <Button
      disabled={Number(activeKey) === 0}
      icon='left'
      shape='circle'
      size='large'
      type='primary'
      onClick={() => setActiveWarningIndex(prevKey)}
    />
  )
}

const renderRightButton = (activeKey, setActiveWarningIndex, warnings) => {
  const nextKey = Math.min(Number(activeKey) + 1, warnings.length - 1)
  return (
    <Button
      disabled={Number(activeKey) === warnings.length - 1}
      icon='right'
      shape='circle'
      size='large'
      type='primary'
      onClick={() => setActiveWarningIndex(nextKey)}
    />
  )
}

const renderWarningTabs = warnings => {
  return warnings.map((warning, i) => {
    const {
      position,
      questionNumber,
      warnings
    } = warning

    return (
      <Tabs.TabPane
        key={i}
        tab={i}
      >
        <ContainerCard>
          <WarningLabel>Questão {renderQuestionNumber(questionNumber)}</WarningLabel>
          {position && `O problema está ${renderQuestionPosition(position)}.`}
          {warnings.map((warning, i) => <WarningType key={i}>{renderQuestionWarning(warning)}</WarningType>)}
        </ContainerCard>
      </Tabs.TabPane>
    )
  })
}

const WarningsNav = ({
  activeWarningIndex,
  setActiveWarningIndex,
  warnings
}) => {
  if (!warnings) return null

  return (
    <StyledWarningsNav>
      {renderLeftButton(activeWarningIndex, setActiveWarningIndex)}
      <Tabs activeKey={activeWarningIndex.toString()}>
        {renderWarningTabs(warnings)}
      </Tabs>
      {renderRightButton(activeWarningIndex, setActiveWarningIndex, warnings)}
    </StyledWarningsNav>
  )
}

WarningsNav.propTypes = {
  activeWarningIndex: PropTypes.number.isRequired,
  setActiveWarningIndex: PropTypes.func.isRequired,
  warnings: PropTypes.array
}

const mapStateToProps = ({ documents, revisions }) => ({
  activeWarningIndex: revisions.activeWarningIndex,
  warnings: get(documents, 'validation.questionSummary.warnings')
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setActiveWarningIndex
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WarningsNav)
