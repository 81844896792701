import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  STUDENT_LOGIN,
  STUDENT_LOGIN_SUCCESS,
  STUDENT_LOGIN_FAILURE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  LOGOUT,
  LOGGED_USER,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_STUDENT,
  REGISTER_STUDENT_SUCCESS,
  REGISTER_STUDENT_FAILURE,
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAILURE,
  INACTIVATE_USER,
  INACTIVATE_USER_SUCCESS,
  INACTIVATE_USER_FAILURE,
  REJECT_USER,
  REJECT_USER_SUCCESS,
  REJECT_USER_FAILURE,
  INVITE_USERS,
  INVITE_USERS_SUCCESS,
  INVITE_USERS_FAILURE,
  INVITE_STUDENT,
  INVITE_STUDENT_SUCCESS,
  INVITE_STUDENT_FAILURE,
  RESEND_EMAIL,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAILURE,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHECKOUT_PAYMENT,
  CHECK_PAYMENT_SUCCESS,
  CHECK_PAYMENT_FAILURE,
  GET_ROLES,
  GET_ROLES_FAILURE,
  GET_ROLES_SUCCESS,
  CREATE_CLASSES,
  CREATE_CLASSES_FAILURE,
  CREATE_CLASSES_SUCCESS,
  GET_CLASSES,
  GET_CLASSES_FAILURE,
  GET_CLASSES_SUCCESS,
  MANAGE_LOGO,
  MANAGE_LOGO_SUCCESS,
  MANAGE_LOGO_FAILURE
} from './constants'

export const login = credentials => ({
  type: LOGIN,
  payload: credentials
})

export const loginSuccess = user => ({
  type: LOGIN_SUCCESS,
  payload: user
})

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: error
})

export const studentLogin = credentials => ({
  type: STUDENT_LOGIN,
  payload: credentials
})

export const studentLoginSuccess = user => ({
  type: STUDENT_LOGIN_SUCCESS,
  payload: user
})

export const studentLoginFailure = error => ({
  type: STUDENT_LOGIN_FAILURE,
  payload: error
})

export const signup = credentials => ({
  type: SIGNUP,
  payload: credentials
})

export const signupSuccess = user => ({
  type: SIGNUP_SUCCESS,
  payload: user
})

export const signupFailure = error => ({
  type: SIGNUP_FAILURE,
  payload: error
})

export const logout = () => ({
  type: LOGOUT
})

export const loggedUserSuccess = loggedUser => ({
  type: LOGGED_USER,
  payload: loggedUser
})

export const manageLogo = logoRequest => ({
  type: MANAGE_LOGO,
  payload: logoRequest,
})

export const manageLogoSuccess = logoInfo => ({
  type: MANAGE_LOGO_SUCCESS,
  payload: logoInfo
})

export const manageLogoFailure = error => ({
  type: MANAGE_LOGO_FAILURE,
  payload: error
})

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
})

export const logoutFailure = error => ({
  type: LOGOUT_FAILURE,
  payload: error
})

export const register = user => ({
  type: REGISTER,
  payload: user
})

export const registerSuccess = () => ({
  type: REGISTER_SUCCESS
})

export const registerFailure = error => ({
  type: REGISTER_FAILURE,
  payload: error
})

export const registerStudent = user => ({
  type: REGISTER_STUDENT,
  payload: user
})

export const registerStudentSuccess = () => ({
  type: REGISTER_STUDENT_SUCCESS
})

export const registerStudentFailure = error => ({
  type: REGISTER_STUDENT_FAILURE,
  payload: error
})

export const activateUser = id => ({
  type: ACTIVATE_USER,
  payload: id
})

export const activateUserSuccess = () => ({
  type: ACTIVATE_USER_SUCCESS
})

export const activateUserFailure = error => ({
  type: ACTIVATE_USER_FAILURE,
  payload: error
})

export const inactivateUser = id => ({
  type: INACTIVATE_USER,
  payload: id
})

export const inactivateUserSuccess = () => ({
  type: INACTIVATE_USER_SUCCESS
})

export const inactivateUserFailure = error => ({
  type: INACTIVATE_USER_FAILURE,
  payload: error
})

export const rejectUser = id => ({
  type: REJECT_USER,
  payload: id
})

export const rejectUserSuccess = () => ({
  type: REJECT_USER_SUCCESS
})

export const rejectUserFailure = error => ({
  type: REJECT_USER_FAILURE,
  payload: error
})

export const inviteUsers = users => ({
  type: INVITE_USERS,
  payload: users
})

export const inviteUsersSuccess = () => ({
  type: INVITE_USERS_SUCCESS
})

export const inviteUsersFailure = error => ({
  type: INVITE_USERS_FAILURE,
  payload: error
})

export const inviteStudent = users => ({
  type: INVITE_STUDENT,
  payload: users
})

export const inviteStudentSuccess = () => ({
  type: INVITE_STUDENT_SUCCESS
})

export const inviteStudentFailure = error => ({
  type: INVITE_STUDENT_FAILURE,
  payload: error
})

export const getRoles = () => ({
  type: GET_ROLES
})

export const getRolesSuccess = () => ({
  type: GET_ROLES_SUCCESS
})

export const getRolesFailure = error => ({
  type: GET_ROLES_FAILURE,
  payload: error
})

export const createClasses = payload => ({
  type: CREATE_CLASSES,
  payload: payload
})

export const createClassesSuccess = () => ({
  type: CREATE_CLASSES_SUCCESS
})

export const createClassesFailure = () => ({
  type: CREATE_CLASSES_FAILURE
})

export const getClasses = payload => ({
  type: GET_CLASSES,
  payload: payload
})

export const getClassesSuccess = payload => ({
  type: GET_CLASSES_SUCCESS,
  payload: payload.Classes
})

export const getClassesFailure = () => ({
  type: GET_CLASSES_FAILURE
})

export const resendEmail = email => ({
  type: RESEND_EMAIL,
  payload: email
})

export const resendEmailSuccess = () => ({
  type: RESEND_EMAIL_SUCCESS
})

export const resendEmailFailure = error => ({
  type: RESEND_EMAIL_FAILURE,
  payload: error
})

export const recoverPassword = email => ({
  type: RECOVER_PASSWORD,
  payload: email
})

export const recoverPasswordSuccess = () => ({
  type: RECOVER_PASSWORD_SUCCESS
})

export const recoverPasswordFailure = error => ({
  type: RECOVER_PASSWORD_FAILURE,
  payload: error
})

export const changePassword = email => ({
  type: CHANGE_PASSWORD,
  payload: email
})

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS
})

export const changePasswordFailure = error => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload: error
})

export const checkoutPayment = sessionId => ({
  type: CHECKOUT_PAYMENT,
  payload: sessionId
})

export const checkPaymentSuccess = () => ({
  type: CHECK_PAYMENT_SUCCESS
})

export const checkPaymentFailure = error => ({
  type: CHECK_PAYMENT_FAILURE,
  payload: error
})