/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import ValidatedSharedInput from './ValidatedSharedInput'
import { FormInput } from './styles/Input.styles'

class PasswordInput extends Component {
  state = {
    visible: false
  }

  static propTypes = {
    label: PropTypes.string,
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    HelpComponent: PropTypes.func,
    placeholder: PropTypes.string
  }

  render () {
    const {
      visible
    } = this.state
    const {
      label,
      field,
      form,
      HelpComponent,
      placeholder
    } = this.props

    const isTouched = form.touched[field.name]
    const isError = form.errors[field.name]

    return (
      <ValidatedSharedInput
        touched={isTouched}
        error={isError}
        label={label}
        HelpComponent={HelpComponent}
      >
        <FormInput
          className='password-input'
          prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
          suffix={this.suffix(isTouched && isError)}
          placeholder={placeholder || 'Senha'}
          type={visible ? 'text' : 'password'}
          autoComplete='new-password'
          {...field}
        />
      </ValidatedSharedInput>
    )
  }

  suffix = hasError => {
    return this.props.field.value
      ? <Icon
        type={this.state.visible ? 'eye' : 'eye-invisible'}
        onClick={this.toggleVisibility}
        onMouseEnter={() => this.toggleVisibility(true)}
        onMouseLeave={() => this.toggleVisibility(false)}
        style={{ cursor: 'pointer', marginRight: hasError && '1rem' }}
      />
      : null
  }

  toggleVisibility = visibility => {
    const visible = typeof visibility === 'boolean'
      ? visibility
      : !this.state.visible
    this.setState({ visible })
  }
}

export default PasswordInput
