import React from 'react'
import PropTypes from 'prop-types'

import ValidatedSharedInput from './ValidatedSharedInput'
import { InputNumber } from 'antd'
import { Container, Label, Icon } from './styles/TemplateSubGroupInput.styles'

import spacingBeforeIcon from '../../assets/spacing-before.svg'

const TemplateSpacingBeforeQuestionInput = ({
  label,
  field,
  form,
  HelpComponent
}) => {
  return (
    <ValidatedSharedInput
      touched={form.touched[field.name]}
      error={form.errors[field.name]}
      label={label}
      HelpComponent={HelpComponent}
    >
      <Container>
        <Icon component={spacingBeforeIcon} />
        <Label>Antes: </Label>
        <InputNumber
          className='template-spacing-before-question-input'
          formatter={value => `${Number(value).toFixed(2)} pt`}
          defaultValue={12}
          maxLength={8}
          max={36}
          min={0}
          parser={value => value.replace(/[^0-9.]/g, '')}
          onChange={value => form.setFieldValue(field.name, value.toString())}
          value={field.value ? Number(field.value) : 0}
        />
      </Container>
    </ValidatedSharedInput>
  )
}

TemplateSpacingBeforeQuestionInput.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  HelpComponent: PropTypes.func
}

export default TemplateSpacingBeforeQuestionInput
