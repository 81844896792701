import createReducer from '../createReducer'
import {
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,
  EDIT_COMPANY,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE,
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,
  FETCH_COMPANY_GRADES,
  FETCH_COMPANY_GRADES_SUCCESS,
  FETCH_COMPANY_GRADES_FAILURE,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE
} from './constants'

const initialState = {
  id: null,
  file: null,
  query: {},
  validation: {},
  companiesList: {},
  company: {},
  loading: false,
  uploading: false,
  error: {},
  grades: []
}

export default createReducer(initialState, {
  [CREATE_COMPANY]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [CREATE_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [CREATE_COMPANY_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [EDIT_COMPANY]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [EDIT_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [EDIT_COMPANY_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [FETCH_COMPANY]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [FETCH_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    company: action.payload,
    loading: false,
    error: initialState.error
  }),
  [FETCH_COMPANY_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [FETCH_COMPANY_GRADES]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [FETCH_COMPANY_GRADES_SUCCESS]: (state, action) => ({
    ...state,
    grades: action.payload,
    loading: false,
    error: initialState.error
  }),
  [FETCH_COMPANY_GRADES_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [FETCH_COMPANIES]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [FETCH_COMPANIES_SUCCESS]: (state, action) => ({
    ...state,
    companiesList: action.payload,
    loading: false,
    error: initialState.error
  }),
  [FETCH_COMPANIES_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [DELETE_COMPANY]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [DELETE_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [DELETE_COMPANY_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  })
})
