/* eslint-disable */
import React from "react";

import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  gabaritoText: {
    marginTop: 8,
    marginBottom: 16,
    width: "100%",
    maxWidth: 728,
    display: "flex",
    flexDirection: "column",
  },
  button: {
    marginTop: 8,
  },
  inputLabel: {
    background: "#fff",
    padding: "0 8px", 
  },
}));

function UploadGabarito({ name, value, handleChange, handleButton }) {
  const classes = useStyles();

  return (
    <div className={classes.gabaritoText}>
      <Paper>
        <FormControl fullWidth variant="outlined">
          <InputLabel
            htmlFor="outlined-adornment-amount"
            className={classes.inputLabel}
          >
            Gabarito (cole o gabarito): {name}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={value}
            onChange={(event) => handleChange(event, name)}
            labelWidth={60}
            multiline
            rows={6}
            maxRows={15}
          />
        </FormControl>
      </Paper>
    </div>
  );
}

export default UploadGabarito;
