export const cacheVersion = 9

export const staleCache = (version = cacheVersion) => {
  clearCache().then(() => localStorage.setItem('cacheVersion', version))
}

const clearCache = () => {
  return Promise.all([
    // localStorage.clear(),
    // sessionStorage.clear(),
    indexedDB.deleteDatabase('Dexie'),
    unregisterAllServiceWorkers(),
    deleteAllCookies(),
    clearCacheStorages()
  ])
}

const unregisterAllServiceWorkers = () => {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker
      .getRegistrations()
      .then(registrations => registrations.map(r => r.unregister()))
  } else {
    return false
  }
}

const deleteAllCookies = () => {
  var cookies = document.cookie.split(';')

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i]
    var eqPos = cookie.indexOf('=')
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}

const clearCacheStorages = () => {
  if ('caches' in window) {
    if (window.caches !== undefined) {
      return window.caches.keys()
        .then(keys => keys.map(key => caches.delete(key)))
    } else {
      return false
    }
  } else {
    return false
  }
}
