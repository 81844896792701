import styled from '@emotion/styled'

export const Content = styled.div`
  width: 100%;
  max-width: 976px;
  margin: 0 auto;
`

export const BackLink = styled.a`
  display: flex;
  margin-bottom: 15px; 
`
export const FloatButtons = styled.div`
  position: fixed;
  bottom: -2px;
  right: 10px;
`
export const ButtonHolder = styled.div`
  padding: 10px 20px;
`
export const Filters = styled.div`
  display: flex;
  flex-direction: row;
`

export const FilterItem = styled.div`
  flex: 1;
  color: #000;
`
