/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import history from '../../routes/history'
import { deleteCompany, fetchCompanies } from '../../store/companies/actions'

import ActionList from '../../components/list/ActionList'
import ContainerCard from '../../components/card/ContainerCard'

import { List, Spin, Tooltip } from 'antd'
import { StyledButton } from '../../components/modal/styles/Modal.styles'
import {
  Row,
  Col,
  Header,
  Empty,
  Pagination,
  Search,
  HeaderRight,
  StyledListItem
} from '../styles/ListExamsPage.styles'

import { Page } from '../styles/Page.styles'
import { Content } from '../styles/DetailsPage.styles'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))

function ListCompaniesPage ({ fetchCompanies, companies, total, loading, deleteCompany }) {
  let [search, setSearch] = useState('')
  let [current, setCurrent] = useState(1)
  const classes = useStyles()

  useEffect(() => {
    fetchCompanies('', current)
  }, [current])

  function handleEditAction (companyId) {
    history.push(`/companies/${companyId}/edit`)
  }

  async function handleDeleteAction (companyId) {
    await deleteCompany(companyId)
  }

  function handleNewAction (examId) {
    history.push('/companies/new')
  }

  const renderSearch = type => (
    <Search
      type={type}
      onChange={e => setSearch(e.target.value)}
      onSearch={search => fetchCompanies(search, 1)}
      placeholder='Buscar'
      value={search}
    />
  )

  const novaEmpresa = (name) => (
    <HeaderRight>
      <StyledButton type='primary'
        onClick={handleNewAction}
        shape='round'
        icon='new'
      > {name}
      </StyledButton>
    </HeaderRight>
  )

  const header = (
    <Row>
      <Col size={6}><Header>NOME</Header></Col>
      <Col size={2}><Header>AÇÕES</Header></Col>
    </Row>
  )

  const renderName = name => (
    <Tooltip
      title={name}
      placement='topLeft'
    >
      {name}
    </Tooltip>
  )

  const renderActions = item => item && (
    <ActionList
      onEdit={() => handleEditAction(item.Id)}
      onDelete={() => handleDeleteAction(item.Id)}
    />
  )

  const renderItem = item => (
    <StyledListItem>
      <Row>
        <Col size={6}>{renderName(item.Name)}</Col>
        <Col size={2} >{renderActions(item)}</Col>
      </Row>
    </StyledListItem>
  )

  const onChangePagination = e => {
    fetchCompanies(search, e)
    setCurrent(e)
  }

  const pagination = (
    <Pagination
      current={current}
      total={total}
      onChange={onChangePagination}
    />
  )

  const empty = (
    <Empty
      description='Nenhuma empresa encontrada'
      image={Empty.PRESENTED_IMAGE_DEFAULT}
    />
  )

  return (
    <Page>
      <Content style={{ width: '100%', maxWidth: '1000px' }}>
        <Row>
          <Col size={6} className={classes.sectionDesktop}>
            {renderSearch('desktop')}
          </Col>
          <Col style={{ width: '100%' }} className={classes.sectionMobile}>
            {renderSearch('mobile')}
          </Col>
          <Col size={2}>
            {novaEmpresa('Nova Empresa')}
          </Col>
        </Row>
        <br />
        <Spin spinning={loading}>
          {header}

          <ContainerCard
            padding={0}
            shadowYOffset={5}
          >
            <List
              dataSource={companies}
              locale={{ emptyText: empty }}
              renderItem={renderItem}
            />

          </ContainerCard>
        </Spin>

        {pagination}
      </Content>
    </Page>

  )
}

ListCompaniesPage.propTypes = {
  fetchCompanies: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
  companies: PropTypes.array,
  loading: PropTypes.bool,
  total: PropTypes.number
}

const mapStateToProps = ({ companies }) => ({
  companies: companies.companiesList.companies,
  total: companies.companiesList.total,
  loading: companies.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchCompanies,
    deleteCompany
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListCompaniesPage)
