import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as sentry from '@sentry/browser'
import { PRODUCTION } from './constants'

// const key = '2a0cd27b318b45b7ba6183888a8cc2fb'
// const project = '1398507'
// const dsn = `https://${key}@sentry.io/${project}`

PRODUCTION

export class ErrorBoundary extends Component {
  state = {
    error: null
  }

  static propTypes = {
    children: PropTypes.node
  }

  componentDidCatch (error, info) {
    this.setState({ error })
    sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key])
      })
      sentry.captureException(error)
    })
  }

  render () {
    const { error } = this.state
    const { children } = this.props

    return error
      ? <a href='/' onClick={this.handleReportFeedback}>Relatar Problema</a>
      : children
  }

  handleReportFeedback = e => {
    e.preventDefault()
    sentry.showReportDialog()
  }
}

export default sentry
