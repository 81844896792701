import styled from '@emotion/styled'
import { Icon as AntdIcon } from 'antd'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Label = styled.div`
  font-size: .9rem;
  width: 11em;
`

export const Icon = styled(AntdIcon)`
  font-size: 1.3rem;
  margin-right: 0.3rem;
`
