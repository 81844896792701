import React from "react";

import { MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";

const GradesSelect = ({ user, userGrades, grade, grades, handleGrade, style }) => {
  const filterGrade = (gs) => {
    if (user && user.Role === "Teacher") {
      return gs.filter((g) => userGrades.includes(g));
    }
    return gs;
  };

  return (
    <FormControl fullWidth className={style}>
      <InputLabel>Selecione a Turma</InputLabel>
      <Select fullWidth value={grade} onChange={handleGrade}>
        {filterGrade(grades).map((d) => (
          <MenuItem value={d}>{d}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GradesSelect;
