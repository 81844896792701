import createReducer from '../createReducer'
import {
  FETCH_FILES,
  FETCH_FILES_SUCCESS,
  FETCH_FILES_FAILURE,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAILURE,
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE
} from './constants'

const initialState = {
  item: null,
  items: [],
  query: {},
  loading: false,
  error: {}
}

export default createReducer(initialState, {
  [FETCH_FILES]: (state, action) => ({
    ...state,
    query: action.payload,
    loading: true,
    error: initialState.error
  }),
  [FETCH_FILES_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload,
    loading: false,
    error: initialState.error
  }),
  [FETCH_FILES_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [DOWNLOAD_FILE]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [DOWNLOAD_FILE_SUCCESS]: (state, action) => ({
    ...state,
    item: action.payload,
    loading: false,
    error: initialState.error
  }),
  [DOWNLOAD_FILE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [DELETE_FILE]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [DELETE_FILE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [DELETE_FILE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  })
})
