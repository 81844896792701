import {
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,
  EDIT_COMPANY,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE,
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,
  FETCH_COMPANY_GRADES,
  FETCH_COMPANY_GRADES_SUCCESS,
  FETCH_COMPANY_GRADES_FAILURE,
  FETCH_COMPANY_BASIC,
  FETCH_COMPANY_BASIC_SUCCESS,
  FETCH_COMPANY_BASIC_FAILURE,
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE
} from './constants'

export const createCompany = company => ({
  type: CREATE_COMPANY,
  payload: company
})

export const createCompanySuccess = () => ({
  type: CREATE_COMPANY_SUCCESS
})

export const createCompanyFailure = error => ({
  type: CREATE_COMPANY_FAILURE,
  payload: error
})

export const editCompany = (id, company) => ({
  type: EDIT_COMPANY,
  payload: { id, company }
})

export const editCompanySuccess = () => ({
  type: EDIT_COMPANY_SUCCESS
})

export const editCompanyFailure = error => ({
  type: EDIT_COMPANY_FAILURE,
  payload: error
})

export const fetchCompany = id => ({
  type: FETCH_COMPANY,
  payload: id
})

export const fetchCompanySuccess = company => ({
  type: FETCH_COMPANY_SUCCESS,
  payload: company
})

export const fetchCompanyFailure = error => ({
  type: FETCH_COMPANY_FAILURE,
  payload: error
})

export const fetchCompanyGrades = id => ({
  type: FETCH_COMPANY_GRADES,
  payload: { id }
})

export const fetchCompanyGradesSuccess = grades => ({
  type: FETCH_COMPANY_GRADES_SUCCESS,
  payload: grades
})

export const fetchCompanyGradesFailure = error => ({
  type: FETCH_COMPANY_GRADES_FAILURE,
  payload: error
})

export const fetchCompanyBasic = id => ({
  type: FETCH_COMPANY_BASIC,
  payload: id
})

export const fetchCompanyBasicSuccess = company => ({
  type: FETCH_COMPANY_BASIC_SUCCESS,
  payload: company
})

export const fetchCompanyBasicFailure = error => ({
  type: FETCH_COMPANY_BASIC_FAILURE,
  payload: error
})

export const deleteCompany = company => ({
  type: DELETE_COMPANY,
  payload: company
})

export const deleteCompanySuccess = company => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: company
})

export const deleteCompanyFailure = error => ({
  type: DELETE_COMPANY_FAILURE,
  payload: error
})

export const fetchCompanies = (title, page) => ({
  type: FETCH_COMPANIES,
  payload: { title, page }
})

export const fetchCompaniesSuccess = companies => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload: companies
})

export const fetchCompaniesFailure = error => ({
  type: FETCH_COMPANIES_FAILURE,
  payload: error
})
