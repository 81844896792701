export const CLEAR_TEMPLATE_MODULE = 'templates:CLEAR_TEMPLATE_MODULE'

export const FETCH_TEMPLATES = 'templates:FETCH_TEMPLATES'
export const FETCH_TEMPLATES_SUCCESS = 'templates:FETCH_TEMPLATES_SUCCESS'
export const FETCH_TEMPLATES_FAILURE = 'templates:FETCH_TEMPLATES_FAILURE'

export const UPLOAD_TEMPLATE = 'templates:UPLOAD_TEMPLATE'
export const UPLOAD_TEMPLATE_SUCCESS = 'templates:UPLOAD_TEMPLATE_SUCCESS'
export const UPLOAD_TEMPLATE_FAILURE = 'templates:UPLOAD_TEMPLATE_FAILURE'

export const VALIDATE_TEMPLATE = 'templates:VALIDATE_TEMPLATE'
export const VALIDATE_TEMPLATE_SUCCESS = 'templates:VALIDATE_TEMPLATE_SUCCESS'
export const VALIDATE_TEMPLATE_FAILURE = 'templates:VALIDATE_TEMPLATE_FAILURE'

export const SET_TEMPLATE_ID = 'templates:SET_TEMPLATE_ID'

export const CREATE_TEMPLATE = 'templates:CREATE_TEMPLATE'
export const CREATE_TEMPLATE_SUCCESS = 'templates:CREATE_TEMPLATE_SUCCESS'
export const CREATE_TEMPLATE_FAILURE = 'templates:CREATE_TEMPLATE_FAILURE'

export const DELETE_TEMPLATE = 'templates:DELETE_TEMPLATE'
export const DELETE_TEMPLATE_SUCCESS = 'templates:DELETE_TEMPLATE_SUCCESS'
export const DELETE_TEMPLATE_FAILURE = 'templates:DELETE_TEMPLATE_FAILURE'

export const FETCH_ORGANIZATIONS = 'templates:FETCH_ORGANIZATIONS'
export const FETCH_ORGANIZATIONS_SUCCESS = 'templates:FETCH_ORGANIZATIONS_SUCCESS'
export const FETCH_ORGANIZATIONS_FAILURE = 'templates:FETCH_ORGANIZATIONS_FAILURE'

export const FETCH_TEMPLATE = 'templates:FETCH_TEMPLATE'
export const FETCH_TEMPLATE_SUCCESS = 'templates:FETCH_TEMPLATE_SUCCESS'
export const FETCH_TEMPLATE_FAILURE = 'templates:FETCH_TEMPLATE_FAILURE'

export const EDIT_TEMPLATE = 'templates:EDIT_TEMPLATE'
export const EDIT_TEMPLATE_SUCCESS = 'templates:EDIT_TEMPLATE_SUCCESS'
export const EDIT_TEMPLATE_FAILURE = 'templates:EDIT_TEMPLATE_FAILURE'
