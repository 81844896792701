import styled from '@emotion/styled'
import { Menu } from 'antd'

export const Container = styled.div`
  .ant-menu {
    line-height: 2rem;
    &-item {
      padding: 0 .25rem;
      margin: 0 .25rem;
    }
  }
`
export const MenuItem = styled(Menu.Item)`
  & > a {
    color: inherit;
    height: 100%;
    position: relative;
    border-color: #514fff;
    border-style: solid;
    border-width: 3px;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0 10px;
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      text-decoration: none;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      &:after {
        width: calc(100% - 10px);
      }
    }

    &.active {
      color: #000; 
      border-style: solid;
      border-width: 5px;
      border-radius: 25px;
      border-color: #FBA661;
      background-color: #eee;

      &:after {
        background-color: #1890ff;
      }
    }
    &:hover {
      border-color: #FBA661;
      border-style: solid;
      border-width: 5px;
      border-radius: 25px;
    }
  }
`
