import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { renderQuestionNumber } from '../../utils/questionHelpers'
import get from 'lodash/get'

import { Bold } from './styles/Number.styles'

const questionNumber = number => (
  <Bold>{renderQuestionNumber(number)}</Bold>
)

const QuestionNumbers = ({ summary }) => {
  if (!summary) return null
  return (
    <span>
      Nós identificamos {questionNumber(summary.count)} questões, numeradas de {questionNumber(summary.first)} a {questionNumber(summary.last)}.
    </span>
  )
}

QuestionNumbers.propTypes = {
  summary: PropTypes.object
}

const mapStateToProps = ({ documents }) => ({
  summary: get(documents.validations.find(v => v.document.id === documents.id), 'questionSummary')
})

export default connect(mapStateToProps)(QuestionNumbers)
