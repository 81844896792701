import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import get from 'lodash/get'
// import history from '../routes/history'

import ContainerCard from '../components/card/ContainerCard'
// import DocumentUploadInput from '../components/input/DocumentUploadInput'
import QuestionNumbers from '../components/number/QuestionNumbers'
import DocumentWarningsSummary from '../components/warnings/DocumentWarningsSummary'
import TemplateInput from '../components/input/TemplateInput'
import Output from '../components/output/Output'
import WarningsCheckbox from '../components/checkbox/WarningsCheckbox'
import { Page, Row, Col } from './styles/Page.styles'

class OutputPage extends Component {
  static propTypes = {
    // documentId: PropTypes.string,
    documentName: PropTypes.string
  }

  // componentDidMount () {
  //   !this.props.documentId && history.push('/upload')
  // }

  render () {
    const {
      documentName
    } = this.props

    return (
      <Page>
        <Row>
          <Col>{documentName && <p><b>Preview</b> - {documentName}</p>}</Col>
          {/* <Col><DocumentUploadInput /></Col> */}
        </Row>
        <Row>
          <QuestionNumbers />
        </Row>
        <DocumentWarningsSummary />
        <Row>
          <Col expand><TemplateInput /></Col>
          <Col><WarningsCheckbox /></Col>
        </Row>
        <ContainerCard
          block
          centerText
          padding={0.5}
          margin={0}
        >
          <Output />
        </ContainerCard>
      </Page>
    )
  }
}

const mapStateToProps = ({ documents, outputs }) => ({
  documentId: documents.id,
  documentName: get(documents, 'file.name')
})

export default connect(mapStateToProps)(OutputPage)
