import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Content } from "./styles/SelectQuestionsPage.styles";
import { Page, Title } from "./styles/Page.styles";
import { Grid, TextField, FormControl, InputLabel, MenuItem, Button, FormControlLabel, FormLabel, Switch } from "@material-ui/core";
import { StyledButton } from "../components/modal/styles/Modal.styles";
import MuiSelect from "@material-ui/core/Select";
import ContainerCard from "../components/card/ContainerCard";
import { Container } from "../components/template/styles/HeaderNavigation.styles";
import { Spin } from "antd";
import {
  createQuestionsByAI,
  fetchExamMatrixEF,
  fetchExamMatrixEM,
  fetchExamHabilities,
} from '../store/exams/actions'
import { Row } from "./styles/Page.styles";
import QuestionBlock from "../components/question/QuestionBlock";
import { gptUploadExam } from "../store/exams/services"
import { checkImageGenerationLimit, getAILimitCount } from "../store/companies/services";
import { TYPE_OF_EDUCATION } from "../components/question/QuestionFilterHeader/constant";
import ReactDropdownTree from "../components/question/QuestionFilterHeader/components/ReactDropdownTree/ReactDropDownTree";

function GPTCreatePage({
  loading,
  createQuestionsByAI,
  createdQuestions,
  examId,
  isSuperAdmin,
  fetchExamMatrixEF,
  fetchExamMatrixEM,
  fetchExamHabilities,
  matrixEF,
  matrixEM,
  habilitiesOption,
  loggedUser,
}) {
  const [selectedType, setSelectedType] = useState("objective");
  const [selectedLevel, setSelectedLevel] = useState("mid");
  const [hasImage, setHasImage] = useState(false);
  const [numQuestions, setNumQuestions] = useState(1);
  const [questionSubject, setQuestionSubject] = useState("");
  const [areaType, setAreaType] = useState("");
  const [disableLoad, setDisableLoad] = useState(true);
  const [showQuestions, setShowQuestions] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [tags, setTags] = useState([]);
  const [examNewId, setExamNewId] = useState("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [segmento, setSegmento] = useState(undefined);
  const [shouldHaveSubject, setShouldHaveSubject] = useState(true);
  const [selectedNodesInTree, setSelectedNodesInTree] = useState([]);
  const [queryNodes, setQueryNodes] = useState([]);
  const [queryHabilitiesNodes, setQueryHabilitiesNodes] = useState([]);
  const [habilities, setHabilities] = useState([]);
  const [enhance, setEnhance] = useState(false);
  const [aiLimitCount, setAILimitCount] = useState(null);
  const blockibutton = true;

  const getQuerySelectedNode = (matrix, selectedNodes, startIdx) => {
    const queries = [];
    for (const selectedNode of selectedNodes) {
      const level = selectedNode.numberLevel;
      const id = selectedNode._id;
      let matrixTmp = matrix;
      const strmap = id.split('-');

      let queryString = "";

      for (let actualLevel = 1; actualLevel <= level; actualLevel++) {
        let idx;

        if (segmento === "EM") {
          if (actualLevel === 1) idx = startIdx;
          else idx = parseInt(strmap[actualLevel]);
        } else {
          idx = parseInt(strmap[actualLevel]);
        }

        const actualNode = matrixTmp[idx];
        matrixTmp = actualNode.children;

        if (segmento === "EM") {
          if (actualLevel === 2) queryString += `${actualNode.level}: ${actualNode.label}`;
          if (actualLevel > 2) queryString += ` - ${actualNode.level}: ${actualNode.label}`;
        } else {
          if (actualLevel === 1) queryString += `${actualNode.level}: ${actualNode.label}`;
          if (actualLevel > 1) queryString += ` - ${actualNode.level}: ${actualNode.label}`;
        }
      }
      if (queryString !== "") queries.push(queryString);
    }
    return queries;
  }

  const handleSelectedNodes = (matrix) => (_currentNodes, selectedNodes) => {
    setSelectedNodesInTree(selectedNodes);
    if (segmento == "EM") setQueryNodes(getQuerySelectedNode(matrix, selectedNodes, areaType));
    else setQueryNodes(getQuerySelectedNode(matrix, selectedNodes, 0));
  }

  const handleSelectedHabilities = (_currentNodes, selectedNodes) => {
    setHabilities(selectedNodes);
    setQueryHabilitiesNodes(getQuerySelectedNode(habilitiesOption, selectedNodes, convertAreaType(areaType)));
  }

  function handleSuffleQuestions(array) {
    let newArray = extractQuestions(array);
    setSelectedQuestions(newArray);
  }

  const handleDiscardQuestions = () => {
    window.location.href = "/ai-creation";
  };

  const handleSaveExam = async () => {
    setIsButtonClicked(true);
    const levelTag = selectedLevel === 'easy' ? 'Fácil' : selectedLevel === 'hard' ? 'Difícil' : 'Médio'
    let examInfo = {
      Title: createdQuestions[0].Title,
      Questions: createdQuestions[0].Questions.map(question => {
        let newQuestion = { ...question };
        newQuestion.Tags.push(levelTag);
        newQuestion.Type = selectedType == "objective" ? "MultipleChoice" : "Dissertative";
        newQuestion.Alternatives = question.Alternatives.map(alt => {
          return {
            Correct: alt.Correct,
            Order: alt.Order,
            Description: alt.OriginalDescription
          };
        });
        return newQuestion;
      }).filter(q => q.Selected === true),
      Processed: createdQuestions[0].Processed,
      Total: createdQuestions[0].Total,
      Scholarship: segmento
    }
    let newExamId = await gptUploadExam(examInfo);
    if (newExamId !== undefined) setExamNewId(newExamId);
  };

  useEffect(() => {
    const subjectCondition = (shouldHaveSubject && questionSubject !== "") || shouldHaveSubject === false;
    if (subjectCondition && segmento && numQuestions > 0) {
      setDisableLoad(false);
    } else {
      setDisableLoad(true);
    }
  }, [selectedType, selectedLevel, segmento, numQuestions, questionSubject, areaType]);

  useEffect(() => {
    console.log(createdQuestions)
    if (createdQuestions && createdQuestions.length > 0 && createdQuestions[0].Questions.length > 0) {
      setTags(extractTags([createdQuestions[0]]));
      setSelectedQuestions(createdQuestions[0].Questions);
      setShowQuestions(true);
    }
    if (examNewId !== "") window.location.href = "/list-exams/list-questions?examId=" + examNewId;
  }, [createdQuestions, examNewId, loading]);

  useEffect(() => {
    if (matrixEF.length > 0) return;
    fetchExamMatrixEF(loggedUser.CompanyId);
  }, []);

  useEffect(() => {
    if (matrixEM.length > 0) return;
    fetchExamMatrixEM(loggedUser.CompanyId);
  }, []);

  useEffect(() => {
    if (habilitiesOption.length > 0) return;
    fetchExamHabilities(loggedUser.CompanyId);
  }, []);

  useEffect(() => {
    const fetchAILimitCount = async () => {
        const limitCount = await getAILimitCount(loggedUser.CompanyId);
        setAILimitCount(limitCount);
    };

    if (aiLimitCount === null) {
        fetchAILimitCount();
    }
  }, [aiLimitCount, loggedUser.CompanyId]);

  function extractQuestions(array) {
    let newArray = [];
    if (array) {
      for (let i = 0; i < array.length; i++) {
        newArray.push(array[i].question);
      }
    }

    return newArray;
  }

  function updateSelected(array) {
    setSelectedQuestions(array);
  }

  function extractTags(exams) {
    let tagList = [];
    if (exams !== undefined) {
      for (let x = 0; x < exams.length; x++) {
        if (exams[x].Questions !== undefined && exams[x].Questions !== null) {
          for (let y = 0; y < exams[x].Questions.length; y++) {
            for (let z = 0; z < exams[x].Questions[y].Tags.length; z++) {
              tagList.push(exams[x].Questions[y].Tags[z]);
            }
          }
        }
      }
    }
    return [...new Set(tagList)];
  }

  function updateCorrectAnswer(question) {
    let updateSelectedQuestions = selectedQuestions.map((quest) => {
      if (quest.Id === question.Id) {
        return question;
      }
      return quest;
    });
    setSelectedQuestions(updateSelectedQuestions);
  }

  function updateQuestionType(question, type) {
    if (question !== "undefined") {
      let updateSelectedQuestions = selectedQuestions.map((quest) => {
        if (quest.Id === question.Id) {
          return { ...quest, Type: type };
        }
        return quest;
      });

      setSelectedQuestions(updateSelectedQuestions);
    }
  }

  function removeModal(array) {
    setSelectedQuestions(array);
  }

  const handleCreateQuestions = async () => {
    if (segmento === undefined) {
      window.alert("Preencha o segmento!")
      return;
    }

    const payload = {
      type: selectedType,
      level: selectedLevel,
      quantity: numQuestions,
      subject: questionSubject,
      area: areaToLabel(areaType),
      knowledge: queryNodes,
      habilities: queryHabilitiesNodes,
      segmento: segmento,
      hasImage: hasImage,
      enhance: enhance
    };

    if (enhance) {
        const canGenerateImage = await checkImageGenerationLimit(loggedUser.CompanyId, numQuestions);
        if (!canGenerateImage) {
            window.alert("Limite de questões geradas por IA avançada alcançado para este mês.");
            return;
        }
    }
    createQuestionsByAI(payload);
  };

  const handleSegmento = (e) => {
    setSegmento(e.target.value);
    setShouldHaveSubject(e.target.value === "TEC" || e.target.value === "SUP" || e.target.value === "OUT");
    setAreaType("");
    setHabilities([]);
    setSelectedNodesInTree([]);
    setQueryHabilitiesNodes([]);
    setQueryNodes([]);
  }

  const convertAreaType = (idx) => {
    if (idx == 0) return 2;
    if (idx == 1) return 3;
    if (idx == 2) return 0;
    if (idx == 3) return 1;
  }

  const areaToLabel = (idx) => {
    if (idx == 0) return "Ciências da Natureza e suas tecnologias";
    if (idx == 1) return "Ciências Humanas e suas tecnologias";
    if (idx == 2) return "Linguagens, Códigos e suas tecnologias";
    if (idx == 3) return "Matemática e suas tecnologias";
    return ""
  }

  const handleEnhance = () => {
    setEnhance(!enhance);
  }

  return (
    <Page>
      <Content
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "976px",
        }}
      >
        <Container>
          <Spin
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            spinning={loading}
          >
            <>
              <ContainerCard>
                {/* Text Input for Free Description */}
                <Grid container direction="column">
                  <FormControl>
                    <InputLabel>Segmento *</InputLabel>
                    <MuiSelect
                      value={segmento}
                      required
                      onChange={handleSegmento}
                      MenuProps={{
                        getContentAnchorEl: () => null,
                      }}
                      style={{ margin: '10px 0px' }}
                    >
                      {Object.keys(TYPE_OF_EDUCATION).map((education) =>
                        <MenuItem value={TYPE_OF_EDUCATION[education].slug}>{TYPE_OF_EDUCATION[education].label}</MenuItem>
                      )}
                    </MuiSelect>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Tipo da Prova</InputLabel>
                    <MuiSelect
                      value={selectedType}
                      required
                      onChange={(e) => setSelectedType(e.target.value)}
                      MenuProps={{
                        getContentAnchorEl: () => null,
                      }}
                      style={{ margin: '10px 0px' }}
                    >
                      <MenuItem key={"objective"} value={"objective"}>
                        Objetiva
                      </MenuItem>
                      <MenuItem key={"discursive"} value={'discursive'}>
                        Discursiva
                      </MenuItem>
                    </MuiSelect>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Dificuldade</InputLabel>
                    <MuiSelect
                      defaultValue={selectedLevel}
                      required
                      onChange={(e) => setSelectedLevel(e.target.value)}
                      MenuProps={{
                        getContentAnchorEl: () => null,
                      }}
                      style={{ margin: '10px 0px' }}
                    >
                      <MenuItem key={"easy"} value={"easy"}>
                        Fácil
                      </MenuItem>
                      <MenuItem key={"mid"} value={'mid'}>
                        Médio
                      </MenuItem>
                      <MenuItem key={"hard"} value={'hard'}>
                        Difícil
                      </MenuItem>
                    </MuiSelect>
                  </FormControl>
                  <FormControl>
                    <TextField
                      id="filled-number"
                      label="Quantidade de questões"
                      type="number"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      value={numQuestions}
                      onChange={(e) => setNumQuestions(e.target.value)}
                      style={{ margin: '10px 0px' }}
                      error={numQuestions > 5 || numQuestions < 1}
                      helperText={
                        numQuestions > 5
                          ? "No máximo 5 questões"
                          : numQuestions < 0
                            ? "Digite número válido de questões"
                            : ''
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      id="outlined-multiline-static"
                      label="Tema das questões e/ou descrições extras"
                      required={segmento === undefined || segmento === "TEC" || segmento === "SUP" || segmento === "OUT"}
                      multiline
                      minRows={4}
                      variant="outlined"
                      onChange={(e) => setQuestionSubject(e.target.value)}
                      value={questionSubject}
                      style={{ margin: '10px 0px', width: '100%' }}
                    />
                  </FormControl>
                  {
                    segmento === TYPE_OF_EDUCATION.EM.slug ? (
                      <>
                        <FormControl>
                          <InputLabel>Área do Conhecimento *</InputLabel>
                          <MuiSelect
                            value={areaType}
                            required
                            onChange={(e) => setAreaType(e.target.value)}
                            MenuProps={{
                              getContentAnchorEl: () => null,
                            }}
                            style={{ margin: '10px 0px' }}
                          >
                            {[0, 1, 2, 3].map(idx => (
                              <MenuItem key={`area-${idx}`} value={idx}>
                                {areaToLabel(idx)}
                              </MenuItem>
                            ))}
                          </MuiSelect>
                        </FormControl>
                        {areaType !== "" ? (
                          <>
                            <FormControl>
                              <ReactDropdownTree
                                className="mdl-demo"
                                inlineSearchInput
                                data={matrixEM[areaType]}
                                texts={{ placeholder: "Matriz Pedagógica", inlineSearchPlaceholder: "Pesquisar" }}
                                showPartiallySelected
                                keepTreeOnSearch
                                onChange={handleSelectedNodes(matrixEM)}
                                value={selectedNodesInTree}
                              />
                            </FormControl>
                            <FormControl>
                              <ReactDropdownTree
                                className="mdl-demo"
                                inlineSearchInput
                                data={habilitiesOption[convertAreaType(areaType)]}
                                texts={{ placeholder: "Habilidades", inlineSearchPlaceholder: "Pesquisar" }}
                                showPartiallySelected
                                keepTreeOnSearch
                                onChange={handleSelectedHabilities}
                                value={habilities}
                              />
                            </FormControl>
                          </>) : null}
                      </>) : <></>
                  }
                  {
                    segmento === TYPE_OF_EDUCATION.EF2.slug ? (
                      <>
                        <FormControl>
                          <ReactDropdownTree
                            className="mdl-demo"
                            inlineSearchInput
                            data={matrixEF}
                            texts={{ placeholder: "Matriz Pedagógica", inlineSearchPlaceholder: "Pesquisar" }}
                            showPartiallySelected
                            keepTreeOnSearch
                            onChange={handleSelectedNodes(matrixEF)}
                            value={selectedNodesInTree}
                          />
                        </FormControl>
                      </>) : <></>
                  }

                  <FormControl style={{ marginTop: '8px' }}>
                    <FormLabel>Aprimorar Questões</FormLabel>
                    <FormControlLabel
                      control={<Switch checked={enhance} onChange={handleEnhance} name="ia" />}
                      label="IA Avançada"
                    />
                  </FormControl>

                  {enhance ? <FormControl>
                    <InputLabel>Estilo Visual *</InputLabel>
                    <MuiSelect
                      defaultValue={hasImage}
                      required
                      onChange={(e) => setHasImage(e.target.value)}
                      MenuProps={{
                        getContentAnchorEl: () => null,
                      }}
                      style={{ margin: '10px 0px' }}
                    >
                      <MenuItem key={"image"} value={false}>
                        Sem imagens
                      </MenuItem>
                      <MenuItem key={"no-image"} value={true}>
                        Com imagens
                      </MenuItem>
                    </MuiSelect>
                  </FormControl> : null}
                  {enhance && aiLimitCount !== null && (
                      <FormLabel>
                        <span style={{ color: 'black' }}>Questões com IA avançada restantes: </span>
                        <span style={{ color: 'blue' }}>{aiLimitCount}</span>
                      </FormLabel>
                    )}
                </Grid>

                {/* "Criar Questões" and "Mostrar Opções" Buttons Container */}
                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={disableLoad}
                    onClick={handleCreateQuestions}
                    style={{
                      margin: '10px 0px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                  >
                    CRIAR QUESTÕES
                  </Button>
                </div>
              </ContainerCard>
            </>
            {showQuestions ? (
              <>
                <QuestionBlock
                  isSuperAdmin={isSuperAdmin}
                  loading={loading}
                  selectedQuestions={selectedQuestions}
                  loadQuestions
                  tags={tags}
                  exam={createdQuestions[0]}
                  onShuffle={handleSuffleQuestions}
                  changeTypeInline
                  examId={examId}
                  updateSelected={updateSelected}
                  updateCorrectAnswer={updateCorrectAnswer}
                  updateType={updateQuestionType}
                  removeModal={removeModal}
                  justView
                  exams={createdQuestions}
                  pickedExams={createdQuestions}
                  editable={false}
                  blockibutton={blockibutton}
                  editView
                  sufflable
                  onlyModal
                  createView={false}
                  view
                />
                <Row style={{ justifyContent: "flex-end" }}>
                  <StyledButton
                    style={{ 'margin': '0px 5px' }}
                    type="primary"
                    disabled={!showQuestions || isButtonClicked}
                    onClick={handleSaveExam}
                    shape="round"
                  >
                    Salvar Prova
                  </StyledButton>
                  <StyledButton
                    style={{ 'margin': '0px 5px' }}
                    type="primary"
                    shape="round"
                    disabled={!showQuestions}
                    onClick={handleDiscardQuestions}
                  >
                    Descartar Prova
                  </StyledButton>
                </Row>
              </>
            ) : null}
          </Spin>
        </Container>
      </Content>
    </Page>
  )
};

GPTCreatePage.PropTypes = {
  loading: PropTypes.bool,
  createQuestionsByAI: PropTypes.func,
  createdQuestions: PropTypes.array,
  examId: PropTypes.string,
  isSuperAdmin: PropTypes.bool,
  fetchExamMatrixEF: PropTypes.func.isRequired,
  fetchExamMatrixEM: PropTypes.func.isRequired,
  fetchExamHabilities: PropTypes.func.isRequired,
  loggedUser: PropTypes.any,
};

const mapStateToProps = ({ exams, auth }) => ({
  loggedUser: auth.loggedUser,
  loading: exams.loading,
  createdQuestions: exams.gptCreatedQuestions,
  isSuperAdmin: auth.loggedUser.Role === "SuperUser" || auth.loggedUser.Role === "Super",
  examId: exams.id,
  matrixEF: exams.matrixEF,
  matrixEM: exams.matrixEM,
  habilitiesOption: exams.habilities,
  treeIdEF: exams.treeIdEF,
  treeIdEM: exams.treeIdEM,
  treeIdHabilities: exams.treeIdHabilities,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createQuestionsByAI,
  fetchExamMatrixEF,
  fetchExamMatrixEM,
  fetchExamHabilities,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GPTCreatePage);