/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import MuiSelect from "@material-ui/core/Select";
import { Modal, Spin, message } from 'antd'
import { EditMatrixKnowledgeAndHabilityModalStyle, SectionTitle, StyledButton, dropdown } from './styles/Modal.styles'
import { FormControl, Grid, InputLabel, MenuItem } from '@material-ui/core'
import { updateMatrixHabilityId, updateMatrixKnowledgeId } from '../../store/questions/services'

const InputChange = ({ disabled = false, numberLevel, level, initialValue, options, onSelect }) => {
  
  return (
    <>
      <FormControl style={{ width: '100%' }}>
        <InputLabel>{level}</InputLabel>
        <MuiSelect
          disabled={disabled}
          defaultValue={initialValue}
          onChange={(e) => onSelect(numberLevel, e.target.value)}
          MenuProps={dropdown}
          style={{ width: '100%' }}
        >
          {options.map(option =>
            <MenuItem value={option} key={option}>{option}</MenuItem>
          )}
        </MuiSelect>
      </FormControl>
      <br />
    </>
  );
};

const EditMatrixKnowledgeAndHabilityModal = ({
  visible,
  questionId,
  treeKnowledge,
  matrixKnowledge,
  treeHability,
  matrixHability,
  scholarship,
  onSave,
  onCancel
}) => {
  const [loading, setLoading] = useState(false);
  const [levels, setLevels] = useState(null);
  const [levelsHability, setLevelsHability] = useState(null);
  const [matrixKnowledgePayload, setMatrixKnowledgePayload] = useState(matrixKnowledge);
  const [matrixHabilityPayload, setMatrixHabilityPayload] = useState(matrixHability);

  const hasHability = scholarship != "EF";
  const allLevelNumbers = Array.from({ length: 10 }, (_, index) => index + 1);
  const allLevelNumbersHability = Array.from({ length: 10 }, (_, index) => index + 1);

  useEffect(() => {
    try {
      updateChildren();
    } catch(e) {
      console.log("error: ", e);
    }
  }, [matrixKnowledgePayload, matrixKnowledge]);

  useEffect(() => {
    if(!hasHability) return;
    try {
      updateChildrenHability();
    } catch(e) {
      console.log("error: ", e);
    }
  }, [matrixHabilityPayload, matrixHability]);

  const handleUpdate = async () => {
    setLoading(true);
    if(!validateInputFields(levels, matrixKnowledgePayload)) {
      setLoading(false);
      message.warn("Campos de Conhecimento não podem ser nulos");
      return;
    };
    if(hasHability && !validateInputFields(levelsHability, matrixHabilityPayload)) {
      setLoading(false);
      message.warn("Campos de Habilidades não podem ser nulos");
      return;
    };
    const newTreeDescription = {
      matrixKnowledge: matrixKnowledge,
      matrixHability: matrixHability
    };
    const newMatrixKnowledge = await updateMatrixKnowledgeId({
      questionId,
      newMatrixKnowledge: matrixKnowledgePayload,
    });
    newTreeDescription.matrixKnowledge = newMatrixKnowledge;
    if(hasHability) {
      const newMatrixHability = await updateMatrixHabilityId({
        questionId,
        newMatrixHability: matrixHabilityPayload,
      });
      newTreeDescription.matrixHability = newMatrixHability;
    }
    onSave(newTreeDescription);
    setLoading(false);
    onCancel();
  };

  const footerButtons = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "end"
      }}
    >
      <Spin spinning={loading}>
        <StyledButton
          style={{ marginRight: "20px" }}
          htmlType='button'
          onClick={handleUpdate}
        >
          Salvar
        </StyledButton>
      </Spin>
      <StyledButton
        htmlType='button'
        onClick={() => {
          setMatrixKnowledgePayload(matrixKnowledge);
          setMatrixHabilityPayload(matrixHability);
          onCancel();
        }}
      >
        Voltar
      </StyledButton>
    </div>
  )

  const updateChildren = () => {
    if(!matrixKnowledgePayload) {
      if(matrixKnowledge) setMatrixKnowledgePayload(matrixKnowledge);
      return;
    }
    let objectLevel;
    let tree = treeKnowledge;
    let newLevels = {};
    for(let i = 1; i <= 10; i++) {
      if(!tree || tree.length === 0) break;
      let selectedValue = matrixKnowledgePayload[`Level${i}`]
      if (!selectedValue) {
        objectLevel = tree[0];
        newLevels[`Level${i}`] = {
          level: objectLevel.level,
          selectedValue: null,
          children: tree.map((child) => child.label)
        }
        tree = objectLevel.children
      } else {
        objectLevel = tree.find((obj) => obj.label === selectedValue);
        if (objectLevel) {
          newLevels[`Level${i}`] = {
            level: objectLevel.level,
            selectedValue: selectedValue,
            children: tree.map((child) => child.label)
          }
          tree = objectLevel.children;
        }
      }
    }
    setLevels(newLevels);
    return newLevels;
  };

  const updateChildrenHability = () => {
    if(!matrixHabilityPayload) {
      if(matrixHability) setMatrixHabilityPayload(matrixHability);
      return;
    }
    let objectLevel;
    let tree = treeHability;
    let newLevels = {};
    for(let i = 1; i <= 5; i++) {
      if( !tree || tree.length === 0) break;
      let selectedValue = matrixHabilityPayload[`Level${i}`]
      if (!selectedValue) {
        objectLevel = tree[0];
        newLevels[`Level${i}`] = {
          level: objectLevel.level,
          selectedValue: null,
          children: tree.map((child) => child.label)
        }
        tree = objectLevel.children
      } else {
        objectLevel = tree.find((obj) => obj.label === selectedValue);
        newLevels[`Level${i}`] = {
          level: objectLevel.level,
          selectedValue: selectedValue,
          children: tree.map((child) => child.label)
        }
        tree = objectLevel.children;
      }
    }
    setLevelsHability(newLevels);
    return newLevels;
  };

  const onSelectLevel = (level, label) => {
    let newMatrixPayload = {
      ...matrixKnowledgePayload,
      [`Level${level}`]: label
    };
    for(let i = level + 1; i <= 10; i++) {
      newMatrixPayload[`Level${i}`] = null;
    }
    setMatrixKnowledgePayload(newMatrixPayload);
  };

  const onSelectLevelHability = (level, label) => {
    let newMatrixPayload = {
      ...matrixHabilityPayload,
      [`Level${level}`]: label
    };
    for(let i = level + 1; i <= 10; i++) {
      newMatrixPayload[`Level${i}`] = null;
    }
    setMatrixHabilityPayload(newMatrixPayload);
  };

  const validateInputFields = (levelsObj, matrixPayload) => {
    for(let i = 1; i <= 10; i++) {
      if(
        levelsObj[`Level${i}`] &&
        levelsObj[`Level${i}`].children.length > 0 &&
        (!levelsObj[`Level${i}`].selectedValue && !matrixPayload[`Level${i}`])
      ) return false;
    }
    return true;
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setMatrixKnowledgePayload(matrixKnowledge);
        setMatrixHabilityPayload(matrixHability);
        onCancel();
      }}
      footer={footerButtons}
      css={EditMatrixKnowledgeAndHabilityModalStyle}
      destroyOnClose
    >
      <Grid container direction="column">
        <SectionTitle>Matriz Pedagógica</SectionTitle>
        {
          levels !== null && matrixKnowledgePayload ?
          allLevelNumbers.map((level) => {
            let disable = false;
            if(level != 1){
              disable = matrixKnowledgePayload[`Level${level - 1}`] === null;
            }
            if(!levels[`Level${level}`]) return <></>;
            return (
              <InputChange
                key={level}
                disabled={disable}
                numberLevel={level}
                level={levels[`Level${level}`].level}
                initialValue={levels[`Level${level}`].selectedValue}
                options={levels[`Level${level}`].children}
                onSelect={onSelectLevel}
              />
            );
          }) : <></>
        }

        { hasHability ?
          <>
            <SectionTitle>Matriz Habilidade</SectionTitle>
            {
              levelsHability !== null && matrixHabilityPayload ?
              allLevelNumbersHability.map((level) => {
                let disable = false;
                if(level != 1){
                  disable = matrixHabilityPayload[`Level${level - 1}`] === null;
                }
                if(!levelsHability[`Level${level}`]) return <></>;
                return (
                  <InputChange
                    key={level}
                    disabled={disable}
                    numberLevel={level}
                    level={levelsHability[`Level${level}`].level}
                    initialValue={levelsHability[`Level${level}`].selectedValue}
                    options={levelsHability[`Level${level}`].children}
                    onSelect={onSelectLevelHability}
                  />
                );
              }) : <></>
            }
          </> 
          : <></>
        }
      </Grid>
    </Modal>
  )
}

EditMatrixKnowledgeAndHabilityModal.propTypes = {
  visible: PropTypes.bool,
  questionId: PropTypes.string,
  treeKnowledge: PropTypes.array,
  matrixKnowledge: PropTypes.object,
  treeHability: PropTypes.array,
  matrixHability: PropTypes.object,
  scholarship: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
}

export default EditMatrixKnowledgeAndHabilityModal;
