import styled from '@emotion/styled'
import { Upload, Input } from 'antd'

export const Label = styled.label`
  font-size: 115%;
  width: 100%;
`
export const DocumentDragger = styled(Upload.Dragger)`
  .ant-upload-drag, p, span, h2, h3, i {
    transition: all .4s ease-out;
  }
  
  .ant-upload-drag {
    background: #fff;
    border-width: 3px;
    border-color: #777;
    border-radius: 15px;
    p { padding: 2rem 0; }
    i, span { color: #514FFF; }
    :hover {
      background: #514FFF;
      border-color: #777 !important;
      p, span, h2, h3, i { color: #fff !important; }
    }
  }

  .anticon {
    font-size: 10rem;
    margin: 4rem 0 3rem;
    color: #1890ff;
  }
`

export const TemplateDragger = styled(Upload.Dragger)`
  .ant-upload-drag, p, span, h2, h3, i {
    transition: all .4s ease-out;
    margin-bottom: 1rem;
  }
  
  .ant-upload-drag {
    background: #fff;
    border-width: 3px;
    p { margin: 0; }
    span { color: #1890ff; }
    :hover {
      background: #1890ff;
      p, span, h2, h3, i { color: #fff !important; }
    }
  }

  .anticon {
    font-size: 5rem;
    margin: 2rem;
    color: #1890ff;
  }
`

export const DraggerTitle = styled.p`
  font-weight: 400;
  font-size: 1.8rem;
`

export const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  label { margin-right: 1rem; }
  .ant-select, .ant-input {
    flex: 1;
    width: 100%;
  }
  .ant-select-selection {
    ${props => props.glow && 'box-shadow: 0 0 5px 2px rgba(24, 144, 255, 0.8);'}
    transition: box-shadow 1s;
  }
`
export const FormInput = styled(Input)`
  .ant-input{
    border-radius: 25px !important;
    border-color: #514fff !important;
  }
  .ant-input:hover,.ant-input:focus{
    border-width: 3px !important;
  }
`
