/* eslint-disable */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import history from '../../routes/history'
import * as yup from 'yup'
import { inviteUsers } from '../../store/auth/actions'
import { infoUser } from '../../store/users/actions'
import { withFormik } from 'formik'

import { Form, Spin } from 'antd'
import SubmitButton from '../button/SubmitButton'
import { StyledRegisterForm } from './styles/Form.styles'
import { isEmail, ReactMultiEmail } from 'react-multi-email'
import 'react-multi-email/style.css'

const validationSchema = props => {
  return yup.object().shape({
    EmailList: yup.string().required()
  })
}
const InviteForm = ({
  isValid,
  loading,
  inviteUsers,
  infoUser,
  validation,
  values
}) => {
  let [emails, setEmails] = useState([])
  let [can, setCan] = useState(true)
  return (
    <StyledRegisterForm>
      <Spin spinning={loading}>
        <p><i>Obs.: clique em Enter para confirmar a adição</i></p>
        <ReactMultiEmail
          placeholder='Insira os e-mails dos docentes'
          emails={emails}
          onChange={(_emails: string[]) => {
            setEmails(_emails)
            if (_emails.length > 0) {
              setCan(false)
              values.EmailList = _emails
            }
          }}
          validateEmail={email => {
            return isEmail(email) // return boolean
          }}
          getLabel={(
            email: string,
            index: number,
            removeEmail: (index: number) => void
          ) => {
            return (
              <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            )
          }}
        />
        <Form.Item>
          <SubmitButton
            disabled={can}
            loading={loading}
          >
              Convidar Docentes
          </SubmitButton>
        </Form.Item>
      </Spin>
    </StyledRegisterForm>
  )
}

InviteForm.propTypes = {
  isValid: PropTypes.bool,
  loading: PropTypes.bool,
  validation: PropTypes.object,
  values: PropTypes.object,
  infoUser: PropTypes.func,
  inviteUsers: PropTypes.func
}

const mapStateToProps = ({ users }) => ({
  user: users.infoUser,
  loading: users.loading,
  validation: users.validation,
  form: users.form
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    infoUser,
    inviteUsers
  }, dispatch)

const handleSubmit = (values, formikBag, user) => {
  formikBag.props.inviteUsers(values)
  history.push('/users')
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit,
    validationSchema,
    enableReinitialize: true
  })
)

export default enhance(InviteForm)
