import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchTemplate } from '../store/templates/actions'

import EditTemplateForm from '../components/form/EditTemplateForm'
import { Spin } from 'antd'
import { Page, Row, Col, Title } from './styles/Page.styles'

const EditTemplatePage = ({
  fetchTemplate,
  template,
  loading,
  match
}) => {
  useEffect(() => {
    fetchTemplate(match.params.id)
  }, [])

  return (
    <Page>
      <Row>
        <Col><Title>Editar Modelo</Title></Col>
      </Row>
      <Spin spinning={loading}>
        <EditTemplateForm initialValues={template} />
      </Spin>
    </Page>
  )
}

EditTemplatePage.propTypes = {
  fetchTemplate: PropTypes.func,
  template: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object
}

const mapStateToProps = ({ templates }) => ({
  template: templates.template,
  loading: templates.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchTemplate
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EditTemplatePage)
