import React, { useState, useEffect } from "react";
import { Grid, IconButton } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { Update } from "@material-ui/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchReadingAndWritingCSVData } from "../../../store/exams/actions";

const CSVExportButton = ({
  fetchReadingAndWritingCSVData,
  readingAndWritingCSVData,
  companies,
  user,
}) => {
  let [updatingCSV, setUpdatingCSV] = useState(false);
  const [readingAndWritingDataCSV, setReadingAndWritingDataCSV] = useState({
    data: [],
    headers: [],
  });

  useEffect(() => {
    if (!readingAndWritingCSVData || readingAndWritingCSVData.length === 0)
      return;
    createReadingWritingCSVData();
  }, [readingAndWritingCSVData]);

  useEffect(() => {
    fetchReadingAndWritingCSVData();
  }, []);

  useEffect(() => {
    if (updatingCSV) {
      fetchReadingAndWritingCSVData();
    }
  }, [updatingCSV]);

  const createReadingWritingCSVData = () => {
    let readingAndWritingDataCSV = [];
    for (const [month, readingWritingByMonth] of Object.entries(
      readingAndWritingCSVData
    )) {
      for (const [studentId, studentData] of Object.entries(
        readingWritingByMonth
      )) {
        const student = studentData.Student;
        const reading = studentData.Reading;
        const writing = studentData.Writing;
        const newRow = {
          nome: student.Name,
          mes: month,
          turmas:
            student.Grades && student.Grades.length > 0
              ? student.Grades.join()
              : "",
          escola: getEscola(student.CompanyId),
        };
        for (const [readingParameter, value] of Object.entries(reading)) {
          newRow[readingParameter] = value ? value.toString() : null;
        }
        for (const [writingParameter, value] of Object.entries(writing)) {
          newRow[writingParameter] = value ? value.toString() : "false";
        }
        readingAndWritingDataCSV.push(newRow);
      }
    }
    let headersCSV = [];
    if (readingAndWritingDataCSV.length > 0)
      headersCSV = mountHeaders(Object.keys(readingAndWritingDataCSV[0]));
    setReadingAndWritingDataCSV({
      data: readingAndWritingDataCSV,
      headers: headersCSV,
    });
    setUpdatingCSV(false);
  };

  const updateCSV = () => {
    setUpdatingCSV(true);
  };

  const getEscola = (compId) => {
    if (user.Role === "Super" && companies) {
      var comp = companies.find((c) => c.Id === compId);

      if (comp) return comp.Name;
      else "";
    }

    return user.Company;
  };

  const mountHeaders = (columns) => {
    return columns.map((columnName) => ({
      label: columnName,
      key: columnName,
    }));
  };

  return (
    <>
      {readingAndWritingDataCSV.data.length > 0 &&
      readingAndWritingDataCSV.headers.length > 0 ? (
        <Grid container justifyContent="flex-end" alignItems="center">
          {updatingCSV ? null : (
            <CSVLink
              data={readingAndWritingDataCSV.data}
              headers={readingAndWritingDataCSV.headers}
              filename="relatorio.csv"
            >
              Exportar CSV
            </CSVLink>
          )}
          <IconButton
            onClick={updateCSV}
            size="small"
            style={{ marginLeft: "8px" }}
          >
            <Update />
          </IconButton>
        </Grid>
      ) : null}
    </>
  );
};

CSVExportButton.propTypes = {
  fetchReadingAndWritingCSVData: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams, users }) => ({
  user: users.infoUser,
  readingAndWritingCSVData: exams.readingAndWritingCSVData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchReadingAndWritingCSVData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CSVExportButton);
