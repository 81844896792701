/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Player } from "video-react";

import "video-react/dist/video-react.css"; // import css

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Grid, Typography, Box } from "@material-ui/core";

import ContainerCard from "../../card/ContainerCard";

import { renderQuestionNumber } from "../../../utils/questionHelpers";
import renderHTML from "react-render-html";
import DOMPurify from "dompurify";

import { List, message } from "antd";
import { QuestionType } from "../QuestionCard/styles";
import {
  Row,
  QuestionHeader,
  QuestionNumber,
  QuestionText,
  QuestionInstructions,
  QuestionNumberFont,
} from "./styles";

import useWindowSize from "../../../utils/useWindowSize";

function QuestionOnlineCard({
  examId,
  question,
  editable = false,
  deletable = false,
  onDelete,
  isSelectable = false,
  onCheck,
  selected = [],
  handleAddTag,
  handleRemoveTag,
  numberQuestion = 0,
  editView = false,
  createView = false,
  onlyModal = false,
  checked = true,
  onShuffle,
  totalChecked = false,
  onChange,
  correctAnswer,
  forceDescheck,
  questionTags,
  master = false,
  checkAns,
  loadingQuest,
  showAlternatives = true,
  errorChecked,
}) {
  // const [isChecked, setIsChecked] = useState(checked)
  const [typeChoice, setTypeChoice] = useState(undefined);
  const [tags, setTags] = useState([]);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (question !== undefined) {
      setTypeChoice(question.Type);
    }
  }, [question, editView]);

  useEffect(() => {
    if (forceDescheck !== undefined) {
      onCheckboxChangeForce();
    }
  }, [forceDescheck]);

  useEffect(() => {
    if (!master) {
      tags.concat(questionTags);
    }
    setTags(tags);
  }, [tags, questionTags, master]);

  useEffect(() => {
    let initialTags =
      question !== undefined &&
      question.Tags !== undefined &&
      question.Tags !== null
        ? question.Tags
        : [];
    if (!master) {
      initialTags.concat(questionTags);
    }
    setTags(initialTags);
  }, [questionTags, master]);

  async function handleAnswer(answer) {
    let alter = JSON.parse(JSON.stringify(question));
    for (let i = 0; i < alter.Alternatives.length; i++) {
      alter.Alternatives[i].Correct = alter.Alternatives[i].Order === answer;
    }
    await correctAnswer([alter]);
  }

  function correctImageSize(text) {
    text = text.replace(/<img /g, '<img style="max-width: 100%;"');
    return (
      '<span style="color: #000000;font-size: 21px;font-family: Arial;">' +
      text +
      "</span>"
    );
  }

  const onKeyPress = (e) => {
    e.preventDefault();
    e.target.blur();
  }

  const renderItem = (item) => (
    <>
      {question.Type !== "Dissertative" && (
        <List.Item style={{ marginBottom: "15px", paddingTop: "0px", paddingBottom: "0px" }}>
          <Grid
            container
            direction="row"
            style={{
              marginBottom: "0px",
              marginTop: "0px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: showAlternatives ? "80px" : "64px",
              }}
            >
              {(editView || createView) && (
                <input
                  style={{ width: "20px", height: "20px"}}
                  type="radio"
                  onKeyDown={onKeyPress}
                  name={question.Id}
                  disabled={onlyModal || loadingQuest}
                  onClick={() => handleAnswer(item.Order)}
                  checked={item.Correct}
                />
              )}
              {
                showAlternatives ?
                  <span style={{ fontSize: '21px', alignSelf: "center" }}>({item.Order}) &nbsp;</span>
                  : <></>
              }
            </Grid>
            <Grid item xs={9} xl={10} md={11} style={{ marginBottom: "-20px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    correctImageSize(
                      item.Description.replace("<p>", "").replace(
                        '<span style="color: #000000;font-size: 21px;font-family: Arial;"></span></p>',
                        ""
                      )
                    )
                  ),
                }}
              />
            </Grid>
          </Grid>
        </List.Item>
      )}
    </>
  );

  const onCheckboxChangeForce = () => {
    let newValue = false;
    onCheck(newValue, question);
  };

  return (
    <>
      {question !== undefined && (
        <ContainerCard>
          <QuestionHeader>
            <QuestionNumber>
              <QuestionInstructions>
                {question !== null &&
                  question !== undefined &&
                  question.Instructions !== undefined &&
                  question.Instructions !== null &&
                  renderHTML(correctImageSize(question.Instructions))}
              </QuestionInstructions>
              <QuestionNumberFont>
                QUESTÃO {renderQuestionNumber(numberQuestion)}
              </QuestionNumberFont>
              <>
                <div>
                  {question.Type === "Multiple Choice" ? (
                    <QuestionType>Objetiva</QuestionType>
                  ) : (
                    <QuestionType>Discursiva</QuestionType>
                  )}
                </div>
              </>
            </QuestionNumber>
          </QuestionHeader>
          {question.IsVideo ? (
            <Player>
              <source src={JSON.parse(question.VideoUrl)["url"]} />
            </Player>
          ) : (
            <Grid container>
              <Box
                sx={{
                  width: '100%',
                  overflowWrap: 'break-word',
                }}
              >
                <Typography>
                  { 
                    question.ParentStatements && question.ParentStatements.length > 0 && 
                    question.ParentStatements.map((content) => (
                        renderHTML(correctImageSize(content))
                    ))
                  }
                  {renderHTML(correctImageSize(question.Statement))}
                </Typography>
              </Box>
            </Grid>
          )}
          <Row>
            {question.Alternatives.length > 0 &&
              (typeChoice === "Multiple Choice" ||
                typeChoice === "MultipleChoice") && (
                <List
                  style={{ width: "100%", marginTop: "10px" }}
                  dataSource={question.Alternatives}
                  renderItem={renderItem}
                />
              )}
          </Row>
        </ContainerCard>
      )}
    </>
  );
}

QuestionOnlineCard.propTypes = {
  onShuffle: PropTypes.func,
  totalChecked: PropTypes.bool,
  master: PropTypes.bool,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  forceDescheck: PropTypes.any,
  correctAnswer: PropTypes.func,
  showAlternatives: PropTypes.bool,
  errorChecked: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(null, mapDispatchToProps)(QuestionOnlineCard);
