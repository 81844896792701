/** @jsx jsx */
import { jsx } from '@emotion/core'

import { Icon } from 'antd'
import { Container, iconStyle, Title, Subtitle } from './styles/Message.styles'

const SelectTemplateMessage = props => {
  return (
    <Container>
      <Icon
        type='arrow-up'
        css={iconStyle}
      />
      <Title>Quase lá ...</Title>
      <Subtitle>Selecione um modelo para continuar</Subtitle>
    </Container>
  )
}

export default SelectTemplateMessage
