import React from "react";
import MaterialSelect from "@material-ui/core/Select";
import { Grid, FormControl, InputLabel, MenuItem } from "@material-ui/core";

export const Select = ({ className, loaded, label, value, onInputChange, options = [] }) => {
  return loaded ? (
    <Grid item>
      <FormControl className={className}>
        <InputLabel>{label}</InputLabel>
        <MaterialSelect
          value={value}
          onChange={onInputChange}
        >
          {options.map(option => (
            <MenuItem value={option.id}>{option.title}</MenuItem>
          ))}
        </MaterialSelect>
      </FormControl>
    </Grid>
  ) : false
}