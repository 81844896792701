export const CLEAR_EXAM_MODULE = 'exams:CLEAR_EXAM_MODULE'
export const EXAM_DOWNLOAD_SUCCESS = 'exams:EXAM_DOWNLOAD_SUCCESS'

export const CHECK_PAGES_AVAILABLE = 'exams:CHECK_PAGES_AVAILABLE'
export const CHECK_PAGES_SUCCESS = 'exams:CHECK_PAGES_SUCCESS'
export const CHECK_PAGES_FAILURE = 'exams:CHECK_PAGES_FAILURE'

export const GET_VIDEOS_LOADED = 'exams:GET_VIDEOS_LOADED'
export const GET_VIDEOS_LOADED_SUCCESS = 'GET_VIDEOS_LOADED_SUCCESS'
export const GET_VIDEOS_LOADED_FAILURE = 'GET_VIDEOS_LOADED_FAILURE'

export const UPLOAD_EXAM = 'exams:UPLOAD_EXAM'
export const UPLOAD_EXAM_SUCCESS = 'exams:UPLOAD_EXAM_SUCCESS'
export const UPLOAD_EXAM_FAILURE = 'exams:UPLOAD_EXAM_FAILURE'

export const UPLOAD_RECOGNITION = 'exams:UPLOAD_RECOGNITION'
export const UPLOAD_RECOGNITION_SUCCESS = 'exams:UPLOAD_RECOGNITION_SUCCESS'
export const UPLOAD_RECOGNITION_FAILURE = 'exams:UPLOAD_RECOGNITION_FAILURE'

export const CREATE_QUESTIONS_BY_AI = 'exams:CREATE_QUESTIONS_BY_AI'
export const CREATE_QUESTIONS_BY_AI_SUCCESS = 'exams:CREATE_QUESTIONS_BY_AI_SUCCESS'
export const CREATE_QUESTIONS_BY_AI_FAILURE = 'exams:CREATE_QUESTIONS_BY_AI_FAILURE'

export const UPLOAD_EXAM_MOBILE = 'exams:UPLOAD_EXAM_MOBILE'
export const UPLOAD_EXAM_MOBILE_SUCCESS = 'exams:UPLOAD_EXAM_MOBILE_SUCCESS'
export const UPLOAD_EXAM_MOBILE_FAILURE = 'exams:UPLOAD_EXAM_MOBILE_FAILURE'

export const FETCH_CHANGE_PAGE = 'exams:FETCH_CHANGE_PAGE'
export const CHANGE_PAGE_SUCCESS = 'exams:CHANGE_PAGE_SUCCESS'
export const CHANGE_PAGE_FAILURE = 'exams:CHANGE_PAGE_FAILURE'

export const FETCH_CLASSIFICATIONS = 'exams:FETCH_CLASSIFICATIONS'
export const FETCH_RECOGNIZED = 'exams:FETCH_RECOGNIZED'

export const SEND_IMAGES = 'exams:SEND_IMAGES'
export const SEND_IMAGES_SUCCESS = 'exams:SEND_IMAGES_SUCCESS'
export const SEND_IMAGES_FAILURE = 'exams:SEND_IMAGES_FAILURE'

export const CLASSIFY_EXAM = 'exams:CLASSIFY_EXAM'
export const CLASSIFY_EXAM_SUCCESS = 'exams:CLASSIFY_EXAM_SUCCESS'
export const CLASSIFY_EXAM_FAILURE = 'exams:CLASSIFY_EXAM_FAILURE'

export const FETCH_EXAM = 'exams:FETCH_EXAM'
export const FETCH_EXAM_SUCCESS = 'exams:FETCH_EXAM_SUCCESS'
export const FETCH_EXAM_FAILURE = 'exams:FETCH_EXAM_FAILURE'

export const FETCH_EXAM_RESULT = 'exams:FETCH_EXAM_RESULT'
export const FETCH_EXAM_RESULT_SUCCESS = 'exams:FETCH_EXAM_RESULT_SUCCESS'
export const FETCH_EXAM_RESULT_FAILURE = 'exams:FETCH_EXAM_RESULT_FAILURE'

export const FETCH_INDIVIDUAL_RESULT_EXAM = 'exams:FETCH_INDIVIDUAL_RESULT_EXAM'
export const FETCH_INDIVIDUAL_RESULT_EXAM_SUCCESS = 'exams:FETCH_INDIVIDUAL_RESULT_EXAM_SUCCESS'
export const FETCH_INDIVIDUAL_RESULT_EXAM_FAILURE = 'exams:FETCH_INDIVIDUAL_RESULT_EXAM_FAILURE'

export const FETCH_STUDENT_EXAMS = 'exams:FETCH_STUDENT_EXAMS'
export const FETCH_STUDENT_EXAMS_SUCCESS = 'exams:FETCH_STUDENT_EXAMS_SUCCESS'
export const FETCH_STUDENT_EXAMS_FAILURE = 'exams:FETCH_STUDENT_EXAMS_FAILURE'

export const FETCH_STUDENT_RESULT_EXAM = 'exams:FETCH_STUDENT_RESULT_EXAM'
export const FETCH_STUDENT_RESULT_EXAM_SUCCESS = 'exams:FETCH_STUDENT_RESULT_EXAM_SUCCESS'
export const FETCH_STUDENT_RESULT_EXAM_FAILURE = 'exams:FETCH_STUDENT_RESULT_EXAM_FAILURE'

export const FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM = 'exams:FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM'
export const FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM_SUCCESS = 'exams:FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM_SUCCESS'
export const FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM_FAILURE = 'exams:FETCH_STUDENT_INDIVIDUAL_RESULT_EXAM_FAILURE'

export const FETCH_STUDENT_RESULT = 'exams:FETCH_STUDENT_RESULT'
export const FETCH_STUDENT_RESULT_SUCCESS = 'exams:FETCH_STUDENT_RESULT_SUCCESS'
export const FETCH_STUDENT_RESULT_FAILURE = 'exams:FETCH_STUDENT_RESULT_FAILURE'

export const FETCH_STUDENT_RESULT_ALL = 'exams:FETCH_STUDENT_RESULT_ALL'
export const FETCH_STUDENT_RESULT_ALL_SUCCESS = 'exams:FETCH_STUDENT_RESULT_ALL_SUCCESS'
export const FETCH_STUDENT_RESULT_ALL_FAILURE = 'exams:FETCH_STUDENT_RESULT_ALL_FAILURE'

export const ANS_QUESTION = 'exams:ANS_QUESTION'
export const ANS_QUESTION_SUCCESS = 'exams:ANS_QUESTION_SUCCESS'
export const ANS_QUESTION_FAILURE = 'exams:ANS_QUESTION_FAILURE'

export const START_ONLINE_EXAM = 'exams:START_ONLINE_EXAM'
export const START_ONLINE_EXAM_SUCCESS = 'exams:START_ONLINE_EXAM_SUCCESS'
export const START_ONLINE_EXAM_FAILURE = 'exams:START_ONLINE_EXAM_FAILURE'

export const END_ONLINE_EXAM = 'exams:END_ONLINE_EXAM'
export const END_ONLINE_EXAM_SUCCESS = 'exams:END_ONLINE_EXAM_SUCCESS'
export const END_ONLINE_EXAM_FAILURE = 'exams:END_ONLINE_EXAM_FAILURE'

export const FETCH_ONLINE_EXAM = 'exams:FETCH_ONLINE_EXAM'
export const FETCH_ONLINE_EXAM_SUCCESS = 'exams:FETCH_ONLINE_EXAM_SUCCESS'
export const FETCH_ONLINE_EXAM_FAILURE = 'exams:FETCH_ONLINE_EXAM_FAILURE'

export const FETCH_ONLINE_EXAM_INFO = 'exams:FETCH_ONLINE_EXAM_INFO'
export const FETCH_ONLINE_EXAM_INFO_SUCCESS = 'exams:FETCH_ONLINE_EXAM_INFO_SUCCESS'
export const FETCH_ONLINE_EXAM_INFO_FAILURE = 'exams:FETCH_ONLINE_EXAM_INFO_FAILURE'

export const FETCH_RESULT_EXAM = 'exams:FETCH_RESULT_EXAM'
export const FETCH_RESULT_EXAM_SUCCESS = 'exams:FETCH_RESULT_EXAM_SUCCESS'
export const FETCH_RESULT_EXAM_FAILURE = 'exams:FETCH_RESULT_EXAM_FAILURE'

export const DOWNLOAD_EXAM = 'exams:DOWNLOAD_EXAM'
export const DOWNLOAD_EXAM_SUCCESS = 'exams:DOWNLOAD_EXAM_SUCCESS'
export const DOWNLOAD_EXAM_FAILURE = 'exams:DOWNLOAD_EXAM_FAILURE'

export const FINISH_EXAM = 'exams:FINISH_EXAM'
export const FINISH_EXAM_SUCCESS = 'exams:FINISH_EXAM_SUCCESS'
export const FINISH_EXAM_FAILURE = 'exams:FINISH_EXAM_FAILURE'

export const FETCH_EXAMS = 'exams:FETCH_EXAMS'
export const FETCH_EXAMS_SUCCESS = 'exams:FETCH_EXAMS_SUCCESS'
export const FETCH_EXAMS_FAILURE = 'exams:FETCH_EXAMS_FAILURE'

export const DELETE_EXAM = 'exams:DELETE_EXAM'
export const DELETE_EXAM_SUCCESS = 'exams:DELETE_EXAM_SUCCESS'
export const DELETE_EXAM_FAILURE = 'exams:DELETE_EXAM_FAILURE'

export const DELETE_TAG = 'exams:DELETE_TAG'
export const DELETE_TAG_SUCCESS = 'exams:DELETE_TAG_SUCCESS'
export const DELETE_TAG_FAILURE = 'exams:DELETE_TAG_FAILURE'

export const ADD_TAG = 'exams:ADD_TAG'
export const ADD_TAG_SUCCESS = 'exams:ADD_TAG_SUCCESS'
export const ADD_TAG_FAILURE = 'exams:ADD_TAG_FAILURE'

export const SET_EXAM_ID = 'exams:SET_EXAM_ID'
export const SET_CAN_EDIT = 'exams:SET_CAN_EDIT'

export const FETCH_SELECTED = 'exams:FETCH_SELECTED'
export const FETCH_SELECTED_SUCCESS = 'exams:FETCH_SELECTED_SUCCESS'
export const FETCH_SELECTED_FAILURE = 'exams:FETCH_SELECTED_FAILURE'

export const DELETE_QUESTION = 'exams:DELETE_QUESTION'
export const DELETE_QUESTION_SUCCESS = 'exams:DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAILURE = 'exams:DELETE_QUESTION_FAILURE'

export const SEARCH_EXAM_TAG = 'exams:SEARCH_EXAM_TAG'
export const SEARCH_EXAM_TAG_SUCCESS = 'exams:SEARCH_EXAM_TAG_SUCCESS'
export const SEARCH_EXAM_TAG_FAILURE = 'exams:SEARCH_EXAM_TAG_FAILURE'

export const SELECTED_EXAMS = 'exams:SELECTED_EXAMS'

export const SELECTED_QUESTIONS = 'exams:SELECTED_QUESTIONS'

export const SET_CART_QUESTIONS = 'SET_CART_QUESTIONS';

export const CREATE_EXAM = 'exams:CREATE_EXAM'
export const CREATE_EXAM_SUCCESS = 'exams:CREATE_EXAM_SUCCESS'
export const CREATE_EXAM_FAILURE = 'exams:CREATE_EXAM_FAILURE'

export const UPDATE_EXAM = 'exams:UPDATE_EXAM'
export const UPDATE_EXAM_SUCCESS = 'exams:UPDATE_EXAM_SUCCESS'
export const UPDATE_EXAM_FAILURE = 'exams:UPDATE_EXAM_FAILURE'

export const OPEN_TEST_UPDATE = 'exams:OPEN_TEST_UPDATE'
export const OPEN_TEST_UPDATE_SUCCESS = 'exams:OPEN_TEST_UPDATE_SUCCESS'
export const OPEN_TEST_UPDATE_FAILURE = 'exams:OPEN_TEST_UPDATE_FAILURE'


export const FETCH_EXAM_IMAGE = 'exams:FETCH_EXAM_IMAGE'
export const FETCH_EXAM_IMAGE_SUCCESS = 'exams:FETCH_EXAM_IMAGE_SUCCESS'
export const FETCH_EXAM_IMAGE_FAILURE = 'exams:FETCH_EXAM_IMAGE_FAILURE'

export const FETCH_EXAM_REPORT = 'exams:FETCH_EXAM_REPORT'

export const FETCH_EXAM_MATRIX_EF = 'exams:FETCH_EXAM_MATRIX_EF'
export const FETCH_EXAM_MATRIX_EF_SUCCESS = 'exams:FETCH_EXAM_MATRIX_EF_SUCCESS'
export const FETCH_EXAM_MATRIX_EF_FAILURE = 'exams:FETCH_EXAM_MATRIX_EF_FAILURE'

export const FETCH_EXAM_MATRIX_EM = 'exams:FETCH_EXAM_MATRIX_EM'
export const FETCH_EXAM_MATRIX_EM_SUCCESS = 'exams:FETCH_EXAM_MATRIX_EM_SUCCESS'
export const FETCH_EXAM_MATRIX_EM_FAILURE = 'exams:FETCH_EXAM_MATRIX_EM_FAILURE'

export const FETCH_EXAM_HABILITIES = 'exams:FETCH_EXAM_HABILITIES'
export const FETCH_EXAM_HABILITIES_SUCCESS = 'exams:FETCH_EXAM_HABILITIES_SUCCESS'
export const FETCH_EXAM_HABILITIES_FAILURE = 'exams:FETCH_EXAM_HABILITIES_FAILURE'

export const FETCH_EQUATION_IMAGE = 'exams:FETCH_EQUATION_IMAGE'
export const FETCH_EQUATION_IMAGE_SUCCESS = 'exams:FETCH_EQUATION_IMAGE_SUCCESS'
export const FETCH_EQUATION_IMAGE_FAILURE = 'exams:FETCH_EQUATION_IMAGE_FAILURE'

export const SELECIONAR_QUESTAO = 'exams:SELECIONAR_QUESTAO'
export const MARCAR_ALTERNATIVA = 'exams:MARCAR_ALTERNATIVA'
export const EDITAR_ALTERNATIVA = 'exams:EDITAR_ALTERNATIVA'
export const SALVAR_QUESTAO = 'exams:SALVAR_QUESTAO'
export const SALVAR_QUESTOES = 'exams:SALVAR_QUESTOES'
export const SET_EDITING = 'exams:SET_EDITING'

export const ADICIONAR_TAG = 'exams:ADICIONAR_TAG'
export const REMOVER_TAG = 'exams:REMOVER_TAG'

export const CHANGE_CONTENT = 'exams:CHANGE_CONTENT'

export const ADICIONAR_EXAM_TAG = 'exams:ADICIONAR_EXAM_TAG'
export const REMOVER_EXAM_TAG = 'exams:REMOVER_EXAM_TAG'

export const SEARCH_SELECTED = 'exams:SEARCH_SELECTED'
export const SEARCH_SELECTED_SUCCESS = 'exams:SEARCH_SELECTED_SUCCESS'
export const SEARCH_SELECTED_FAILURE = 'exams:SEARCH_SELECTED_FAILURE'

export const FETCH_POWERBI_REPORT = 'exams:FETCH_POWERBI_REPORT'
export const FETCH_POWERBI_REPORT_SUCCESS = 'exams:FETCH_POWERBI_REPORT_SUCCESS'
export const FETCH_POWERBI_REPORT_FAILURE = 'exams:FETCH_POWERBI_REPORT_FAILURE'

export const FETCH_ANO_TRILHAS = 'exams:FETCH_ANO_TRILHAS'
export const FETCH_ANO_TRILHAS_SUCCESS = 'exams:FETCH_ANO_TRILHAS_SUCCESS'
export const FETCH_ANO_TRILHAS_FAILURE = 'exams:FETCH_ANO_TRILHAS_FAILURE'

export const FETCH_DISCIPLINAS_TRILHAS = 'exams:FETCH_DISCIPLINAS_TRILHAS'
export const FETCH_DISCIPLINAS_TRILHAS_SUCCESS = 'exams:FETCH_DISCIPLINAS_TRILHAS_SUCCESS'
export const FETCH_DISCIPLINAS_TRILHAS_FAILURE = 'exams:FETCH_DISCIPLINAS_TRILHAS_FAILURE'

export const FETCH_STUDENTS_TRILHAS = 'exams:FETCH_STUDENTS_TRILHAS'
export const FETCH_STUDENTS_TRILHAS_SUCCESS = 'exams:FETCH_STUDENTS_TRILHAS_SUCCESS'
export const FETCH_STUDENTS_TRILHAS_FAILURE = 'exams:FETCH_STUDENTS_TRILHAS_FAILURE'

export const FETCH_RESULT_TRILHAS = 'exams:FETCH_RESULT_TRILHAS'
export const FETCH_RESULT_TRILHAS_SUCCESS = 'exams:FETCH_RESULT_TRILHAS_SUCCESS'
export const FETCH_RESULT_TRILHAS_FAILURE = 'exams:FETCH_RESULT_TRILHAS_FAILURE'

export const FETCH_UNIDADES_TEMATICAS = 'exams:FETCH_UNIDADES_TEMATICAS'
export const FETCH_UNIDADES_TEMATICAS_SUCCESS = 'exams:FETCH_UNIDADES_TEMATICAS_SUCCESS'
export const FETCH_UNIDADES_TEMATICAS_FAILURE = 'exams:FETCH_UNIDADES_TEMATICAS_FAILURE'

export const FETCH_READ_AND_WRITING = 'exams:FETCH_READ_AND_WRITING'
export const FETCH_READ_AND_WRITING_SUCCESS = 'exams:FETCH_READ_AND_WRITING_SUCCESS'
export const FETCH_READ_AND_WRITING_FAILURE = 'exams:FETCH_READ_AND_WRITING_FAILURE'

export const FETCH_READING_AND_WRITING_DATA_CSV = 'exams:FETCH_READING_AND_WRITING_DATA_CSV'
export const FETCH_READING_AND_WRITING_DATA_CSV_SUCCESS = 'exams:FETCH_READING_AND_WRITING_DATA_CSV_SUCCESS'
export const FETCH_READING_AND_WRITING_DATA_CSV_FAILURE = 'exams:FETCH_READING_AND_WRITING_DATA_CSV_FAILURE'

export const UPDATE_RESULT_RW = 'exams:UPDATE_RESULT_RW'
export const UPDATE_RESULT_RW_SUCCESS = 'exams:UPDATE_RESULT_RW_SUCCESS'
export const UPDATE_RESULT_RW_FAILURE = 'exams:UPDATE_RESULT_RW_FAILURE'

export const FETCH_CONSOLIDACAO = 'exams:FETCH_CONSOLIDACAO'
export const FETCH_CONSOLIDACAO_SUCCESS = 'exams:FETCH_CONSOLIDACAO_SUCCESS'
export const FETCH_CONSOLIDACAO_FAILURE = 'exams:FETCH_CONSOLIDACAO_FAILURE'
