import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateResultRW } from "../../../store/exams/actions";

import AssessmentCheckbox from "./AssessmentCheckbox";
import AdaptativeAssessment from "../writing/AdaptativeAssessment";
import ProsodySelect from "../reading/ProsodySelect";
import ComprehensionSelect from "../reading/ComprehensionSelect";
import PrecisionInput from "../reading/PrecisionInput";
import TimeInput from "../reading/TimeInput";
import ProgressBar from "./ProgressBar";
import Observation from "./Observation";
import { message } from "antd";

const useStyles = makeStyles((theme) => ({
  paddingCell: {
    "&.MuiTableCell-paddingNone": {
      padding: "8px 8px 4px 8px",
    },
  },
  studentsNameColumn: {
    width: "250px",
    "&.MuiTableCell-paddingNone": {
      padding: "8px 8px 4px 8px",
    },
  },
  checkboxTitleColumn: {
    "&.MuiTableCell-paddingNone": {
      padding: "8px 8px 4px 8px",
    },
    width: "100px",
  },
  turmaTable: {
    marginTop: "16px",
  },
  conditionalFieldsColumn: {
    "&.MuiTableCell-paddingNone": {
      padding: "8px 8px 4px 8px",
    },
    width: "130px",
    backgroundColor: "#EEE",
  },
}));

const StudentEvaluationTable = ({
  grade,
  month,
  semanticField,
  area,
  setSizeAndCount,
  setResultRW,
  resultRW,
  updateRW,
  updateError,
  loadingUpdate,
  sizeResult,
  countResult,
  writingFields,
  writingLabels,
  ReadingFluencyFields,
  ReadingFluencyLabels,
  conditionalFields,
  checkedFieldColors
}) => {
  const [openObsevation, setOpenObservation] = useState(false);
  const [observation, setObservation] = useState({
    id: "",
    message: "",
  });
  let [studentsResult, setStudentsResult] = useState([]);
  const studentsData = useRef([]);
  const classes = useStyles();

  useEffect(() => {
    if (month && resultRW && resultRW[month]) {
      const students = Object.entries(resultRW[month])
        .map(([key, value]) => ({
          StudentId: key,
          ...value,
        }))
        .sort((a, b) => a.Name.localeCompare(b.Name));
      setStudentsResult(students);
      studentsData.current = students;
    } else {
      setStudentsResult([]);
    }
  
  }, [month, resultRW, updateRW]);

  useEffect(() => {
    console.log(studentsResult);
  }, [studentsResult]);

  useEffect(() => {
    if(loadingUpdate) return;
    if (updateError && typeof updateError === 'object' && Object.keys(updateError).length > 0) {
      message.error("Houve um erro interno ou de conexão. Verifique a internet e tente novamente.")
    } else {
      if (updateError === null)
        message.success("Marcações carregadas e atualizadas!");
    }
  }, [updateError, loadingUpdate]);

  const showEditObservationButton = (studentId, field) => {
    const studentIndex = studentsResult.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsResult[studentIndex];
    const naoAvaliado = area === "Writing" ? "NaoAvaliado" : "NaoAvaliadoRF";

    if (field === naoAvaliado) {
      if (studentData[area][field] === true) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const handleEditObservation = (studentId) => {
    console.log(studentId);
    const studentIndex = studentsData.current.findIndex(
      (item) => item.StudentId === studentId
    );
    let studentData = studentsData.current[studentIndex];
    if (!studentData.Observation) {
      studentData.Observation = {};
    }
    let observation = studentData.Observation[area];
    console.log(studentData);
    console.log(studentsData.current[studentIndex]);

    setObservation({
      id: studentId,
      message: observation ? observation : "",
    });
    setOpenObservation(true);
  };

  return (
    <>
      {grade && month ? (
        <TableContainer
          key={"table"}
          component={Paper}
          className={classes.turmaTable}
        >
          <Table size="small" id={1}>
            <TableBody>
              <TableRow key="">
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.studentsNameColumn}
                >
                  <div>Nome</div>
                </TableCell>
                {(area === "Writing"
                  ? writingFields
                  : ReadingFluencyFields
                ).map((f) => (
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    className={classes.checkboxTitleColumn}
                  >
                    <div>
                      {
                        (area === "Writing"
                          ? writingLabels
                          : ReadingFluencyLabels)[f]
                      }
                    </div>
                  </TableCell>
                ))}
              </TableRow>
              {studentsResult.map((row, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      <TableCell
                        key={index}
                        component="th"
                        scope="row"
                        className={classes.paddingCell}
                      >
                        {row.Name}
                      </TableCell>
                      {(area === "Writing"
                        ? writingFields
                        : ReadingFluencyFields
                      ).map((f) =>
                        !conditionalFields.includes(f) ? (
                          <AssessmentCheckbox
                            semanticField={semanticField}
                            conditionalFields={conditionalFields}
                            initialStudentsData={studentsResult}
                            style={classes.paddingCell}
                            studentId={row.StudentId}
                            month={month}
                            field={f}
                            area={area}
                            checked={row[area][f]}
                            setObservation={setObservation}
                            setOpenObservation={setOpenObservation}
                            handleEditObservation={handleEditObservation}
                            showEditObservationButton={
                              showEditObservationButton
                            }
                            key={row.Name + grade + month + f + row[area][f] + semanticField}
                          />
                        ) : (
                          <>
                            {area === "Writing" ? (
                              <AdaptativeAssessment
                                studentsResult={studentsResult}
                                studentId={row.StudentId}
                                month={month}
                                setResultRW={setResultRW}
                                cellStyle={classes.conditionalFieldsColumn}
                                key={
                                  row.Name + grade + month + f + row[area][f]
                                }
                              />
                            ) : (
                              <>
                                {f === "Prosodia" && (
                                  <ProsodySelect
                                    studentsResult={studentsResult}
                                    studentId={row.StudentId}
                                    month={month}
                                    setResultRW={setResultRW}
                                    style={classes.conditionalFieldsColumn}
                                    key={
                                      row.Name +
                                      grade +
                                      month +
                                      f +
                                      row[area][f]
                                    }
                                  />
                                )}
                                {f === "Compreensao" && (
                                  <ComprehensionSelect
                                    studentsResult={studentsResult}
                                    studentId={row.StudentId}
                                    month={month}
                                    setResultRW={setResultRW}
                                    style={classes.conditionalFieldsColumn}
                                    key={
                                      row.Name +
                                      grade +
                                      month +
                                      f +
                                      row[area][f]
                                    }
                                  />
                                )}
                                {f === "Precisao" && (
                                  <PrecisionInput
                                    studentsResult={studentsResult}
                                    studentId={row.StudentId}
                                    month={month}
                                    setResultRW={setResultRW}
                                    style={classes.conditionalFieldsColumn}
                                    key={
                                      row.Name +
                                      grade +
                                      month +
                                      f +
                                      row[area][f]
                                    }
                                  />
                                )}
                                {f === "Tempo" && (
                                  <TimeInput
                                    studentsResult={studentsResult}
                                    studentId={row.StudentId}
                                    month={month}
                                    setResultRW={setResultRW}
                                    style={classes.conditionalFieldsColumn}
                                    key={
                                      row.Name +
                                      grade +
                                      month +
                                      f +
                                      row[area][f]
                                    }
                                  />
                                )}
                              </>
                            )}
                          </>
                        )
                      )}
                    </TableRow>
                    <Observation
                      studentId={observation.id}
                      setResultRW={setResultRW}
                      openObsevation={openObsevation}
                      setOpenObservation={() => setOpenObservation()}
                      setSizeAndCount={setSizeAndCount}
                      observation={observation}
                      setObservation={setObservation}
                      area={area}
                      month={month}
                    />
                  </>
                );
              })}

              <ProgressBar
                area={area}
                fields={
                  area === "Writing" ? writingFields : ReadingFluencyFields
                }
                labels={
                  area === "Writing" ? writingLabels : ReadingFluencyLabels
                }
                style={classes.paddingCell}
                sizeResult={sizeResult}
                countResult={countResult}
                conditionalFields={conditionalFields}
                checkedFieldColors={checkedFieldColors}
              />
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
};

StudentEvaluationTable.propTypes = {
  updateResultRW: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams }) => ({
  resultRW: exams.resultRW,
  updateRW: exams.updateRW,
  updateError: exams.error,
  loadingUpdate: exams.loadingUpdateRW,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateResultRW,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentEvaluationTable);
