import { all, call, takeLatest, put } from 'redux-saga/effects'
import { normalizeError } from '../../utils/errorHelpers'
import { message } from 'antd'
import * as actions from './actions'
import * as types from './constants'
import * as services from './services'

export function * createUser ({ payload }) {
  try {
    let response = yield call(services.createUser, payload)
    if (response.status !== undefined) {
      const errorInfo = normalizeError(response.data, response.data[0])
      message.error(errorInfo.message)
      yield put(actions.createUserFailure(errorInfo))
    } else {
      message.success('Usuário salvo com sucesso')
      yield put(actions.createUserSuccess(response))
    }
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Criar Usuário')
    message.error(errorInfo.message)
    yield put(actions.createUserFailure(errorInfo))
  }
}

export function * updateUser ({ payload }) {
  try {
    let response = yield call(services.updateUser, payload)
    if (payload.Password !== null && payload.Password !== undefined && payload.Password !== '') {
      let newPass = {
        UserId: payload.Id,
        New: payload.Password,
        ConfirmNew: payload.Password
      }
      yield call(services.updatePassword, newPass)
    }
    message.success('Usuário Atualizado com sucesso')
    yield put(actions.updateUserSuccess(response))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Atualizar Usuário')
    message.error(errorInfo.message)
    yield put(actions.updateUserFailure(errorInfo))
  }
}

export function * fetchUser ({ payload: id }) {
  try {
    let response = yield call(services.fetchUser, id)
    yield put(actions.fetchUserSuccess(response))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Buscar Usuário')
    message.error(errorInfo.message)
    yield put(actions.fetchUserFailure(errorInfo))
  }
}

export function * infoUser () {
  try {
    let response = yield call(services.infoUser)
    yield put(actions.infoUserSuccess(response))
  } catch (error) {
    // const errorInfo = normalizeError(error, 'Falha ao Buscar Usuário')
    // message.error(errorInfo.message)
    // yield put(actions.infoUserFailure(errorInfo))
  }
}

export function * fetchPlanExpirationDate () {
  try {
    let response = yield call(services.fetchPlanExpirationDate)
    yield put(actions.fetchPlanExpirationDateSuccess(response))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Buscar Data de Expiração')
    message.error(errorInfo.message)
    yield put(actions.fetchPlanExpirationDateFailure(errorInfo))
  }
}

export function * checkSubscriptionStatus () {
  try {
    let response = yield call(services.checkSubscriptionStatus)
    yield put(actions.checkSubscriptionStatusSuccess(response))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Buscar Status da Assinatura')
    message.error(errorInfo.message)
    yield put(actions.checkSubscriptionStatusFailure(errorInfo))
  }
}

export function * deleteUser ({ payload: id }) {
  try {
    yield call(services.deleteUser, id)
    yield put(actions.deleteUserSuccess())
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Remover Usuário')
    message.error(errorInfo.message)
    yield put(actions.deleteUserFailure(errorInfo))
  }
}

export function * fetchUsers ({ payload: { title, page } }) {
  try {
    let users = {
      users: [],
      total: 0
    }
    let response = yield call(services.fetchUsers, title, page, 'active')
    let responsePending = yield call(services.fetchUsers, title, page, 'pending')

    let auxItens = response.Itens.concat(responsePending.Itens)
    let totalItens = response.TotalItens + responsePending.TotalItens
    users.total = totalItens
    users.users = auxItens
    console.log('users', users)
    yield put(actions.fetchUsersSuccess(users))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao Buscar Usuários')
    message.error(errorInfo.message)
    yield put(actions.fetchUsersFailure(errorInfo))
  }
}

export function * uploadSpreadsheet({ payload : file }){
  try {
    let response = yield call(services.uploadSpreadsheet, file)
    if (response === undefined) {
      throw new Error();
    } else {
      message.success('Planilha enviada com sucesso')
      yield put(actions.uploadSpreadsheetSuccess(response))
    }
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao enviar planilha')
    message.error(errorInfo.message)
    yield put(actions.uploadSpreadsheetFailure(errorInfo))
  }
}

export function * handleTrilhasLogin({ payload: email }) {
  try {
    let response = yield call(services.trilhasLogin, email)
    if (response === undefined) {
      throw new Error();
    } else {
      window.open(
        `https://www.ailo.ai/?token=${response.access}&email=${email}`,
        '_blank'
      );

      yield put(actions.handleTrilhasLoginSuccess(response))
    }
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao se autenticar nas trilhas')
    message.error(errorInfo.message)
    yield put(actions.handleTrilhasLoginFailure(errorInfo))
  }
}

export function * watchFetchUsers () {
  yield takeLatest(types.FETCH_USERS, fetchUsers)
}

export function * watchFetchUser () {
  yield takeLatest(types.FETCH_USER, fetchUser)
}

export function * watchInfoUser () {
  yield takeLatest(types.INFO_USER, infoUser)
}

export function * watchFetchPlanExpirationDate () {
  yield takeLatest(types.FETCH_PLAN_EXPIRATION_DATE, fetchPlanExpirationDate)
}

export function * watchCheckSubscriptionStatus () {
  yield takeLatest(types.CHECK_SUBSCRIPTION_STATUS, checkSubscriptionStatus)
}

export function * watchCreateUser () {
  yield takeLatest(types.CREATE_USER, createUser)
}

export function * watchUpdateUser () {
  yield takeLatest(types.UPDATE_USER, updateUser)
}

export function * watchDeleteUser () {
  yield takeLatest(types.DELETE_USER, deleteUser)
}

export function* watchUploadSpreadsheet() {
  yield takeLatest(types.UPLOAD_SPREADSHEET, uploadSpreadsheet);
}

export function* watchHandleTrilhasLogin() {
  yield takeLatest(types.HANDLE_TRILHAS_LOGIN, handleTrilhasLogin);
}

export default function * () {
  yield all([
    watchCreateUser(),
    watchUpdateUser(),
    watchFetchUsers(),
    watchFetchUser(),
    watchInfoUser(),
    watchDeleteUser(),
    watchFetchPlanExpirationDate(),
    watchUploadSpreadsheet(),
    watchCheckSubscriptionStatus(),
    watchHandleTrilhasLogin()
  ])
}
