import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import OutputErrorFeedback from '../feedback/OutputErrorFeedback'
import ProcessingMessage from '../message/ProcessingMessage'
import SelectTemplateMessage from '../message/SelectTemplateMessage'
import ProcessingSuccessfulMessage from '../message/ProcessingSuccessfulMessage'
import { Button } from 'antd'
import { StyledOutput } from './styles/Output.styles'

const Output = ({
  loading,
  outputs,
  hideWarnings,
  documentId,
  templateId,
  validations,
  documentError,
  outputError
}) => {
  const validation = validations.find(validation => validation.document.id === documentId)
  const isError = documentError.message || outputError.message || (validation && validation.status !== 'OK')
  if (!loading && isError) return <OutputErrorFeedback />

  const output = outputs.find(o => o.template.id === templateId && o.document.id === documentId)

  const url = !output
    ? null
    : hideWarnings
      ? output.without_correction
      : output.files_url && output.files_url[0]

  const successMessage = (
    <ProcessingSuccessfulMessage>
      <Button
        style={{ color: 'white' }}
        disabled={loading}
        href={url}
        icon='download'
        shape='round'
        size='large'
        type='primary'
      >
        Baixar Documento
      </Button>
    </ProcessingSuccessfulMessage>
  )

  return (
    <StyledOutput>
      {
        loading
          ? <ProcessingMessage />
          : !templateId
            ? <SelectTemplateMessage />
            : successMessage
      }
    </StyledOutput>
  )
}

Output.propTypes = {
  outputs: PropTypes.array,
  hideWarnings: PropTypes.bool,
  loading: PropTypes.bool,
  documentId: PropTypes.string,
  templateId: PropTypes.string,
  validations: PropTypes.array,
  documentError: PropTypes.object,
  outputError: PropTypes.object
}

const mapStateToProps = ({ documents, templates, outputs }) => ({
  outputs: outputs.outputs,
  hideWarnings: outputs.hideWarnings,
  loading: documents.loading || outputs.loading || templates.loading,
  documentId: documents.id,
  templateId: templates.id,
  validations: documents.validations,
  documentError: documents.error,
  outputError: outputs.error
})

export default connect(mapStateToProps)(Output)
