import React from 'react'

const DefaultHelp = () => (
  <>
    <p>Arquivo inválido.</p>
    <p>Certifique-se de que o seu arquivo:</p>
    <ol>
      <li>
        Está em algum dos seguintes formatos: DOCX, DOC, PDF, RTF, JPEG, JPG, PNG
      </li>
      <li>
        Possui menos de 10 MB.
      </li>
    </ol>
  </>
)

export default DefaultHelp
