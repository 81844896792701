import React, { Component } from 'react'
import CheckoutMessage from '../components/message/CheckoutMessage'

import { Page } from './styles/Page.styles'

class CheckoutPage extends Component {

  render () {

    return (
      <Page>
        <CheckoutMessage />
      </Page>
    )
  }

}

export default CheckoutPage