import React from 'react'

export const handleBlur = (form, field) => {
  form.handleBlur(field.name)
  form.setFieldTouched(field.name)
}

export const handleChange = (form, field, value) => {
  form.setFieldValue(field.name || field, value)
}

export const getOptions = (Component, options) =>
  options.map(option => (
    <Component
      key={option.value}
      value={option.value}
      selected={option.selected}
    >
      {option.label}
    </Component>
  ))

export const mapEntriesToJSON = entries => {
  if (!entries) return
  return entries.reduce((acc, cur) => ({
    ...acc,
    [cur[0]]: (Number(acc[cur[0]]) || 0) + Number(cur[1])
  }), {})
}
