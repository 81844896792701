/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'

import { Modal, Button } from 'antd'

const WarningModal = ({
  onCancel,
  visible,
  message
}) => {
  const backButton = (
    <Button
      htmlType='button'
      onClick={onCancel}
    >
      Ok
    </Button>
  )

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={backButton}
      destroyOnClose
    >
      <h4>{message}</h4>
    </Modal>
  )
}

WarningModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string,
  visible: PropTypes.bool.isRequired
}

export default WarningModal
