import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
// import ValidatedSharedInput from './ValidatedSharedInput'
import { handleChange } from '../../utils/formHelpers'
import { getRoles, getClasses } from '../../store/auth/services'

class SelectGrades extends Component {
  state = {
    initialValue: this.props.initialValue,
    loading: false,
    timeout: false,
    rolesOptions: [],
    input: ''
  }

  static propTypes = {
    // label: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    user: PropTypes.object,
    initialValue: PropTypes.string
    // search: PropTypes.bool
  }

  rolesOptions() {
    getClasses().then(classes => {
      if (classes && classes.Classes) {
        const uniqueOptions = Array.from(new Set(classes.Classes)).map(j => (
          <Select.Option key={j} value={j} title={j}>
            {j}
          </Select.Option>
        ));
        
        this.setState({ rolesOptions: uniqueOptions });
      }
    });
  }

  rolesOptionsSearch (value) {
    let dados = []
    getRoles().then(roles => {
      roles.map(j => {
        if (j === 'Admin') { j = 'Master' }
        let option =
          <Select.Option
            key={j}
            value={j}
            title={j}
          >
            {j}
          </Select.Option>

        if (this.props.user.Role === 'Master') {
          if (j === 'Teacher' || j === 'Student') {
            dados.push(option)
          }
        } else {
          dados.push(option)
        }
        return option
      })
    })

    this.setState({ rolesOptions: dados })
  }

  componentDidMount () {
    this.rolesOptions()
  }

  componentDidUpdate (prevProps) {
    const { initialValue } = this.props
    prevProps.initialValue !== initialValue && this.setState({ initialValue })
  }

  render () {
    const {
      initialValue,
      // loading,
      rolesOptions
    } = this.state
    const {
      // label,
      field,
      form
      // // search
    } = this.props

    return (
      <div>
        <Select
          mode={'multiple'}
          className='select-input'
          // value={selected}
          value={initialValue || (field.value !== null ? field.value : undefined)}
          onChange={value => this.setState({ initialValue: '' }, () => handleChange(form, field, value))}
          // onChange={value => handleChange(value)}
          showSearch
          // onSearch={value => rolesOptionsSearch(value)}
          style={{ width: '100%' }}
          // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          // notFoundContent={loading ? <Spin spinning size='small' /> : 'Nada encontrado'}
          placeholder={'Turma(s)'}
          // loading={loading}
          allowClear
        >
          {rolesOptions}
        </Select>
      </div>
    )
  }

  handleSearch = input => {
    clearTimeout(this.state.timeout)
    const timeout = setTimeout(() => this.rolesOptionsSearch({ input }), 400)

    this.setState({ input, timeout, loading: false })
  }
}

export default SelectGrades
