import React from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withFormik, Field } from 'formik'
import { Form } from 'antd'
import * as yup from 'yup'
import { StyledLoginForm, Title } from './styles/Form.styles'
import PasswordInput from '../input/PasswordInput'
import SubmitButton from '../button/SubmitButton'
import { changePassword } from '../../store/auth/actions'

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changePassword
  }, dispatch)

const handleSubmit = (values, formikBag) => {
  const { token } = formikBag.props
  const payload = { ...values, token }
  formikBag.props.changePassword(payload)
}

const validationSchema = props => {
  return yup.object().shape({
    password: yup.string().min(6).required(),
    passwordConfirmation: yup.string().min(6).equals(yup.ref('password'), 'Senhas não conferem').required()
  })
}

const ChangePasswordForm = ({
  loading,
  isValid
}) => {
  return (
    <StyledLoginForm>
      <Title>Alterar Senha</Title>
      <Field
        name='password'
        placeholder='Nova Senha'
        component={PasswordInput}
      />
      <Field
        name='passwordConfirmation'
        placeholder='Confirmação de Senha'
        component={PasswordInput}
      />
      <Form.Item>
        <SubmitButton
          disabled={!isValid}
          loading={loading}
        >
          Alterar Senha
        </SubmitButton>
      </Form.Item>
    </StyledLoginForm>
  )
}

ChangePasswordForm.propTypes = {
  loading: PropTypes.bool,
  isValid: PropTypes.bool
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit,
    validationSchema
  })
)

export default enhance(ChangePasswordForm)
