import React, { useEffect } from 'react'
import LabelStudio from '../../label-studio'
import '../../label-studio/build/static/css/main.css'

// eslint-disable-next-line
export default function LabelStudioUI ({ images, updateImage, labelConfig }) {
  useEffect(() => {
    // eslint-disable-next-line
    var labelStudio = new LabelStudio('label-studio', {
      config: labelConfig,

      interfaces: [
        'submit',
        'update',
        'controls',
        'side-column'
      ],

      task: images,

      onLabelStudioLoad: function (LS) {
        var c = LS.annotationStore.addAnnotation({
          userGenerate: true
        })
        LS.annotationStore.selectAnnotation(c.id)
      },
      onSubmitAnnotation: async function (LS, annotation) {
        updateImage(annotation.serializeAnnotation())
      },
      onUpdateAnnotation: async function (LS, annotation) {
        updateImage(annotation.serializeAnnotation())
      }
    })
    console.log('labelStudio.annotation', labelStudio.annotationStore.annotations.toJSON()[0].serializeAnnotation())
  }, [images, labelConfig])

  return (
    <div
      id='label-studio'
    >{console.log('label-studio', images)}</div>
  )
}
