import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import get from 'lodash/get'

const AdminPermission = ({
  isAdmin,
  children
}) => {
  return (
    <Fragment>
      {isAdmin && children}
    </Fragment>
  )
}

AdminPermission.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  children: PropTypes.node
}

const mapStateToProps = ({ auth }) => ({
  isAdmin: get(auth, 'user.role') === 'admin'
})

export default connect(mapStateToProps)(AdminPermission)
