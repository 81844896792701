import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { renderQuestionNumber, renderQuestionPosition, renderQuestionWarning } from '../../utils/questionHelpers'
import get from 'lodash/get'

import { Collapse, Icon } from 'antd'
import { Container, Header, Warning, WarningLabel, WarningContent, WarningType } from './styles/DocumentWarningsSummary.styles'

const DocumentWarningsSummary = ({ warnings }) => {
  const [isOpen, setIsOpen] = useState(false)
  if (!warnings || warnings.length === 0) return null

  const renderHeader = warnings => (
    <Header>
      <Icon
        type='warning'
        theme='twoTone'
        twoToneColor='#e67e22'
      />
      <span>Identificamos <b>{warnings.length}</b> problema{warnings.length !== 1 && 's'}.</span>
    </Header>
  )

  const renderWarnings = (warnings) => {
    return warnings.map((warning, i) =>
      <Warning key={i}>
        <WarningLabel>questão {renderQuestionNumber(warning.questionNumber) || '??'}</WarningLabel>
        <WarningContent>
          {renderPosition(warning.position)}
          {warning.warnings.map(renderWarning)}
        </WarningContent>
      </Warning>
    )
  }

  const renderWarning = (warning, i) => {
    return (
      <WarningType key={i}>
        <Icon
          type='warning'
          theme='twoTone'
          twoToneColor='#dd4d41'
          style={{ marginRight: '.5rem' }}
        />
        {renderQuestionWarning(warning)}
      </WarningType>
    )
  }

  const renderPosition = position => {
    return position &&
      <p>
        <Icon
          type='environment'
          theme='twoTone'
          twoToneColor='#ffb30b'
          style={{ marginRight: '.5rem' }}
        />
        {`O problema está ${renderQuestionPosition(position)}.`}
      </p>
  }

  return (
    <Container onBlur={() => setIsOpen(false)}>
      <Collapse
        activeKey={isOpen ? '0' : '-1'}
        bordered={false}
        onChange={() => setIsOpen(!isOpen)}
      >
        <Collapse.Panel header={renderHeader(warnings)}>
          {renderWarnings(warnings)}
        </Collapse.Panel>
      </Collapse>
    </Container>
  )
}

DocumentWarningsSummary.propTypes = {
  warnings: PropTypes.array
}

const mapStateToProps = ({ documents }) => ({
  warnings: get(documents.validations.find(validation => validation.document.id === documents.id), 'questionSummary.warnings')
})

export default connect(mapStateToProps)(DocumentWarningsSummary)
