/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withFormik, Field } from 'formik'
import { Form } from 'antd'
// import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { StyledLoginForm, Title, Subtitle, A } from './styles/Form.styles'
import SubmitButton from '../button/SubmitButton'
import EmailInput from '../input/EmailInput'
import PasswordInput from '../input/PasswordInput'
import { signup } from '../../store/auth/actions'
import VoucherInput from '../input/VoucherInput'
import NameInput from '../input/NameInput'
import { Content, Group } from '../template/styles/HeaderTemplate.styles' 
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo.svg'
import PhoneInput from '../input/PhoneInput'

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    signup
  }, dispatch)

const handleSubmit = (values, formikBag) => {
  values.grant_type = 'password'
  console.log('signup')
  formikBag.props.signup(values)
}

const phoneRegEx = /^[0-9]{2}[0-9]?[0-9]{4}[0-9]{4}$/;

const validationSchema = yup.object().shape({
  username: yup.string().required(),
  email: yup.string().email().required(),
  password1: yup.string().required(),
  password2: yup
    .string()
    .oneOf([yup.ref('password1'), null], 'Digite a mesma senha')
    .required(),
  termos: yup.bool().oneOf([true], 'Campo deve ser marcado').required(),
  telefone: yup
  .string()
  .test(
    'phone-number',
    'Apenas números. Padrão: (xx) xxxxx-xxxx ou (xx) xxxx-xxxx',
    (value) => {
      if (!value) {
        return true; // Skip validation if the field value is empty
      }
      const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      return phoneRegEx.test(numericValue);
    }
  )
  .required(),
  voucher: yup.string(),
});

const handleForgotPassword = delegate => e => {
  e.preventDefault()
  delegate()
}

const SignUpForm = ({
  loading,
  isValid,
  onForgotPassword
}) => {
  return (
    <div>
      <StyledLoginForm style={{marginTop: "2rem"}}> 
        <Content style={{margin: "15px auto"}}>
          <Group>
            <Link to='#'>
              <Logo width='100%' height='100%' />
            </Link>
          </Group>
        </Content>
        <div className={'col-sm-12'}>
          <Title>Bem vindo!</Title>
        </div>
        <div className={'col-sm-12'}>
          <Subtitle>Inicie seu cadastro conosco:</Subtitle>
        </div>
        <div className={'col-sm-12'}>
          <Field
            name='username'
            style={{ width: '100%' }}
            component={NameInput}
          />
        </div>
        <div className={'col-sm-12'}>
          <Field
            name='email'
            style={{ width: '100%' }}
            component={EmailInput}
          />
        </div>
        <div className={'col-sm-12'}>
          <Field
            name='telefone'
            style={{ width: '100%' }}
            component={PhoneInput}
          />
        </div>
        <div className={'col-sm-12'}>
          <Field
            name='password1'
            component={PasswordInput}
          />
        </div>
        <div className={'col-sm-12'}>
          <Field
            name='password2'
            component={PasswordInput}
            glaceholder='Confirmar senha'
          />
        </div>
        {/* <div className={'col-sm-12'}>
          <Field
            name='voucher'
            style={{ width: '100%' }}
            component={VoucherInput}
          />
        </div> */}
        <div className={'col-sm-12'}>
          <Field
            name='termos'
            type="checkbox"
          />
          {" Concordo com os "} <A href="https://simulie-api-prod.s3.sa-east-1.amazonaws.com/Termos+e+Condic%CC%A7o%CC%83es+de+Uso+SIMULIE.pdf" target="_blank" rel="noreferrer noopener" >termos de uso</A>
        </div>
        <br />
        <div className={'col-sm-12'}>
          <Form.Item>
            <SubmitButton
              disabled={!isValid}
              loading={loading}
            >
            Cadastrar
            </SubmitButton>
            {/* <Link to='/register'>Registrar</Link> */}
            <A
              href='/login'
              style={{ float: 'right' }}
            >
            Já tenho cadastro
            </A>
          </Form.Item>
        </div>
      </StyledLoginForm>
      <div className={'col-sm-12'} style={{ justifyContent: 'center', display: 'flex', marginTop: '16px' }}>
        <A
          href='/student'
        >
          Área do estudante
        </A>
      </div>
    </div>
  )
}

SignUpForm.propTypes = {
  loading: PropTypes.bool,
  isValid: PropTypes.bool,
  onForgotPassword: PropTypes.func
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit,
    validationSchema
  })
)

export default enhance(SignUpForm)