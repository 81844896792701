import React from 'react';
import { DebounceInput } from 'react-debounce-input';
const DebounceInputField = React.forwardRef((props, ref) => {
    return (
      <DebounceInput
        {...props}
        inputRef={ref}
        debounceTimeout={500}
      />
    );
  });
export default DebounceInputField;