/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react';
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from "powerbi-client";
import authorization from "./authorization.json";
import "./styles.css";
import { Spin, message } from 'antd';
import { getEmbedToken } from '../../store/users/services';

const PowerBiComponent = ({
  user,
  typeReport = "exams"
}) => {
  const [groupId, setGroupId] = useState(null);
  const [reportId, setReportId] = useState(null);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [token, setToken] = useState(null);
  const [filters, setFilters] = useState([]);
  const [allowed, setAllowed] = useState(false);
  const embedRef = useRef();

  useEffect(() => {
    if(loaded) return;
    if(reportId != null && embedUrl != null) return;
    const powerbiInfo = authorization[typeReport];
    let key;
    if(Object.keys(powerbiInfo).includes(user.SuperId)) {
      key = user.SuperId;
    } else if(Object.keys(powerbiInfo).includes(user.Id)) {
      key = user.Id;
    } else {
      message.info("Você não possui acesso a essa área, entre em contato com a administração", 10);
      key = "default";
    }
    setGroupId(powerbiInfo[key].groupId);
    setReportId(powerbiInfo[key].reportId);
    setEmbedUrl(powerbiInfo[key].embedUrl);
    if(key === "default") {
      setAllowed(true);
      return;
    }
    setPowerBiFiltersBasedOnRole(powerbiInfo[key]);
  }, [embedUrl, reportId]);

  useEffect(() => {
    if(!reportId || !groupId || !allowed) return;
    saveNewToken();
  }, [reportId, groupId]);

  const saveNewToken = async () => {
    const token = await getNewToken();
    if(!token) {
      setLoaded(true);
      message.error("Houve um erro interno, tente novamente mais tarde");
      return;
    }
    message.success("Seu dashboard será carregado em breve");
    setToken(token);
    setLoaded(true);
  };

  const getNewToken = async () => {
    try{
      const newEmbedToken = await getEmbedToken(reportId, groupId);
      if(!newEmbedToken) {
        message.error("Houve um erro interno, tente novamente mais tarde");
        return null;
      }
      return newEmbedToken;
    } catch (err) {
      message.info("Erro ao gerar novo token, tente novamente mais tarde", 10);
      setLoaded(true);
      console.log("error: ", err);
    }
  };

  const setPowerBiFiltersBasedOnRole = (powerbiInfo) => {
    if(!user.Role || !["Super", "Master", "Teacher", "Guest"].includes(user.Role)) return;
    if(user.Role === "Super" || user.Role === "Guest") {
      setAllowed(true);
      return;
    }
    const filtersPowerbi = powerbiInfo.filters;
    if(!filtersPowerbi) {
      setAllowed(true);
      return;
    }
    const listOffilters = [];
    for(const filter of filtersPowerbi) {
      const { table, schoolColumn, turmaColumn } = filter;
      if(!table || !schoolColumn || !turmaColumn) continue;
      const company = user.Company;
      const grades = user.Grades;
      if(!company || (!grades && user.Role === "Teacher")) continue;
      listOffilters.push({
        "$schema": "http://powerbi.com/product/schema#basic",
        "target": {
          "table": table,
          "column": schoolColumn
        },
        "operator": "Is",
        "values": [company]
      });
      if(user.Role === "Teacher") {
        listOffilters.push({
          "$schema": "http://powerbi.com/product/schema#basic",
          "target": {
            "table": table,
            "column": turmaColumn
          },
          "operator": "In",
          "values": grades
        });
      }
    }
    if(listOffilters.length === 0) return;
    setFilters(listOffilters);
    setAllowed(true);
  };

  return (
    <div style={{ width: "100%", maxWidth: "4000px", margin: "0 auto" }}>
      <Spin spinning={!loaded}>
        <PowerBIEmbed
          ref={embedRef}
          embedConfig = {{
            type: 'report',
            id: reportId,
            embedUrl: embedUrl,
            accessToken: token,
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false
                }
              },
            },
            filters: filters
          }}

          eventHandlers = {
            new Map([
              ['loaded', function () {console.log('Report loaded');}],
              ['rendered', function () {console.log('Report rendered');}],
              ['error', function (event) {console.log(event.detail);}],
              ['visualClicked', () => console.log('visual clicked')],
              ['pageChanged', (event) => console.log(event)],
            ])
          }

          cssClassName = { "powerbi-container" }

          getEmbeddedComponent = { (embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      </Spin>
    </div>
  );
}

PowerBiComponent.propTypes = {
  user: PropTypes.object,
  typeReport: PropTypes.string
};

export default PowerBiComponent;