/** @jsx jsx */
import { jsx } from '@emotion/core'

import { Icon } from 'antd'
import { Container, iconStyle, Title } from './styles/Message.styles'

const ProcessingMessage = props => {
  return (
    <Container>
      <Icon
        type='loading'
        css={iconStyle}
      />
      <Title>Processando ...</Title>
    </Container>
  )
}

export default ProcessingMessage
