import styled from '@emotion/styled'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 128.1vw;
  margin: 0 auto;
  max-height: 1103px;
  max-width: 838px;
  overflow: hidden;
  position: relative;
  text-align: center;
  iframe {
    flex: 1;
    height: 100%;
    width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.center ? 'center' : 'space-between'};
  width: 100%;
`
