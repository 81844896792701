import { all, call, select, takeLatest, put } from 'redux-saga/effects'
import { normalizeError } from '../../utils/errorHelpers'
import { findOutput } from '../../utils/outputHelpers'
import { message } from 'antd'

import * as actions from './actions'
import * as types from './constants'
import * as documentTypes from '../documents/constants'
import * as templateTypes from '../templates/constants'
import * as services from './services'

export function * processOutput ({ type }) {
  try {
    if (type !== types.PROCESS_OUTPUT) return yield put(actions.processOutput())

    const documentModule = yield select(state => state.documents)
    const { id: documentId, validations: documentValidations } = documentModule
    const templateId = yield select(state => state.templates.id)
    const userId = yield select(state => state.auth.user.id)

    if (!documentId) return yield put(actions.processOutputCancel())
    if (!templateId) return yield put(actions.processOutputCancel())
    if (!userId) return yield put(actions.processOutputCancel())

    const documentValidation = documentValidations
      .find(validation => validation.document.id === documentId)

    if (!documentValidation || documentValidation.status !== 'OK') return yield put(actions.processOutputCancel())

    const outputs = yield select(state => state.outputs.outputs)
    const cachedOutput = findOutput(documentId, templateId)

    if (cachedOutput) return yield put(actions.processOutputSuccess(outputs))

    const output = yield call(services.processOutput, { documentId, templateId, userId })
    const enhancedOutput = yield call(services.fetchOutputWithoutWarnings, output.id)
    yield put(actions.processOutputSuccess([...outputs, enhancedOutput]))
    message.success('Arquivo de saída processado com sucesso')
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao processar arquivo de saída')
    yield put(actions.processOutputFailure(errorInfo))
  }
}

export function * fetchOutput ({ payload }) {
  try {
    const output = yield call(services.fetchOutputWithoutWarnings, payload)
    yield put(actions.fetchOutputSuccess(output))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao processar arquivo')
    yield put(actions.fetchOutputFailure(errorInfo))
  }
}

export function * clearOutputError () {
  yield put(actions.clearOutputError())
}

// Watchers
export function * watchProcessOutput () {
  yield takeLatest(types.PROCESS_OUTPUT, processOutput)
}

export function * watchSetTemplateId () {
  yield takeLatest(templateTypes.SET_TEMPLATE_ID, processOutput)
}

export function * watchValidateDocumentSuccess () {
  yield takeLatest(documentTypes.VALIDATE_DOCUMENT_SUCCESS, processOutput)
}

export function * watchValidateTemplateSuccess () {
  yield takeLatest(templateTypes.VALIDATE_TEMPLATE_SUCCESS, processOutput)
}

export function * watchUploadDocument () {
  yield takeLatest(documentTypes.UPLOAD_DOCUMENT, clearOutputError)
}

export function * watchFetchOutput () {
  yield takeLatest(types.FETCH_OUTPUT, fetchOutput)
}

export default function * () {
  yield all([
    watchProcessOutput(),
    watchSetTemplateId(),
    watchValidateDocumentSuccess(),
    watchValidateTemplateSuccess(),
    watchUploadDocument(),
    watchFetchOutput()
  ])
}
