import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { processOutput } from '../../store/outputs/actions'

import { Button, Icon } from 'antd'
import { Group } from './styles/Feedback.styles'

const OutputErrorFeedback = ({ error, processOutput }) => {
  const { message, isTimeout } = error

  const timeoutButton = isTimeout && (
    <Button
      onClick={processOutput}
      size='small'
      type='ghost'
    >
      Tentar Novamente!
    </Button >
  )

  return (
    <Group>
      <h2>Oops! Parece que algo deu errado</h2>
      <h3>{message}</h3>
      <Icon
        type='close-circle'
        style={{ fontSize: '10rem', color: '#ff3437' }}
      />
      <Group>
        {timeoutButton}
      </Group>
    </Group>
  )
}

OutputErrorFeedback.propTypes = {
  error: PropTypes.object.isRequired,
  processOutput: PropTypes.func.isRequired
}

const mapStateToProps = ({ outputs }) => ({
  error: outputs.error
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    processOutput
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OutputErrorFeedback)
