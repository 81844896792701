export const CLEAR_QUESTION_MODULE = 'questions:CLEAR_QUESTION_MODULE'

export const ADD_TAG = 'questions:ADD_TAG'
export const ADD_TAG_SUCCESS = 'questions:ADD_TAG_SUCCESS'
export const ADD_TAG_FAILURE = 'questions:ADD_TAG_FAILURE'

export const DELETE_TAG = 'questions:DELETE_TAG'
export const DELETE_TAG_SUCCESS = 'questions:DELETE_TAG_SUCCESS'
export const DELETE_TAG_FAILURE = 'questions:DELETE_TAG_FAILURE'

export const GET_QUESTIONS = 'questions:GET_QUESTIONS'
export const GET_QUESTIONS_SUCCESS = 'questions:GET_QUESTIONS_SUCCESS'
export const GET_QUESTIONS_FAILURE = 'questions:GET_QUESTIONS_FAILURE'

export const CHANGE_TO_DISSERTATIVE = 'questions:CHANGE_TO_DISSERTATIVE'
export const CHANGE_TO_DISSERTATIVE_SUCCESS = 'questions:CHANGE_TO_DISSERTATIVE_SUCCESS'
export const CHANGE_TO_DISSERTATIVE_FAILURE = 'questions:CHANGE_TO_DISSERTATIVE_FAILURE'

export const GET_TAGS = 'questions:GET_TAGS'
export const GET_TAGS_SUCCESS = 'questions:GET_TAGS_SUCCESS'
export const GET_TAGS_FAILURE = 'questions:GET_TAGS_FAILURE'

export const ADD_COMMENT = 'questions:ADD_COMMENT'
export const ADD_COMMENT_SUCCESS = 'questions:ADD_COMMENT_SUCCESS'
export const ADD_COMMENT_FAILURE = 'questions:ADD_COMMENT_FAILURE'

export const ADD_SOLUTION = 'questions:ADD_SOLUTION'
export const ADD_SOLUTION_SUCCESS = 'questions:ADD_SOLUTION_SUCCESS'
export const ADD_SOLUTION_FAILURE = 'questions:ADD_SOLUTION_FAILURE'