import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { clearDocumentModule } from '../store/documents/actions'
import { clearTemplateModule } from '../store/templates/actions'
import { clearOutputModule } from '../store/outputs/actions'

import DocumentUploadInput from '../components/input/DocumentUploadInput'
import FilesTable from '../components/table/FilesTable'
import { Spin } from 'antd'
import { Page, Row, Col, Title } from './styles/Page.styles'

const FilesPage = ({
  clearDocumentModule,
  clearTemplateModule,
  clearOutputModule,
  loading
}) => {
  useEffect(() => {
    clearDocumentModule()
    clearTemplateModule()
    clearOutputModule()
  }, [])

  return (
    <Page>
      <Row>
        <Col><Title>Diagramados</Title></Col>
        <Col><DocumentUploadInput /></Col>
      </Row>
      <Spin spinning={loading}>
        <FilesTable />
      </Spin>
    </Page>
  )
}

FilesPage.propTypes = {
  clearDocumentModule: PropTypes.func.isRequired,
  clearTemplateModule: PropTypes.func.isRequired,
  clearOutputModule: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

const mapStateToProps = ({ files }) => ({
  loading: files.loading
})

const mapDispatchToPtops = dispatch =>
  bindActionCreators({
    clearDocumentModule,
    clearTemplateModule,
    clearOutputModule
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToPtops)(FilesPage)
