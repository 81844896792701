import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { SUPPORTED_FILE_TYPES } from '../../utils/constants'
import { uploadDocument } from '../../store/documents/actions'

import InputHelpModal from '../modal/InputHelpModal'
import { Button, Upload } from 'antd'

const DocumentUploadInput = ({ disabled, loading, uploadDocument }) => {
  const [helpType, setHelpType] = useState('')

  const validate = file => {
    const helpType = {
      [file.size > 10485760]: 'heavy',
      [!SUPPORTED_FILE_TYPES.includes(file.type)]: 'default'
    }[true]
    helpType && setHelpType(helpType)
    return !helpType
  }

  const handleChange = ({ file }) => {
    validate(file) && uploadDocument(file)
  }

  return (
    <Fragment>
      <Upload
        accept={SUPPORTED_FILE_TYPES.join(',')}
        beforeUpload={() => false}
        disabled={disabled || loading}
        onChange={handleChange}
        showUploadList={false}
      >
        <Button
          disabled={disabled || loading}
          icon='upload'
          loading={loading}
          shape='round'
          size='large'
          type='primary'
        >
          {loading ? 'Enviando arquivo ...' : 'Enviar novo arquivo'}
        </Button>
      </Upload>
      <InputHelpModal
        type={helpType}
        onClose={() => setHelpType(null)}
      />
    </Fragment>
  )
}

DocumentUploadInput.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  uploadDocument: PropTypes.func.isRequired
}

const mapStateToProps = ({ documents, outputs }) => ({
  disabled: outputs.loading,
  loading: documents.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    uploadDocument
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUploadInput)
