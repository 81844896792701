import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import GoogleForms from "../components/form/GoogleForms";
import { STUDENT_FORM_LINK, INTERN_FORM_LINK, EMPLOYEE_FORM_LINK } from "../utils/constants";
import { Grid } from "@material-ui/core";
import { infoUser } from "../store/users/actions";

const mapStateToProps = ({ users }) => ({
  user: users.infoUser,
});

const StudentSurveyPage = ({ infoUser, user }) => {
  const [formLink, setFormLink] = useState(STUDENT_FORM_LINK);

  useEffect(() => {
    infoUser();
  }, [infoUser]);

  useEffect(() => {
    if (user && user.Email) {
      switch (user.Email) {
        case "estagiarios@edu.nh.rs.gov.br":
        case "estagiario@edu.nh.rs.gov.br":
          setFormLink(INTERN_FORM_LINK);
          break;
        case "funcionarios@edu.nh.rs.gov.br":
        case "funcionario@edu.nh.rs.gov.br":
          setFormLink(EMPLOYEE_FORM_LINK);
          break;
        default:
          setFormLink(STUDENT_FORM_LINK);
          break;
      }
    }
  }, [user]);

  return (
    <Grid container direction="column" justifyContent="center">
      <Grid item xs={12}>
        <GoogleForms link={formLink} />
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    infoUser,
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StudentSurveyPage)
