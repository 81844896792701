import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import { Link } from 'react-router-dom'
import { LinkContainer } from './styles/Link.styles'

const FeedbackLink = ({
  link,
  title,
  icon,
  iconColor,
  onClick,
  children
}) => {
  return (
    <LinkContainer>
      {title && <h2>{title}</h2>}
      {icon &&
        <Icon
          type={icon}
          style={{ fontSize: '10rem', color: iconColor }}
        />
      }
      <Link to={link} onClick={onClick}>
        {children}
      </Link>
    </LinkContainer>
  )
}

FeedbackLink.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node
}

FeedbackLink.defaultProps = {
  link: '#'
}

export default FeedbackLink
