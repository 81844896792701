import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchCompany } from '../../store/companies/actions'

import CompanyEditForm from '../../components/form/CompanyEditForm'
import { Spin } from 'antd'
import { Col, Row } from '../../components/table/styles/Table.styles'
import { Title } from '../../components/form/styles/Form.styles'
import { Page } from '../styles/Page.styles'

const EditCompanyPage = ({
  fetchCompany,
  selected,
  loading,
  match
}) => {
  useEffect(() => {
    fetchCompany(match.params.id)
  }, [])

  return (
    <Page>
      <Row center>
        <Col center size={5}><Title>Editar Empresa</Title></Col>
      </Row>
      <Spin spinning={loading}>
        <CompanyEditForm initialValues={selected} />
      </Spin>
    </Page>
  )
}

EditCompanyPage.propTypes = {
  fetchCompany: PropTypes.func.isRequired,
  selected: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object
}

const mapStateToProps = ({ companies }) => ({
  selected: companies.company,
  loading: companies.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchCompany
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyPage)
