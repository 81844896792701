/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { Divider, Icon, Popconfirm, Tooltip } from 'antd'
import compact from 'lodash/compact'
import last from 'lodash/last'

import { InformationIconStyle, ViewIconStyle, LinkIconStyle, DiagramarIconStyle, EditIconStyle, DownloadIconStyle, DeleteIconStyle } from './styles/ActionList.styles'

const renderActionButtons = icons => icons.map((icon, i) =>
  icon === last(icons) || compact(icons).length === 1
    ? <span key={i} >{icon}</span>
    : <span key={i}>{icon}<Divider type='vertical' /></span>
)

const renderIcon = (onClick, tooltip, style, type, showIcon = true) => (
  onClick &&
    showIcon ? 
      <Tooltip title={tooltip}>
        <Icon onClick={onClick} css={style} type={type} />
      </Tooltip>
    : null
)

const renderIconActions = (onClick, tooltip, style, type, item) => {
  if (item !== undefined && item !== null && item.Status === 'Pending') {
    return (onClick &&
    <Tooltip title={tooltip}>
      <Icon onClick={onClick} css={style} type={type} />
    </Tooltip>
    )
  }
}

const renderIconGabarito = (onClick, tooltip, style, type, item) => {
  if (item !== undefined && item !== null && !item.IsResolutionFilled) {
    return (onClick &&
    <Tooltip title={tooltip}>
      <Icon onClick={onClick} css={style} type={type} />
    </Tooltip>
    )
  }
}

const renderPopconfirmIcon = (onClick, tooltip, style, type) => (
  onClick &&
  <Tooltip title={tooltip}>
    <Popconfirm
      arrowPointAtCenter
      cancelText='Não'
      icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
      okText='Sim'
      okType='danger'
      onConfirm={onClick}
      placement='topRight'
      title='Tem certeza?'
    >
      <Icon css={style} type={type} />
    </Popconfirm>
  </Tooltip>
)

const ActionList = ({ onInformation, onView, onEdit, onDownload, onGerarLink, onDiagramar, onDelete, onActive, onReject, item, onGabarito, examStatus }) => {
  const showIcon = examStatus !== "draft";
  const actionButtons = renderActionButtons(
    compact([
      renderIcon(onInformation, 'Informações', InformationIconStyle, 'info-circle'),
      renderIcon(onView, 'Visualizar', ViewIconStyle, 'eye-o'),
      renderIcon(onEdit, 'Editar', EditIconStyle, 'edit'),
      renderIcon(onDownload, 'Download', DownloadIconStyle, 'download', showIcon),
      renderIcon(onGerarLink, 'Gerar Link', LinkIconStyle, 'link', showIcon),
      renderIcon(onDiagramar, 'Diagramar', DiagramarIconStyle, 'book', showIcon),
      renderIconGabarito(onGabarito, 'Adicionar Gabarito', DownloadIconStyle, 'edit', item),
      renderIconActions(onActive, 'Ativar', DownloadIconStyle, 'save', item),
      renderIconActions(onReject, 'Rejeitar', DeleteIconStyle, 'close', item),
      renderPopconfirmIcon(onDelete, 'Deletar', DeleteIconStyle, 'delete')
    ])
  )

  return (
    <span>
      {
        actionButtons.length > 0
          ? actionButtons
          : (
            <Tooltip arrowPointAtCenter placement='topRight' title={'Não há ações possíveis'}>
              <Icon style={{ color: '#b0b0b0' }} type='info-circle' theme='outlined' />
            </Tooltip>
          )
      }
    </span>
  )
}

ActionList.propTypes = {
  onInformation: PropTypes.func,
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onDownload: PropTypes.func,
  onGerarLink: PropTypes.func,
  onActive: PropTypes.func,
  onReject: PropTypes.func,
  onGabarito: PropTypes.func,
  item: PropTypes.object,
  onDelete: PropTypes.func
}

export default ActionList
