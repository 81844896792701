import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import ValidatedSharedInput from './ValidatedSharedInput'
import { FormInput } from './styles/Input.styles'

const TextInput = ({
  label,
  field,
  form,
  HelpComponent,
  icon,
  onKeyUp,
  ref,
  disabled
}) => {
  return (
    <ValidatedSharedInput
      touched={form.touched[field.name]}
      error={form.errors[field.name]}
      label={label}
      HelpComponent={HelpComponent}
    >
      { (onKeyUp !== undefined && onKeyUp !== null) &&
        <FormInput
          disabled={disabled}
          className='text-input'
          onKeyUp={onKeyUp}
          prefix={icon && <Icon type={icon} style={{ color: 'rgba(0,0,0,.25)' }} />}
          ref={ref}
          {...field}
        />
      }
      { (onKeyUp === undefined || onKeyUp === null) &&
        <FormInput
          disabled={disabled}
          className='text-input'
          prefix={icon && <Icon type={icon} style={{ color: 'rgba(0,0,0,.25)' }} />}
          ref={ref}
          {...field}
        />
      }
    </ValidatedSharedInput>
  )
}

TextInput.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  HelpComponent: PropTypes.func,
  onKeyUp: PropTypes.func,
  ref: PropTypes.object,
  icon: PropTypes.string
}

export default TextInput
