import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchUser } from '../../store/users/actions'

import { Spin } from 'antd'
import { Col, Row } from '../../components/table/styles/Table.styles'
import { Title } from '../../components/form/styles/Form.styles'
import { Page } from '../styles/Page.styles'
import UserForm from '../../components/form/UserForm'

const EditUserPage = ({
  fetchUser,
  selected,
  loading,
  match
}) => {
  useEffect(() => {
    fetchUser(match.params.id)
  }, [match])

  return (
    <Page>
      <Row center>
        <Col center size={5}><Title>Editar Usuário</Title></Col>
      </Row>
      <Spin spinning={loading}>
        <UserForm initialValues={selected} />
      </Spin>
    </Page>
  )
}

EditUserPage.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  selected: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object
}

const mapStateToProps = ({ users }) => ({
  selected: users.user,
  loading: users.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchUser
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EditUserPage)
