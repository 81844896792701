import React, { Component } from 'react'
import PropTypes from 'prop-types'

import HeaderStudentTemplate from './HeaderStudentTemplate'
// import FeedbackDrawer from '../drawer/FeedbackDrawer'
import { Container, Content } from './styles/DashboardTemplate.styles'

class StudentDashboardTemplate extends Component {
  state = { initialization: 'test' }

  render () {
    return (
      <Container>
        <HeaderStudentTemplate />
        <Content>
          {this.props.children}
        </Content>
        {/* <FeedbackDrawer /> */}
      </Container>
    )
  }
}

StudentDashboardTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.array
  ]).isRequired
}

export default StudentDashboardTemplate
