import createReducer from '../createReducer'
import {
  SET_ACTIVE_WARNING_INDEX
} from './constants'

const initialState = {
  activeWarningIndex: 0,
  loading: false,
  error: {}
}

export default createReducer(initialState, {
  [SET_ACTIVE_WARNING_INDEX]: (state, action) => ({
    ...state,
    activeWarningIndex: action.payload
  })
})
