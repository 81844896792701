import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Empty as AntdEmpty, Input, Pagination as AntdPagination, Button, List, InputNumber, Tabs } from 'antd'

export const HeaderBar = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: 10px;
`

export const HeaderLeft = styled.div`
  width: 100%;
`

export const HeaderRight = styled.div`
  display: flex; 
  justify-content: flex-end
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  width: 100%;
`

export const Col = styled.div`
  flex: ${props => props.size || 1};
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.center && 'text-align: center;'}
  ${props => props.left && 'text-align: left;'}
  ${props => props.right && 'text-align: right;'}
  white-space: nowrap;

  .tagButton{
    margin: 0 5px;

    height: 25px;
    padding: 0 8px;
    font-size: 14px;

  }
  
  .tagButtonSuccess{
    margin: 0 5px;
    background-color: green;
    height: 25px;
    padding: 0 8px;
    font-size: 14px;
    color: white;
    font-weight: bold;
  }
  
  .tagButtonRed{
    margin: 0 5px;
    background-color: red;
    height: 25px;
    padding: 0 8px;
    font-size: 14px;
    color: white;
    font-weight: bold;
  }

  .deleteTag {
    border-left: 1px solid #fff;
    padding-left: 5px;
    padding-bottom: 2px;
  }
`

export const Header = styled.p`
  display: inline-block;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  white-space: normal;
  color: #514fff;
`

export const Empty = styled(AntdEmpty)`
  margin: 2rem auto;
`

export const Pagination = styled(AntdPagination)`
  margin-top: 2rem;
  text-align: center;
  .ant-pagination-item {
    border: 1px solid #514fff;
    border-radius: 25px;
    
    :hover{
      border: 2px solid #514fff;
    }
  }
  .ant-pagination-item-active {
    background: #514fff;
    border-color: #514fff;
  }
  .ant-pagination-item-active a {
    color: #fff;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #514fff;
    border: 3px solid #514fff;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
  .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
  .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {

    align-items: center;
    justify-content: center;
    display: flex;
    min-width: 32px;
    height: 32px;
    border: 3px solid #514fff;
    border-radius: 25px;
    color: #514fff;
  }
  .ant-pagination-item-ellipsis{
    border: 1px solid #514fff;
    border-radius: 25px;
    color: #514fff;
  }
`
export const PaginationItem = styled.div`
  background-color: #808080;
  border: 0px solid #808080;
  border-radius: 25px;
  :hover{
    border: 0px;
  }
`

export const Search = styled(Input.Search)`
  .ant-input-group-addon {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    :hover{
      border: 1px solid #514fff;
    }
  }
  
  .ant-input-search-button {
    background-color: #514fff;
    border-radius: 25px;
    height: 40px;
    border-color: transparent;
  }
  .ant-input-affix-wrapper{
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    display: flex;
    :hover{
      border-color: #514fff !important;
      border-style: solid;
      border-width: 2px;
    }
  }

  ${props => props.type === 'desktop' && css`
    max-width: 60%;
    margin-left: 15px;
    @media only screen and (max-width: 600px) { display: none; }
  `}
  ${props => props.type === 'mobile' && css`
    width: 10px
    margin-bottom: .5rem;
  `}
`
export const ButtonSearch = styled(Button)`
  &.ant-btn {
    background-color: #514fff !important;
    color: #fff;
    :hover{
      border-color: #FBA661 !important;
      border-style: solid;
      border-width: 3px;
    }
  }
`

export const ButtonQuestions = styled.div`
  display: flex;
  margin-right: 22px;
  padding-top: 35px;
  padding-bottom: 25px;
  justify-content: flex-end;
`
export const StyledListItem = styled(List.Item)`
  span {
    color: #000 !important;
  }
`
export const StyledTitle = styled.span`
  color: #000 !important;
`
export const StyledInputNumber = styled(InputNumber)`
  border-color: #514fff;
  &.ant-input-number:hover {
  border-color: #514fff;
  border-width: 3px !important;
}
&.ant-input-number-focused {
  border-color: #514fff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
`
export const StyledTabs = styled(Tabs)`
  
  &.ant-tabs .ant-tabs-nav-scroll .ant-tabs-tab {
    color:#000 !important;
  }
`
export const PaginationDiv = styled.div`
  padding-top: 24px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  @media (max-width: 525px) {
    flex-direction: column;
    align-items: center;
  }
`
export const MobItensDiv = styled.div`
  @media (max-width: 400px) {
    font-size: 12px
  }
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
`
export const MobStatusDiv = styled.div`
  @media (max-width: 400px) {
    font-size: 12px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
  display: flex;
  flex-direction: column;
`