/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { Button } from 'antd'
import { Page, Row } from './styles/Page.styles'
import { Content, Buttons } from './styles/ReviseQuestionsPage.styles'

import QuestionBlock from '../components/question/QuestionBlock'

import { createExam } from '../store/exams/actions'

// import history from '../routes/history'

function ReviseQuestionsPage ({ questions, createExam, newExamId }) {
  const [data, setData] = useState([])

  // function newExam () {
  //   const questionList = data.map((ques, index) => {
  //     var tempQuestion = {
  //       Id: ques.Id,
  //       Number: index + 1
  //     }

  //     return tempQuestion
  //   })

  //   const exam = {
  //     'Title': 'Prova Criada Online',
  //     'Description': null,
  //     'Questions': questionList,
  //     'Tags': null
  //   }

  //   return exam
  // }

  function handleSuffleQuestions (array) {
    const newArray = array.reduce((accumulator, currentValue) => {
      accumulator.push(currentValue.question)
      return accumulator
    }, [])

    setData(newArray)
  }

  function handleDeleteQuestion (questionId) {
    setData(data.filter(q => q.Id !== questionId))
  }

  // function handleSaveDownload () {
  //   createExam(newExam())
  //   history.push('/list-exams')
  // }

  useEffect(() => {
    setData(questions)
  }, [])

  return (
    <Page>
      <Content>
        <QuestionBlock exams={[{ Id: null, Questions: data }]} editable sufflable onShuffle={handleSuffleQuestions} softDelete={handleDeleteQuestion} />

        <Row>
          <Buttons>
            <Button type='primary' shape='round' >salvar e baixar</Button>
          </Buttons>
        </Row>
      </Content>

    </Page>
  )
}

ReviseQuestionsPage.propTypes = {
  newExamId: PropTypes.string,
  questions: PropTypes.array,
  createExam: PropTypes.func.isRequired
}

const mapStateToProps = ({ exams, auth }) => ({
  newExamId: exams.id,
  questions: exams.selectedQuestions
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    createExam
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ReviseQuestionsPage)
