import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import ListItemButton from "@mui/material/ListItemButton";
import { List, ListItemText } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import { NH_SUPER_ID } from "../../utils/constants";

const ReleasePopover = ({ popoverAnchorEl, setPopoverAnchorEl, user }) => {
  const [showSurveyOption, setShowSurveyOption] = useState(false);

  const handleClose = () => {
    setPopoverAnchorEl(null);
  };

  useEffect(() => {
    if (user && user.SuperId === NH_SUPER_ID) {
      setShowSurveyOption(true);
    }
  }, [user]);

  return (
    <Popover
      id="simple-menu"
      open={Boolean(popoverAnchorEl)}
      anchorEl={popoverAnchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          padding: "10px",
          width: "140px",
          marginTop: "10px",
        },
      }}
    >
      <div onMouseLeave={handleClose}>
        <List component={Stack} direction="column" spacing={1}>
          <ListItemButton
            onClick={() => (window.location = "/releases?selected=Writing")}
          >
            <ListItemText primary="Escrita" />
          </ListItemButton>
          <ListItemButton
            onClick={() => (window.location = "/releases?selected=Reading")}
          >
            <ListItemText primary="Leitura" />
          </ListItemButton>
          {showSurveyOption && (
            <ListItemButton
              onClick={() => (window.location = "/releases?selected=Survey")}
            >
              <ListItemText primary="Pesquisa" />
            </ListItemButton>
          )}
        </List>
      </div>
    </Popover>
  );
};

export default ReleasePopover;
