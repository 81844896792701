import React from "react";
import MaterialTextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";

export const TextField = ({ fullWidth = false, label, text, className, variant, onChange }) => (
  <Grid item>
    <MaterialTextField
      label={label}
      className={className}
      fullWidth={fullWidth}
      variant={variant}
      onChange={onChange}
    >
      {text}
    </MaterialTextField>
  </Grid>
);
