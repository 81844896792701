import styled from '@emotion/styled'
import { Checkbox } from 'antd'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  .ant-list-split .ant-list-item {
    border-bottom: none;
  }

  input {
    margin-right: 10px;
    margin-top: 5px;
  }
`
export const QuestionHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #514FFF;
  border-radius: 15px;

  .ant-btn {
    border: 1px solid transparent;
    color: #fff;
    background-color: #514FFF;
    margin-left: 5px;
    :hover {
      border: 3px solid #FF7C11;
    }
  }
`
export const QuestionNumber = styled.div`
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #74809D;
`
export const QuestionInstructions = styled.div`
  margin-bottom: 5px;
  position: relative;
  font-weight: none !important;
`

export const QuestionNumberFont = styled.div`
  font-size: 14px;
  font-weight: bold;
  border: 1px solid transparent;
  border-radius: 25px;
  padding: 5px 15px 5px 15px;
  margin-right: 15px;
  color: #fff;
  background-color: #514FFF;
  display: inline;
`
export const QuestionType = styled.div`
  font-weight: bold;
  border: 1px solid #514FFF;
  color: #000;
  padding: 3px 10px 5px 10px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  display: inline;
`

export const QuestionAction = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  align-items: center;
  justify-content: center;
  display: flex;

  .ant-btn-danger {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
  }
  .ant-checkbox {
    padding-left: 10px;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #514FFF;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #514FFF;
    border-color: #514FFF;
  }
  .ant-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #514FFF;
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
            animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    content: '';
  }
`
export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #514FFF;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #514FFF;
    border-color: #514FFF;
  }
  .ant-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #514FFF;
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
            animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    content: '';
  }
`
export const QuestionText = styled.div`
  margin: 15px 0;
`
