import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select, Spin } from 'antd'
import ValidatedSharedInput from './ValidatedSharedInput'
import { handleBlur, handleChange } from '../../utils/formHelpers'
import { fetchCompanies } from '../../store/companies/services'
import { StyledSelect } from '../question/QuestionFilterHeader/styles'

class SelectCompany extends Component {
  state = {
    initialValue: this.props.initialValue,
    loading: false,
    timeout: false,
    companiesOptions: [],
    input: ''
  }

  static propTypes = {
    label: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    initialValue: PropTypes.string,
    search: PropTypes.bool
  }

  companiesOptions () {
    let dados = []
    let first
    fetchCompanies('', 1).then(companies => {
      companies && companies.Itens.map(j => {
        if (first === undefined) {
          first = j.Id
        }
        let option =
          <Select.Option
            key={j.Id}
            value={j.Id}
            title={j.Name}
          >
            {j.Name}
          </Select.Option>

        dados.push(option)

        return option
      })
    })
    this.setState({ companiesOptions: dados })
  }

  companiesOptionsSearch (value) {
    let dados = []
    fetchCompanies(value, 1).then(companies => {
      companies.Itens.map(j => {
        let option =
          <Select.Option
            key={j.id}
            value={j.id}
            title={j.name}
          >
            {j.name}
          </Select.Option>

        dados.push(option)
        return option
      })
    })

    this.setState({ companiesOptions: dados })
  }

  componentDidMount () {
    this.companiesOptions()
  }

  componentDidUpdate (prevProps) {
    const { initialValue } = this.props
    prevProps.initialValue !== initialValue && this.setState({ initialValue })
  }

  render () {
    const {
      initialValue,
      loading,
      companiesOptions
    } = this.state
    const {
      label,
      field,
      form,
      search
    } = this.props

    return (
      <ValidatedSharedInput
        touched={form.touched[field.name]}
        error={form.errors[field.name]}
        label={label}
      >

        <StyledSelect
          className='select-input'
          value={initialValue || field.value}
          onBlur={() => handleBlur(form, field)}
          onChange={value => this.setState({ initialValue: '' }, () => handleChange(form, field, value))}
          showSearch={search}
          onSearch={this.handleSearch}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          notFoundContent={loading ? <Spin spinning size='small' /> : 'Nada encontrado'}
          placeholder={'Empresa'}
          loading={loading}
          allowClear
        >
          {companiesOptions}
        </StyledSelect>
      </ValidatedSharedInput>
    )
  }

  handleSearch = input => {
    clearTimeout(this.state.timeout)
    const timeout = setTimeout(() => this.companiesOptionsSearch({ input }), 400)

    this.setState({ input, timeout, loading: false })
  }
}

export default SelectCompany
