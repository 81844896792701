import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchTemplates, setTemplateId, deleteTemplate } from '../../store/templates/actions'

// import AdminPermission from '../permission/AdminPermission'
// import { Select, Popconfirm, Tooltip, Icon } from 'antd'
import { Select } from 'antd'
import { InlineContainer, Label } from './styles/Input.styles'

const TemplateInput = ({
  fetchTemplates,
  loading,
  templateId,
  templates,
  setTemplateId,
  deleteTemplate
}) => {
  useEffect(() => {
    fetchTemplates()
  }, [])

  const options = templates.map(template =>
    <Select.Option
      key={template.id}
      value={template.id}
      title={template.name}
    >
      {template.name}
    </Select.Option>
  )

  // const deleteButton = (
  //   <Popconfirm
  //     arrowPointAtCenter
  //     cancelText='Não'
  //     icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
  //     okText='Sim'
  //     okType='danger'
  //     onConfirm={e => deleteTemplate(templateId)}
  //     placement='topRight'
  //     title='Tem certeza?'
  //   >
  //     <Tooltip title='Deletar template'>
  //       <Icon
  //         onClick={e => e.stopPropagation()}
  //         type='delete'
  //         style={{ marginLeft: '1rem', color: '#ff4d4f' }}
  //       />
  //     </Tooltip>
  //   </Popconfirm>
  // )

  return (
    <InlineContainer glow={!templateId}>
      <Label>Modelo:</Label>
      <Select
        allowClear
        addonAfter='addon'
        filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        loading={loading}
        onChange={setTemplateId}
        placeholder='Selecione ou busque por um modelo'
        showSearch
      >
        {options}
      </Select>
      {/* <AdminPermission>
        {templateId && deleteButton}
      </AdminPermission> */}
    </InlineContainer>
  )
}

TemplateInput.propTypes = {
  fetchTemplates: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  templateId: PropTypes.string,
  templates: PropTypes.array.isRequired,
  setTemplateId: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func.isRequired
}

const mapStateToProps = ({ templates }) => ({
  loading: templates.loading,
  templateId: templates.id,
  templates: templates.templates
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchTemplates,
    setTemplateId,
    deleteTemplate
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TemplateInput)
