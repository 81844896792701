import http, { updateToken } from '../../utils/http2'

const feedbacksEndpoint = '/feedbacks'

export const sendFeedback = feedback => {
  updateToken()
  const { documentId, templateId, description } = feedback

  const req = {
    feedback: {
      document_id: documentId,
      template_id: templateId,
      description: description || 'Gerado automaticamente'
    }
  }

  return http
    .post(feedbacksEndpoint, req)
    .then(res => res.data).catch(error => error.data)
}
