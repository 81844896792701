import styled from '@emotion/styled'

export const Content = styled.div`
  margin: 0 auto;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`

export const TotalQuestions = styled.div`
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  color: #3577EF;
`
export const Shuffle = styled.div`
  display: flex;
  align-items: flex-end;
`

export const DisplayStyle = styled.div`
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  color: #3577EF;
`

export const QuestionList = styled.div`
    margin-bottom: 15px;
`
