export const CLEAR_DOCUMENT_MODULE = 'documents:CLEAR_DOCUMENT_MODULE'
// export const SET_FILE = 'documents:SET_FILE'

export const UPLOAD_DOCUMENT = 'documents:UPLOAD_DOCUMENT'
export const UPLOAD_DOCUMENT_SUCCESS = 'documents:UPLOAD_DOCUMENT_SUCCESS'
export const UPLOAD_DOCUMENT_FAILURE = 'documents:UPLOAD_DOCUMENT_FAILURE'

export const VALIDATE_DOCUMENT = 'documents:VALIDATE_DOCUMENT'
export const VALIDATE_DOCUMENT_SUCCESS = 'documents:VALIDATE_DOCUMENT_SUCCESS'
export const VALIDATE_DOCUMENT_FAILURE = 'documents:VALIDATE_DOCUMENT_FAILURE'
