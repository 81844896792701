import http, { updateToken } from '../../utils/http2'
import { mapHeadersToData } from '../../utils/httpHelpers'

const filesEndpoint = '/core_processes'

export const fetchFiles = (query = {}) => {
  updateToken()
  const { page, pageSize, search } = query
  const params = {
    'q[status_eq]': 'done',
    'q[document_name_cont]': search,
    page: page || 1,
    per: pageSize || 10
  }
  return http
    .get(filesEndpoint, { params })
    .then(mapHeadersToData('x-total-count', 'x-page'))
}

export const downloadFile = ({ id }) => {
  updateToken()
  return http
    .get(`${filesEndpoint}/${id}`)
    .then(res => res.data).catch(error => error.data)
}

export const deleteFile = id => {
  updateToken()
  return http
    .delete(`${filesEndpoint}/${id}`)
    .then(res => res.data).catch(error => error.data)
}
