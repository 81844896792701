import {
  CLEAR_QUESTION_MODULE,
  ADD_TAG,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAILURE,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  GET_QUESTIONS,
  GET_QUESTIONS_FAILURE,
  GET_QUESTIONS_SUCCESS,
  CHANGE_TO_DISSERTATIVE,
  CHANGE_TO_DISSERTATIVE_FAILURE,
  CHANGE_TO_DISSERTATIVE_SUCCESS,
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
  ADD_COMMENT,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAILURE,
  ADD_SOLUTION,
  ADD_SOLUTION_SUCCESS,
  ADD_SOLUTION_FAILURE,
} from './constants'

export const clearQuestionModule = () => ({
  type: CLEAR_QUESTION_MODULE
})

export const addTag = (questionId, tag) => ({
  type: ADD_TAG,
  payload: { questionId, tag }
})

export const addTagSuccess = (tag) => ({
  type: ADD_TAG_SUCCESS,
  payload: tag
})

export const addTagFailure = error => ({
  type: ADD_TAG_FAILURE,
  payload: error
})

export const deleteTag = (questionId, tag) => ({
  type: DELETE_TAG,
  payload: { questionId, tag }
})

export const deleteTagSuccess = (tag) => ({
  type: DELETE_TAG_SUCCESS,
  payload: tag
})

export const deleteTagFailure = error => ({
  type: DELETE_TAG_FAILURE,
  payload: error
})

export const listQuestions = (companyId, userId) => ({
  type: GET_QUESTIONS,
  payload: { companyId, userId }
})

export const listQuestionsSuccess = () => ({
  type: GET_QUESTIONS_SUCCESS
})

export const listQuestionsFailure = error => ({
  type: GET_QUESTIONS_FAILURE,
  payload: error
})

export const changeToDissertative = (questionId) => ({
  type: CHANGE_TO_DISSERTATIVE,
  payload: { questionId }
})

export const changeToDissertativeSuccess = () => ({
  type: CHANGE_TO_DISSERTATIVE_SUCCESS
})

export const changeToDissertativeFailure = error => ({
  type: CHANGE_TO_DISSERTATIVE_FAILURE,
  payload: error
})

export const getTags = (value) => ({
  type: GET_TAGS,
  payload: value,
});

export const getTagsSuccess = (tags) => ({
  type: GET_TAGS_SUCCESS,
  payload: tags,
});

export const getTagsFailure = (error) => ({
  type: GET_TAGS_FAILURE,
  payload: error,
});

export const addComment = (examId, questionId, comment) => ({
  type: ADD_COMMENT,
  payload: { examId, questionId, comment }
});

export const addCommentSuccess = (comment) => ({
  type: ADD_COMMENT_SUCCESS,
  payload: comment,
});

export const addCommentFailure = (error) => ({
  type: ADD_COMMENT_FAILURE,
  payload: error,
});

export const addSolution = (examId, questionId, solution) => ({
  type: ADD_SOLUTION,
  payload: { examId, questionId, solution }
});

export const addSolutionSuccess = (solution) => ({
  type: ADD_SOLUTION_SUCCESS,
  payload: solution,
});

export const addSolutionFailure = (error) => ({
  type: ADD_SOLUTION_FAILURE,
  payload: error,
});