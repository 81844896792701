import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import http, { updateToken } from '../utils/http2'
import moment from "moment";
import history from '../routes/history'

import { fetchStudentExams, fetchStudentResultExam, setExamId, searchExamTag, setCanEdit, clearExamModule } from '../store/exams/actions'
import { listQuestions } from '../store/questions/services'
// import * as services from '../store/exams/services'
import { infoUser } from '../store/users/actions'

import ActionList from '../components/list/ActionList'
import ContainerCard from '../components/card/ContainerCard'
import { Title, Page } from './styles/Page.styles'

import { List, Tooltip } from 'antd'
import {
  Row,
  Col,
  Header,
  Empty,
  Pagination,
  Search,
  StyledListItem,
  MobItensDiv,
  MobStatusDiv
} from './styles/ListExamsPage.styles'

import { Content } from './styles/DetailsPage.styles'

function StudentPainelPage ({ fetchStudentExams, fetchStudentResultExam, exams, total, loading, setExamId, setCanEdit, searchExamTag, clearExamModule, infoUser, user}) {
  let [current, setCurrent] = useState(1)
  let [list, setList] = useState([])
  let [load, setLoad] = useState(false)
  let [listTotal, setListTotal] = useState(undefined)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    infoUser()
    clearExamModule()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  useEffect(() => {
    if (user !== null && user !== undefined) {
      console.log('user.CompanyId', user.CompanyId)
      // Se for super admin, nesta tela, lista todas as provas
      // Se for master ou docente, lista as provas da escola
      fetchStudentExams('', 1, undefined, undefined, 10)
      // setTimeout(function () {
      //   console.log('asdasd')
      // }, 500)
    }
  }, [user])

  function handleViewAction (examId) {
    setLoad(true)
    history.push('/student/exam?examId=' + examId)
  }

  function isMobile (width) {
    if (width > 600){
      return false
    } else {
      return true
    }
  }

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const header = (
    <Row>
      <Col size={4}><Header>STATUS</Header></Col>
      <Col size={4}><Header>TÍTULO</Header></Col>
      <Col size={4}><Header>DATA DE LIBERAÇÃO</Header></Col>
      <Col size={4}><Header>DATA DE EXPIRAÇÃO</Header></Col>
      <Col size={4}><Header>INICIAR PROVA</Header></Col>
    </Row>
  )

  const headerMobile = (
    <Row>
      <Col size={4}><Header>STATUS</Header></Col>
      <Col size={4}><Header>TÍTULO</Header></Col>
      <Col size={4}><Header>INICIAR</Header></Col>
    </Row>
  )

  const renderName = name => (
    <Tooltip
      title={name}
      placement='topLeft'
    >
      {name}
    </Tooltip>
  )

  const renderStatus = (status, color) => (
    <div style={{backgroundColor:color, color:"#fff", inlineSize:"min-content", padding:"3px 10px 3px 10px", border:"1px solid", borderRadius:"20px"}}>
      {status}
    </div>
  )

  const handleStatus = (startAt, stopAt, status) => {
    let startDay = startAt.slice(0, 2)
    let startMonth = startAt.slice(3, 5)
    let startYear = startAt.slice(6)
    let stopDay = stopAt.slice(0, 2)
    let stopMonth = stopAt.slice(3, 5)
    let stopYear = stopAt.slice(6)

    let startDate = `${startMonth}-${startDay}-${startYear}`
    let stopDate = `${stopMonth}-${stopDay}-${stopYear}`

    const startAtDate = new Date(startDate)
    const stopAtDate = new Date(stopDate)
    const date = new Date()
    //Formata a data zerando o horario para não interferir na comparação
    const momentDate = moment(date).format('MM/DD/YYYY')
    const currentDate = new Date(momentDate)

    if(status === "Soon"){
      return (
        <Tooltip
          title={"Em Breve"}
          placement='topLeft'
        >
          {renderStatus("Em breve", "#514FFF")}
        </Tooltip>
      )  
    } else if (status === "NotStarted"){
        return (
          <Tooltip
            title={"Disponível"}
            placement='topLeft'
          >
            {renderStatus("Disponível", "#008000")}
          </Tooltip>
        )
      } else if (status === "InProgress"){
        return (
          <Tooltip
            title={"Em andamento"}
            placement='topLeft'
          >
            {renderStatus("Em andamento", "#FBA661")}
          </Tooltip>
        )
      } else if (status === "Finished"){
        return (
          <Tooltip
            title={"Finalizada"}
            placement='topLeft'
          >
            {renderStatus("Finalizada", "#178BF6")}
          </Tooltip>
        )
      }
      else {
        return (
          <Tooltip
            title={"Perdida"}
            placement='topLeft'
          >
            {renderStatus("Perdida", "#ff0000")}
          </Tooltip>
        )
      }  
    }

  const renderActions = (item, user) => {
    if (item.IsSimulieDatabase === true && user.Role !== 'SuperUser') {
      return (<ActionList
        onView={() => handleViewAction(item.Id)}
      />)
    } else {
      return (<ActionList
        onView={() => handleViewAction(item.Id)}
      />)
    }
  }

  const onSearch = (value) => {
    let url = new URL(value)
    history.push('/student/exam?examId=' + url.searchParams.get('examId'))
  }

  const renderItem = item => (
    <StyledListItem>
      <Row>
        <Col size={4}>{handleStatus(item.StartAt, item.StopAt, item.StudentOnlineExamStatus ? item.StudentOnlineExamStatus : "")}</Col>
        <Col size={4}>{renderName(item.Title)}</Col>
        <Col size={4}>{renderName(item.StartAt)}</Col>
        <Col size={4} >{renderName(item.StopAt)}</Col>
        <Col size={4} >{renderActions(item, user)}</Col>
      </Row>
    </StyledListItem>
  )

  const renderItemMobile = item => {
    let start = item.StartAt
    let end = item.StopAt
    return (
    <StyledListItem>
      <Row>
        <Col size={4}>
          <MobStatusDiv>
            {handleStatus(item.StartAt, item.StopAt, item.StudentOnlineExamStatus ? item.StudentOnlineExamStatus : "")}
            {`De ${start}`}<br/>
            {`Até ${end}`}
          </MobStatusDiv>
        </Col>
        <Col size={4}><MobItensDiv>{renderName(item.Title)}</MobItensDiv></Col>
        <Col size={4} ><MobItensDiv>{renderActions(item, user)}</MobItensDiv></Col>
      </Row>
    </StyledListItem>
  )}

  const onChangePagination = e => {
    fetchStudentExams('', e, undefined, undefined, 10)
    setListTotal(undefined)
    setList([])
    setCurrent(e)
  }

  const pagination = (
    <Pagination
      current={current}
      total={listTotal === undefined ? total : listTotal}
      onChange={onChangePagination}
    />
  )

  const empty = (
    <Empty
      description='Nenhum arquivo encontrado!'
      image={Empty.PRESENTED_IMAGE_DEFAULT}
    />
  )

  const formatDate = (date) => {
    let d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = d.getFullYear()

    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }

    if (isNaN(year)) {
      return date
    }

    return [day, month, year].join('-')
  }

  const filterList = e => {
    if (e && e.length >= 0) {
      return e.map(element => {
        element.StartAt = formatDate(element.StartAt)
        element.StopAt = formatDate(element.StopAt)
        return element
      })
    }

    return e
  }

  return (
    <Page style={{ display: 'flex', alignItems: 'center' }}>
      <br />
      <div>
        <Title>Entrar com Link</Title>
      </div>
      <div style={{ width: '100%', maxWidth: '640px' }} >
        <Search
          placeholder='www.simulie.com/student/exam?examId=00000000-0000-0000-0000-000000000000'
          allowClear
          onSearch={onSearch}
          enterButton='Entrar'
        />
      </div>
      <br />
      {!load && <Content style={{ width: '100%', maxWidth: '1200px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Title>Provas Disponíveis</Title>
        </div>
        <br />
        {isMobile(screenWidth) ? headerMobile : header}
        <ContainerCard
          padding={0}
          shadowYOffset={5}
        >
          <List
            dataSource={(list.length > 0 && exams.length > 0) ? filterList(list) : filterList(exams)}
            locale={{ emptyText: empty }}
            renderItem={isMobile(screenWidth) ? renderItemMobile : renderItem}
          />
        </ContainerCard>
        {pagination}
      </Content>}
    </Page>
  )
}

StudentPainelPage.propTypes = {
  fetchStudentExams: PropTypes.func.isRequired,
  fetchStudentResultExam: PropTypes.func.isRequired,
  user: PropTypes.object,
  exams: PropTypes.array,
  loading: PropTypes.bool,
  total: PropTypes.number,
  setExamId: PropTypes.func.isRequired,
  setCanEdit: PropTypes.func.isRequired,
  clearExamModule: PropTypes.func,
  searchExamTag: PropTypes.func.isRequired,
  infoUser: PropTypes.func.isRequired
}

const mapStateToProps = ({ exams, questions, users }) => ({
  user: users.infoUser,
  exams: exams.examsList !== undefined ? exams.examsList.exams : [],
  total: exams.examsList !== undefined ? exams.examsList.total : 0,
  loading: exams.loading,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchStudentExams,
    fetchStudentResultExam,
    infoUser,
    clearExamModule,
    setExamId,
    setCanEdit,
    searchExamTag
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StudentPainelPage)
