import {
  CLEAR_TEMPLATE_MODULE,
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_FAILURE,
  UPLOAD_TEMPLATE,
  UPLOAD_TEMPLATE_SUCCESS,
  UPLOAD_TEMPLATE_FAILURE,
  VALIDATE_TEMPLATE,
  VALIDATE_TEMPLATE_SUCCESS,
  VALIDATE_TEMPLATE_FAILURE,
  SET_TEMPLATE_ID,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_FAILURE,
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  EDIT_TEMPLATE,
  EDIT_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE_FAILURE
} from './constants'

export const clearTemplateModule = () => ({
  type: CLEAR_TEMPLATE_MODULE
})

export const uploadTemplate = file => ({
  type: UPLOAD_TEMPLATE,
  payload: file
})

export const uploadTemplateSuccess = info => ({
  type: UPLOAD_TEMPLATE_SUCCESS,
  payload: info
})

export const uploadTemplateFailure = error => ({
  type: UPLOAD_TEMPLATE_FAILURE,
  payload: error
})

export const fetchTemplates = query => ({
  type: FETCH_TEMPLATES,
  payload: query
})

export const fetchTemplatesSuccess = templates => ({
  type: FETCH_TEMPLATES_SUCCESS,
  payload: templates
})

export const fetchTemplatesFailure = error => ({
  type: FETCH_TEMPLATES_FAILURE,
  payload: error
})

export const validateTemplate = template => ({
  type: VALIDATE_TEMPLATE,
  payload: template
})

export const validateTemplateSuccess = validation => ({
  type: VALIDATE_TEMPLATE_SUCCESS,
  payload: validation
})

export const validateTemplateFailure = error => ({
  type: VALIDATE_TEMPLATE_FAILURE,
  payload: error
})

export const setTemplateId = id => ({
  type: SET_TEMPLATE_ID,
  payload: id
})

export const createTemplate = data => ({
  type: CREATE_TEMPLATE,
  payload: data
})

export const createTemplateSuccess = () => ({
  type: CREATE_TEMPLATE_SUCCESS
})

export const createTemplateFailure = error => ({
  type: CREATE_TEMPLATE_FAILURE,
  payload: error
})

export const deleteTemplate = id => ({
  type: DELETE_TEMPLATE,
  payload: id
})

export const deleteTemplateSuccess = () => ({
  type: DELETE_TEMPLATE_SUCCESS
})

export const deleteTemplateFailure = error => ({
  type: DELETE_TEMPLATE_FAILURE,
  payload: error
})

export const fetchOrganizations = query => ({
  type: FETCH_ORGANIZATIONS,
  payload: query
})

export const fetchOrganizationsSuccess = organizations => ({
  type: FETCH_ORGANIZATIONS_SUCCESS,
  payload: organizations
})

export const fetchOrganizationsFailure = error => ({
  type: FETCH_ORGANIZATIONS_FAILURE,
  payload: error
})

export const fetchTemplate = (id) => ({
  type: FETCH_TEMPLATE,
  payload: id
})

export const fetchTemplateSuccess = template => ({
  type: FETCH_TEMPLATE_SUCCESS,
  payload: template
})

export const fetchTemplateFailure = error => ({
  type: FETCH_TEMPLATE_FAILURE,
  payload: error
})

export const editTemplate = (data) => ({
  type: EDIT_TEMPLATE,
  payload: data
})

export const editTemplateSuccess = (template) => ({
  type: EDIT_TEMPLATE_SUCCESS,
  payload: template
})

export const editTemplateFailure = error => ({
  type: EDIT_TEMPLATE_FAILURE,
  payload: error
})
