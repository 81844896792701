/* eslint-disable */
import React, { useState, useEffect, memo } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import ContainerCard from "../../card/ContainerCard";

import "quill-mention";
import "react-quill/dist/quill.snow.css";

import { sendImages as sendImagesAPI } from "../../../store/exams/services";
import { setEditing } from "../../../store/exams/actions";
import { Divider } from "@material-ui/core";
import VisualizarQuestao from "../VisualizarQuestao";
import EditarQuestao from "../EditarQuestao";
import QuestionBar from "../QuestionBar";

function QuestionCard({
  updateComponent,
  setUpdateComponent,
  disableSelect = false,
  disableEdicao = false,
  disableBotaoEdicao = false,
  showContent = false,
  editing,
  setEditing,
  examId,
  question,
  marginTop = "2em",
  onCheck,
  numberQuestion = 0,
  onlyModal = false,
  merged = false,
  forceDescheck,
  marcarAlternativa,
  adicionarTag,
  removerTag,
  changeContent,
  selecionarQuestao,
  salvarQuestao,
  contentOptions,
  treeIdEF,
  treeIdEM,
  treeIdHabilities,
  scholarship,
  blockibutton,
  disableComment = false,
  onAddComment,
  onAddSolution,
  handleQuestionsEditing,
}) {
  const [editar, setEditar] = useState(false);

  useEffect(() => {
    if (forceDescheck !== undefined) {
      onCheckboxChangeForce();
    }
  }, [forceDescheck]);

  function correctImageSize(text) {
    text = text.replace(/<img /g, '<img style="max-width: 100%;"');
    return (
      '<span style="color: #000000;font-size: 21px;font-family: Arial;">' +
      text +
      "</span>"
    );
  }

  const onCheckboxChangeForce = () => {
    let newValue = false;
    onCheck(newValue, question);
  };

  let width = "96vw";
  if (onlyModal) {
    width = "100%";
  }

  const checkQuestao = (questao) => () => {
    setUpdateComponent(!updateComponent);
    selecionarQuestao(examId, questao);
  };

  const atualizarAlternativa = (questao, alternativa) => {
    setUpdateComponent(!updateComponent);
    return marcarAlternativa(examId, questao, alternativa);
  };

  const checkAlternativa = (questao, alternativa) => () =>
    atualizarAlternativa(questao, alternativa);

  const handleEditar = () => {
    handleQuestionsEditing(!editar, question.Id);
    setEditar(!editar);
  };

  const handleSalvar = (questao) => () => {
    setEditar(false);
    setUpdateComponent(!updateComponent);
    salvarQuestao(examId, questao);
    handleQuestionsEditing(false, question.Id);
  };

  const handleAdicionarTag = (questao) => (tag) => {
    setUpdateComponent(!updateComponent);
    adicionarTag(examId, questao, tag);
  };

  const handleRemoverTag = (questao) => (tagIndex) => {
    setUpdateComponent(!updateComponent);
    removerTag(examId, questao, tagIndex);
  };

  const handleChangeContent = (questao) => (content) => {
    setUpdateComponent(!updateComponent);
    changeContent(examId, questao, content);
  };

  return (
    <>
      {merged && cancelEditQuestionStatement()}
      {question !== undefined && (
        <ContainerCard
          marginTop={2}
          style={{
            marginTop: marginTop,
            marginLeft: "0px",
            width: width,
            maxWidth: "976px",
          }}
        >
          <QuestionBar
            showContent={showContent}
            editar={editar}
            question={question}
            numberQuestion={numberQuestion}
            disableSelect={disableSelect}
            disableEdicao={disableEdicao}
            disableBotaoEdicao={disableBotaoEdicao}
            blockibutton={blockibutton}
            examId={examId}
            checkQuestao={checkQuestao}
            handleEditar={handleEditar}
            removerTag={handleRemoverTag(question)}
            adicionarTag={handleAdicionarTag(question)}
            changeContent={handleChangeContent(question)}
            contentOptions={contentOptions}
            scholarship={scholarship}
            disableComment={disableComment}
            onAddComment={onAddComment}
            onAddSolution={onAddSolution}
          />
          <br />
          <Divider />
          <br />
          {editar ? (
            <EditarQuestao
              questao={question}
              editing={editing}
              setEditing={setEditing}
              correctImageSize={correctImageSize}
              checkAlternativa={checkAlternativa}
              sendImagesAPI={sendImagesAPI}
              salvarQuestao={handleSalvar(question)}
              updateComponent={updateComponent}
              setUpdateComponent={setUpdateComponent}
            />
          ) : (
            <VisualizarQuestao
              salvarQuestao={handleSalvar(question)}
              questao={question}
              disableEdicao={disableEdicao}
              correctImageSize={correctImageSize}
              checkAlternativa={checkAlternativa}
            />
          )}
        </ContainerCard>
      )}
    </>
  );
}

QuestionCard.propTypes = {
  master: PropTypes.bool,
  handleAddTag: PropTypes.func,
  handleRemoveTag: PropTypes.func,
  setEditing: PropTypes.func,
  forceDescheck: PropTypes.any,
  questionTags: PropTypes.any,
  loadingUrls: PropTypes.bool,
  uploadedUrls: PropTypes.any,
  justSelected: PropTypes.any,
  question: PropTypes.any,
  marginTop: PropTypes.any,
  isSelectable: PropTypes.any,
  onCheck: PropTypes.any,
  selected: PropTypes.any,
  numberQuestion: PropTypes.any,
  editView: PropTypes.any,
  createView: PropTypes.any,
  onlyModal: PropTypes.any,
  checkQuestionSelected: PropTypes.any,
  numberQuestionAndIdQuestion: PropTypes.any,
  correctAnswer: PropTypes.func,
  similarStatement: PropTypes.array,
  sendImages: PropTypes.func,
  treeIdEF: PropTypes.string,
  treeIdEM: PropTypes.string,
  treeIdHabilities: PropTypes.string,
  descriptionInTree: PropTypes.string,
};

const mapStateToProps = ({ exams }) => ({
  loadingUrls: exams.loadingUrls,
  uploadedUrls: exams.uploadedUrls,
  editing: exams.editing,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendImagesAPI,
      setEditing,
    },
    dispatch
  );

const areEqual = (prevProps, nextProps) => {
  // Manter assim por mais que pareça bronco, loucuras do JS
  return (
    prevProps.question.Statement === nextProps.question.Statement &&
    prevProps.updateComponent === nextProps.updateComponent
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(QuestionCard, areEqual));
