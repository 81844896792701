/* eslint-disable */
import React, { useEffect, useState } from "react";

import { Button, Select } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { StyledButton, StyledInput } from "../../modal/styles/Modal.styles";
import { StyledTitle } from "../../../pages/styles/ListExamsPage.styles";
import { StyledCheckbox } from "../QuestionCard/styles";
import { FormControl } from "@material-ui/core";
import { RadioGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Radio } from "@material-ui/core";

import {
  Col,
  Row,
  Item,
  Header,
  DisplayStyle,
  TotalQuestionsSelected,
  FloatText,
} from "./styles";
import {
  SelectAll,
  SelectRandom,
  StyledSelect,
} from "../QuestionFilterHeader/styles";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import UploadGabarito from "../UploadGabarito";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function QuestionHeader({
  changePage,
  total = 0,
  selecionadas = 0,
  editable = true,
  onChange,
  onCheck,
  onRandom,
  createView = true,
  editView = false,
  onlyModal = false,
  onDesCheck,
  examId,
  fetchClassifications,
  handleEdit,
  handleApplyAnswers,
  getAnswerGPT,
  disableGabarito,
  scholarship
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isQuestionHeaderOpen = Boolean(anchorEl);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileQuestionHeaderOpen = Boolean(mobileMoreAnchorEl);
  const { Option } = Select;
  const [isChecked, setIsChecked] = useState(false);
  const [randomValue, setRandomValue] = useState("");
  const [pathName, setPathName] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [openGabarito, setOpenGabarito] = useState(false);
  const [gabarito, setGabarito] = useState("");
  const [selectedOption, setSelectedOption] = useState("paste");

  function handleChange(value) {
    handleEdit();
    onChange(value);
  }

  const handleMobileQuestionHeaderClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseGabarito = () => {
    setOpenGabarito(false);
  };

  const handleQuestionHeaderClose = () => {
    setAnchorEl(null);
    handleMobileQuestionHeaderClose();
  };

  function handleRandom(e) {
    setIsChecked(false);
    let intn = parseInt(e.target.value);
    if (e.target.value !== "" && !isNaN(intn)) {
      onRandom(0);
      let number = intn > total ? total : intn;

      number > 0 && onRandom(number);
      number > 0 && setRandomValue(number);
    } else {
      onCheckboxChangeClear();
    }
  }

  const onCheckboxChange = (e) => {
    setRandomValue("");
    setIsChecked(e.target.checked);
    onCheck(e);
  };
  const onCheckboxChangeClear = () => {
    setRandomValue("");
    setIsChecked(false);
    onDesCheck();
  };

  useEffect(() => {
    let url = new URL(window.location.href);
    setPathName(url.pathname);
    if (editView) {
      onCheck();
    }
  }, [editView]);

  /*
  useEffect(() => {
    let numberRef = React.createRef()
    setNumberRef(numberRef)
  }, []) */

  const QuestionHeaderId = "primary-search-account-QuestionHeader";
  const renderQuestionHeader = (
    <Header
      className={classes.sectionDesktop}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={QuestionHeaderId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isQuestionHeaderOpen}
      onClose={handleQuestionHeaderClose}
    >
      <DisplayStyle>
        {createView && !onlyModal && (
          <Row style={{ width: "100%" }}>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "left",
              }}
            >
              <SelectAll>
                <StyledTitle>Todas: </StyledTitle>
                <StyledCheckbox
                  type="checkbox"
                  name="select-all"
                  onChange={onCheckboxChange}
                  checked={isChecked}
                />
              </SelectAll>
            </div>
            <div>
              <SelectRandom>
                <StyledTitle>
                  Random:{" "}
                  <StyledInput
                    type="text"
                    value={randomValue}
                    onChange={handleRandom}
                  />
                </StyledTitle>
              </SelectRandom>
            </div>
            {/* {onlyModal &&
            <TotalQuestionsSelected>
              <StyledTitle>Selecionadas:<span style={{ marginLeft: '3px' }} />{total}</StyledTitle>
            </TotalQuestionsSelected>
          }
          {!onlyModal &&
            <TotalQuestionsSelected>
              <StyledTitle>Selecionadas:<span style={{ marginLeft: '3px' }} />{selecionadas}</StyledTitle>
            </TotalQuestionsSelected>
          } */}
            <div>
              <span>
                <StyledButton
                  type="primary"
                  shape="round"
                  onClick={onCheckboxChangeClear}
                >
                  Limpar
                </StyledButton>
                &nbsp;&nbsp;
              </span>
            </div>
          </Row>
        )}
        {pathName === "/list-exams/list-questions" && (
          <StyledButton
            type="primary"
            shape="round"
            style={{ marginLeft: "auto" }}
            disabled={disableGabarito}
            onClick={() => {
              setOpenGabarito(true);
            }}
          >
            Carregar Gabarito
          </StyledButton>
        )}
        {pathName === "/list-exams/list-questions" && (
          <StyledButton
            type="primary"
            shape="round"
            style={{ marginLeft: "16px" }}
            onClick={() => {
              setOpen(true);
              handleEdit();
            }}
          >
            Editar Classificações
          </StyledButton>
        )}
      </DisplayStyle>
    </Header>
  );

  const mobileQuestionHeaderId = "primary-search-account-QuestionHeader-mobile";
  const renderMobileQuestionHeader = (
    <Header
      className={classes.sectionMobile}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileQuestionHeaderId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileQuestionHeaderOpen}
      onClose={handleMobileQuestionHeaderClose}
      mode="horizontal"
    >
      {pathName === "/list-exams/list-questions" && (
        <StyledButton
          type="primary"
          shape="round"
          style={{ marginLeft: "auto" }}
          onClick={() => {
            setOpenGabarito(true);
          }}
        >
          Carregar Gabarito
        </StyledButton>
      )}

      {createView && !onlyModal && (
        <Row>
          <Col>
            <Item>
              <SelectAll>
                <StyledTitle>Todas: </StyledTitle>
                <StyledCheckbox
                  type="checkbox"
                  name="select-all"
                  onChange={onCheckboxChange}
                  checked={isChecked}
                />
              </SelectAll>
            </Item>
            <br />
            <Item>
              <SelectRandom>
                <StyledTitle>
                  Random:{" "}
                  <StyledInput
                    type="text"
                    value={randomValue}
                    onChange={handleRandom}
                  />
                </StyledTitle>
              </SelectRandom>
            </Item>
          </Col>
          <Col>
            <br />
            <Item
              style={{
                paddingRight: "10px",
                marginTop: "5px",
                justifyContent: "center",
              }}
            >
              {!onlyModal && (
                <span>
                  <StyledButton
                    type="primary"
                    shape="round"
                    onClick={onCheckboxChangeClear}
                  >
                    Limpar
                  </StyledButton>
                  &nbsp;&nbsp;
                </span>
              )}
            </Item>
          </Col>
        </Row>
      )}
      <FloatText>
        {!editView && (
          <>
            {onlyModal && (
              <Item>
                <TotalQuestionsSelected>
                  <StyledTitle style={{ color: "green" }}>{total}</StyledTitle>
                </TotalQuestionsSelected>
              </Item>
            )}
            {!onlyModal && (
              <Item>
                <TotalQuestionsSelected>
                  <StyledTitle style={{ color: "green" }}>
                    {selecionadas}
                  </StyledTitle>
                </TotalQuestionsSelected>
              </Item>
            )}
          </>
        )}
      </FloatText>
    </Header>
  );

  const handleChangeGabarito = (event, _fname) => {
    setGabarito(event.target.value);
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleButton = () => {
    if (selectedOption === "paste") {
      handleApplyAnswers(gabarito);
      window.alert("O gabarito foi aplicado!")
      setOpenGabarito(false);
    } else if (selectedOption === "ai") {
      const answerGPT = getAnswerGPT();
      const answerGPTString = answerGPT.join('');
      handleApplyAnswers(answerGPTString);
      window.alert("O gabarito foi aplicado!")
      setOpenGabarito(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Atenção!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao continuar com a edição de classificação, as modificações feitas
            por edição de texto serão perdidas.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => {
            fetchClassifications(examId, scholarship);
        }} color="primary">
          Continuar
        </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openGabarito}
        onClose={handleCloseGabarito}
        maxWidth="sm" // Set the maximum width of the dialog
        fullWidth // Make the dialog full width
      >
        <DialogTitle>{"Selecionar Opção de Gabarito"}</DialogTitle>
        <DialogContent style={{ minHeight: "300px", height: "300px" }}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gabarito-options"
              name="gabarito-options"
              value={selectedOption}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="paste"
                control={<Radio />}
                label="Colar Gabarito"
              />
              <FormControlLabel
                value="ai"
                control={<Radio />}
                label="Gabarito com Inteligência Artificial"
              />
            </RadioGroup>
          </FormControl>
          {selectedOption === "ai" && (
            <DialogContentText style={{ marginTop: "16px", color: "red", fontSize: '18px' }}>
              *Atenção, o gabarito fornecido por inteligência artificial pode apresentar erros.
            </DialogContentText>
          )}
          {selectedOption === "paste" && (
            <UploadGabarito
              name=""
              value={gabarito}
              handleChange={handleChangeGabarito}
              handleButton={handleButton}
            />
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseGabarito} color="secondary">
            Cancelar
          </StyledButton>
          <StyledButton onClick={handleButton} color="primary">
            Confirmar
          </StyledButton>
        </DialogActions>
      </Dialog>
      {renderQuestionHeader}
      {renderMobileQuestionHeader}
    </>
  );
}

export default QuestionHeader;
