import styled from '@emotion/styled'

export const Card = styled.div`
  background: #fff;
  border-radius: 24px;
  box-shadow: ${props => props.shadowXOffset || 0}px ${props => props.shadowYOffset || 0}px 10px 0px lightgrey;  
  ${props => props.block && 'flex: 1;'}
  margin: ${({ centered, margin }) => centered ? '0 auto' : `${margin}rem`};
  ${props => props.marginTop && `margin-top: ${props.marginTop}rem;`}
  ${props => props.maxWidth && `max-width: ${props.maxWidth}rem;`}
  padding: ${props => !(typeof props.padding).match(/undefined|object/) ? props.padding : '1.5'}rem;
  ${props => props.centerText && 'text-align: center;'}
  ${props => props.width && `width: ${props.width}rem;`}
  margin-bottom: -10px;
`
