import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Container, Label, Wording, Heading, Question, Alternative } from './styles/TemplatePreview.styles'

const TemplatePreview = ({
  values: {
    font,
    font_size: fontSize,
    numbering_type: numberingType,
    alternative_type: alternativeType,
    indentation_question: indentationQuestion,
    indentation_answer: indentationAnswer,
    spacing_before_question: spacingBeforeQuestion,
    spacing_after_question: spacinfgAfterQuestion
  },
  ratio
}) => {
  const getNumbering = (number) => {
    const defaultValue = { isNewLine: false, text: `${number}.` }
    if (!numberingType) return defaultValue

    const match = numberingType.match(/#/g)
    if (!match) return defaultValue

    const padding = match.length

    const isNewLine = !numberingType.includes('\t')

    const text = numberingType.replace(
      '#'.repeat(padding),
      number.toString().padStart(padding, '0')
    )

    return { isNewLine, text }
  }

  const getAlternative = (alternativeNumber) => {
    const defaultValue = `${String.fromCharCode('a'.charCodeAt(0) + alternativeNumber - 1)})`
    if (!alternativeType) return defaultValue

    const match = alternativeType.match(/[a-zA-Z]/g)
    if (!match) return defaultValue

    const letter = match[match.length - 1]
    const charCode = letter.charCodeAt(0)

    return alternativeType.replace(
      letter,
      String.fromCharCode(charCode + alternativeNumber - 1)
    )
  }

  const renderWording = (number) => (
    <Wording
      font={font}
      fontSize={fontSize}
    >
      <Heading
        indentation={indentationQuestion / ratio}
        spacingBefore={spacingBeforeQuestion}
        spacingAfter={spacinfgAfterQuestion}
      >
        <Question>
          {getNumbering(number).text}
          {getNumbering(number).isNewLine && <br />}
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
        </Question>
      </Heading>
      <Alternative
        type={alternativeType}
        indentation={indentationAnswer / ratio}
      >
        {getAlternative(1)} Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur
      </Alternative>
      <Alternative
        type={alternativeType}
        indentation={indentationAnswer / ratio}
      >
        {getAlternative(2)} Tortor vitae lacinia potenti
      </Alternative>
      <Alternative
        type={alternativeType}
        indentation={indentationAnswer / ratio}
      >
        {getAlternative(3)} Sagittis accumsan egestas
      </Alternative>
      <Alternative
        type={alternativeType}
        indentation={indentationAnswer / ratio}
      >
        {getAlternative(4)} Risus lobortis eros
      </Alternative>
      <Alternative
        type={alternativeType}
        indentation={indentationAnswer / ratio}
      >
        {getAlternative(5)} Class convallis conubia
      </Alternative>
    </Wording>
  )

  return (
    <Fragment>
      <Label>Pré-visualizar Estilos</Label>
      <Container>
        {renderWording(1)}
        {renderWording(2)}
        {renderWording(3)}
      </Container>
    </Fragment>
  )
}

TemplatePreview.propTypes = {
  values: PropTypes.shape({
    font: PropTypes.string,
    font_size: PropTypes.string,
    numbering_type: PropTypes.string,
    alternative_type: PropTypes.string,
    indentation_question: PropTypes.string,
    indentation_answer: PropTypes.string,
    spacing_before_question: PropTypes.string,
    spacing_after_question: PropTypes.string
  }),
  ratio: PropTypes.number
}

TemplatePreview.defaultProps = {
  ratio: 1
}

export default TemplatePreview
