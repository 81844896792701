import createReducer from '../createReducer'
import {
  CLEAR_ORGANIZATION_MODULE,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAILURE
} from './constants'

const initialState = {
  id: null,
  file: null,
  query: {},
  validation: {},
  organizations: [],
  templates: [],
  template: {},
  loading: false,
  uploading: false,
  error: {}
}

export default createReducer(initialState, {
  [CLEAR_ORGANIZATION_MODULE]: () => initialState,
  [FETCH_ORGANIZATION]: (state, action) => ({
    ...state,
    organizations: initialState.organizations,
    loading: true,
    error: initialState.error
  }),
  [FETCH_ORGANIZATION_SUCCESS]: (state, action) => ({
    ...state,
    organizations: action.payload,
    loading: false,
    error: initialState.error
  }),
  [FETCH_ORGANIZATION_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  })
})
