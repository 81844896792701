import React from 'react';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

const CustomAlertModal = ({ open, handleClose, message, severity }) => {
 return (
    <Dialog open={open} onClose={handleClose}>
        <Box sx={{p:1}}>
            <Alert variant="outlined" severity={severity}>
                {message}
            </Alert>
        </Box>
    </Dialog>
 );
}

export default CustomAlertModal;