import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import {
  fetchResultExam,
  fetchStudentResultExam,
  fetchDisciplinasTrilhas,
  fetchAnoTrilhas,
  fetchStudentsTrilhas,
  fetchResultTrilhas,
  fetchUnidadesTematicas,
  fetchReadAndWriting,
  fetchConsolidacao,
  fetchReadingAndWritingCSVData,
  fetchStudentResultAll,
  updateResultRW,
  setExamId,
  searchExamTag,
  setCanEdit,
  clearExamModule,
  fetchExamReport,
  fetchPowerBIReport,
} from "../store/exams/actions";

import { fetchStudentResultExam as fetchStudentResultExamData, getTRIFiles } from "../store/exams/services";

import {
  getClasses
} from "../store/auth/actions";

import {
  fetchCompanies,
  fetchCompanyGrades
} from "../store/companies/actions";

import { infoUser } from "../store/users/actions";
import { fetchResultExam as getPowerBIExams } from "../store/exams/services";

import { Typography } from "antd";
import {
  Header,
} from "./styles/ListExamsPage.styles";

import { CSVLink } from "react-csv";
import { Page } from "./styles/Page.styles";
import { Pagination } from "./styles/ListExamsPage.styles";
import { Content } from "./styles/DetailsPage.styles";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  InputLabel,
  Checkbox,
  FormControl,
  Switch,
  FormControlLabel,
  Menu,
  Box,
  List,
  ListItemText,
  Collapse,
  CircularProgress,
  ButtonGroup,
} from "@material-ui/core";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import MuiTypography from '@mui/material/Typography';

import { AssignmentReturned, ExpandMore, ExpandLess, Print, Settings, Update, Visibility } from "@material-ui/icons";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import CustomizedProgressBars from "../components/progress/Progress";
import TextFieldDialog from "../components/modal/TextFieldDialog";
import CustomAlertModal from "../components/modal/CustomAlertModal";
import DebounceInputField from "../components/input/DebounceInput";

import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';

import TablePDF from '../components/tablepdf/Table';
import PowerBiComponent from "../components/reports/report";
import AccordionResults from "../components/results/AccordionResult";
import { NH_SUPER_ID, FORTALEZA_SUPER_ID, LIST_OF_SUPER_IDS_WITH_TRI } from "../utils/constants";
import CreateTurmaTable from "../components/table/CreateTurmaTable";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  content: {
    justifyContent: "space-between",
  },
  paper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px",
    alignItems: "center",
    padding: "16px",
  },
  formControl: {
    display: "flex",
    maxWidth: "720px",
    justifyContent: "space-around",
  },
  checkboxConfig: {
    display: "flex",
    maxWidth: "720px",
    margin: "40px auto",
    justifyContent: "center",
    flexDirection: "column",
  },
  buttonSearch: {
    marginBottom: "50px",
    marginTop: "30px",
    display: "flex",
    maxWidth: "720px",
    margin: "auto",
    justifyContent: "space-around",
  },
  buttonViewReport: {
    textTransform: "none",
    backgroundColor: "#514FFF",
    color: "#fff",
    marginBottom: "16px",
    "&:hover": {
      backgroundColor: "#3837B2",
    },
  },
  powerBI: {
    height: 1024,
  },
  paddingCell: {
    "&.MuiTableCell-paddingNone": {
      padding: "8px 8px 4px 8px",
    },
  },
  studentsNameColumn: {
    width: "250px",
    "&.MuiTableCell-paddingNone": {
      padding: "8px 8px 4px 8px",
    },
  },
  checkboxTitleColumn: {
    "&.MuiTableCell-paddingNone": {
      padding: "8px 8px 4px 8px",
    },
    width: "100px",
  },
  adaptiveAssessmentColumn: {
    "&.MuiTableCell-paddingNone": {
      padding: "8px 8px 4px 8px",
    },
    width: "130px",
    backgroundColor: "#EEE"
  },
  select: {
    marginTop: "8px"
  },
  turmaTable: {
    marginTop: "16px"
  }
}));

function ResultExamsPage({
  fetchCompanyGrades,
  fetchCompanies,
  fetchResultExam,
  fetchStudentResultExam,
  fetchDisciplinasTrilhas,
  fetchAnoTrilhas,
  getClasses,
  fetchResultTrilhas,
  fetchUnidadesTematicas,
  fetchReadAndWriting,
  fetchConsolidacao,
  fetchReadingAndWritingCSVData,
  fetchStudentResultAll,
  readingAndWritingCSVData,
  updateResultRW,
  companies,
  exams,
  students,
  clearExamModule,
  infoUser,
  user,
  loading,
  powerbiEmbed,
  consolidacaoRaw,
  resultRW,
  updateRW,
  studentsAll,
  grades,
  userGrades,
  fetchPowerBIReport,
  fetchExamReport,
  anos = [],
  disciplinas = [],
  unidades = [],
  resultTrilhas = []
}) {
  let [list, setList] = useState([]);
  let [selectedExam, setSelectedExam] = useState("");
  let [selectedExamTitle, setSelectedExamTitle] = useState("");
  let [grade, setGrade] = useState("");
  let [company, setCompany] = useState("");
  let [accordeons, setAccordeons] = useState(undefined);
  let [period, setPeriod] = useState(undefined);
  let [serie, setSerie] = useState(undefined);
  let [consolidacaoReadView, setConsolidacaoReadView] = useState(undefined);
  let [consolidacaoWritingView, setConsolidacaoWritingView] = useState(undefined);
  let [openTurma, setOpenTurma] = useState(false);
  let [turmaTable, setTurmaTable] = useState(false);
  let [month, setMonth] = useState(undefined);
  let [studentsResult, setStudentsResult] = useState([]);
 
  let [showAccordeons, setShowAccordeons] = useState(true);
  let [questionBar, setQuestionBar] = useState(undefined);
  let [newReportLoading, setNewReportLoading] = useState(false);

  const [openObjectiveOption, setOpenObjectiveOption] = useState(true);
  const [openReadingAndWritingOption, setOpenReadingAndWritingOption] = useState(true);
  const [examsOptionsPbi, setExamsOptionsPbi] = useState([]);
  const [examsSelectedPbi, setExamsSelectedPbi] = useState([]);
  const [powerbiExamsIds, setPowerbiExamsIds] = useState([]);
  const [showResults, setShowResults] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [schoolCount, setSchoolCount] = useState(undefined)

  const [ano, setAno] = useState("");
  const [disciplina, setDisciplina] = useState("");
  const [unidadesDict, setUnidadesDict] = useState(undefined);
  const [habilidadeView, setHabilidadeView] = useState(false);
  const [adaptedExamData, setAdaptedExamData] = useState([]);
  const [currentAdaptedExamId, setCurrentAdaptedExamId] = useState("");
  const [adaptedExamGrades, setAdaptedExamGrades] = useState([]);
  const [currentAdaptedExamGrade, setCurrentAdaptedExamGrade] = useState("");
  const [adaptedExamSchools, setAdaptedExamSchools] = useState([]);
  const [currentAdaptedExamSchool, setCurrentAdaptedExamSchool] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [turmaName, setTurmaName] = useState("");
  const [correctPercent, setCorrectPercent] = useState({});
  const [studentsData, setStudentsData] = useState({});
  const [schoolName, setSchoolName] = useState("");
  const [totalStudents, setTotalStudents] = useState(0);
  const [rows, setRows] = useState([]);
  const [questionAns, setQuestionAns] = useState({});

  const adaptedExamRows = useRef([]);
  const adaptedExamCorrectPercent = useRef({});
  const adaptedExamSchoolId = useRef("");
  const adaptedExamQuestionAns = useRef([]);
  const adaptedExamName = useRef("");
  const adaptedExamTurmaName = useRef("");
  const adaptedExamTotalStudents = useRef(0);
  const showAdaptedExamTable = useRef(false);

  const ShowResultsOptions = {
    BY_EXAM: 0,
    BY_SCHOOL: 1,
    TRAILS: 2,
    CONSOLIDATED: 3,
    INTEGRATED_ASSESSMENTS: 4,
    PRESCHOOL: 5
  }

  const tabsIndicatorStyle = {
    backgroundColor: '#514fff',
    height: '2px',
  };

  useEffect(() => {
    if(unidadesDict) return;
    const ud = {}
    if (unidades["unidades_tematicas"]) for (var u of unidades["unidades_tematicas"]) ud[u.id] = u.title;
    setUnidadesDict(ud);
  }, [unidades && unidades["unidades_tematicas"]])

  const classes = useStyles();
  const [dataCSV, setDataCSV] = useState({
    data: [],
    headers: [],
  });

  const listOfSuperIdsWithPowerBI = [NH_SUPER_ID, FORTALEZA_SUPER_ID];
  const NHSuperIdPowerBi = NH_SUPER_ID;

  const fetchPowerBIExams = async () => {
    try {
      let response = await getPowerBIExams("", undefined, undefined, 1, 100);
      if (Object.keys(response).length !== 0 && response.Itens && response.Itens.length > 0) {
        setExamsOptionsPbi(response.Itens);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const generatePdfDocument = async (document, fileName) => {
    const blob = await pdf((
      document
    )).toBlob();
    saveAs(blob, fileName);
  };

  const getNota = (qs) => {
    const acertos = qs.filter(q => q.IsCorrect === true).length;
    const total = qs.length;

    const nota = 10 * acertos / total;

    return `${nota.toFixed(2)}`
  }

  useEffect(() => {
    if (studentsAll && studentsAll.length > 0) {
      console.log(studentsAll);
      const nome = studentsAll[0].Name;
      const data = {
        nome: nome,
        header: {
          prova: "Prova",
          nota: "Nota"
        },
        data: {
          id: studentsAll[0].Id,
          items: studentsAll.map((sa, idx) => ({
            sr: idx,
            prova: sa.Title,
            nota: getNota(sa.Questions)
          })),
        }
      };

      generatePdfDocument(<TablePDF data={data} />, `${nome}-boletim.pdf`);
    }
  }, [studentsAll])

  const handlePowerBIReport = (newReport) => {
    let examIds = examsSelectedPbi.map((exam) => exam.Id);
    setPowerbiExamsIds(examIds);
    fetchPowerBIReport(examIds, newReport);
  };

  useEffect(() => {
    infoUser();
    clearExamModule();
    // fetchAnoTrilhas();
    getClasses();
  }, []);

  useEffect(() => {
    if (user && user.Role) {
      if (user.Role !== "Super" && user.Role !== "Guest") {
        setCompany(user.CompanyId);
        fetchCompanyGrades(user.CompanyId);
      };
      if (user.Role === "Super" || user.Role === "SuperUser") fetchCompanies("", 1);
    }
  }, [user])

  useEffect(() => {
    if (user !== null && user !== undefined) {
      console.log("user.CompanyId", user.CompanyId);
      // Se for super admin, nesta tela, lista todas as provas
      // Se for master ou docente, lista as provas da escola.
      fetchResultExam("", 1, undefined, undefined, 100);
      fetchPowerBIExams();
    }
  }, [user]);

  useEffect(() => {
    if (currentAdaptedExamId) {
      getAdaptedExamData();
    }
  }, [currentAdaptedExamId]);

  useEffect(()=>{
    if (students && students.length > 0)
      setSchoolCount(students[0].total);
  },[students]);

  const getMonthList = (month) => {
    return {
      "1m": ["Janeiro"],
      "3m": ["Novembro", "Dezembro", "Janeiro"],
      "6m": ["Agosto", "Setembro", "Outubro", "Novembro", "Dezembro", "Janeiro"]
    };
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const buildConsolidacao = (rw, fields = [], type = "Reading") => {
    const periodList = getMonthList(month);
    const consolidacaoAll = {};

    Object.entries(periodList).map(([p, monthList]) => {
      const consolidacao = {};
      let count = 0;
      let countMonth = 0;

      fields.map(f => {
        consolidacao[f] = 0;
      });

      for (const m of [month]) {
        const rwm = studentsResult.length > 0 ? studentsResult : rw[m];
        if (rwm) {
          Object.values(rwm).map(value => {
            fields.map(f => {
              consolidacao[f] += value[type][f] ? 1 : 0
            })
            count += 1;
          })
          countMonth += 1
        }
      }

      fields.map(f => {
        consolidacao[f] = {
          "value": consolidacao[f],
          "months": countMonth,
          "all": count
        };
      });

      consolidacaoAll[p] = consolidacao;
    })

    return consolidacaoAll;
  }

  useEffect(() => {
    if (consolidacaoRaw[month]) updateConsolidacao("1m");
  }, [consolidacaoRaw, month]);

  useEffect(() => {
    if (serie) fetchConsolidacao(serie);
  }, [updateRW]);

  const calculateCorrectPercent = (student) => {
    const correctPercent = {};
    const examQuestions = {};
    Object.entries(student.Table).map(([schoolId, turma]) => {
      correctPercent[schoolId] = {};

      let schoolCorrectPercent = {
        correct: 0,
        total: 0,
        totalStudents: 0,
      };

      Object.entries(turma).map(([turmaName, student]) => {
        correctPercent[schoolId][turmaName] = {};

        let turmaCorrectPercent = {
          correct: 0,
          total: 0,
          totalStudents: 0,
        };

        Object.entries(student).map(([studentId, studentInfo]) => {
          const correctAns = studentInfo.questions.reduce(
            (psum, q) => psum + q.correct,
            0
          );

          studentInfo.questions.map((q, idx) => {
            if (idx in examQuestions) {
              if (q.alternative !== null) {
                examQuestions[idx]["t"] += 1;
              }
              examQuestions[idx]["c"] += q.correct;
            } else {
              examQuestions[idx] = {
                c: q.correct,
                t: q.alternative !== null ? 1 : 0,
                tags: q && q.tags ? q.tags.join(", ") : "",
              };
            }
          });

          const totalAns = studentInfo.questions.length;

          correctPercent[schoolId][turmaName][studentId] = {
            correct: correctAns,
            total: totalAns,
          };

          if (studentInfo.questions[0].alternative !== null) {
            turmaCorrectPercent.correct += correctAns;
            turmaCorrectPercent.total += totalAns;
            turmaCorrectPercent.totalStudents += 1;
          }
        });

        schoolCorrectPercent.correct += turmaCorrectPercent.correct;
        schoolCorrectPercent.total += turmaCorrectPercent.total;
        schoolCorrectPercent.totalStudents += turmaCorrectPercent.totalStudents;

        correctPercent[schoolId][turmaName].data = turmaCorrectPercent;
      });

      correctPercent[schoolId].data = schoolCorrectPercent;
    });

    return { correctPercent, examQuestions };
  };

  const mountHeaders = (columns) => {
    return columns.map((columnName) => ({
      label: columnName,
      key: columnName,
    }));
  };

  const loadStudentsResults = (schoolName, gradeName, students) => {
    let newRows = [];
    for (const [studentId, student] of Object.entries(students)) {
      let newRow = {
        Nome: student.name,
        Escola: schoolName,
        Turma: gradeName,
      };
      let tags_count = {};
      let tags_correct = {};
      student.questions.map((q, idx) => {
        newRow[`Questão ${idx + 1}`] = q.correct;
        for (const tag of q.tags) {
          if (tag in tags_count) {
            tags_count[tag] += 1;
            tags_correct[tag] += q.correct;
          } else {
            tags_count[tag] = 1;
            tags_correct[tag] = q.correct;
          }
        }
      });
      for (const tag of Object.keys(tags_count)) {
        newRow[tag] = tags_correct[tag] / tags_count[tag];
      }
      newRows.push(newRow);
    }
    return newRows;
  };

  const createCSVData = (studentExams) => {
    let dataCSV = [];
    for (const [schoolId, gradesData] of Object.entries(studentExams[0].report.Table)) {
      let schoolName = studentExams[0].report.Schools[schoolId]["name"];
      for (const [gradeName, students] of Object.entries(gradesData)) {
        let newRows = loadStudentsResults(schoolName, gradeName, students);
        dataCSV = [...dataCSV, ...newRows];
      }
    }
    let headersCSV = [];
    if (dataCSV.length > 0) headersCSV = mountHeaders(Object.keys(dataCSV[0]));

    setDataCSV({
      data: dataCSV,
      headers: headersCSV,
    });
  };

  useEffect(() => {
    mountAccordeons();
    setNewReportLoading(false);
    if (students && students.length > 0) createCSVData(students);
  }, [students]);

  const handleOpenStudentExam = (userId) => {
    window.location.href =
      "/result-exams/individual?examId=" +
      selectedExam +
      "&studentId=" +
      userId;
  };

  const handleBoletim = (userId) => {
    fetchStudentResultAll(userId);
  }

  const removeAbsentStudents = (students) => {
    students = students.filter(item => 
      !item.questions.every(question => question.alternative === null)
    );

    return students;
  };

  const getAdaptedExamData = async () => {
    fetchStudentResultExamData({
      examId: currentAdaptedExamId,
      page: 1,
      itemsPerPage: 100,
      newReport: false
    })
      .then((response) => {
        setAdaptedExamData(response[0].report);
        setAdaptedExamSchools(Object.entries(response[0].report.Schools)
          .map(([id, school]) => ({ id, name: school.name }))
          .sort((a, b) => a.name.localeCompare(b.name)));
        const { correctPercent, examQuestions } = calculateCorrectPercent(
          response[0].report
        );
        adaptedExamCorrectPercent.current = correctPercent;
      })
      .catch((error) => {
        console.error(error);
        showAdaptedExamTable.current = false;
      });
  };

  const extractStudentsData = (students) => {
    const questionAns = {};
    const rows = Object.entries(students)
      .map(([_key, value]) => {
        value.questions.map((q, idx) => {
          if (idx in questionAns) {
            if (q.alternative in questionAns[idx]) {
              questionAns[idx][q.alternative] += 1;
            } else {
              questionAns[idx][q.alternative] = 1;
            }
          } else {
            questionAns[idx] = {
              [q.alternative]: 1,
            };
          }
        });

        return { ...value, id: _key };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    
    return { rows, questionAns };
  };

  const defineTableData = (schoolId, turmaName, correctPercent, alunos, schoolName) => {
    const questionAns = {};
    let totalStudents = 0;

    if (turmaName === "Todas as Turmas") {
      Object.entries(correctPercent[schoolId]).forEach(([turma, data]) => {
        if (turma !== "data") {
          totalStudents += data.data.totalStudents;
        }
      });
    } else {
      totalStudents = correctPercent[schoolId][turmaName].data.totalStudents;
    }

    const rows = Object.entries(alunos)
      .map(([_key, value]) => {
        value.questions.map((q, idx) => {
          if (idx in questionAns) {
            if (q.alternative in questionAns[idx]) {
              questionAns[idx][q.alternative] += 1;
            } else {
              questionAns[idx][q.alternative] = 1;
            }
          } else {
            questionAns[idx] = {
              [q.alternative]: 1,
            };
          }
        });

        return { ...value, id: _key };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    
    setRows(rows);
    setTotalStudents(totalStudents);
    setQuestionAns(questionAns);
    setSchoolId(schoolId);
    setTurmaName(turmaName);
    setCorrectPercent(correctPercent);
    setStudentsData(alunos);
    setSchoolName(schoolName);
  };

  const handleDialog = () => {
    setOpenTurma(!openTurma);
  };

  const handleVisibility = (schoolId, turmaName, correctPercent, alunos, schoolName) => {
    if (turmaName === "Todas as Turmas") {
      let combinedAlunos = {};
      Object.entries(alunos).forEach(([key, value]) => {
        combinedAlunos[key] = value;
      });
      defineTableData(schoolId, turmaName, correctPercent, combinedAlunos, schoolName);
      handleDialog();
    } else {
      defineTableData(schoolId, turmaName, correctPercent, alunos, schoolName);
      handleDialog();
    }
  };

  const handleUpdateReport = () => {
    fetchExamReport(selectedExam, true);
    window.alert(
      "Estamos atualizando o relatório da prova, este processo pode levar até 3 minutos, caso contrário, atualize a página.."
    );
  };

  const mountAccordeons = () => {
    if (students && students.length > 0) {
      const { correctPercent, examQuestions } = calculateCorrectPercent(
        students[0].report
      );
  
      const sortableTable = Object.fromEntries(
        Object.entries(students[0].report.Table).sort(([keyA], [keyB]) =>
          students[0].report.Schools[keyA].name.localeCompare(
            students[0].report.Schools[keyB].name
          )
        )
      );
  
      setQuestionBar(
        Object.entries(examQuestions).map(([qIdx, value]) => (
          <CustomizedProgressBars
            label
            labelBottom={"Q" + (parseInt(qIdx) + 1)}
            vertical
            tooltip={value["tags"]}
            color={"#e0e03d"}
            percent={value["c"] / value["t"]}
          />
        ))
      );
  
      const allStudents = {};
  
      setAccordeons(
        Object.entries(sortableTable).map(([schoolId, turmas]) => {
          const turmaNames = Object.keys(turmas);
          const multipleTurmas = turmaNames.length > 1;
  
          Object.entries(turmas).forEach(([turmaName, alunos]) => {
            if (!allStudents[schoolId]) {
              allStudents[schoolId] = {};
            }
            allStudents[schoolId] = {
              ...allStudents[schoolId],
              ...alunos,
            };
          });
  
          return (
            <Accordion key={schoolId}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  content: classes.content,
                }}
              >
                <Grid xs={6} md={3} container>
                  <Typography>
                    {students[0].report.Schools[schoolId].name.replace(
                      "ESCOLA MUNICIPAL DE EDUCAÇÃO BÁSICA",
                      "EMEB"
                    )}
                  </Typography>
                </Grid>
                <Grid xs={4} md={3} justifyContent="space-between" container>
                  <Typography style={{ width: 100, color: "#737300" }}>
                    <CustomizedProgressBars
                      label
                      tooltip={"Progresso da Avaliação"}
                      color={"#e0e03d"}
                      percent={
                        correctPercent[schoolId].data.totalStudents /
                        students[0].report.Schools[schoolId].numStudents
                      }
                    />
                  </Typography>
                  <Typography style={{ width: 100, color: "#007315" }}>
                    <CustomizedProgressBars
                      label
                      tooltip={"Taxa de Acerto"}
                      color={"#3de048"}
                      percent={
                        correctPercent[schoolId].data.correct /
                        correctPercent[schoolId].data.total
                      }
                    />
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
              >
                {Object.entries(turmas).map(([turmaName, alunos]) => (
                  <Paper className={classes.paper} key={turmaName}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Grid xs={6} md={3} container>
                        <Typography>{turmaName}</Typography>
                      </Grid>
                      <Grid xs={4} md={3} justifyContent="space-between" container>
                        <Typography style={{ width: 100 }}>
                          {students[0].report.Schools[schoolId].turmas ? (
                            <CustomizedProgressBars
                              label
                              tooltip={"Progresso da Avaliação"}
                              color={"#e0e03d"}
                              percent={
                                correctPercent[schoolId][turmaName].data.totalStudents /
                                students[0].report.Schools[schoolId].turmas[turmaName]
                              }
                            />
                          ) : null}
                        </Typography>
                        <Typography style={{ width: 100, color: "#007315" }}>
                          <CustomizedProgressBars
                            label
                            tooltip={"Taxa de Acerto"}
                            color={"#3de048"}
                            percent={
                              correctPercent[schoolId][turmaName].data.correct /
                              correctPercent[schoolId][turmaName].data.total
                            }
                          />
                        </Typography>
                      </Grid>
                    </div>
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleVisibility(
                          schoolId,
                          turmaName,
                          correctPercent,
                          alunos,
                          students[0].report.Schools[schoolId].name
                        )
                      }
                    >
                      <Visibility />
                    </IconButton>
                  </Paper>
                ))}
                {multipleTurmas && (
                  <Paper className={classes.paper}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Grid xs={6} md={3} container>
                        <Typography>Todas as Turmas</Typography>
                      </Grid>
                      <Grid xs={4} md={3} justifyContent="space-between" container>
                        <Typography style={{ width: 100 }}>
                          {students[0].report.Schools[schoolId].turmas ? (
                            <CustomizedProgressBars
                              label
                              tooltip={"Progresso da Avaliação"}
                              color={"#e0e03d"}
                              percent={
                                correctPercent[schoolId].data.totalStudents /
                                students[0].report.Schools[schoolId].numStudents
                              }
                            />
                          ) : null}
                        </Typography>
                        <Typography style={{ width: 100, color: "#007315" }}>
                          <CustomizedProgressBars
                            label
                            tooltip={"Taxa de Acerto"}
                            color={"#3de048"}
                            percent={
                              correctPercent[schoolId].data.correct /
                              correctPercent[schoolId].data.total
                            }
                          />
                        </Typography>
                      </Grid>
                    </div>
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleVisibility(
                          schoolId,
                          "Todas as Turmas",
                          correctPercent,
                          allStudents[schoolId],
                          students[0].report.Schools[schoolId].name
                        )
                      }
                    >
                      <Visibility />
                    </IconButton>
                  </Paper>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })
      );
    }
  };

  const filterList = (e) => {
    if (e && e.length >= 0) {
      return e.map((element) => (
        <MenuItem key={element.id} value={element.Id}>
          {element.Title}
        </MenuItem>
      ));
    }

    return e;
  };

  const handleSelectedExam = (e) => {
    const examId = e.target.value;
    setSelectedExam(examId);
    fetchStudentResultExam({
      examId: examId,
      page: 1,
      itemsPerPage: 20
    });
    setSelectedExamTitle(getExamSelectedTitle(examId))
  };

  const handleCurrentAdaptedExam = (e) => {
    const option = e.target.value;
    if (option) {
      setCurrentAdaptedExamId(e.target.value);
      const examTitle = getExamSelectedTitle(e.target.value)
      adaptedExamName.current = examTitle;
      showAdaptedExamTable.current = true;
      setCurrentAdaptedExamSchool('');
      setCurrentAdaptedExamGrade('');
      adaptedExamRows.current = [];
    } else {
      setCurrentAdaptedExamId('');
      setCurrentAdaptedExamSchool('');
      setCurrentAdaptedExamGrade('');
      showAdaptedExamTable.current = false;
      adaptedExamRows.current = [];
    }
  };

  const handleCurrentAdaptedExamSchool = (e) => {
    let schoolId = e.target.value;
    setAdaptedExamGrades(
      Object.keys(adaptedExamData.Schools[schoolId].turmas)
    );
    setCurrentAdaptedExamSchool(schoolId);
    setCurrentAdaptedExamGrade('');
    adaptedExamSchoolId.current = schoolId; 
    adaptedExamRows.current = [];
  };

  const handleCurrentAdaptedExamGrade = (e) => {
    let correctPercent = adaptedExamCorrectPercent.current;
    let schoolId = adaptedExamSchoolId.current;
    let grade = e.target.value;
    let totalStudents = correctPercent[schoolId][grade].data.totalStudents;
    setCurrentAdaptedExamGrade(grade);
    defineTableRows(schoolId, grade);
    adaptedExamTurmaName.current = grade;
    adaptedExamTotalStudents.current = totalStudents;
  };

  const defineTableRows = (school, grade) => {
    const students = adaptedExamData.Table[school][grade];
    const { rows, questionAns } = extractStudentsData(students);
    adaptedExamRows.current = removeAbsentStudents(rows);
    adaptedExamQuestionAns.current = questionAns;
  };

  const getExamSelectedTitle = (examId) => {
    const examTitle = exams.find((exam) => exam.Id === examId);

    return examTitle ? examTitle.Title : undefined;
  }

  const handleAno = (e) => {
    setAno(e.target.value);
    setDisciplina("");
    fetchDisciplinasTrilhas(e.target.value);
  };

  const handleDisciplina = (e) => {
    setDisciplina(e.target.value);
    fetchUnidadesTematicas(ano, e.target.value);
    fetchResultTrilhas(ano, e.target.value);
    setUnidadesDict(undefined);
  };

  const buildConsolidacaoView = (p, labels, fields, consolidacao) => (
    <TableContainer component={Paper} className={classes.turmaTable}>
      <Table size="small" id={1}>
        <TableBody>
          <TableRow key="">
            {
              fields.map(f => (
                (f !== "AvaliacaoAdaptativa") &&
                <TableCell component="th" scope="row" align="center">
                  <div>{labels[f]}</div>
                </TableCell>
              ))
            }
          </TableRow>
          <TableRow key={"last"}>
            {fields.map(f => (
              (f !== "AvaliacaoAdaptativa") &&
              <TableCell
                padding="none"
                className={classes.paddingCell}
                size="small"
                align="center"
              >

                <CustomizedProgressBars
                  consolidacaoValue={consolidacao[p][f]["all"]}
                  label
                  labelBottom={""}
                  vertical
                  tooltip={labels[f]}
                  color={checkedFieldColors[f]}
                  progressHeight={64}
                  percent={consolidacao[p][f]["value"] / consolidacao[p][f]["all"]}
                  rawNumber={consolidacao[p][f]["value"] / consolidacao[p][f]["months"]}
                  position="center"
                  showRawNumber
                />
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )

  const updateConsolidacao = (p) => {
    setConsolidacaoReadView(buildConsolidacaoView(p, ReadingFluencyLabels, ReadingFluencyFields, buildConsolidacao(consolidacaoRaw, ReadingFluencyFields, "Reading")));
    setConsolidacaoWritingView(buildConsolidacaoView(p, writingLabels, writingFields, buildConsolidacao(consolidacaoRaw, writingFields, "Writing")));
  }

  const handleSerie = (e) => {
    setSerie(e.target.value);
    setPeriod("1m");
    setConsolidacaoReadView(undefined);
    setConsolidacaoWritingView(undefined);
    fetchConsolidacao(e.target.value);
  }

  const handlePeriod = (e) => {
    // buildConsolidacao(consolidacaoRaw, readFields, "Reading");
    // buildConsolidacao(consolidacaoRaw, writingFields, "Writing");

    setPeriod(e.target.value);
    if (consolidacaoRaw[month]) updateConsolidacao(e.target.value);
  }

  const handleShowAccordeons = () => {
    setShowAccordeons(!showAccordeons);
  };

  const printDocument = (divToPrint) => {
    const input = document.getElementById(divToPrint);
    input.style.width = `${input.scrollWidth}px`;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      const imgProps = pdf.getImageProperties(imgData);

      let pdfWidth;
      let pdfHeight;

      const pdfWidthPage = pdf.internal.pageSize.getWidth();
      const pdfHeightPage = pdf.internal.pageSize.getHeight();

      pdfHeight = (imgProps.height * pdfWidthPage) / imgProps.width;

      if (pdfHeight > pdfHeightPage) {
        pdfHeight = pdfHeightPage;
        pdfWidth = (imgProps.width * pdfHeightPage) / imgProps.height;
      } else {
        pdfWidth = (imgProps.width * pdfHeight) / imgProps.height;
      }

      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  const handleMenu = (menu) => {
    setShowResults(menu);
  }

  const onChangePagination = (e) => {
    fetchStudentResultExam({
      examId: selectedExam,
      page: e,
      itemsPerPage: 20
    });
    setCurrentPage(e);
  };

  const handleDownload = async () => {
    try {
      const listOfFiles = await getTRIFiles();
      listOfFiles.forEach((file )=> {
        window.open(file.url)
      })
    } catch(err) {
      console.log(err);
    }
  };

  const pagination = (
    <Pagination
      current={currentPage}
      total={20*schoolCount}
      pageSize={20}
      onChange={onChangePagination}
    />
  );

  return (
    <Page>
      <Content style={{ width: "100%", maxWidth: "2000px" }}>
        {/* {user !== null && user !== undefined && user.Role === "Super" ? (
          <FormControlLabel
            control={
              <Switch
                checked={showPowerBI}
                onChange={handleSwitch}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label={"Acertos por Escola"}
          />
        ) : null} */}
        {user !== null && user !== undefined && ["Super", "Master", "Teacher", "Guest"].includes(user.Role) ? (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Box sx={{ width: "100%", mb: 5 }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  aria-label="basic tabs"
                  TabIndicatorProps={{ style: tabsIndicatorStyle }}
                >
                  <Tab
                    label="Por Prova"
                    onClick={() => handleMenu(ShowResultsOptions.BY_EXAM)}
                  />
                  {listOfSuperIdsWithPowerBI.includes(user.SuperId)  || listOfSuperIdsWithPowerBI.includes(user.Id) ? (
                    <Tab
                      label="Por Escola"
                      onClick={() => handleMenu(ShowResultsOptions.BY_SCHOOL)}
                    />
                  ) : null}
                  {user.Role !== "Super" && user.Role !== "Guest" ? (
                    <Tab
                      label="Trilhas"
                      onClick={() => handleMenu(ShowResultsOptions.TRAILS)}
                    />
                  ) : null}
                  {listOfSuperIdsWithPowerBI.includes(user.SuperId) || listOfSuperIdsWithPowerBI.includes(user.Id) ? (
                    <Tab
                      label="Consolidado"
                      onClick={() =>
                        handleMenu(ShowResultsOptions.CONSOLIDATED)
                      }
                    />
                  ) : null}
                  {listOfSuperIdsWithPowerBI.includes(user.SuperId) || listOfSuperIdsWithPowerBI.includes(user.Id) ? (
                    <Tab
                      label="Avaliações Integradas"
                      onClick={() =>
                        handleMenu(ShowResultsOptions.INTEGRATED_ASSESSMENTS)
                      }
                    />
                  ) : null}
                  {(user.SuperId === NHSuperIdPowerBi || user.Id === NHSuperIdPowerBi) ? (
                    <Tab
                      label="ED. INFANTIL"
                      onClick={() => handleMenu(ShowResultsOptions.PRESCHOOL)}
                    />
                  ) : null}
                </Tabs>
              </Box>
            </Box>
          </div>
        ) : null}
        {showResults === ShowResultsOptions.BY_EXAM ? (
          <>
            <Header>PROVAS</Header>
            <FormControl fullWidth>
              <InputLabel>Selecione a prova</InputLabel>
              <Select
                fullWidth
                value={selectedExam}
                onChange={handleSelectedExam}
              >
                {list.length > 0 && exams.length > 0
                  ? filterList(list)
                  : filterList(exams)}
              </Select>
            </FormControl>
            <br />
            <br />
            {selectedExam && (
              <div style={{ marginBottom:"30px" }}>
                <FormControl fullWidth>
                  <InputLabel>Selecione a prova adaptativa (Opcional)</InputLabel>
                  <Select fullWidth value={currentAdaptedExamId} onChange={handleCurrentAdaptedExam}>
                  <MenuItem key={"clear"} value={''}>Nenhuma</MenuItem>
                    {list.length > 0 && exams.length > 0
                      ? filterList(list)
                      : filterList(exams)}
                  </Select>
                </FormControl>
              </div>
            )}

            {currentAdaptedExamId && (
              <div style={{ marginBottom:"30px" }}>
                <FormControl fullWidth>
                  <InputLabel>Selecione a escola da prova adaptativa</InputLabel>
                  <Select fullWidth value={currentAdaptedExamSchool} onChange={handleCurrentAdaptedExamSchool}>
                    {adaptedExamSchools.map((school) => (
                      <MenuItem key={school.id} value={school.id}>{school.name}</MenuItem>
                    ))}                 
                  </Select>
                </FormControl>
              </div>
            )}

            {currentAdaptedExamSchool && (
              <div style={{ marginBottom:"30px" }}>
                <FormControl fullWidth>
                  <InputLabel>Selecione a turma da prova adaptativa</InputLabel>
                  <Select fullWidth value={currentAdaptedExamGrade} onChange={handleCurrentAdaptedExamGrade}>
                    {adaptedExamGrades.map((grade) => (
                      <MenuItem key={grade} value={grade}>{grade}</MenuItem>
                    ))}                 
                  </Select>
                </FormControl>
              </div>
            )}

            {
              user && [FORTALEZA_SUPER_ID].includes(user.Id) ?
              <Grid container>
                <Button
                  className={classes.buttonViewReport}
                  variant="outlined"
                  onClick={handleDownload}
                >
                  Baixar relatório TRI
                </Button>
              </Grid> : null
            }
            
            {/* {selectedExam && accordeons !== undefined && user.Role !== "Guest" &&
              (user && ![FORTALEZA_SUPER_ID].includes(user.Id)) ?
            (
              <Grid container justifyContent="flex-end">
                <Button
                  className={classes.buttonViewReport}
                  variant="outlined"
                  onClick={handleUpdateReport}
                >
                  Atualizar Relatório
                </Button>
              </Grid>
            ) : null} */}
            {selectedExam &&
              students &&
              students.length > 0 &&
              dataCSV.data.length > 0 &&
              dataCSV.headers.length > 0 && (
                <Grid container justifyContent="flex-end">
                  <CSVLink
                    data={dataCSV.data}
                    headers={dataCSV.headers}
                    filename="relatorio.csv"
                  >
                    Exportar CSV
                  </CSVLink>
                </Grid>
              )}
            <Grid container justifyContent="flex-end">
              {newReportLoading ? "Carregando o novo relatório..." : null}
            </Grid>
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              {user !== null &&
                user !== undefined &&
                accordeons !== undefined ? (
                <FormControlLabel
                  control={
                    <Typography component="div">
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>Acertos por Questão</Grid>
                        <Grid item>
                          <Switch
                            checked={showAccordeons}
                            onChange={handleShowAccordeons}
                            name="checkedA"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                        </Grid>
                        <Grid item>Tabelas</Grid>
                      </Grid>
                    </Typography>
                  }
                />
              ) : null}
            </div>
            {showAccordeons ? (
              <>
                {" "}
                {accordeons}
                <Dialog
                  fullWidth
                  maxWidth={1024}
                  onClose={handleDialog}
                  open={openTurma}
                >
                  <DialogTitle>Resultado por Turma</DialogTitle>
                  <CreateTurmaTable 
                    schoolId={schoolId}
                    turmaName={turmaName}
                    correctPercent={correctPercent}
                    alunos={studentsData}
                    schoolName={schoolName}
                    totalStudents={totalStudents}
                    selectedExamTitle={selectedExamTitle}
                    tableRows={rows}
                    questionAns={questionAns}
                    showAdaptedExamTable={showAdaptedExamTable.current}
                    adaptedExamSchoolId={adaptedExamSchoolId.current}
                    adaptedExamTurmaName={adaptedExamTurmaName.current}
                    adaptedExamRows={adaptedExamRows.current}
                    adaptedExamQuestionAns={adaptedExamQuestionAns.current}
                    adaptedExamTotalStudents={adaptedExamTotalStudents.current}
                    adaptedExamCorrectPercent={adaptedExamCorrectPercent.current}
                    adaptedExamName={adaptedExamName.current}
                    handleOpenStudentExam={handleOpenStudentExam}
                    handleBoletim={handleBoletim}
                    printDocument={printDocument}
                  />
                </Dialog>{" "}
              </>
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {questionBar}
              </div>
            )}
            {selectedExam && accordeons !== undefined && pagination}
          </>
        ) : null}
        {
          showResults === ShowResultsOptions.BY_SCHOOL ?
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <PowerBiComponent user={user} typeReport={"exams"} />
            </div> : <></>
        }
        {/* {showResults === 1 ? (
          user !== null &&
          user !== undefined &&
          user.Role === "Super" &&
          examsOptionsPbi &&
          examsOptionsPbi.length > 0 && (
            <>
              <div>
                <Button
                  width="100px"
                  value="show"
                  variant="outlined"
                  size="small"
                  style={{ margin: "10px", float: "left" }}
                  color="primary"
                  onClick={() => handlePowerBIReport(false)}
                >
                  Exibir Acertos por Escola
                </Button>
              </div>
              <br />
              <br />
              <div style={{ height: "800px" }}>
                {powerbiEmbed !== {} &&
                  powerbiEmbed.ReportId !== undefined &&
                  powerbiEmbed.EmbedUrl !== undefined &&
                  powerbiEmbed.EmbedToken !== undefined && (
                    <PowerBIEmbed
                      cssClassName="report-style-class"
                      embedConfig={{
                        type: "report",
                        id: powerbiEmbed.ReportId,
                        embedUrl: powerbiEmbed.EmbedUrl,
                        accessToken: powerbiEmbed.EmbedToken,
                        tokenType: models.TokenType.Embed,
                      }}
                    />
                  )}
              </div>
            </>
          )
        ) : null} */}
        {showResults === ShowResultsOptions.TRAILS ? (
          <>
            <Header>SELECIONE AS TRILHAS</Header>
            <FormControl fullWidth>
              <InputLabel>Selecione o ano</InputLabel>
              <Select
                fullWidth
                value={ano}
                onChange={handleAno}
              >
                {anos.map(a => (
                  <MenuItem value={a.id}>{a.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            {ano !== "" ? <FormControl fullWidth>
              <InputLabel>Selecione a disciplina</InputLabel>
              <Select
                fullWidth
                value={disciplina}
                onChange={handleDisciplina}
              >
                {disciplinas.map(d => (
                  <MenuItem value={d.id}>{d.title}</MenuItem>
                ))}
              </Select>
            </FormControl> : null}
            <br />
            <br />
            {disciplina !== "" ? (
              <Box sx={{ textAlign: "center" }}>
                <ButtonGroup color="primary">
                  <Button variant={habilidadeView ? "outlined" : "contained"} onClick={() => setHabilidadeView(false)}>Unidades</Button>
                  <Button variant={habilidadeView ? "contained" : "outlined"} onClick={() => setHabilidadeView(true)}>Habilidades</Button>
                </ButtonGroup>
                <br />
                {loading ? <CircularProgress /> : null}
              </Box>
            ) : null}
            {disciplina !== "" && loading === false ? <List sx={{ width: 1, maxWidth: 728, minWidth: 200 }}>
              {resultTrilhas && resultTrilhas["hability"] && habilidadeView === true ? (
                <Grid>
                  <h5 style={{ textAlign: "center" }}>{Object.keys(resultTrilhas["hability"]).length > 0 ? null : "Sem resultados"}</h5>
                  {Object.keys(resultTrilhas["hability"]).map((u, idx) => (
                    <AccordionResults title={u} score={resultTrilhas["hability"][u].corrected / resultTrilhas["hability"][u].number_of_questions} />
                  ))}
                </Grid>) : null}
              {resultTrilhas && resultTrilhas["unidade"] && unidadesDict && habilidadeView === false ? (
                <Grid>
                  <h5 style={{ textAlign: "center" }}>{Object.keys(resultTrilhas["hability"]).length > 0 ? null : "Sem resultados"}</h5>
                  {Object.keys(resultTrilhas["unidade"]).map((u, idx) => unidadesDict[u] ? (
                    <AccordionResults title={unidadesDict[u]} score={resultTrilhas["unidade"][u].corrected / resultTrilhas["unidade"][u].number_of_questions} />
                  ) : null)}
                </Grid>) : null}
            </List> : null}
          </>
        ) : null}
        {
          showResults === ShowResultsOptions.CONSOLIDATED ?
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <PowerBiComponent user={user} typeReport={"readingWriting"} />
            </div> : <></>
        }
        {
          showResults === ShowResultsOptions.INTEGRATED_ASSESSMENTS ?
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <PowerBiComponent user={user} typeReport={"consolidado"} />
            </div> : <></>
        }
        {
          showResults === ShowResultsOptions.PRESCHOOL ?
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <PowerBiComponent user={user} typeReport={"preschool"} />
            </div> : <></>
        }
        <br />
        <br />
      </Content>
    </Page>
  );
}

ResultExamsPage.propTypes = {
  fetchResultExam: PropTypes.func.isRequired,
  fetchPowerBIReport: PropTypes.func.isRequired,
  fetchStudentResultExam: PropTypes.func.isRequired,
  fetchDisciplinasTrilhas: PropTypes.func.isRequired,
  fetchStudentsTrilhas: PropTypes.func.isRequired,
  fetchResultTrilhas: PropTypes.func.isRequired,
  fetchUnidadesTematicas: PropTypes.func.isRequired,
  fetchReadAndWriting: PropTypes.func.isRequired,
  fetchReadingAndWritingCSVData: PropTypes.func.isRequired,
  fetchStudentResultAll: PropTypes.func.isRequired,
  updateResultRW: PropTypes.func.isRequired,
  fetchExamReport: PropTypes.func.isRequired,
  user: PropTypes.object,
  exams: PropTypes.array,
  loading: PropTypes.bool,
  total: PropTypes.number,
  students: PropTypes.object,
  setExamId: PropTypes.func.isRequired,
  setCanEdit: PropTypes.func.isRequired,
  clearExamModule: PropTypes.func,
  searchExamTag: PropTypes.func.isRequired,
  infoUser: PropTypes.func.isRequired,
  companies: PropTypes.object,
  fetchCompanies: PropTypes.func.isRequired,
  fetchCompanyGrades: PropTypes.func.isRequired,
  fetchConsolidacao: PropTypes.func.isRequired
};

const mapStateToProps = ({ exams, questions, users, auth, companies }) => ({
  user: users.infoUser,
  exams: exams.examsList !== undefined ? exams.examsList.exams : [],
  total: exams.examsList !== undefined ? exams.examsList.total : 0,
  questionsCompany: questions.questionsCompany.length,
  loading: exams.loading,
  students: exams.result,
  anos: exams.anos,
  disciplinas: exams.disciplinas,
  unidades: exams.unidades,
  resultTrilhas: exams.resultTrilhas,
  powerbiEmbed: exams.powerbiEmbed,
  grades: companies.grades,
  userGrades: auth.classes,
  readingAndWritingCSVData: exams.readingAndWritingCSVData,
  resultRW: exams.resultRW,
  updateRW: exams.updateRW,
  studentsAll: exams.studentsAll,
  companies: companies.companiesList.companies,
  consolidacaoRaw: exams.consolidacaoRaw
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCompanyGrades,
      fetchCompanies,
      fetchResultExam,
      fetchStudentResultExam,
      fetchDisciplinasTrilhas,
      fetchAnoTrilhas,
      fetchConsolidacao,
      getClasses,
      updateResultRW,
      fetchStudentsTrilhas,
      fetchResultTrilhas,
      fetchUnidadesTematicas,
      fetchReadAndWriting,
      fetchReadingAndWritingCSVData,
      fetchStudentResultAll,
      fetchPowerBIReport,
      fetchExamReport,
      infoUser,
      clearExamModule,
      setExamId,
      setCanEdit,
      searchExamTag,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResultExamsPage);
