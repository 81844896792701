import { all, call, takeLatest, put } from 'redux-saga/effects'
import { normalizeError } from '../../utils/errorHelpers'
import { message } from 'antd'

import * as actions from './actions'
import * as types from './constants'
import * as services from './services'

export function * addTag ({ payload: { questionId, tag } }) {
  try {
    // console.log(questionId)
    // console.log(tag)
    yield call(services.addTag, questionId, tag)

    yield put(actions.addTagSuccess(tag))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao deletar tag')
    message.error(errorInfo.message)
    yield put(actions.addTagFailure(errorInfo))
  }
}
export function * deleteTag ({ payload: { questionId, tag } }) {
  try {
    // console.log(questionId)
    // console.log(tag)
    yield call(services.deleteTag, questionId, tag)

    yield put(actions.deleteTagSuccess(tag))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao deletar tag')
    message.error(errorInfo.message)
    yield put(actions.deleteTagFailure(errorInfo))
  }
}

export function * listQuestions ({ payload: { companyId, userId }}) {
  try {
    yield call(services.listQuestions, companyId, userId)
    yield put(actions.listQuestionsSuccess)
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao buscar questões da empresa')
    message.error(errorInfo.message)
    yield put(actions.listQuestionsFailure(errorInfo))
  }
}

export function * changeToDissertative ({ payload: { questionId } }) {
  try {
    yield call(services.changeToDissertative, questionId)
    yield put(actions.changeToDissertativeSuccess)
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao atualizar tipo de questão')
    message.error(errorInfo.message)
    yield put(actions.changeToDissertativeFailure(errorInfo))
  }
}
 
export function * getTags(action) {
  try {
    const tags = yield call(services.getTags, action.payload);
    yield put(actions.getTagsSuccess(tags));
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao buscar tags');
    message.error(errorInfo.message);
    yield put(actions.getTagsFailure(errorInfo));
  }
}

export function * addComment ({ payload: { examId, questionId, comment } }) {
  try {
    yield call(services.addComment, examId, questionId, comment)

    yield put(actions.addCommentSuccess(comment))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao adicionar comentário')
    message.error(errorInfo.message)
    yield put(actions.addCommentFailure(errorInfo))
  }
}

export function * addSolution ({ payload: { examId, questionId, solution } }) {
  try {
    yield call(services.addSolution, examId, questionId, solution)

    yield put(actions.addSolutionSuccess(solution))
  } catch (error) {
    const errorInfo = normalizeError(error, 'Falha ao adicionar solução')
    message.error(errorInfo.message)
    yield put(actions.addSolutionFailure(errorInfo))
  }
}

// Watchers
export function * watchAddTag () {
  yield takeLatest(types.ADD_TAG, addTag)
}

export function * watchDeleteTag () {
  yield takeLatest(types.DELETE_TAG, deleteTag)
}

export function * watchListQuestions () {
  yield takeLatest(types.GET_QUESTIONS, listQuestions)
}

export function * watchChangeToDissertative () {
  yield takeLatest(types.CHANGE_TO_DISSERTATIVE, changeToDissertative)
}

export function * watchGetTags() {
  yield takeLatest(types.GET_TAGS, getTags);
}

export function * watchAddComment () {
  yield takeLatest(types.ADD_COMMENT, addComment)
}

export function * watchAddSolution () {
  yield takeLatest(types.ADD_SOLUTION, addSolution)
}

export default function * () {
  yield all([
    watchAddTag(),
    watchDeleteTag(),
    watchListQuestions(),
    watchChangeToDissertative(),
    watchGetTags(),
    watchAddComment(),
    watchAddSolution(),
  ])
}
