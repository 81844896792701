import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withFormik, Field } from 'formik'
import * as yup from 'yup'
import { Modal, Button } from 'antd'
import EmailInput from '../input/EmailInput'
import { StyledForm } from '../form/styles/Form.styles'
import { recoverPassword } from '../../store/auth/actions'

const mapStateToProps = ({ auth }) => ({
  loading: auth.loadingPasswordRecovery
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    recoverPassword
  }, dispatch)

const handleSubmit = (values, formikBag) => {
  formikBag.props.recoverPassword(values)
}

const validationSchema = props => {
  return yup.object().shape({
    email: yup.string().email().required()
  })
}

class PasswordRecoveryModal extends Component {
  static propTypes = {
    isValid: PropTypes.bool,
    submitForm: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    toggle: PropTypes.func,
    loading: PropTypes.bool
  }

  componentDidUpdate (prevProps) {
    prevProps.loading && !this.props.loading && this.props.toggle()
  }

  render () {
    const { visible, toggle } = this.props

    return (
      <StyledForm>
        <Modal
          visible={visible}
          title='Esqueci a senha'
          onCancel={toggle}
          footer={[this.renderBackButton(), this.renderSubmitButton()]}
        >
          <Field
            label='E-mail cadastrado'
            name='email'
            component={EmailInput}
          />
        </Modal>
      </StyledForm>
    )
  }

  renderBackButton = () => (
    <Button
      key='back'
      onClick={this.props.toggle}
    >
      Voltar
    </Button>
  )

  renderSubmitButton = () => {
    const {
      loading,
      isValid,
      submitForm
    } = this.props

    return (
      <Button
        key='submit'
        type='primary'
        htmlType='submit'
        loading={loading}
        disabled={loading || !isValid}
        onClick={submitForm}
      >
        Recuperar Senha
      </Button>
    )
  }
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit,
    validationSchema
  })
)

export default enhance(PasswordRecoveryModal)
