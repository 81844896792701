/* eslint react/prop-types: 0 */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'

export const Container = styled.div`
  margin-bottom: 2rem;
  .ant-collapse {
    border: 1px solid #e67e22;
    position: relative;
    &-content {
      background-color: #fff !important;
      border: 1px solid rgb(230, 126, 34);
      max-height: 45vh;
      overflow-y: scroll;
      position: absolute;
      width: 100%;
      z-index: 2;
      &-box { padding: 0rem !important; }
    }
    &-item {
      border: 0;
    }
    &-header {
      .ant-collapse-arrow.anticon {
        color: #fff;
        font-size: 1.75rem;
        left: initial;
        line-height: unset;
        right: 1rem;
      }
      background-color: #e67e22;
      padding: .5rem !important;
    }
  }
`

export const Header = styled.div`
  color: #fff;
  i {
    background: #fff;
    border-radius: 50%;
    font-size: 1.1rem;
    margin: 0 .5rem;
    padding: .25rem;
  }
`

export const StyledWarningsNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-tabs {
    flex: 1;
    &-bar { display: none; }
  }
`

const warningStyle = active => css`
  padding: .5rem 1rem;
`

export const Warning = props => (
  <div
    css={warningStyle(props.active)}
    {...props}
    active={`${props.active}`}
  />
)

export const WarningLabel = styled.p`
  text-transform: uppercase;
  font-weight: bold;
`

export const WarningContent = styled.div`
  p { margin-bottom: 0; }
`

export const WarningType = styled.div`
  color: #dd4d41;
`
