import http, { updateToken } from '../../utils/http2'

const loginEndpoint = '/token'
// const logoutEndpoint = '/logout'
const userEndpoint = '/user'
const signupEndpoint = userEndpoint + '/register-retail'
const checkoutEndpoint = userEndpoint + '/check-payment'
const loginFailEndpoint = userEndpoint + '/login-fail'
const companyEndpoint = '/company'
const ownCompanyEndpoint = '/own'
const classCompanyEndpoint = '/class'
const registerEndpoint = userEndpoint + '/register'
const registerStudentEndpoint = userEndpoint + '/register-student'
const activationEndpoint = '/approve'
const inactivationEndpoint = '/inactive'
const rejectionEndpoint = '/reject'
const inviteEndpoint = '/invite/employees'
const inviteStudentEndpoint = '/invite/student'
const resendEmailEndpoint = '/confirmation'
const passwordEndpoint = '/forgot-password'
// const validatePasswordEndpoint = '/forget-password/validate'
const createPasswordEndpoint = '/forgot-password/create-password'
const rolesEndpoint = '/domain/roles'
const testVoucherEndpoint = userEndpoint + '/create-voucher'
const manageLogoEndpoint = userEndpoint + '/save-switch-logo'

export const manageLogo = ( payload ) => {
  updateToken();
  let baseEndpoint = manageLogoEndpoint + '?logoUrlS3=' + payload.logoUrl + "&switchNow=" + payload.switchNow + "&isPrimaryLogo=" + payload.isPrimaryLogo;
  return http
    .get(baseEndpoint)
    .then(res => res.data).catch(error => error.data);
}

export const getRoles = () => {
  updateToken()
  return http
    .get(rolesEndpoint)
    .then(res => res.data).catch(error => error.data)
}

export const getClasses = () => {
  updateToken()
  return http
    .get(companyEndpoint + ownCompanyEndpoint)
    .then(res => res.data).catch(error => error.data)
}

export const createClasses = classes => {
  updateToken()
  return http
    .post(companyEndpoint + classCompanyEndpoint, classes)
    .then(res => res.data).catch(error => error.data)
}

export const login = user => {
  let data = 'grant_type=' + user.grant_type + '&username=' + user.username + '&password=' + user.password
  return http
    .post(loginEndpoint, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
    .then(res => res.data).catch(error => error.data)
}

export const createVoucherTest = (voucher, percentOff) => {
  updateToken();
  let data = 'Name=' + voucher + '&PercentOff=' + percentOff;
  return http
    .post(testVoucherEndpoint, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
    .then(res => res.data).catch(error => error.data)
}

export const signup = user => {
  const telefone = user.telefone.replace(/\D/g, ''); // Remove non-numeric characters
  const data = 'grant_type=' + user.grant_type + '&Name=' + user.username + '&Email=' + user.email + '&Password=' + user.password1 + '&Phone=' + telefone + '&Voucher=' + user.voucher
  return http
    .post(signupEndpoint, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
    .then(res => res.data)
    .catch(error => error.data);
};

export const checkoutPayment = user => {
  let attrCheckoutEndpoint = checkoutEndpoint + `/${user}`
  return http
    .get(attrCheckoutEndpoint)
    .then(res => res.data).catch(error => error.data)
}

export const loginFail = user => {
  let data = 'grant_type=' + user.grant_type + '&Email=' + user.username + '&Password=' + user.password
  return http
    .post(loginFailEndpoint, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
    .then(res => res.data).catch(error => error.data)
}

export const logout = user => {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      resolve(true)
    }, 100)
  })
}

export const register = user => {
  return http
    .post(registerEndpoint, user)
    .then(res => res.data).catch(error => error.data)
}

export const registerStudent = user => {
  return http
    .post(registerStudentEndpoint, user)
    .then(res => res.data).catch(error => error.data)
}

export const activateUser = id => {
  updateToken()
  return http
    .put(userEndpoint + '/' + id + activationEndpoint)
    .then(res => res.data).catch(error => error.data)
}

export const inactivateUser = id => {
  updateToken()
  return http
    .put(userEndpoint + '/' + id + inactivationEndpoint)
    .then(res => res.data).catch(error => error.data)
}

export const rejectUser = id => {
  updateToken()
  return http
    .put(userEndpoint + '/' + id + rejectionEndpoint)
    .then(res => res.data).catch(error => error.data)
}

export const inviteUsers = emails => {
  updateToken()
  return http
    .post(userEndpoint + inviteEndpoint, emails)
    .then(res => res.data).catch(error => error.data)
}

export const inviteStudent = emails => {
  updateToken()
  return http
    .post(userEndpoint + inviteStudentEndpoint, emails)
    .then(res => res.data).catch(error => error.data)
}

export const resendEmail = email => {
  const req = { user: { email } }
  return http
    .post(resendEmailEndpoint, req)
    .then(res => res.data).catch(error => error.data)
}

export const recoverPassword = credentials => {
  return http
    .post(passwordEndpoint + '?email=' + credentials.email, {})
    .then(res => res.data).catch(error => error.data)
}

export const changePassword = credentials => {
  const req = {
    hash: credentials.token,
    password: credentials.password,
    confirmPassword: credentials.passwordConfirmation
  }
  return http
    .post(createPasswordEndpoint, req)
    .then(res => res.data).catch(error => error.data)
}
