import http2, { updateToken } from "../../utils/http2";
import FileSaver from 'file-saver';
import axios from "axios";

const examsUploadEndPoint = process.env.UNOCONV_URL + "/file";
const examsUploadEndPointV2 = process.env.UNOCONV_URL + "/file-v2";
const examsRecognitionEndPoint = process.env.UNOCONV_URL + "/recognition";
const recognitionClassificationEndPoint = process.env.UNOCONV_URL + "/recognition/classification";
const examsClassificationEndPoint = process.env.UNOCONV_URL + "/classification";
const downloadExamEndPoint = process.env.UNOCONV_URL + "/download-pdf";
const sendImagesEndPoint = process.env.UNOCONV_URL + "/send-images";
const diagramExamEndPoint = process.env.UNOCONV_URL + "/diagram";
const createAIquestionsEndpoint = process.env.UNOCONV_URL + "/questions-gpt";
const downloadScantronsEndpoint = process.env.UNOCONV_URL + "/download-scantrons";

const examsAnswersEndPoint = "/exam/create-answers";
const examsFetchClassificationsEndPoint = "/exam/classification/";
const examsImageEndPoint = "/exam/image/";
const examsEquationImageEndPoint = "/exam/equation/image?latex=";
const examsCheckPagesAvailableEndpoint = "/user/pages";
const examsCreateFromGPTEndpoint = "exam/upload/gpt-file";

const anosTrilhasEndpoint = process.env.TRILHAS_URL + "/core/simulie/anos_simulie"
const disciplinasTrilhasEndpoint = process.env.TRILHAS_URL + "/core/simulie/disciplinas_simulie"
const unidadesTrilhasEndpoint = process.env.TRILHAS_URL + "/core/simulie/unidades_simulie"
const resultTrilhasEndpoint = process.env.TRILHAS_URL + "/core/simulie/get_result_of_students"


export const fetchAnoTrilhas = () => {
  return http2
    .post(anosTrilhasEndpoint, {
      grupo: 1
    })
    .then(res => res.data).catch(error => error.data)
}

export const fetchDisciplinasTrilhas = (ano) => {
  return http2
    .post(disciplinasTrilhasEndpoint, {
      grupo: 1,
      ano: ano
    })
    .then(res => res.data).catch(error => error.data)
}

export const fetchResultTrilhas = (ano, disciplina) => {
  let token = window.sessionStorage.getItem('token')
  return http2
    .post(resultTrilhasEndpoint, {
      ano: ano,
      disciplina: disciplina,
      grupo: 1,
      auth: "Bearer " + token
    })
    .then(res => res.data).catch(error => error.data)
}

export const fetchUnidadesTematicas = (ano, disciplina) => {
  let token = window.sessionStorage.getItem('token')
  return http2
    .post(unidadesTrilhasEndpoint, {
      ano: ano,
      disciplina: disciplina,
      grupo: 1,
      auth: "Bearer " + token
    })
    .then(res => res.data).catch(error => error.data)
}

export const gptUploadExam = (examPayload) => {
  updateToken();
  return http2
    .post(examsCreateFromGPTEndpoint, examPayload)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchExamImage = (id) => {
  updateToken();
  return http2
    .get(examsImageEndPoint + id)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchExamReport = (examId, newReport) => {
  updateToken();
  let url = `/online-exam/exam-report?examId=${examId}&newReport=${newReport}`;
  return http2
    .get(url)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const checkPagesAvailable = () => {
  updateToken();
  return http2
    .get(examsCheckPagesAvailableEndpoint)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchEquationImage = (id) => {
  updateToken();
  return http2
    .get(examsEquationImageEndPoint + id)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const uploadRecognition = (file) => {
  updateToken();
  var formData = new FormData();
  formData.append("file", file);

  return http2
    .post(`${examsRecognitionEndPoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (e) => {
        console.log(e);
      },
    })
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const recognitionClassification = (exam) => {
  updateToken();

  return http2
    .post(`${recognitionClassificationEndPoint}`, exam)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const uploadExam = (file) => {
  updateToken();
  var formData = new FormData();
  formData.append("file", file);

  return http2
    .post(`${examsUploadEndPoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (e) => {
        console.log(e);
      },
    })
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const uploadExamV2 = (file, scholarship) => {
  updateToken();
  var formData = new FormData();
  formData.append("file", file);
  formData.append("scholarship", scholarship);

  return http2
  .post(`${examsUploadEndPointV2}`, formData, {
    headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (e) => {
        console.log(e);
      },
    })
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const uploadExamMobile = (file) => {
  updateToken();
  var formData = new FormData();
  formData.append("file", file);

  return http2
    .post(`${examsUploadEndPoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (e) => {
        console.log(e);
      },
    })
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchClassifications = (id) => {
  updateToken();

  return http2
    .get(examsFetchClassificationsEndPoint + id)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const classifyExam = (classification) => {
  updateToken();

  return http2
    .post(`${examsClassificationEndPoint}`, classification)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const sendImages = (image) => {
  updateToken();
  var formData = new FormData();
  formData.append("file", image);

  return http2
    .post(`${sendImagesEndPoint}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (e) => {
        console.log(e);
      },
    })
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchOnlineExam = (
  examId,
  studentId = undefined,
  isOpen = false
) => {
  let baseUrl = `/online-exam`;
  if (isOpen && studentId !== undefined) {
    baseUrl += `/get-open?examId=${examId}&studentId=${studentId}`;
  } else {
    updateToken();
    baseUrl += `?examId=${examId}`;
    if (studentId) baseUrl += `&studentId=${studentId}`;
  }

  return http2.get(baseUrl).then((res) => res.data);
};

export const getVideosLoaded = (examId) => {
  let url = `/online-exam/videos-loaded?onlineExamId=${examId}`;
  return http2.get(url).then((res) => res.data);
};

export const fetchOnlineExamInfo = (examId) => {
  updateToken();
  let url = `/online-exam/info?examId=${examId}`;
  return http2.get(url).then((res) => res.data);
};

export const fetchOnlineExamById = async (examId) => {
  updateToken();
  let url = `/online-exam/getExamById?examId=${examId}`;
  return http2.get(url).then((res) => res.data);
};

export const fetchPowerBIReport = (examIds, newReport) => {
  updateToken();
  let url = `/online-exam/search-students/bi?examIds=${examIds}&newReport=${newReport}`;
  return http2
    .get(url)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchResultExam = (
  title,
  dateFrom,
  dateTotags,
  page,
  itemsPerPage
) => {
  updateToken();
  if (page === undefined || page === "") {
    page = 1;
  }
  let url = `/online-exam/search/paged?status=active&page=` + page;
  if (title !== undefined && title !== "") {
    // url += `&relevance=` + title
    url += `&title=` + title;
    // url += `&description=` + title
  }
  if (itemsPerPage !== undefined && itemsPerPage !== "") {
    url += `&itensPerPage=` + itemsPerPage;
  }

  return http2
    .get(url)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchIndividualResultExam = (
  title,
  dateFrom,
  dateTotags,
  page,
  itemsPerPage
) => {
  updateToken();
  if (page === undefined || page === "") {
    page = 1;
  }
  let url = `/online-exam/search-individual/paged?type=result&page=` + page;
  if (title !== undefined && title !== "") {
    // url += `&relevance=` + title
    url += `&title=` + title;
    // url += `&description=` + title
  }
  if (itemsPerPage !== undefined && itemsPerPage !== "") {
    url += `&itensPerPage=` + itemsPerPage;
  }

  return http2
    .get(url)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchStudentExams = (
  title,
  dateFrom,
  dateTotags,
  page,
  itemsPerPage
) => {
  updateToken();
  if (page === undefined || page === "") {
    page = 1;
  }
  let url = `/online-exam/own/paged?page=` + page;
  if (itemsPerPage !== undefined && itemsPerPage !== "") {
    url += `&itensPerPage=` + itemsPerPage;
  }

  return http2
    .get(url)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchStudentResult = (examId, userId, isOpen = false) => {
  if (!isOpen) updateToken();
  let url = `/online-exam/` + examId;
  url += `/result-student/` + userId;

  return http2
    .get(url)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchStudentResultAll = (userId) => {
  updateToken();
  let url = `/online-exam/result-student-all/` + userId;

  return http2
    .get(url)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchStudentResultExam = ({
  examId,
  page = 1,
  itemsPerPage,
  newReport
}) => {
  let baseUrl = "/online-exam/exam-reports";
  updateToken();
  if (page === undefined || page === "") {
    page = 1;
  }
  baseUrl += `/paged?page=` + page;
  if (examId !== undefined && examId !== "") {
    // url += `&relevance=` + title
    baseUrl += `&examId=` + examId;
    // url += `&description=` + title
  }
  if (itemsPerPage !== undefined && itemsPerPage !== "") {
    baseUrl += `&itemsPerPage=` + itemsPerPage;
  }

  if (newReport === true) {
    baseUrl += `&newReport=true`;
  }

  return http2
    .get(baseUrl)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchStudentIndividualResultExam = (
  examId,
  studentId = undefined
) => {
  let baseUrl = "online-exam";
  if (studentId !== undefined) {
    baseUrl += `/individual/open-result-student?examId=${examId}&studentId=${studentId}`;
  } else {
    updateToken();
    baseUrl += `/individual/result-student?examId=${examId}`;
  }
  return http2.get(baseUrl).then((res) => res.data);
};

export const fetchExam = (id) => {
  updateToken();
  return http2
    .get(`/exam/${id}`)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchExamMatrixEF = (companyId, scholarship) => {
  updateToken();
  return http2
    .get(`/matrix/matrixFilter?companyId=${companyId}&scholarship=${scholarship}`)
    .then((res) => res.data)
    .catch((error) => error.data);
  
};

export const fetchExamMatrixEM = (companyId, scholarship) => {
  updateToken();
  return http2
    .get(`/matrix/matrixFilter?companyId=${companyId}&scholarship=${scholarship}`)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchExamHabilities = (companyId) => {
  updateToken();
  return http2
    .get(`/matrix/matrixFilter?companyId=${companyId}&scholarship=Habilidades`)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchReadingWriting = (grade, companyId) => {
  updateToken();
  return http2
    .get(`/online-exam/reading-writing/result?grade=${grade}&companyId=${companyId}`)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const setReadingWriting = (payload) => {
  updateToken();
  return http2
    .post(`/online-exam/reading-writing/set`, payload)
    .then((res) => res.data)
};

export const fetchConsolidacao = (serie) => {
  updateToken();
  return http2
    .get(`/online-exam/reading-writing/result-serie?serie=${serie}`)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchReadingAndWritingCSVData = () => {
  updateToken();
  return http2
    .get(`/online-exam/reading-writing/result-csv`)
    .then((res) => res.data)
    .catch((error) => error.data);
}

export const fetchExamResult = (id) => {
  updateToken();
  return http2
    .get(`/online-exam/${id}/result`)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const openTestUpdate = (examId, openId, openTestUserMean) => {
  let url = `/online-exam/open-test-update?examId=${examId}&studentId=${openId}&openTestGrade=${openTestUserMean}`;
  return http2
    .get(url)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const downloadExam = (data, format) => {
  updateToken();
  let objectType = "application/pdf";
  const link = document.createElement("a");

  if (format === "docx") {
    objectType =
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  }

  link.target = "_blank";
  link.download = data["Title"].replace(/\.[^/.]+$/, "") + "." + format;
  return http2
    .post(
      `${downloadExamEndPoint}`,
      { ...data, format: format },
      { responseType: "blob" }
    )
    .then((res) => {
      console.log(res);
      link.href = URL.createObjectURL(
        new Blob([res.data], { type: objectType })
      );
      link.click();
      link.delete();
    })
    .catch((error) => error.data);
};

export const diagramExam = (
  data,
  companyId,
  userId,
  authToken,
  name,
  email
) => {
  updateToken();
  return http2
    .post(`${diagramExamEndPoint}`, {
      ...data,
      CompanyId: companyId,
      UserId: userId,
      AuthToken: authToken,
      Name: name,
      Email: email,
    })
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const finishExam = (examID, resolutions) => {
  updateToken();
  return http2
    .put(`/exam/${examID}/finish`, resolutions)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const updateExam = (examID, resolutions) => {
  updateToken();
  console.log(resolutions);
  return http2
    .put(`/exam/${examID}`, resolutions)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchExams = (
  title,
  page,
  tags,
  itemsPerPage,
  userId,
  companyId,
  isSimulie,
  examType = "Todas",
  queryTree = "",
  examStatus = "",
) => {
  updateToken();
  if (page === undefined || page === "") {
    page = 1;
  }
  let url = `/exam/search/paged?page=` + page;
  if (title !== undefined && title !== "") {
    // url += `&relevance=` + title
    url += `&relevance=` + title;
    // url += `&description=` + title
  }
  if (itemsPerPage !== undefined && itemsPerPage !== "") {
    url += `&itensPerPage=` + itemsPerPage;
  }
  if (userId !== undefined && userId !== "") {
    url += `&userId=` + userId;
  }

  if (companyId !== undefined && companyId !== "" && companyId !== null) {
    console.log("companyId", companyId);
    let idCompany = companyId.toString().replace(",", "&companyId=");
    url += `&companyId=` + idCompany;
  }
  if (isSimulie && isSimulie !== "") {
    url += `&isSimulieDatabase=` + isSimulie;
  }
  if (examType) {
    url += `&examType=` + examType;
  }
  if (queryTree) {
    url += `&queryTree=` + queryTree;
  }
  if (examStatus !== undefined && examStatus !== "") {
    url += `&status=` + examStatus;
  } else {
    url += `&status=active`;
  }

  return http2
    .get(url)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const createQuestionsByAI = (payload) => {
  updateToken();

  return http2
    .post(createAIquestionsEndpoint, payload)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const deleteExam = (id) => {
  updateToken();
  return http2
    .delete(`/exam/${id}`)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const deleteTagExam = (examId, tag) => {
  updateToken();
  return http2
    .delete(`/exam/${examId}/tag/${tag}`)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const addTagExam = (examId, tag) => {
  updateToken();
  return http2
    .put(`/exam/${examId}/tag/${tag}`)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const fetchSelected = (examsId) => {
  updateToken();
  let examsRequest = "?";
  if (Array.isArray(examsId)) {
    for (let i = 0; i < examsId.length; i++) {
      if (i !== 0) {
        examsRequest += "&listExamsId=" + examsId[i];
      } else {
        examsRequest += "listExamsId=" + examsId[i];
      }
    }
  } else {
    examsRequest += "listExamsId=" + examsId;
  }

  return http2
    .get(`/exam/selected` + examsRequest)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const searchSelected = (
  educationType,
  tipoProva,
  tipoQuestao,
  provaIds,
  questaoTags,
  year,
  listOfMatrixObject,
  listOfMatrixHabilityObject,
  treeId,
  treeIdHabilities,
  page = 1,
  limit = 10,
  searchStatement
) => {
  updateToken();

  const payload = {
    PeriodoEscolar: educationType,
    TipoProva: tipoProva,
    TipoQuestao: tipoQuestao,
    ProvaIds: provaIds,
    QuestaoTags: questaoTags,
    Ano: year,
    ListOfMatrixObject: listOfMatrixObject,
    ListOfMatrixHabilityObject: listOfMatrixHabilityObject,
    TreeId: treeId,
    TreeHabilityId: treeIdHabilities,
    page,
    limit,
    searchStatement: searchStatement,
  };

  return http2
    .post(`/exam/search`, payload)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const deleteQuestion = (examId, questionId) => {
  updateToken();
  return http2
    .delete(`/exam/${examId}/question/${questionId}`)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const searchExamTag = (tags, page) => {
  updateToken();
  if (page === undefined || page === "") {
    page = 1;
  }
  let url = `/exam/search/paged?status=active&page=` + page;
  if (tags !== undefined && tags !== "") {
    url += `&tags[]=` + tags;
  }
  return http2
    .get(url)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const startOnlineExam = (
  examID,
  studentId = undefined,
  isOpen = false
) => {
  let baseUrl = "online-exam/";
  if (isOpen) {
    baseUrl += `start-open?examId=${examID}`;
  } else {
    updateToken();
    baseUrl += `start?examId=${examID}`;
    if (studentId) {
      baseUrl += `&studentId=${studentId}`;
    }
  }

  return http2.get(baseUrl).then((res) => res.data);
};

export const endOnlineExam = (
  examID,
  studentId = undefined,
  isOpen = false
) => {
  let baseUrl = "/online-exam";
  if (isOpen && studentId !== undefined) {
    baseUrl += `/end-open?examId=${examID}&studentId=${studentId}`;
  } else {
    updateToken();
    baseUrl += `/end?examId=${examID}`;
    if (studentId) {
      baseUrl += `&studentId=${studentId}`;
    }
  }
  return http2
    .get(baseUrl)
    .then((res) => res)
    .catch((error) => error.data);
};

export const createExam = (exam) => {
  updateToken();
  return http2
    .post(`/exam/create`, exam)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const createOnlineExam = (payload) => {
  updateToken();
  return http2
    .post(`/online-exam/create-from-questions`, payload)
    .then((res) => res.data)
    .catch((error) => error.data);
};

export const downloadExamAnswerSheets = async (examId) => {
  updateToken();
  try {
    const response = await http2.get(`${downloadScantronsEndpoint}?examId=${examId}`, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    FileSaver.saveAs(url, 'exam_answer_sheets.zip');
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const ansQuestion = (payload, isOpen = false) => {
  let baseUrl = `online-exam`;
  let params = `?ExamId=${payload.ExamId}&QuestionId=${payload.QuestionId}&AlternativeOrder=${payload.AlternativeOrder}&StudentId=${payload.StudentId}`;
  if (isOpen) {
    baseUrl += "/ans-open-question" + params;
  } else {
    updateToken();
    baseUrl += "/answer-question" + params;
  }

  return http2
    .get(baseUrl)
    .then((res) => res.data)
    // .catch((error) => error.data);
};

export const getTRIFiles = async () => {
  try {
    const caminhos_url = "https://caminhos-50b7be77e3cb.herokuapp.com/simulie/tri/fortaleza";
    const caminhos_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzc0NzUzMTkyLCJpYXQiOjE3MjI5MTMxOTIsImp0aSI6IjQzYTVkM2I3M2M2MDQzMWE5YzM4NTE1NjhjZjlmZGNhIiwidXNlcl9pZCI6MTQ0fQ.7_SdeZIwnGC-ZNe9elvxdpM4P8IA0Fv6MWkQIxSvJmQ";
  
    const response = await axios.get(caminhos_url, {
      headers: {
        Authorization: `Bearer ${caminhos_token}`
      }
    });
    return response.data.data;
  } catch(err) {
    console.log(err);
    return [];
  }
};
