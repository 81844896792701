export const CREATE_USER = 'users:CREATE_USER'
export const CREATE_USER_SUCCESS = 'users:CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'users:CREATE_USER_FAILURE'

export const UPDATE_USER = 'users:UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'users:UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'users:UPDATE_USER_FAILURE'

export const FETCH_USER = 'users:FETCH_USER'
export const FETCH_USER_SUCCESS = 'users:FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'users:FETCH_USER_FAILURE'

export const INFO_USER = 'users:INFO_USER'
export const INFO_USER_SUCCESS = 'users:INFO_USER_SUCCESS'
export const INFO_USER_FAILURE = 'users:INFO_USER_FAILURE'

export const FETCH_PLAN_EXPIRATION_DATE = 'users:FETCH_PLAN_EXPIRATION_DATE'
export const FETCH_PLAN_EXPIRATION_DATE_SUCCESS = 'users:FETCH_PLAN_EXPIRATION_DATE_SUCCESS'
export const FETCH_PLAN_EXPIRATION_DATE_FAILURE = 'users:FETCH_PLAN_EXPIRATION_DATE_FAILURE'

export const FETCH_USERS = 'users:FETCH_USERS'
export const FETCH_USERS_SUCCESS = 'users:FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'users:FETCH_USERS_FAILURE'

export const DELETE_USER = 'users:DELETE_USER'
export const DELETE_USER_SUCCESS = 'users:DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'users:DELETE_USER_FAILURE'

export const UPLOAD_SPREADSHEET = 'users:UPLOAD_SPREADSHEET'
export const UPLOAD_SPREADSHEET_SUCCESS = 'users:UPLOAD_SPREADSHEET_SUCCESS'
export const UPLOAD_SPREADSHEET_FAILURE = 'users:UPLOAD_SPREADSHEET_FAILURE'

export const CHECK_SUBSCRIPTION_STATUS = 'users:CHECK_SUBSCRIPTION_STATUS';
export const CHECK_SUBSCRIPTION_STATUS_SUCCESS = 'users:CHECK_SUBSCRIPTION_STATUS_SUCCESS';
export const CHECK_SUBSCRIPTION_STATUS_FAILURE = 'users:CHECK_SUBSCRIPTION_STATUS_FAILURE';

export const HANDLE_TRILHAS_LOGIN = 'users:HANDLE_TRILHAS_LOGIN';
export const HANDLE_TRILHAS_LOGIN_SUCCESS = 'users:HANDLE_TRILHAS_LOGIN_SUCCESS';
export const HANDLE_TRILHAS_LOGIN_FAILURE = 'users:HANDLE_TRILHAS_LOGIN_FAILURE';
