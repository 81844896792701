/* eslint-disable */
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";

import {
  fetchResultExam,
  fetchStudentIndividualResultExam,
  fetchStudentResultExam,
  setExamId,
  searchExamTag,
  setCanEdit,
  clearExamModule,
} from "../store/exams/actions";

import { infoUser } from "../store/users/actions";

import {
  HeaderBar,
  HeaderLeft,
  HeaderRight,
} from "./styles/ListExamsPage.styles";

import { Page } from "./styles/Page.styles";
import { Content } from "./styles/DetailsPage.styles";
import { Button } from "@material-ui/core";

function ResultExamsPage({
  fetchStudentResultExam,
  clearExamModule,
  infoUser,
  students,
}) {
  let [listTotal, setListTotal] = useState(undefined);
  let [idExam, setIdExam] = useState("");
  let [tableQuestions, setTableQuestions] = useState(undefined);
  let [tableTags, setTableTags] = useState(undefined);
  let [showTags, setShowTags] = useState(false);
  const [dataCSV, setDataCSV] = useState({
    data: [],
    headers: [],
  });

  useEffect(() => {
    infoUser();
    clearExamModule();
  }, []);

  useEffect(() => {
    let url = new URL(window.location.href);
    let urlExamId = url.searchParams.get("examId");
    setIdExam(urlExamId);
    fetchStudentResultExam({
      examId: urlExamId,
      page:1,
      itemsPerPage: 10
    });
    setListTotal(students.total);
  }, []);

  useEffect(() => {
    mountTable();
  }, [students]);

  function handleViewAction(examId, event) {
    const userId = event.row.studentId;
    if (userId)
      window.location.href =
        "/result-exams/individual?examId=" + examId + "&studentId=" + userId;
  }

  const mountTotal = (
    row,
    grade_row,
    school_row,
    total_pont,
    features,
    num_studs_per_grade
  ) => {
    row["total_grade"] = total_pont / features.questions.length; // total_grade field
    if ("total_grade" in grade_row) {
      // increment notes f/ each student in grade
      grade_row["total_grade"] += row["total_grade"] / num_studs_per_grade;
    } else {
      grade_row["total_grade"] = row["total_grade"] / num_studs_per_grade;
    }
    if ("total_grade" in school_row) {
      // increment notes f/ each student in grade
      school_row["total_grade"] += row["total_grade"];
    } else {
      school_row["total_grade"] = row["total_grade"];
    }

    return {
      row,
      grade_row,
      school_row,
    };
  };

  const mountQuestions = (
    q_id,
    features,
    row,
    grade_row,
    school_row,
    num_studs_per_grade
  ) => {
    let total_pont = 0;

    for (const question of features.questions) {
      let q_key = `question${q_id++}`;
      row[q_key] = question; // q1, q2, q3, ..., are fields of columns
      total_pont += question;
      if (q_key in grade_row) {
        // increment mean of notes f/ each question in grade
        grade_row[q_key] += question / num_studs_per_grade;
      } else {
        grade_row[q_key] = question / num_studs_per_grade;
      }
      if (q_key in school_row) {
        school_row[q_key] += question;
      } else {
        school_row[q_key] = question;
      }
    }

    return {
      row,
      grade_row,
      school_row,
      total_pont,
    };
  };

  const mountTags = (
    features,
    row,
    grade_row,
    school_row,
    num_studs_per_grade
  ) => {
    for (const [tag, tag_mean] of Object.entries(features.tags_mean)) {
      row[tag] = tag_mean;
      if (tag in grade_row) {
        // increment mean of notes f/ each question in grade
        grade_row[tag] += tag_mean / num_studs_per_grade;
      } else {
        grade_row[tag] = tag_mean / num_studs_per_grade;
      }
      if (tag in school_row) {
        school_row[tag] += tag_mean;
      } else {
        school_row[tag] = tag_mean;
      }
    }

    return {
      row,
      grade_row,
      school_row,
    };
  };

  const mountGrade = (
    grade_row,
    school_row,
    num_studs_per_school,
    studs,
    grade_rows,
    id_iter
  ) => {
    let num_studs_per_grade = Object.keys(studs).length;
    num_studs_per_school += num_studs_per_grade;
    for (const [studentId, features] of Object.entries(studs)) {
      let q_id = 1;
      let total_pont;
      let row = {
        id: id_iter++,
        type: "Aluno",
        name: features["name"],
        studentId: studentId,
      }; // name field

      ({ row, grade_row, school_row, total_pont } = mountQuestions(
        q_id,
        features,
        row,
        grade_row,
        school_row,
        num_studs_per_grade
      ));

      ({ row, grade_row, school_row } = mountTotal(
        row,
        grade_row,
        school_row,
        total_pont,
        features,
        num_studs_per_grade
      ));

      ({ row, grade_row, school_row } = mountTags(
        features,
        row,
        grade_row,
        school_row,
        num_studs_per_grade
      ));
      grade_rows.push(row);
    }
    return {
      grade_rows,
      num_studs_per_school,
      grade_row,
      school_row,
      id_iter,
    };
  };

  const mountSchool = (
    grades,
    school_row,
    school_rows,
    num_studs_per_school,
    id_iter
  ) => {
    let totalSchool = {};
    let count = 0;
    for (const [grade, studs] of Object.entries(grades)) {
      const grade_rows = [];
      let grade_row = {
        id: id_iter++,
        type: "Turma",
        name: grade,
        studentId: undefined,
      }; // name field

      ({
        grade_rows,
        num_studs_per_school,
        grade_row,
        school_row,
        id_iter,
      } = mountGrade(
        grade_row,
        school_row,
        num_studs_per_school,
        studs,
        grade_rows,
        id_iter
      ));

      for (const key of Object.keys(grade_row)) {
        // atualiza media das escolas com numero total de alunos por escola
        if (
          !key.startsWith("name") &&
          !key.startsWith("id") &&
          !key.startsWith("type")
        ) {
          totalSchool[key] =+ grade_row[key];
        }
      }

      count += 1;
      grade_rows.unshift(grade_row);
      school_rows = school_rows.concat(grade_rows);
    }

    for (const key of Object.keys(school_row)) {
      // atualiza media das escolas com numero total de alunos por escola
      if (
        !key.startsWith("name") &&
        !key.startsWith("id") &&
        !key.startsWith("type")
      ) {
        school_row[key] = totalSchool[key] / count;
      }
    }

    return {
      school_row,
      school_rows,
      id_iter,
    };
  };

  const getType = (key) => {
    if (key.startsWith("name")) {
      return "name";
    } else if (key.startsWith("student")) {
      return "student";
    } else if (key.startsWith("type")) {
      return "type";
    } else if (key.startsWith("question")) {
      return "question";
    } else if (key.startsWith("total_")) {
      return "total";
    } else if (key.startsWith("id")) {
      return "id";
    } else if (key.startsWith("type")) {
      return "type";
    } else {
      return "default";
    }
  };

  const isNumeric = (num) => {
    return !isNaN(num);
  };

  const mountTable = () => {
    const keys = Object.keys(students);
    if (keys.length > 0) {
      let rows = [];
      let id_iter = 0;

      for (const [school, grades] of Object.entries(students)) {
        const school_row = {
          id: id_iter++,
          type: "Escola",
          name: school,
          studentId: undefined,
        }; // name field
        let num_studs_per_school = 0;
        let school_rows = [];

        ({ school_row, school_rows, id_iter } = mountSchool(
          grades,
          school_row,
          school_rows,
          num_studs_per_school,
          id_iter
        ));
        school_rows.unshift(school_row); // adiciona coluna da escola no topo da tabela
        rows = rows.concat(school_rows);
      }

      // definir colunas
      let question = 1;

      const columType = {
        type: (key) => ({
          field: key,
          headerName: "Tipo",
          sortable: false,
          width: 160,
          renderCell: (params) => params.value === "Aluno" ? (
            <strong>
              {params.value}
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
              >
                Open
              </Button>
            </strong>
          ) : params.value
        }),
        name: (key) => ({
          field: key,
          headerName: "Nome",
          sortable: false,
          width: 200,
        }),
        student: (key) => ({
          field: key,
          headerName: "Student",
          hide: true,
          sortable: false,
          width: 200,
        }),
        question: (key) => ({
          field: key,
          headerName: `Questão ${question++}`,
          sortable: false,
          type: "number",
          width: 120,
        }),
        total: (key) => ({
          field: key,
          headerName: "Média total",
          sortable: false,
          type: "number",
          width: 120,
        }),
        id: (key) => ({
          field: key,
          headerName: "ID",
          sortable: false,
          width: 40,
        }),
        default: (key) => ({
          field: key,
          headerName: key.toUpperCase(),
          width: 120,
          type: "number",
          renderCell: (params) => params.value || "0.00"
        }),
      };

      const columns = Object.keys(rows[0]).map((key) => {
        const type = getType(key);
        return columType[type](key);
      });

      for (var i = 0; i < rows.length; i++) {
        for (var key in rows[i]) {
          if (isNumeric(rows[i][key]) && key != "id" && key != "click") {
            rows[i][key] = Number(rows[i][key]).toFixed(2);
          }
        }
      }

      const posName = columns.map((e) => e.field).indexOf("name") + 1;
      const posQuestion = posName + 1;
      const posTotalGrade =
        columns.map((e) => e.field).indexOf("total_grade") + 1;

      const headerColumns = columns.slice(1, posName);
      const questionColumns = columns.slice(posQuestion, posTotalGrade);
      const tagColumns = columns.slice(posTotalGrade);

      const questionsArea = headerColumns.concat(questionColumns);
      const tagsArea = headerColumns.concat(tagColumns);

      setTableQuestions(
        <div style={{ height: "70vh", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={questionsArea}
            pageSize={10}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            disableColumnMenu
            onRowClick={(event) => handleViewAction(idExam, event)}
          />
        </div>
      );

      setTableTags(
        <div style={{ height: "70vh", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={tagsArea}
            pageSize={10}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            disableColumnMenu
            onRowClick={(event) => handleViewAction(idExam, event)}
          />
        </div>
      );

      setDataCSV({
        data: rows,
        headers: columns,
      });
    }
  };

  const handleSwitch = (_event) => {
    setShowTags(!showTags);
  };

  return (
    <Page>
      <Content style={{ width: "100%", maxWidth: "1200px" }}>
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item>Questões</Grid>
          <Grid item>
            <Switch
              checked={showTags}
              onChange={handleSwitch}
              color="primary"
            />
          </Grid>
          <Grid item>Tags</Grid>
        </Grid>
        <br />
        {showTags ? tableTags : tableQuestions}
        <br />
        <Grid container justifyContent="center">
          <CSVLink data={dataCSV.data} filename="relatorio.csv">
            Exportar CSV
          </CSVLink>
        </Grid>
      </Content>
    </Page>
  );
}

ResultExamsPage.propTypes = {
  fetchResultExam: PropTypes.func.isRequired,
  fetchStudentResultExam: PropTypes.func.isRequired,
  fetchStudentIndividualResultExam: PropTypes.func.isRequired,
  user: PropTypes.object,
  students: PropTypes.object,
  exams: PropTypes.array,
  loading: PropTypes.bool,
  total: PropTypes.number,
  setExamId: PropTypes.func.isRequired,
  setCanEdit: PropTypes.func.isRequired,
  clearExamModule: PropTypes.func,
  searchExamTag: PropTypes.func.isRequired,
  infoUser: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams, questions, users }) => ({
  user: users.infoUser,
  exams: exams.examsList !== undefined ? exams.examsList.exams : [],
  total: exams.selected.total !== undefined ? exams.selected.total : 0,
  // questionsCompany: questions.questionsCompany.length,
  loading: exams.loading,
  students: exams.result,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchResultExam,
      fetchStudentResultExam,
      fetchStudentIndividualResultExam,
      infoUser,
      clearExamModule,
      setExamId,
      setCanEdit,
      searchExamTag,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResultExamsPage);
