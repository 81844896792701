const aliases = {
  'x-total-count': 'total',
  'x-page': 'page'
}

export const mapHeadersToData = (...headers) => res => {
  Object
    .entries(res.headers)
    .filter(([key]) => !headers || headers.includes(key))
    .forEach(([header, value]) => { res.data[aliases[header] || header] = value })

  return res.data
}
