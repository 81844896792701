/* eslint-disable */
import React from "react";

import { Grid, Typography, Accordion, AccordionSummary } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import CustomizedProgressBars from "../../progress/Progress";

const useStyles = makeStyles((theme) => ({
  content: {
    justifyContent: "space-between",
  },
}));

function AccordionResult({
  title,
  score
}) {
  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        classes={{
          content: classes.content,
        }}
      >
        <Grid xs={6} md={3} container>
          <Typography>
            {title}
          </Typography>
        </Grid>
        <Grid xs={4} md={3} justifyContent="space-between" container>
          <Typography style={{ width: 100, color: "#007315" }}>
            <CustomizedProgressBars
              label
              tooltip={"Taxa de Acerto"}
              color={"#3de048"}
              percent={score}
            />
          </Typography>
        </Grid>
      </AccordionSummary>
    </Accordion>
  );
}

export default AccordionResult;

