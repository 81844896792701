import React from 'react'

import { Icon } from 'antd'
import { Container, WarnText, BgIcon } from './styles'

export default function WarningMessage () {
  return (
    <Container>
      <BgIcon>
        <Icon type='warning' style={{ fontSize: '35px', color: '#fff' }} />
      </BgIcon>
      <WarnText>Encontramos alguns erros no arquivo</WarnText>
    </Container>
  )
}
