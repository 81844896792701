import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "left",
  },
  description: {
    textAlign: "left",
    marginLeft: "128px",
    width: "156px",
    borderWidth: 1,
    borderColor: 'black',
    padding: 2
  },
  xyz: {
    textAlign: "left",
    marginLeft: "0px",
    width: "156px",
    borderWidth: 1,
    borderColor: 'black',
    padding: 2
  },
});

const TableRow = ({ items }) => {
  const rows = items.map((item) => (
    <View style={styles.row} key={item.sr.toString()}>
      <Text style={styles.description}>{item.prova}</Text>
      <Text style={styles.xyz}>{item.nota}</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default TableRow;
