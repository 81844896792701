export const TYPE_OF_TEST = {
  "Todas": "Todas",
  "Olimpiadas": "Olimpiadas",
  "Rascunho": "Rascunho",
  "Aprovadas": "Aprovadas",
  "ENEM": "ENEM",
  "Autorais": "Autorais",
  "Vestibulares": "Vestibulares",
  "VestibularesCE": "Vestibulares CE",
}

export const MAP_SCHOLARSHIP_TO_TYPE_OF_TEST = {
  "EF1": [ "Aprovadas", "Rascunho" ],
  "EF2": [ "Aprovadas", "Rascunho" ],
  "EF": [ "Aprovadas", "Rascunho" ],
  "EM": [
    "Aprovadas",
    "Rascunho",
    "ENEM",
    "Vestibulares",
    "VestibularesCE",
  ],
  "TEC": [ "Aprovadas", "Rascunho" ],
  "SUP": [ "Aprovadas", "Rascunho" ],
  "OUT": [ "Aprovadas", "Rascunho" ],
};

export const TYPE_OF_EDUCATION = {
  // "EF1": {
  //   label: "Ensino Fundamental I",
  //   slug: "EF1",
  // },
  "EF2": {
    label: "Ensino Fundamental",
    slug: "EF",
  },
  "EM": {
    label: "Ensino Médio",
    slug: "EM",
  },
  "TEC": {
    label: "Técnico",
    slug: "TEC",
  },
  "SUP": {
    label: "Superior",
    slug: "SUP",
   },
  "OUT": {
    label: "Outros",
    slug: "OUT",
  },
};

export const LEVEL_OF_QUESTION = {
  "Todas": "Todas",
  "Fácil": "Fácil",
  "Médio": "Médio",
  "Difícil": "Difícil",
}

export const getTreeInfo = (scholarship, trees) => {
  const { treeIdEF, treeIdEM, matrixEF, matrixEM } = trees;
  switch(scholarship){
    case "EF1":
      return {
        treeId: treeIdEF,
        matrix: matrixEF,
      };
    case "EF2":
      return {
        treeId: treeIdEF,
        matrix: matrixEF,
      };
    case TYPE_OF_EDUCATION.EF2.slug:
      return {
        treeId: treeIdEF,
        matrix: matrixEF,
      };
    case TYPE_OF_EDUCATION.EM.slug:
      return {
        treeId: treeIdEM,
        matrix: matrixEM,
      };
    case TYPE_OF_EDUCATION.TEC.slug:
      return {
        treeId: treeIdEM,
        matrix: matrixEM,
      };
    case TYPE_OF_EDUCATION.SUP.slug:
      return {
        treeId: treeIdEM,
        matrix: matrixEM,
      };
    case TYPE_OF_EDUCATION.OUT.slug:
      return {
        treeId: treeIdEM,
        matrix: matrixEM,
      };
    default:
      return {
        treeId: treeIdEM,
        matrix: matrixEM,
      };
  }
};
