import createReducer from '../createReducer'
import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  INFO_USER,
  INFO_USER_SUCCESS,
  INFO_USER_FAILURE,
  UPLOAD_SPREADSHEET,
  UPLOAD_SPREADSHEET_SUCCESS,
  UPLOAD_SPREADSHEET_FAILURE,
  FETCH_PLAN_EXPIRATION_DATE,
  FETCH_PLAN_EXPIRATION_DATE_SUCCESS,
  FETCH_PLAN_EXPIRATION_DATE_FAILURE,
  CHECK_SUBSCRIPTION_STATUS,
  CHECK_SUBSCRIPTION_STATUS_SUCCESS,
  CHECK_SUBSCRIPTION_STATUS_FAILURE,
} from './constants'

const initialState = {
  id: null,
  file: null,
  query: {},
  validation: {},
  usersList: {},
  user: {},
  infoUser: null,
  loading: false,
  uploading: false,
  error: {},
  subscriptionStatus: {}
}

export default createReducer(initialState, {
  [CREATE_USER]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [CREATE_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [CREATE_USER_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [UPDATE_USER]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [UPDATE_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [UPDATE_USER_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [FETCH_USER]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [FETCH_USER_SUCCESS]: (state, action) => ({
    ...state,
    user: action.payload,
    loading: false,
    error: initialState.error
  }),
  [FETCH_USER_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [INFO_USER]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [INFO_USER_SUCCESS]: (state, action) => ({
    ...state,
    infoUser: action.payload,
    loading: false,
    error: initialState.error
  }),
  [INFO_USER_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),

  [FETCH_PLAN_EXPIRATION_DATE]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [FETCH_PLAN_EXPIRATION_DATE_SUCCESS]: (state, action) => ({
    ...state,
    planExpirationDate: action.payload,
    loading: false,
    error: initialState.error
  }),
  [FETCH_PLAN_EXPIRATION_DATE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [FETCH_USERS]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [FETCH_USERS_SUCCESS]: (state, action) => ({
    ...state,
    usersList: action.payload,
    loading: false,
    error: initialState.error
  }),
  [FETCH_USERS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [DELETE_USER]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [DELETE_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [DELETE_USER_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [UPLOAD_SPREADSHEET]: (state, action) => ({
    ...state,
    id: initialState.id,
    file: action.payload,
    loading: true,
    error: initialState.error,
  }),
  [UPLOAD_SPREADSHEET_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: initialState.error
  }),
  [UPLOAD_SPREADSHEET_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
  [CHECK_SUBSCRIPTION_STATUS]: (state, action) => ({
    ...state,
    loading: true,
    error: initialState.error
  }),
  [CHECK_SUBSCRIPTION_STATUS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    subscriptionStatus: action.payload,
    error: initialState.error
  }),
  [CHECK_SUBSCRIPTION_STATUS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }),
})
