import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Empty as AntdEmpty, Input, Pagination as AntdPagination } from 'antd'

export const Page = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  padding: 0.75rem;
  width: 100%;
`

export const HeaderBar = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: 10px;
`

export const HeaderLeft = styled.div`
  width: 100%;
`

export const HeaderRight = styled.div`
  display: flex; 
  justify-content: flex-end
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  width: 100%;
`

export const Col = styled.div`
  flex: ${props => props.size || 1};
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.center && 'text-align: center;'}
  ${props => props.left && 'text-align: left;'}
  ${props => props.right && 'text-align: right;'}
  white-space: nowrap;

  .tagButton{
    margin: 0 5px;

    height: 25px;
    padding: 0 8px;
    font-size: 14px;

  }
  
  .tagButtonSuccess{
    margin: 0 5px;
    background-color: green;
    height: 25px;
    padding: 0 8px;
    font-size: 14px;
    color: white;
    font-weight: bold;
  }
  
  .tagButtonRed{
    margin: 0 5px;
    background-color: red;
    height: 25px;
    padding: 0 8px;
    font-size: 14px;
    color: white;
    font-weight: bold;
  }

  .deleteTag {
    border-left: 1px solid #fff;
    padding-left: 5px;
    padding-bottom: 2px;
  }
`

export const Header = styled.p`
  display: inline-block;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  white-space: normal;
`

export const Empty = styled(AntdEmpty)`
  margin: 2rem auto;
`

export const Pagination = styled(AntdPagination)`
  margin-top: 2rem;
  text-align: center;
`
export const UploadTitle = styled.h2`
  font-weight: 400;
  font-size: 1.8rem;
`

export const Search = styled(Input.Search)`
  input { border-radius: 9999px; }
  ${props => props.type === 'desktop' && css`
    max-width: 60%;
    margin-left: 15px;
    @media only screen and (max-width: 600px) { display: none; }
  `}
  ${props => props.type === 'mobile' && css`
    width: 10px
    margin-bottom: .5rem;
  `}
`

export const Name = styled.span`
  margin-left: .5rem;
`

export const OrganizationName = styled.span`
  opacity: ${props => props.disabled ? 0.5 : 1};
`

export const Title = styled.h2`
  font-weight: 500;
  font-size: 1.25rem;
`
