/* eslint react/prop-types: 0 */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Button } from 'antd'

const buttonStyle = css`
  width: 100%;
  background-color: #514fff;
  border-color: #514fff;
  border-radius: 25px;
  color: #fff;
  :hover {
    background-color: #514fff;
    border-color: #FBA661;
    border-width: 4px;
    color: #fff;
  }
  &.ant-btn:focus{
    background-color: #514fff;
    color: #fff;
    border-color: transparent;
    :hover{
      border-color: #FBA661;
      border-width: 4px;
    }
  }
`

export const StyledButton = props => (
  <Button {...props} css={buttonStyle}>
    {props.children}
  </Button>
)
