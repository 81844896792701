import http, { updateToken } from '../../utils/http2'

const processesEndpoint = '/core_processes'

export const processOutput = ({ documentId, templateId, userId }) => {
  updateToken()
  const req = {
    core_process: {
      document_id: documentId,
      template_id: templateId,
      user_id: userId
    }
  }
  return http
    .post(processesEndpoint, req)
    .then(res => {
      if (res.data.status !== 'done') throw res.data
      return res.data
    })
}

export const fetchOutputWithoutWarnings = id => {
  updateToken()
  return http
    .get(`${processesEndpoint}/${id}/without_correction`)
    .then(res => {
      if (res.data.status !== 'done') throw res.data
      return res.data
    })
}
