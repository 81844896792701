/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { Divider, Icon, Popconfirm, Tooltip } from 'antd'
import compact from 'lodash/compact'
import last from 'lodash/last'

import { UploadImageIconStyle, UpIconStyle, DownIconStyle, DeleteIconStyle, AddIconStyle } from './styles/AlternativeList.styles'

const renderActionButtons = icons => icons.map((icon, i) =>
  icon === last(icons) || compact(icons).length === 1
    ? <span key={i} >{icon}</span>
    : <span key={i}>{icon}<Divider type='vertical' /></span>
)

const renderIcon = (onClick, tooltip, style, type, id) => (
  onClick &&
  <Tooltip title={tooltip}>
    <Icon id={id} onClick={onClick} css={style} type={type} />
  </Tooltip>
)

const renderPopconfirmIcon = (onClick, tooltip, style, type) => (
  onClick &&
  <Tooltip title={tooltip}>
    <Popconfirm
      arrowPointAtCenter
      cancelText='Não'
      icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
      okText='Sim'
      okType='danger'
      onConfirm={onClick}
      placement='topRight'
      title='Tem certeza?'
    >
      <Icon css={style} type={type} />
    </Popconfirm>
  </Tooltip>
)

const ActionList = ({ onUploadImage, onUp, onDown, onAdd, onDelete }) => {
  const actionButtons = renderActionButtons(
    compact([
      renderIcon(onUploadImage, 'Upload de Imagens', UploadImageIconStyle, 'picture-o', 'upload'),
      renderIcon(onUp, 'Subir Alternativa', UpIconStyle, 'up-o', 'up'),
      renderIcon(onDown, 'Descer Alternativa', DownIconStyle, 'down-o', 'down'),
      renderIcon(onAdd, 'Adicionar Alternativa', AddIconStyle, 'plus-circle-o', 'add'),
      renderPopconfirmIcon(onDelete, 'Deletar Alternativa', DeleteIconStyle, 'delete', 'delete')
    ])
  )

  return (
    <span>
      {
        actionButtons.length > 0
          ? actionButtons
          : (
            <Tooltip arrowPointAtCenter placement='topRight' title={'Não há ações possíveis'}>
              <Icon style={{ color: '#b0b0b0' }} type='info-circle' theme='outlined' />
            </Tooltip>
          )
      }
    </span>
  )
}

ActionList.propTypes = {
  onUploadImage: PropTypes.func,
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func
}

export default ActionList
