import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChangePasswordForm from '../components/form/ChangePasswordForm'
import { Page } from './styles/Page.styles'
import queryString from 'query-string'

class PasswordRecoveryPage extends Component {
  state = {
    token: null
  }

  static propTypes = {
    location: PropTypes.object.isRequired
  }

  componentDidMount () {
    const query = queryString.parse(this.props.location.search)
    console.log('query', query)
    const token = query.token
    this.setState({ token })
  }

  render () {
    const { token } = this.state

    return (
      <Page>
        <ChangePasswordForm token={token} />
      </Page>
    )
  }
}

export default PasswordRecoveryPage
