import React from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as yup from 'yup'
import get from 'lodash/get'
import { withFormik, Field } from 'formik'
import { editTemplate } from '../../store/templates/actions'
import { TEMPLATE_RATIOS } from '../../utils/constants'

import AdminPermission from '../permission/AdminPermission'
import TextInput from '../input/TextInput'
import OrganizationInput from '../input/OrganizationInput'
import TemplateFontFamilyInput from '../input/TemplateFontFamilyInput'
import TemplateFontSizeInput from '../input/TemplateFontSizeInput'
import TemplateQuestionNumberingInput from '../input/TemplateQuestionNumberingInput'
import TemplateQuestionAlternativeInput from '../input/TemplateQuestionAlternativeInput'
import TemplateQuestionIndentationInput from '../input/TemplateQuestionIndentationInput'
import TemplateAnswerIndentationInput from '../input/TemplateAnswerIndentationInput'
import TemplateSpacingBeforeQuestionInput from '../input/TemplateSpacingBeforeQuestionInput'
import TemplateSpacingAfterQuestionInput from '../input/TemplateSpacingAfterQuestionInput'
import TemplatePreview from '../preview/TemplatePreview'
import SubmitButton from '../button/SubmitButton'
import { Spin } from 'antd'
import { StyledForm, SubGroup, SubGroupLabel, FlexGroup } from './styles/Form.styles'

const handleSubmit = (values, formikBag) => {
  formikBag.props.editTemplate(values)
}

const validationSchema = props => {
  return yup.object().shape({
    name: yup.string().required(),
    organization_id: yup.string(),
    font: yup.string(),
    font_size: yup.string(),
    numbering_type: yup.string(),
    alternative_type: yup.string(),
    indentation_question: yup.string(),
    indentation_answer: yup.string(),
    spacing_before_question: yup.string(),
    spacing_after_question: yup.string()
  })
}

const defaultValues = {
  name: '',
  font: 'Arial',
  font_size: '12',
  numbering_type: '##.\t',
  alternative_type: 'a)\t',
  indentation_question: '0',
  indentation_answer: '0',
  spacing_before_question: '12',
  spacing_after_question: '0'
}

const mapPropsToValues = ({ initialValues }) => {
  if (!initialValues || !Object.keys(initialValues).length) return initialValues
  return {
    ...defaultValues,
    ...initialValues
  }
}

const EditTemplateForm = ({
  isValid,
  loading,
  values
}) => {
  const ratio = get(TEMPLATE_RATIOS, [values.font, values.numbering_type, values.font_size], 1)

  return (
    <Spin spinning={loading}>
      <StyledForm>
        <FlexGroup flex={1}>
          <Field
            name='name'
            label='Nome'
            component={TextInput}
          />
          <AdminPermission>
            <Field
              name='organization_id'
              label='Organização'
              component={OrganizationInput}
            />
          </AdminPermission>
        </FlexGroup>
        <FlexGroup>
          <SubGroup>
            <SubGroupLabel>Fonte</SubGroupLabel>
            <Field
              name='font'
              component={TemplateFontFamilyInput}
            />
            <Field
              name='font_size'
              component={TemplateFontSizeInput}
            />
          </SubGroup>
          <SubGroup>
            <SubGroupLabel>Estilo</SubGroupLabel>
            <Field
              name='numbering_type'
              component={TemplateQuestionNumberingInput}
            />
            <Field
              name='alternative_type'
              component={TemplateQuestionAlternativeInput}
            />
          </SubGroup>
          <SubGroup>
            <SubGroupLabel>Indentação</SubGroupLabel>
            <Field
              name='indentation_question'
              component={TemplateQuestionIndentationInput}
              ratio={ratio}
              unit='id'
            />
            <Field
              name='indentation_answer'
              component={TemplateAnswerIndentationInput}
              ratio={ratio}
              unit='id'
            />
          </SubGroup>
          <SubGroup>
            <SubGroupLabel>Espaçamento do Enunciado</SubGroupLabel>
            <Field
              name='spacing_before_question'
              component={TemplateSpacingBeforeQuestionInput}
            />
            <Field
              name='spacing_after_question'
              component={TemplateSpacingAfterQuestionInput}
            />
          </SubGroup>
        </FlexGroup>
        <TemplatePreview
          values={values}
          ratio={ratio}
        />
        <SubmitButton
          disabled={!isValid}
          loading={loading}
        >
          Salvar
        </SubmitButton>
      </StyledForm>
    </Spin>
  )
}

EditTemplateForm.propTypes = {
  isValid: PropTypes.bool,
  loading: PropTypes.bool,
  values: PropTypes.object
}

const mapStateToProps = ({ templates }) => ({
  loading: templates.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    editTemplate
  }, dispatch)

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit,
    validationSchema,
    enableReinitialize: true,
    isInitialValid: true,
    mapPropsToValues
  })
)

export default enhance(EditTemplateForm)
