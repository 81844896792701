/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'

import { Icon } from 'antd'
import { Container, iconStyle, Title, Subtitle } from './styles/Message.styles'

const ProcessingSuccessfulMessage = ({
  children
}) => {
  return (
    <Container>
      <Icon
        rotate={20}
        type='file-word'
        css={iconStyle}
      />
      <Title>Pronto!</Title>
      <Subtitle>Seu arquivo foi processado com sucesso</Subtitle>
      {children}
    </Container>
  )
}

ProcessingSuccessfulMessage.propTypes = {
  children: PropTypes.node
}

export default ProcessingSuccessfulMessage
