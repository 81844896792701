import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { message } from "antd";
import { SUPPORTED_FILE_TYPES } from "../../utils/constants";
import {
  uploadExam,
  uploadRecognition,
  clearExamModule,
} from "../../store/exams/actions";

import InputHelpModal from "../modal/InputHelpModal";
import { DocumentDragger } from "./styles/Input.styles";
import { Button } from "@material-ui/core";

const DocumentDraggerInput = ({
  children,
  loading,
  uploadExam,
  uploadRecognition,
  clearExamModule,
  scholarship = undefined,
  authorization = false,
  recognition = false,
  count = 1,
}) => {
  const [helpType, setHelpType] = useState("");
  const [fs, setFs] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [heavys, setHeavys] = useState([]);

  useEffect(() => {
    console.log("Componente");
  }, []);

  const validate = (file) => {
    if (scholarship === undefined) {
      message.error("Selecione o segmento!");
      return false;
    };

    const helpType = {
      [file.size > 10485760]: "heavy",
      [!SUPPORTED_FILE_TYPES.includes(file.type)]: "default",
    }[true];
    helpType && setHelpType(helpType);
    if (helpType === "heavy") {
      const heavies = [
        ...heavys,
        file.originFileObj.name,
      ]
      setHeavys(heavies);
      console.log("THIS IS A HEAVY FILE")
    }

    return !helpType
  };

  const handleChange = ({ file, fileList }) => {
    // setFile(file)
    // window.sessionStorage.removeItem('saved_file')
    // window.sessionStorage.setItem('saved_file', file)
    clearExamModule();

    let files = fileList.map((f) => {
      if (validate(f)) {
        return f.originFileObj;
      }
      return null;
    });

    if (recognition) {
      if (files !== fs) {
        setFs(files);
        setSelectedFiles(files.filter((file) => file !== null).map((file) => file.name));
      }
    } else {
      if (files[0] !== null) {
        uploadExam(files, authorization, scholarship);
      };
    }
  };

  return (
    <Fragment>
      <DocumentDragger
        // accept={SUPPORTED_FILE_TYPES.join(',')}
        beforeUpload={() => false}
        disabled={loading}
        onChange={handleChange}
        showUploadList={false}
        fileList={[]}
        multiple={recognition}
        maxCount={count}
      >
        {children}
      </DocumentDragger>
      <InputHelpModal type={helpType} onClose={() => setHelpType("")} />
      {recognition ? (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => uploadRecognition(fs)}
          >
            Enviar Lote
          </Button>
          {selectedFiles.length > 0 && ( // Render selected filenames if there are any
            <>
              <br />
              <div>
                Arquivos Selecionados: {selectedFiles.join(", ")}
              </div>
            </>
          )}
          <br />
          {heavys.length > 0 && (
            <div>
              Arquivos que ultrapassam limite de 10MB e não serão enviados: {heavys.join(", ")}
            </div>
          )}
        </>
      ) : (
        <>
          {heavys.length > 0 &&
            <div>
              O arquivo "{heavys[0]}" ultrapassa o limite de 10MB. Por favor selecione outro.
            </div>
          }
        </>
      )}
    </Fragment>
  );
};

DocumentDraggerInput.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  // uploadDocument: PropTypes.func.isRequired,
  uploadExam: PropTypes.func.isRequired,
  uploadRecognition: PropTypes.func.isRequired,
  clearExamModule: PropTypes.func.isRequired,
  // setFile: PropTypes.func.isRequired
};

const mapStateToProps = ({ exams }) => ({
  loading: exams.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadExam,
      uploadRecognition,
      clearExamModule,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentDraggerInput);
