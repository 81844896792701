import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import InputHelpModal from '../modal/InputHelpModal'
import { Label } from './styles/Input.styles'

class ValidatedSharedInput extends Component {
  static propTypes = {
    touched: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({ uid: PropTypes.bool })
    ]),
    error: PropTypes.string,
    label: PropTypes.string,
    HelpComponent: PropTypes.func,
    initializeHelp: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.object
  }

  helpModal = React.createRef()

  componentDidUpdate (prevProps) {
    this.props.initializeHelp && this.fieldHasError() && this.helpModal.current.toggleModal()
  }

  render () {
    const {
      label,
      children,
      style
    } = this.props

    return (
      <>
        <Label style={style}>{label && `${label}:`}
          <Form.Item
            className='input'
            hasFeedback={this.fieldHasError()}
            validateStatus={this.fieldHasError() ? 'error' : ''}
            help={this.fieldHasError() ? this.fieldError() : ''}
          >
            {children}
          </Form.Item>
        </Label>
      </>
    )
  }

  fieldError = () => {
    const { error, HelpComponent } = this.props
    return HelpComponent
      ? <>{error} <InputHelpModal ref={this.helpModal}><HelpComponent /></InputHelpModal> </>
      : error
  }

  fieldHasError = () => {
    const { touched, error } = this.props
    return touched && !!error
  }
}

export default ValidatedSharedInput
