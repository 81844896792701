import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import PropTypes from "prop-types";
import { Checkbox, TextField, Tooltip } from "@material-ui/core";
import TreeItem from "../TreeItem";

function DropdownTree({
  options,
  label,
  optionsSelected,
  setOptionsSelected,
}) {

  const [searchString, setSearchString] = useState("");
  const handleSearchChange = (event) => {
    const newSearchString = event.target.value;
    setSearchString(newSearchString);
  };

  return (
    <div>
      <Autocomplete
        multiple
        options={options}
        getOptionLabel={(option) => option["label"]}
        onChange={(event, value) => setOptionsSelected(value)}
        renderOption={(option, { selected }) => {
          return (
            <TreeItem option={option} selected={selected} />
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={optionsSelected.length > 0 ? "" : label}
            id="autocomplete"
            value={searchString}
            onChange={handleSearchChange}
          />
        )}
      />
    </div>
  );
}

DropdownTree.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  optionsSelected: PropTypes.array,
  setOptionsSelected: PropTypes.func,
};

export default DropdownTree;