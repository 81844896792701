/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchOutput } from '../../store/outputs/actions'

import Output from '../output/Output'
import WarningsCheckbox from '../checkbox/WarningsCheckbox'
import { Modal, Button } from 'antd'
import { OutputModalStyle } from './styles/Modal.styles'

const OutputModal = ({
  fetchOutput,
  hideWarnings,
  loading,
  onCancel,
  output,
  visible
}) => {
  useEffect(() => {
    if (!output || !hideWarnings || output.without_correction) return
    fetchOutput(output.id)
  }, [hideWarnings, visible])

  const backButton = (
    <Button
      htmlType='button'
      onClick={onCancel}
    >
      Voltar
    </Button>
  )

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={backButton}
      css={OutputModalStyle}
      destroyOnClose
    >
      {output &&
        <Output
          loading={loading}
          urls={hideWarnings ? [output.without_correction] : output.files_url}
        >
          <WarningsCheckbox />
        </Output>
      }
    </Modal>
  )
}

OutputModal.propTypes = {
  fetchOutput: PropTypes.func.isRequired,
  hideWarnings: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  output: PropTypes.object,
  visible: PropTypes.bool.isRequired
}

const mapStateToProps = ({ outputs }) => ({
  hideWarnings: outputs.hideWarnings,
  loading: outputs.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchOutput
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OutputModal)
