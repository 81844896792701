import styled from '@emotion/styled'
import { Button } from 'antd'

export const Container = styled.div`
  width: 100%;
  display: flex;
`
export const TagPanel = styled.div`
  flex: 1;
  height: 60px;

  background: rgba(225, 225, 225, 0.4);
  border: 1px solid rgba(81, 79, 255, 1);
  box-sizing: border-box;
  border-radius: 18px;
  padding: 15px;

  .ant-tag-has-color{
    background-color: #514FFF !important;
  }
`
export const TagButton = styled(Button)`
  &.ant-btn {
    background-color: #514FFF;
    border-color: transparent;
  }
`
